
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import PriceLabel from "./../furniture/PriceLabel.vue";
import util from "@/js/util";

export default defineComponent({
  name: "FurnitureLineView",
  components: {
    PriceLabel
  },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    button2Title: {
      type: String,
      default: "",
    },
    dataResult: {
      type: Object,
      required: true,
    },
    msg: {
      type: String,
      default: "",
    },
    boxClass: {
      type: String,
      default: "",
    },
  },
  emits: ["clickEdit"],
  data: function () {
    return {
      className: "",
      contentClass: "content",
      price: 3000,
    };
  },
  watch: {
    "dataResult.is_free": function (value) {
      if (value == 1) {
        this.className = "";
      } else {
        this.className = "red";
      }
    },
  },
  created() {
    if (this.dataResult.is_free !== 1) {
      this.className = "red";
    }
    if (
      new Date(this.dataResult.kaishu_date + "T00:00") <=
      new Date(new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" }))
    ) {
      this.contentClass += " opacity";
    }
  },
  methods: {
    filterCurrency(value: string) {
      return util.getPrice(value, false) + "円（税込）";
    },
    getPaymentMethod(value: any) {
      const methods: { [key: string]: any } = {
        "1": "現金",
        "2": "カード",
        "5": "Pay支払い",
      };
      return methods[value] ? methods[value] : "";
    },
    getRequestLabel(result: any) {
      let request_list = [
        { id: "checkbox1", label: "洋服タンス", value: "3C01" },
        { id: "checkbox2", label: "和服タンス", value: "3C02" },
        { id: "checkbox3", label: "整理ダンス", value: "3C03" },
        { id: "checkbox4", label: "ベッド", value: "3C04" },
        { id: "checkbox5", label: "本棚", value: "3C05" },
        { id: "checkbox6", label: "食器棚", value: "3C06" },
        { id: "checkbox7", label: "ソファ", value: "3C07" },
        { id: "checkbox8", label: "薄型TV", value: "3C08" },
        { id: "checkbox9", label: "机", value: "3C09" },
        { id: "checkbox10", label: "上記以外の家具", value: "3C99" }
      ];
      request_list = request_list.filter(function (el: any) {
        return result.moving_furniture.indexOf(el.value) >= 0;
      });
      let items = (request_list || []).map(function (el: any) {
        return el["label"];
      });
      if (items.length > 0) {
        return items.join(", ");
      }
      return "";
    },
  },
  setup() {
    const store = useStore();
    const buttonWidth = computed(() => {
      return store.state.device == "mobile" ? "100%" : "26.55vw";
    });
    return {
      buttonWidth,
    };
  },
});
