// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/goods/goods.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".goods .banner[data-v-7aec4607]{width:100%;margin:0 auto;display:inline-block;position:relative;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.goods .banner[data-v-7aec4607]:after{padding-top:26%;display:block;content:\"\"}.goods .banner>div[data-v-7aec4607]{margin-left:50px;padding-top:30px;width:50%;text-shadow:0 0 5px #fff}.goods .banner>img[data-v-7aec4607]{width:100%}.goods .button[data-v-7aec4607]{background:#fff;padding:6rem 0 12rem;margin:6rem auto 0;text-align:center}.goods .button>div[data-v-7aec4607]{width:29.5rem;display:inline-block}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
