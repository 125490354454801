
import { defineComponent,  } from "vue";
import InquiriesDesktop from "./partials/InquiriesDesktop.vue";
import InquiriesMobile from "./partials/InquiriesMobile.vue";

export default defineComponent({
  components: { InquiriesMobile, InquiriesDesktop },
  setup() {
    console.log("setup")
  },
});
