
import DatetimeLongBox from "@/components/mypage/DatetimeLongBox.vue";
import InfoAddressBlock from "@/components/mypage/InfoAddressBlock.vue";
import TitleBlock from "@/components/mypage/TitleBlock.vue";
import util from "@/js/util";
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    DatetimeLongBox,
    TitleBlock,
    InfoAddressBlock,
  },
  props: {
    moving_info_list: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.state.mypage.topbar = "お引越し詳細";
    });
    const getDayOfWeek = util.getDayOfWeek;
    const removeZero = util.removeZero;
    return {
      getDayOfWeek,
      removeZero,
    };
  },
});
