// import { createStore } from "vuex";
import { Store } from "vuex";
import { createStore } from "vuex-extensions";

const moduleAccount = {
  namespaced: true,
  state: () => ({
    email: "",
  }),
  mutations: {
    setEmail(state: any, email: string) {
      state.email = email;
    },
  },
  actions: {
    setEmail(context: any, email: string) {
      context.commit("setEmail", email);
    },
  },
  getters: {},
};

const moduleCollection = {
  namespaced: true,
  state: () => ({
    zipcode: "",
    address_01: "",
    address_02: "",
    address_rest: "",
    address_specific_area: "",
    address_apartment_name: "",
    address_room_number: "",
    address_code: "",
    kaishu_date: "",
    kaishu_time: "",
    phone_type: 1,
    tel: "",
    collect_item: [], // check!
    cardbord_num: 0, // check!
    is_autolock: 0,
    request: [],
    payment_method: 0,

    contract_id: "",
    free_or_paid: "",
    has_paid: false,
    tel_list: [],
    address_done: false,
    datetime_done: false,
    confirm_done: false,
    itemForEdit: {},
    address_list: {},
    address_branch: {},
    request_list: [],
    validation_address: 0,
    branch: {},
    no_available_date: false,
  }),
  mutations: {
    setAddress(state: any, address: string) {
      state.address_02 = address;
    },
    setCity(state: any, city: string) {
      state.address_01 = city;
    },
    setAddressRest(state: any, address: string) {
      state.address_rest = address;
    },
    setAddressSpecificArea(state: any, address: string) {
      state.address_specific_area = address;
    },
    setAddressApartmentName(state: any, address: string) {
      state.address_apartment_name = address;
    },
    setAddressRoomNumber(state: any, address: string) {
      state.address_room_number = address;
    },
    setAddressCode(state: any, value: string) {
      state.address_code = value;
    },
    setTime(state: any, time: string) {
      state.kaishu_time = time;
    },
    setDatetime(state: any, datetime: string) {
      state.kaishu_date = datetime;
    },
    setPhonetype(state: any, phone_type: string) {
      state.phone_type = phone_type;
    },
    setPhonenumber(state: any, phone_number: string) {
      state.tel = phone_number;
    },
    setMaterialType(state: any, item: any[]) {
      state.collect_item = item;
    },
    setMaterial(state: any, item: string) {
      state.cardbord_num = item;
    },
    setLock(state: any, lock: string) {
      state.is_autolock = lock;
    },
    setPayment(state: any, item: string) {
      state.payment_method = item;
    },
    setRequest(state: any, request: string[]) {
      state.request = request;
    },
    setZipcode(state: any, zipcode: string) {
      state.zipcode = zipcode;
    },
    resetAddressBook(state: any) {
      state.zipcode = "";
      state.address_02 = "";
      state.address_01 = "";
      state.address_code = "";

      state.address_rest = "";
      state.address_specific_area = "";
      state.address_apartment_name = "";
      state.address_room_number = "";
    },
    setContractId(state: any, value: string) {
      state.contract_id = value;
    },
    setFreeOrPaid(state: any, value: string) {
      state.free_or_paid = value;
    },
    setHasPaid(state: any, value: boolean) {
      state.has_paid = value;
    },
    setTelList(state: any, value: any) {
      state.tel_list = value;
    },
    setItemForEdit(state: any, item: any) {
      state.itemForEdit = item;
    },
    setDatetimeDone(state: any, value: any) {
      state.datetime_done = value;
    },
    setAddressDone(state: any, value: any) {
      state.address_done = value;
    },
    setAddressList(state: any, value: any) {
      state.address_list = value;
    },
    setAddressBranch(state: any, value: any) {
      state.address_branch = value;
    },
    setRequestList(state: any, value: any) {
      state.request_list = value;
    },
    setValidationAddress(state: any, value: any) {
      state.validation_address = value;
    },
    setBranch(state: any, value: any) {
      state.branch = value;
    },
    setNoAvailableDate(state: any, value: boolean) {
      state.no_available_date = value;
    },
    resetCreate(state: any) {
      state.zipcode = "";
      state.address_01 = "";
      state.address_02 = "";
      state.address_code = "";
      state.address_rest = "";
      state.address_specific_area = "";
      state.address_apartment_name = "";
      state.address_room_number = "";
      state.kaishu_date = "";
      state.kaishu_time = "";
      state.phone_type = 1;
      state.tel = "";
      state.collect_item = [];
      state.cardbord_num = 0;
      state.is_autolock = 0;
      state.request = [];
      state.payment_method = 0;

      state.address_done = false;
      state.datetime_done = false;
      state.confirm_done = false;
      state.validation_address = 0;
      state.branch = {};
      state.no_available_date = false;
    },
    resetEdit(state: any) {
      state.itemForEdit = {};
    },
  },
  actions: {
    inputCity(context: any, city: string) {
      context.commit("setCity", city);
    },
    inputAddress(context: any, address: string) {
      context.commit("setAddress", address);
    },
    inputAddressRest(context: any, address: string) {
      context.commit("setAddressRest", address);
    },
    inputAddressSpecificArea(context: any, address: string) {
      context.commit("setAddressSpecificArea", address);
    },
    inputAddressApartmentName(context: any, address: string) {
      context.commit("setAddressApartmentName", address);
    },
    inputAddressRoomNumber(context: any, address: string) {
      context.commit("setAddressRoomNumber", address);
    },
    inputAddressCode(context: any, value: string) {
      context.commit("setAddressCode", value);
    },
    inputTime(context: any, time: string) {
      context.commit("setTime", time);
    },
    inputDatetime(context: any, datetime: string) {
      context.commit("setDatetime", datetime);
    },
    inputPhonetype(context: any, phone_type: string) {
      context.commit("setPhonetype", phone_type);
    },
    inputPhonenumber(context: any, phone_number: string) {
      context.commit("setPhonenumber", phone_number);
    },
    inputMaterialType(context: any, item: any[]) {
      context.commit("setMaterialType", item);
    },
    inputMaterial(context: any, item: string) {
      context.commit("setMaterial", item);
    },
    inputLock(context: any, lock: string) {
      context.commit("setLock", lock);
    },
    inputPayment(context: any, item: string) {
      context.commit("setPayment", item);
    },
    inputRequest(context: any, request: string[]) {
      context.commit(
        "setRequest",
        (request || []).filter(function (el) {
          return typeof el == "string";
        })
      );
    },
    inputZipcode(context: any, zipcode: string) {
      context.commit("setZipcode", zipcode);
    },
    resetAddressBook(context: any) {
      context.commit("resetAddressBook");
    },
    saveContractId(context: any, value: string) {
      context.commit("setContractId", value);
    },
    saveFreeOrPaid(context: any, value: string) {
      context.commit("setFreeOrPaid", value);
    },
    saveHasPaid(context: any, value: boolean) {
      context.commit("setHasPaid", value);
    },
    saveTelList(context: any, value: any) {
      context.commit("setTelList", value);
    },
    saveItemForEdit(context: any, item: any) {
      context.commit("setItemForEdit", item);
    },
    setAddressDone(context: any, value: any) {
      context.commit("setAddressDone", value);
    },
    setDatetimeDone(context: any, value: any) {
      context.commit("setDatetimeDone", value);
    },
    saveAddressList(context: any, value: any) {
      context.commit("setAddressList", value);
    },
    saveAddressBranch(context: any, value: any) {
      context.commit("setAddressBranch", value);
    },
    saveRequestList(context: any, value: any) {
      context.commit("setRequestList", value);
    },
    saveValidationAddress(context: any, value: any) {
      context.commit("setValidationAddress", value);
    },
    saveBranch(context: any, value: any) {
      context.commit("setBranch", value);
    },
    saveNoAvailableDate(context: any, value: boolean) {
      context.commit("setNoAvailableDate", value);
    },
    resetCreate(context: any) {
      context.commit("resetCreate");
    },
    resetEdit(context: any) {
      context.commit("resetEdit");
    },
  },
  getters: {},
};

const moduleDelivery = {
  namespaced: true,
  state: () => ({
    item: {},
    deliveries: {},
    contract_id: "",
    delivery_date: "",
    delivery_time: "",
    is_autolock: 0,
    placement: 0,
    request: [],
    branchInfo: {},
  }),
  mutations: {
    setItem(state: any, item: any) {
      state.item = item;
    },
    setDeliveries(state: any, items: any) {
      state.deliveries = items;
    },
    setContractId(state: any, value: string) {
      state.contract_id = value;
    },
    setTime(state: any, time: string) {
      state.delivery_time = time;
    },
    setDatetime(state: any, datetime: string) {
      state.delivery_date = datetime;
    },
    setLock(state: any, lock: number) {
      state.is_autolock = lock;
    },
    setPlacement(state: any, value: number) {
      state.placement = value;
    },
    setRequest(state: any, value: string[]) {
      state.request = value;
    },
    setBranchInfo(state: any, items: any) {
      state.branchInfo = items;
    },
    reset(state: any) {
      state.item = {};
      state.delivery_date = "";
      state.delivery_time = "";
      state.is_autolock = 0;
      state.placement = 0;
      state.request = [];
      state.branchInfo = {};
    },
  },
  actions: {
    selectItem(context: any, item: any) {
      context.commit("setItem", item);
    },
    setDeliveries(context: any, result: any) {
      context.commit("setDeliveries", result);
    },
    saveContractId(context: any, value: string) {
      context.commit("setContractId", value);
    },
    inputTime(context: any, time: string) {
      context.commit("setTime", time);
    },
    inputDatetime(context: any, datetime: string) {
      context.commit("setDatetime", datetime);
    },
    inputLock(context: any, value: number) {
      context.commit("setLock", value);
    },
    inputPlacement(context: any, value: number) {
      context.commit("setPlacement", value);
    },
    inputRequest(context: any, value: string[]) {
      context.commit("setRequest", value);
    },
    setBranchInfo(context: any, item: any) {
      context.commit("setBranchInfo", item);
    },
    reset(context: any) {
      context.commit("reset");
    },
  },
  getters: {},
};

const moduleFurniture = {
  namespaced: true,
  state: () => ({
    moving_furniture: [],
    other_furniture: "",
    datetime: "",
    phone_type: "",
    phone_number: "",
    comment: "",
    zipcode: "",
    address_01: "",
    address_02: "",
    address_rest: "",
    address_specific_area: "",
    address_apartment_name: "",
    address_room_number: "",
    address_code: "",

    contract_id: "",
    is_exist: undefined,
    address_done: false,
    datetime_done: false,
    confirm_done: false,
    address_list: {},
    address_branch: {},
    tel_list: [],
    validation_address: 0,
    branch: {},
    no_available_date: false,
  }),
  mutations: {
    setAddress(state: any, address: string) {
      state.address_02 = address;
    },
    setCity(state: any, city: string) {
      state.address_01 = city;
    },
    setAddressRest(state: any, address: string) {
      state.address_rest = address;
    },
    setAddressSpecificArea(state: any, address: string) {
      state.address_specific_area = address;
    },
    setAddressApartmentName(state: any, address: string) {
      state.address_apartment_name = address;
    },
    setAddressRoomNumber(state: any, address: string) {
      state.address_room_number = address;
    },
    setAddressCode(state: any, value: string) {
      state.address_code = value;
    },
    setGoods(state: any, items: string[]) {
      state.moving_furniture = items;
    },
    setOther(state: any, item: string) {
      state.other_furniture = item;
    },
    setDatetime(state: any, datetime: string) {
      state.datetime = datetime;
    },
    setPhonetype(state: any, phone_type: string) {
      state.phone_type = phone_type;
    },
    setPhonenumber(state: any, phone_number: string) {
      state.phone_number = phone_number;
    },
    setComment(state: any, comment: string) {
      state.comment = comment;
    },
    setZipcode(state: any, zipcode: string) {
      state.zipcode = zipcode;
    },
    resetAddressBook(state: any) {
      state.zipcode = "";
      state.address_02 = "";
      state.address_01 = "";
      state.address_code = "";

      state.address_rest = "";
      state.address_specific_area = "";
      state.address_apartment_name = "";
      state.address_room_number = "";
      state.validation_address = 0;
      state.address_done = false;
      state.datetime_done = false;

      state.branch = {};
      state.no_available_date = false;
    },
    setValidationAddress(state: any, value: any) {
      state.validation_address = value;
    },
    setContractId(state: any, value: string) {
      state.contract_id = value;
    },
    setIsExist(state: any, value: boolean) {
      state.is_exist = value;
    },
    setBranch(state: any, value: any) {
      state.branch = value;
    },
    setAddressDone(state: any, value: any) {
      state.address_done = value;
    },
    setDatetimeDone(state: any, value: any) {
      state.datetime_done = value;
    },
    setAddressList(state: any, value: any) {
      state.address_list = value;
    },
    setAddressBranch(state: any, value: any) {
      state.address_branch = value;
    },
    setTelList(state: any, value: any) {
      state.tel_list = value;
    },
    setNoAvailableDate(state: any, value: boolean) {
      state.no_available_date = value;
    },
    resetCreate(state: any) {
      state.zipcode = "";
      state.address_02 = "";
      state.address_01 = "";
      state.address_rest = "";
      state.address_specific_area = "";
      state.address_apartment_name = "";
      state.address_room_number = "";
      state.address_code = "";
      state.moving_furniture = [];
      state.other_furniture = "";
      state.datetime = "";
      state.phone_type = "";
      state.phone_number = "";
      state.comment = "";
      state.phone_number = "";
      state.address_done = false;
      state.datetime_done = false;
      state.confirm_done = false;
      state.branch = {};
      state.no_available_date = false;
      state.tel_list = [];
      state.address_list = {};
    },
  },
  actions: {
    inputCity(context: any, city: string) {
      context.commit("setCity", city);
    },
    inputAddress(context: any, address: string) {
      context.commit("setAddress", address);
    },
    inputAddressRest(context: any, address: string) {
      context.commit("setAddressRest", address);
    },
    inputAddressSpecificArea(context: any, address: string) {
      context.commit("setAddressSpecificArea", address);
    },
    inputAddressApartmentName(context: any, address: string) {
      context.commit("setAddressApartmentName", address);
    },
    inputAddressRoomNumber(context: any, address: string) {
      context.commit("setAddressRoomNumber", address);
    },
    inputAddressCode(context: any, value: string) {
      context.commit("setAddressCode", value);
    },
    inputGoods(context: any, items: string[]) {
      context.commit("setGoods", items);
    },
    inputOther(context: any, item: string) {
      context.commit("setOther", item);
    },
    inputDatetime(context: any, datetime: string) {
      context.commit("setDatetime", datetime);
    },
    inputPhonetype(context: any, phone_type: string) {
      context.commit("setPhonetype", phone_type);
    },
    inputPhonenumber(context: any, phone_number: string) {
      context.commit("setPhonenumber", phone_number);
    },
    inputComment(context: any, comment: string) {
      context.commit("setComment", comment);
    },
    inputZipcode(context: any, zipcode: string) {
      context.commit("setZipcode", zipcode);
    },
    resetAddressBook(context: any) {
      context.commit("resetAddressBook");
    },
    saveContractId(context: any, value: string) {
      context.commit("setContractId", value);
    },
    saveIsExist(context: any, value: boolean) {
      context.commit("setIsExist", value);
    },
    saveValidationAddress(context: any, value: any) {
      context.commit("setValidationAddress", value);
    },
    saveBranch(context: any, value: any) {
      context.commit("setBranch", value);
    },
    setAddressDone(context: any, value: any) {
      context.commit("setAddressDone", value);
    },
    setDatetimeDone(context: any, value: any) {
      context.commit("setDatetimeDone", value);
    },
    saveAddressList(context: any, value: any) {
      context.commit("setAddressList", value);
    },
    saveAddressBranch(context: any, value: any) {
      context.commit("setAddressBranch", value);
    },
    saveTelList(context: any, value: any) {
      context.commit("setTelList", value);
    },
    saveNoAvailableDate(context: any, value: boolean) {
      context.commit("setNoAvailableDate", value);
    },
    resetCreate(context: any) {
      context.commit("resetCreate");
    },
  },
  getters: {},
};

const moduleGoods = {
  namespaced: true,
  state: () => ({
    item: {},
    order_list: [],
    size: 1,
    sheet: 1,
    quantity: 1,

    recommendedGoods: [],
  }),
  mutations: {
    setItem(state: any, value: object[]) {
      state.item = value;
    },
    setOrderList(state: any, value: object[]) {
      state.order_list = value;
    },
    setSize(state: any, value: string) {
      state.size = value;
    },
    setSheet(state: any, value: string) {
      state.sheet = value;
    },
    setQuantity(state: any, value: number) {
      state.quantity = value;
    },
    resetGoods(state: any) {
      state.size = 1;
      state.sheet = 1;
      state.quantity = 1;
      state.item = {};
    },
    setRecommendedGoods(state: any, value: any) {
      state.recommendedGoods = value;
    },
  },
  actions: {
    setItem(context: any, value: object[]) {
      context.commit("setItem", value);
    },
    setOrderList(context: any, value: object[]) {
      context.commit("setOrderList", value);
    },
    resetOrderList(context: any) {
      context.commit("setOrderList", []);
    },
    setSize(context: any, value: string) {
      context.commit("setSize", value);
    },
    setSheet(context: any, value: string) {
      context.commit("setSheet", value);
    },
    setQuantity(context: any, value: number) {
      context.commit("setQuantity", value);
    },
    resetGoods(context: any) {
      context.commit("resetGoods");
    },
    setRecommendedGoods(context: any, value: any) {
      context.commit("setRecommendedGoods", value);
    },
  },
  getters: {},
};

const moduleConfirmation = {
  namespaced: true,
  state: () => ({
    confirmation: [],
    options: [],
    otherItems: [],
    progress: [],
    dangerous: [],
    valuables: [],
    price: [],

    // Contract detail
    // {
    //   "status": "success",
    //   "result": {
    //     "ukt_no": "1234123456",
    //     "name": "田中 太郎",
    //     "tel_list": [
    //       "090-1234-1234",
    //       "090-1234-0001"
    //     ],
    //     "total_contract_amount": 10000,
    //     "moving_info_list": [
    //       {
    //         "item_no": "1",
    //         "old_address_info_list": [
    //           {
    //             "is_main": "0:not main, 1:main",
    //             "zipcode": "1000005",
    //             "address": "東京都千代田区丸の内１丁目",
    //             "packing_date": "2021-01-01",
    //             "packing_time": "9:00頃",
    //             "moving_date": "2021-01-01",
    //             "moving_time": "9:00頃",
    //             "visiting_time": "8:00-10:00"
    //           }
    //         ],
    //         "new_address_info_list": [
    //           {
    //             "is_main": "0:not main, 1:main",
    //             "zipcode": "1000005",
    //             "address": "東京都千代田区丸の内１丁目",
    //             "delivery_date": "2021-01-01",
    //             "delivery_time": "9:00頃",
    //             "unpacking_date": "2021-01-01",
    //             "unpacking_time": "9:00頃",
    //             "visiting_time": "8:00-10:00"
    //           }
    //         ]
    //       }
    //     ],
    //     "option_category_list": [
    //       {
    //         "service_kbn": "11",
    //         "category_name": "電気工事",
    //         "option_list": [
    //           {
    //             "option_name": "Sエアコン取付",
    //             "option_working_date": "2021-01-01",
    //             "option_working_time": "9:00頃",
    //             "option_num": 1,
    //             "option_unit": "個",
    //             "option_working_place": "現住所にて",
    //             "option_msg": "一行メッセージ"
    //           }
    //         ]
    //       }
    //     ],
    //     "amount_already_deposited": 10000,
    //     "amount_paid_on_the_day": 10000,
    //     "payment_method": "引越当日現金支払",
    //     "material_delivery_date": "2022-01-01",
    //     "material_delivery_time": "9:00頃",
    //     "material_collection_date": "2022-01-01",
    //     "material_collection_time": "9:00頃",
    //     "moving_furniture_date": "2022-01-01",
    //     "is_rakumo": "0:not rakumo, 1:rakumo",
    //     "status_code": "R:決定|H:保留|C:キャンセル",
    //     "sales_record_date": "2022-01-01",
    //     "packing_branch": "",
    //     "departure_branch": "",
    //     "arrival_branch": "",
    //     "other_matters": "string",
    //     "progress_of_outsourcing_operations": "string",
    //     "handling_of_hazardous_materials": "string",
    //     "management_of_valuables": "string",
    //     "cancellation_and_postponement_fees": "string"
    //   }
    // }
    contractDetail: {},

    // get some data from contractDetail
    // {
    //   moving_date: "", // 2021年11月16日（●）
    //   visiting_time: "", // 08:00 - 12:00
    //   zipcode: "", // 560-0054
    //   address: "", // 大阪府豊中市桜の町６−９−３０ル・ルペール５０７
    //   delivery_date: "", // 2021年11月16日（●）
    //   visiting_time_2: "", // 08:00 - 12:00
    //   zipcode_2: "", // 560-0054
    //   address_2: "", // 大阪府豊中市桜の町６−９−３０ル・ルペール５０７
    // }
    currentInfo: {},

    contract_id: "",
    arrival_departure_val: "",
    is_other_company_delivery: "",

    is_rakumo: 0,
  }),
  mutations: {
    setConfirmation(state: any, result: string[]) {
      state.confirmation = result;
    },
    setOptions(state: any, result: string[]) {
      state.options = result;
    },
    setOtherItems(state: any, result: string[]) {
      state.otherItems = result;
    },
    setProgress(state: any, result: string[]) {
      state.progress = result;
    },
    setDangerous(state: any, result: string[]) {
      state.dangerous = result;
    },
    setValuables(state: any, result: string[]) {
      state.valuables = result;
    },
    setPrice(state: any, result: string[]) {
      state.price = result;
    },
    setContractDetail(state: any, value: any) {
      state.contractDetail = value;
    },
    setCurrentInfo(state: any, value: any) {
      state.currentInfo = value;
    },
    setContractID(state: any, value: string) {
      state.contract_id = value;
    },
    setArrivalDepartureVal(state: any, value: string) {
      state.arrival_departure_val = value;
    },
    setIsOtherCompanyDelivery(state: any, value: string) {
      state.is_other_company_delivery = value;
    },
    setIsRakumo(state: any, value: number) {
      state.is_rakumo = value;
    },
  },
  actions: {
    setConfirmation(context: any, result: string[]) {
      context.commit("setConfirmation", result);
    },
    setOptions(context: any, result: string[]) {
      context.commit("setOptions", result);
    },
    setOtherItems(context: any, result: string[]) {
      context.commit("setOtherItems", result);
    },
    setProgress(context: any, result: string[]) {
      context.commit("setProgress", result);
    },
    setDangerous(context: any, result: string[]) {
      context.commit("setDangerous", result);
    },
    setValuables(context: any, result: string[]) {
      context.commit("setValuables", result);
    },
    setPrice(context: any, result: string[]) {
      context.commit("setPrice", result);
    },
    updateContractDetail(context: any, value: any) {
      context.commit("setContractDetail", value);
    },
    updateCurrentInfo(context: any, value: any) {
      context.commit("setCurrentInfo", value);
    },
    updateContractID(context: any, value: string) {
      context.commit("setContractID", value);
    },
    updateArrivalDepartureVal(context: any, value: string) {
      context.commit("setArrivalDepartureVal", value);
    },
    updateIsOtherCompanyDelivery(context: any, value: string) {
      context.commit("setIsOtherCompanyDelivery", value);
    },
    updateIsRakumo(context: any, value: number) {
      context.commit("setIsRakumo", value);
    },
  },
  getters: {},
};

const moduleMypage = {
  namespaced: true,
  state: () => ({
    info: [],
    topbar: "",

    // 契約情報一覧
    // {
    //   "contract_id": "93e9b6893d0d4dd4a01c14234e3e8c27",
    //   "ukt_no": "1234-123456",
    //   "status": "0:確認中, 1:発行済み",
    //   "moving_date": "2021-12-27",
    //   "is_rakumo": "0:not rakumo, 1:rakumo"
    // }
    allContracts: [],

    // 契約情報詳細
    // {
    //   "ukt_no": "1234123456",
    //   "name": "田中 太郎",
    //   "tel_list": ["090-1234-1234", "090-1234-0001"],
    //   "total_contract_amount": "10000",
    //   "total_amount_of_goods": "10000",
    //   "contract_payment_method": "現金支払い",
    //   "moving_info_list": [   <- 引越し作業日ごと
    //     {
    //       "old_address_info_list": [
    //         {
    //           "is_main": "0:not main, 1:main",
    //           "zipcode": "100-0005",
    //           "address": "東京都千代田区丸の内１丁目",
    //           "packing_date": "2021-01-01",
    //           "packing_time": "9:00頃",
    //           "moving_date": "2021-01-01",
    //           "moving_time": "9:00頃",
    //           "visiting_time": "8:00-10:00"
    //         }
    //       ],
    //       "new_address_info_list": [
    //         {
    //           "is_main": "0:not main, 1:main",
    //           "zipcode": "100-0005",
    //           "address": "東京都千代田区丸の内１丁目",
    //           "delivery_date": "2021-01-01",
    //           "delivery_time": "9:00頃",
    //           "unpacking_date": "2021-01-01",
    //           "unpacking_time": "9:00頃",
    //           "visiting_time": "8:00-10:00"
    //         }
    //       ]
    //     }
    //   ],
    //   "option_category_list": [
    //     {
    //       "service_kbn": 11,
    //       "category_name": "電気工事",
    //       "option_list": [
    //         {
    //           "is_main": "0:not main, 1:main",
    //           "option_name": "Sエアコン取付",
    //           "option_working_date": "2021-01-01",
    //           "option_working_time": "9:00頃"
    //         }
    //       ]
    //     }
    //   ],
    //   "other_matters": "string",
    //   "progress_of_outsourcing_operations": "string",
    //   "handling_of_hazardous_materials": "string",
    //   "management_of_valuables": "string",
    //   "amount_already_deposited": "10000",
    //   "amount_paid_on_the_day": "10000",
    //   "payment_method": "引越当日現金支払",
    //   "cancellation_and_postponement_fees": "string",
    //   "material_delivery_date": "2022-01-01",
    //   "material_collection_date": "2022-01-01",
    //   "moving_furniture_date": "2022-01-01",
    //   "is_rakumo": "0:not rakumo, 1:rakumo"
    // }
    contractDetail: {},

    // currentInfo: {} -> get some daya from contractDetail
    // {
    // month: "",
    // day: "",
    // week: "",
    // time: "",
    // price: "",
    // paymentMethod: "",
    // deliveryYear: "",
    // deliveryMonth: "",
    // deliveryDay: "",
    // deliveryWeek: "",
    // furnitureDone: false,
    // }
    currentInfo: {},

    // moving is already done or not
    done: false,

    hasFailureContract: false,
  }),
  mutations: {
    setInfo(state: any, value: Array<any>) {
      state.info = value;
    },
    setContracts(state: any, value: any) {
      state.allContracts = value;
    },
    setContractDetail(state: any, value: any) {
      state.contractDetail = value;
    },
    setCurrentInfo(state: any, value: any) {
      state.currentInfo = value;
    },
    setDone(state: any, value: boolean) {
      state.done = value;
    },
    setHasFailureContract(state: any, value: boolean) {
      state.hasFailureContract = value;
    },
  },
  actions: {
    changeInfo(context: any, value: Array<any>) {
      context.commit("setInfo", value);
    },
    updateContracts(context: any, value: any) {
      context.commit("setContracts", value);
    },
    updateContractDetail(context: any, value: any) {
      context.commit("setContractDetail", value);
    },
    updateCurrentInfo(context: any, value: any) {
      context.commit("setCurrentInfo", value);
    },
    updateDone(context: any, value: boolean) {
      context.commit("setDone", value);
    },
    updateHasFailureContract(context: any, value: boolean) {
      context.commit("setHasFailureContract", value);
    },
  },
  getters: {},
};

const moduleAlert = {
  namespaced: true,
  state: () => ({
    visible: false,
    confirm_button: "",
    cancel_button: "",
    callback: () => undefined,
    msg: "",
    title: "",
    showArrow: false,
  }),
  mutations: {
    setVisiable(state: any, value: any) {
      state.visible = value;
    },
    setInitAlert(state: any, value: any) {
      state.confirm_button = value.confirm_button;
      state.cancel_button = value.cancel_button;
      state.msg = value.msg;
      state.callback = value.callback;
      state.title = value.title;
      state.showArrow = value.showArrow;
    },
    resetAlert(state: any) {
      state.visible = false;
      state.confirm_button = "";
      state.cancel_button = "";
      state.msg = "";
      state.title = "";
      state.showArrow = false;
      state.callback = () => undefined;
    },
  },
  actions: {
    setVisiable(context: any, value: any) {
      context.commit("setVisiable", value);
    },
    setInitAlert(context: any, value: any) {
      context.commit("setInitAlert", value);
    },
    resetAlert(context: any) {
      context.commit("resetAlert");
    },
  },
  getters: {},
};

const moduleInquiries = {
  namespaced: true,
  state: () => ({
    inquiry_type: "",
    inquiry_text: "",
    inquiry_tel: "",
    inquiry_email: "",
  }),
  mutations: {
    setInquiry(state: any, value: any) {
      state.inquiry_type = value.inquiry_type;
      state.inquiry_text = value.inquiry_text;
      state.inquiry_tel = value.inquiry_tel;
      state.inquiry_email = value.inquiry_email;
    },
    resetInquiry(state: any) {
      state.inquiry_type = "";
      state.inquiry_text = "";
      state.inquiry_tel = "";
      state.inquiry_email = "";
    },
  },
  actions: {
    setInquiry(context: any, value: any) {
      context.commit("setInquiry", value);
    },
    resetInquiry(context: any) {
      context.commit("resetInquiry");
    },
  },
  getters: {},
};

export default createStore(Store, {
  modules: {
    account: moduleAccount,
    collection: moduleCollection,
    delivery: moduleDelivery,
    furniture: moduleFurniture,
    goods: moduleGoods,
    mypage: moduleMypage,
    confirmation: moduleConfirmation,
    alert: moduleAlert,
    inquiry: moduleInquiries,
  },
  state: () => ({
    device: "",
    jwt: "",
    loading: false,
    allNews: [],
    errMsgFromServer: "",
  }),
  mutations: {
    setDevice(state: any, value: string) {
      state.device = value;
    },
    setJwt(state: any, value: string) {
      state.jwt = value;
    },
    setLoading(state: any, value: boolean) {
      state.loading = value;
    },
    setAllNews(state: any, value: Array<any>) {
      state.allNews = value;
    },
    setErrMsg(state: any, value: string) {
      state.errMsgFromServer = value;
    },
  },
  actions: {
    initDevice(context: any, value: string) {
      context.commit("setDevice", value);
    },
    saveJwt(context: any, value: string) {
      context.commit("setJwt", value);
    },
    startLoading(context: any, value: boolean) {
      context.commit("setLoading", value);
    },
    saveNews(context: any, value: Array<any>) {
      context.commit("setAllNews", value);
    },
    showErrMsg(context: any, value: string) {
      context.commit("setErrMsg", value);
      window.scrollTo(0, 0);
    },
    resetErrMsg(context: any) {
      context.commit("setErrMsg", "");
    },
  },
});
