// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/flickity/dist/flickity.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".flickity .carousel[data-v-e46d527a]{background:#eee;margin-bottom:40px}.flickity[data-v-e46d527a] .carousel-cell{padding:1rem;margin:0 1rem;background-size:cover;color:#fff}.flickity[data-v-e46d527a] .carousel-cell>div h3{font-size:1.6rem;margin-bottom:1rem}.flickity[data-v-e46d527a] .carousel-cell>div p{font-size:1.1rem}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
