import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectDatetimeMobile = _resolveComponent("SelectDatetimeMobile")!
  const _component_SelectDatetimeDesktop = _resolveComponent("SelectDatetimeDesktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_SelectDatetimeMobile, {
          key: 0,
          availableDate: _ctx.availableDate,
          onClick: _ctx.goNext
        }, null, 8, ["availableDate", "onClick"]))
      : (_openBlock(), _createBlock(_component_SelectDatetimeDesktop, {
          key: 1,
          availableDate: _ctx.availableDate,
          onClick: _ctx.goNext
        }, null, 8, ["availableDate", "onClick"]))
  ]))
}