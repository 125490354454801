
import useRouteTo from "../../js/useRouteTo";
import { defineComponent, onMounted, ref } from "vue";
import apis from "@/js/apis";

export default defineComponent({
  setup() {
    const { routeTo } = useRouteTo();
    const email = ref("");
    onMounted(async () => {
      const userInfo = await apis.getUserInfo();
      email.value = userInfo.email;
    });
    return {
      routeTo,
      email,
    };
  },
});
