
import CollectionView from "@/components/collection/CollectionView.vue";
import CollectionDesktopView from "@/components/collection/desktop/CollectionView.vue";
import PaidCollectionView from "@/components/collection/PaidCollectionView.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { defineComponent, onMounted, ref } from "vue";
import useRouteTo from "../../js/useRouteTo";
import { useStore } from "vuex";
import useCollectionInit from "@/js/useCollectionInit";
import { useRoute } from "vue-router";
import PaidCollectionDesktopView from "@/components/collection/desktop/PaidCollectionView.vue";

export default defineComponent({
  components: {
    CollectionView,
    CollectionDesktopView,
    Logo,
    Footer,
    PaidCollectionView,
    PaidCollectionDesktopView,
  },
  setup() {
    const { routeTo } = useRouteTo();
    const route = useRoute();
    const store = useStore();
    const free_or_paid = ref("");
    const submit = () => {
      store.dispatch("collection/resetCreate");
      store.dispatch("collection/resetEdit");
      store.dispatch("collection/saveAddressList", []);
      routeTo("BookingCollection");
    };
    onMounted(async () => {
      // check if info is already in store
      free_or_paid.value = store.state.collection.free_or_paid;
      if (!free_or_paid.value) {
        await useCollectionInit();
        free_or_paid.value = store.state.collection.free_or_paid;
      }
    });
    return {
      free_or_paid,
      submit,
    };
  },
});
