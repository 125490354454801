interface errorInterface {
  [key: string]: string;
}

const errors = {
  errorMsgs: {
    "429": "時間をおいてから再度実行してください",
    "invalid.token": "トークンが無効です",
    "token.expired": "トークンの有効期限が切れています",
    "invalid.file_size": "ファイルのサイズが大きすぎます",
    "invalid.file_type": "この種類のファイルは許可されていません",
    "auth.incorrect.email_or_password":
      "メールアドレスまたはパスワードが違います",
    "auth.inactive.user": "このアカウントは有効ではありません",
    "auth.invalid.token": "URLが無効です",
    "auth.disallow.many_forgot_password_email":
      "時間をおいてから再度実行してください",
    "ukt_no.is.checking": "この契約情報は現在確認中です",
    "ukt_no.is.linked": "この契約情報はすでに追加済みです",
    "ukt_no.not.exist": "入力された内容に誤りがございます",
    "ukt_no.is.locked":
      "これ以上このお客様番号での申請はできません<br>お電話にてお問い合わせください",
    "ukt_no.is.held_or_canceled":
      "この契約情報は保留中またはキャンセルされました",
    "ukt_no.is.deleted": "削除したお客様番号は追加できません",
    "ukt_no.max.request": "1日の上限数を超えたためこれ以上は受付できません",
    "value_error.email": "メールアドレスを正しい形式で入力してください",
    "user.duplicate.username": "このメールアドレスはすでに登録されています",
    "user.invalid.status": "このアカウントは有効ではありません",
    "user.invalid.password_min_length":
      "パスワードを8文字以上で入力してください",
    "user.invalid.password_max_length":
      "パスワードを64文字以下で入力してください",
    "user.invalid.password_number_required":
      "パスワードに数字を１つ以上含めてください",
    "user.invalid.password_upper_case_letter_required":
      "パスワードに大文字を１つ以上含めてください",
    "user.invalid.password_lower_case_letter_required":
      "パスワードに小文字を１つ以上含めてください",
    "user.not.active": "このアカウントは有効ではありません",
    "user.wrong.password": "現在のパスワードが間違っています",
    "user.same.email": "新しいメールアドレスを入力してください",
    "delivery.is.processing": "現在変更手続き中です",
    "delivery.invalid.moving_date": "登録されている住所IDに問題があります",
    "delivery.not.found": "配達データが見つかりません",
    "delivery.completed.date": "この配達は既に終了しています",
    "delivery.over.15pm": "この配達は変更できません",
    "delivery.after.moving.date": "この配達は既に終了しています",
    "delivery_date.ekoraku.is.past.date":
      "申し込み済みの日時より前の日付を選択することはできません",
    "available_date.not.found": "当システムでご予約可能な日付がございません",
    "available_date.is.filled": "入力した利用可能日はすでに埋まっています",
    "available_date.is.past.date": "過去の日付は選択できません",
    "collection.not.found": "回収データが見つかりません",
    "collection.completed.date": "この回収は既に終了しています",
    "collection.over.15pm": "この回収は変更できません",
    "collection.after.93.days":
      "お引越し日から３ヶ月が経過しましたので、資材回収をご希望の場合はお手数ではございますが、担当支店までご連絡お願いいたします。",
    "target.is.fc": "担当支店までお問い合わせ下さい",
    "kaishu_available.not.allowed": "担当支店までお問い合わせ下さい",
    "zip_code.not.found": "郵便番号が見つかりません",
    "zip_code.address_code.invalid": "担当支店情報が見つかりません",
    "furniture.already.accepted": "既に家具移動サービスが申し込み済みです",
    "goods_order.has.deleted_product": "この商品は現在取り扱っておりません",
    "goods_order.has.parent_product": "この商品は現在取り扱っておりません",
    "goods_order.has.draft_product": "この商品は現在取り扱っておりません",
    "goods_order.has.preview_product": "この商品は現在取り扱っておりません",
    "goods.over.max_contents": "合計で99個までしか予約できません",
    "mypocket.download.failed": "ダウンロードに失敗しました",
    "mypocket.invalid.download_term": "ダウンロード期限が過ぎています",
    "infomail.download.failed": "ダウンロードに失敗しました",
    "infomail.invalid.download_term": "ダウンロード期限が過ぎています",
    "db.duplicate_key.error": "少し時間をおいてから再度実行してください",
  } as errorInterface,

  errorPages: {
    "404": "ページが存在しません",
    "permission.denied": "アクセス許可がありません",
    "unauthorized.ip_address": "このIPアドレスは許可されていません",
    "art.not.found": "ページが存在しません",
    "contract.not.found": "契約情報が存在しません",
    "goods.not.found": "商品が存在しません",
  } as errorInterface,

  errorPagesWithMsg: {
    "500": "サーバーでエラーが発生しました",
    "db.unexpected.error": "サーバーでエラーが発生しました",
    "art.commnunication.error": "サーバーとの接続に失敗しました",
    "art.timeout.error": "サーバーがタイムアウトしました",
    "art.error": "サーバーでエラーが発生しました",
    "ocs.server.error": "サーバーでエラーが発生しました",
  } as errorInterface,
};
export default errors;
