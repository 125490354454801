
import { defineComponent } from "vue";
import CancelButton from "./../collection/CancelButton.vue";

export default defineComponent({
  name: "ContactSuccess",
  components: {
    // CancelButton
  },
  emits: ["click"],
});
