import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f3d3002"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clearfix full-height" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "form-pc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_text_block = _resolveComponent("text-block")!
  const _component_email_and_password = _resolveComponent("email-and-password")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_label_and_input = _resolveComponent("label-and-input")!
  const _component_input_block = _resolveComponent("input-block")!
  const _component_container_block = _resolveComponent("container-block")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Logo, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$store.state.device === 'mobile')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.status === 'success')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_text_block, {
                    title: "パスワード変更",
                    text: "新しいパスワードをご入力ください。"
                  }),
                  _createVNode(_component_email_and_password, {
                    label1: "新しいパスワード",
                    type1: "password",
                    placeholder1: "小文字、大文字、数字を含む8文字以上",
                    onInput1: _cache[0] || (_cache[0] = ($event: any) => (_ctx.password = $event)),
                    errMsg1: _ctx.errMsg1
                  }, null, 8, ["errMsg1"]),
                  _createVNode(_component_base_button, {
                    buttonText: "送信",
                    onClick: _ctx.submit
                  }, null, 8, ["onClick"])
                ]))
              : (_ctx.status === 'failure')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_text_block, {
                      title: "URLが無効です。",
                      text: "もう一度やり直してください。"
                    }),
                    _createVNode(_component_base_button, {
                      buttonText: "パスワードをお忘れの方へ",
                      onClick: _ctx.forgetpassword
                    }, null, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.status === 'success')
              ? (_openBlock(), _createBlock(_component_container_block, {
                  key: 0,
                  title: "パスワード変更",
                  text: "新しいパスワードをご入力ください。"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_input_block, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_label_and_input, {
                            label: "新しいパスワード",
                            type: "password",
                            placeholder: "小文字、大文字、数字を含む8文字以上",
                            margin: "0 0 5.4rem",
                            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.password = $event)),
                            errMsg: _ctx.errMsg1
                          }, null, 8, ["errMsg"]),
                          _createVNode(_component_base_button, {
                            buttonText: "送信",
                            width: "34rem",
                            onClick: _ctx.submit
                          }, null, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }))
              : (_ctx.status === 'failure')
                ? (_openBlock(), _createBlock(_component_container_block, {
                    key: 1,
                    title: "",
                    text: "URLが無効です。<br>もう一度やり直してください。",
                    buttonText: "パスワードをお忘れの方へ",
                    width: "26.55vw",
                    onClick: _ctx.forgetpassword
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
          ]))
    ]),
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Footer, {
          key: 1,
          className: "mt"
        }))
      : _createCommentVNode("", true)
  ]))
}