import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2241c542"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_symbol_mark = _resolveComponent("symbol-mark")!
  const _component_horizontal_line = _resolveComponent("horizontal-line")!

  return (_openBlock(), _createElementBlock("div", {
    class: "box-container bound-no-gray",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_symbol_mark, { mark: _ctx.mark }, null, 8, ["mark"]),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_horizontal_line, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.text,
            class: _normalizeClass({ text: !_ctx.headline, headline: _ctx.headline })
          }, null, 10, _hoisted_4))
        : _createCommentVNode("", true),
      (!_ctx.noButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickDetail', $event)))
          }, _toDisplayString(_ctx.buttonText), 1))
        : _createCommentVNode("", true)
    ])
  ], 4))
}