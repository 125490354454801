
import useCheckStoreForConfirmation from "@/js/useCheckStoreForConfirmation";
import util from "@/js/util";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

import ContactDetailDesktop from "./partials/ContactDetailDesktop.vue";
import ContactDetailMobile from "./partials/ContactDetailMobile.vue";

export default defineComponent({
  components: { ContactDetailMobile, ContactDetailDesktop },
  setup() {
    const store = useStore();
    const currentInfo = ref({
      ukt_no_with_hyphen: "",
      tel_main: "",
      moving_date: "", // 2021年11月16日（●）
      visiting_time: "", // 08:00 - 12:00
      zipcode: "", // 560-0054
      address: "", // 大阪府豊中市桜の町６−９−３０ル・ルペール５０７
      delivery_date: "", // 2021年11月16日（●）
      visiting_time_2: "", // 08:00 - 12:00
      zipcode_2: "", // 560-0054
      address_2: "", // 大阪府豊中市桜の町６−９−３０ル・ルペール５０７
      isTrunkroom: false,
      packing_list: [] as any,
      bf_packing_list: [] as any,
    });
    const contractDetail = ref({} as any);

    onMounted(async () => {
      const res = await useCheckStoreForConfirmation();
      if (res == "ok") {
        const detail = store.state.confirmation.contractDetail;

        // check if is_rakumo and save in store
        store.dispatch("confirmation/updateIsRakumo", detail.is_rakumo);

        // get main tel
        currentInfo.value.tel_main = detail.tel_list[0];

        currentInfo.value.ukt_no_with_hyphen =
          detail.ukt_no.slice(0, 4) + "-" + detail.ukt_no.slice(4);

        // check if trunkroom or not
        for (let option of detail.option_category_list) {
          if (option.service_kbn == "11") {
            currentInfo.value.isTrunkroom = true;
            console.log("yes");
            break;
          }
        }

        // get main old address info from moving_info_list
        // for (let moving_info of detail.moving_info_list) {
        //   for (let info of moving_info.old_address_info_list) {
        //     if (info.is_main) {
        //       currentInfo.value.moving_date = util.getJapaneseDate(
        //         info.moving_date
        //       );
        //       currentInfo.value.visiting_time = info.visiting_time;
        //       currentInfo.value.zipcode = info.zipcode;
        //       currentInfo.value.address = info.address;
        //       break;
        //     }
        //   }
        // }
        // get main new address info from moving_info_list
        // for (let moving_info of detail.moving_info_list) {
        //   for (let info of moving_info.new_address_info_list) {
        //     if (info.is_main) {
        //       currentInfo.value.delivery_date = util.getJapaneseDate(
        //         info.delivery_date
        //       );
        //       currentInfo.value.visiting_time_2 = info.visiting_time;
        //       currentInfo.value.zipcode_2 = info.zipcode;
        //       currentInfo.value.address_2 = info.address;
        //       break;
        //     }
        //   }
        // }

        // get packing info
        for (let moving_info of detail.moving_info_list) {
          for (let info of moving_info.old_address_info_list) {
            if (
              info.packing_date &&
              (new Date(info.moving_date).valueOf() -
                new Date(info.packing_date).valueOf()) /
                1000 /
                60 /
                60 <=
                24
            ) {
              currentInfo.value.packing_list.push({
                date: info.packing_date,
                time: info.packing_time,
                zipcode: info.zipcode,
                address: info.address,
              });
            } else if (info.bf_packing_date) {
              currentInfo.value.bf_packing_list.push({
                date: info.bf_packing_date,
                time: info.bf_packing_time,
                zipcode: info.zipcode,
                address: info.address,
              });
            }
          }
        }

        //save currentInfo in store
        store.dispatch("confirmation/updateCurrentInfo", currentInfo.value);

        contractDetail.value = { ...currentInfo.value, ...detail };
      }
    });
    return {
      contractDetail,
    };
  },
});
