
import apis from "@/js/apis";
import useRouteTo from "@/js/useRouteTo";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import ConfirmationDesktop from "./partials/ConfirmationDesktop.vue";
import ConfirmationMobile from "./partials/ConfirmationMobile.vue";

export default defineComponent({
  components: { ConfirmationMobile, ConfirmationDesktop },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { routeTo } = useRouteTo();
    onMounted(async () => {
      // check if step1(and 2) is already done
      if (
        !store.state.collection.address_done ||
        !store.state.collection.datetime_done
      ) {
        routeTo("BookingCollection");
      }
    });
    const click = async () => {
      if (store.state.device === "mobile") {
        store.dispatch("startLoading", true);
      }

      const res = await apis.callAPI(
        "post",
        apis.endpoints.createCollection.replace(
          "{}",
          store.state.collection.contract_id
        ),
        store.state.jwt,
        {
          ukt_no: localStorage.getItem("ukt_no") as string,
          zipcode:
            store.state.collection.zipcode.slice(0, 3) +
            "-" +
            store.state.collection.zipcode.slice(3),
          address_01: store.state.collection.address_01,
          address_02: store.state.collection.address_02,
          address_code: store.state.collection.address_code,
          kaishu_date: store.state.collection.kaishu_date,
          kaishu_time: store.state.collection.kaishu_time,
          tel: store.state.collection.tel,
          collect_item: store.state.collection.collect_item,
          cardbord_num: store.state.collection.cardbord_num,
          is_autolock: store.state.collection.is_autolock,
          request: store.state.collection.request.filter(
            (el: any) => el != "0"
          ),
          payment_method: store.state.collection.payment_method,
          is_free: store.state.collection.free_or_paid === "free" ? 1 : 0,
        }
      );
      if (res && res.data.status === "success") {
        // clear store
        store.dispatch("collection/resetCreate");
        routeTo("SuccessCollection");
      }
    };
    return {
      click,
    };
  },
});
