import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1ffa9b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-item-container" }
const _hoisted_2 = {
  key: 0,
  class: "success-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_box = _resolveComponent("add-box")!
  const _component_date_time_block = _resolveComponent("date-time-block")!
  const _component_item_block = _resolveComponent("item-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.success)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "資材のお届け日時変更を承りました"))
      : _createCommentVNode("", true),
    _createVNode(_component_add_box),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (items, datetime) => {
      return (_openBlock(), _createElementBlock("div", { key: datetime }, [
        _createVNode(_component_date_time_block, { datetime: datetime }, null, 8, ["datetime"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item) => {
          return (_openBlock(), _createBlock(_component_item_block, {
            key: item,
            src: item.is_ekoraku == 1 ? _ctx.imageMap[1] : _ctx.imageMap[0],
            alt: "box_image",
            materialType: 
          item.is_ekoraku == 1 ? [_ctx.materialTypeMap[1]] : [_ctx.materialTypeMap[0]]
        ,
            address: ['〒' + item.zipcode, item.address_01 + item.address_02],
            hasZipcode: item.zipcode.length > 0,
            materials: item.shohin_list,
            dataResult: item,
            isProcessing: item.is_processing == 1 ? true : false,
            deliveryDate: item.delivery_date,
            onClickButton: ($event: any) => (_ctx.click(item))
          }, null, 8, ["src", "materialType", "address", "hasZipcode", "materials", "dataResult", "isProcessing", "deliveryDate", "onClickButton"]))
        }), 128))
      ]))
    }), 128))
  ]))
}