const deliveryConst = {
  imageMap: {
    0: require("../assets/delivery/cardboard_new.png"),
    1: require("../assets/delivery/ecoraku.png"),
  },
  materialTypeMap: {
    0: "ダンボール 他",
    1: "エコ楽ボックス 他",
  },
};

export default deliveryConst;
