
import { defineComponent, computed } from "vue";
import InputBlock from "./../collection/InputBlock.vue";

export default defineComponent({
  components: {
    InputBlock,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
    },
    itemInput: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: "",
    },
    checkType: {
      type: String,
      default: "",
    },
    buttonWidth: {
      type: String,
      default: "100%",
    }
  },
  emits: ["change", "input"],
  data(props) {
    return {
      inputValue: props.itemInput,
      selectedItems: [] as any,
      selectedInput: props.selected,
    };
  },
  setup(props) {
    const style = computed(() => {
      return "checkbox_group " + props.className;
    });
    return {
      style,
    };
  },
  methods: {
    updateItem(): void {
      if (this.selectedItems.indexOf("上記以外の家具") < 0) {
        this.inputValue = ""
        this.$emit("input", "");
      }
      this.$emit("change", this.selectedItems);
    },
    setSelectedItem(value: any): void {
      this.selectedItems = value;
    },
    changeInput(item: string): void {
      this.inputValue = item;
      if (this.selectedItems.indexOf("上記以外の家具") < 0)
        this.selectedItems.push("上記以外の家具");
      if (item.length == 0 && this.selectedItems.indexOf("上記以外の家具") >= 0) {
        this.selectedItems = (this.selectedItems || []).filter(
            (i: any) => i !== "上記以外の家具"
          );
      }
        
      this.$emit("change", this.selectedItems);
      this.$emit("input", item);
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.setSelectedItem(this.selected || []);
    });
  },
});
