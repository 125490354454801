<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import apis from "@/js/apis";
import util from "@/js/util";

import useRouteTo from "../js/useRouteTo";
import store from "@/store";

export default {
  data() {
    return {
      selectTimes: [],
      selectItems: [],
      selectLock: [
        { id: "radio10", label: "設定なし", value: "0" },
        { id: "radio11", label: "あり", value: "1" },
        { id: "radio12", label: "なし", value: "2" },
      ],
      selectRequests: [
        {
          id: "request1",
          label: "到着前に連絡を希望します",
          value: "到着前に連絡を希望します",
        },
        {
          id: "request2",
          label: "到着時、管理人（コンシェルジュ）の方への声掛けを希望します",
          value: "到着時、管理人（コンシェルジュ）の方への声掛けを希望します",
        },
        { id: "request0", label: "要望なし", value: "0" },
      ],
      materialItems: [
        {
          id: "radio30",
          label: "ダンボール",
          value: "2C01",
          selectItems: [
            { label: "選択してください", value: "0" },
            { label: "10枚以下", value: "1" },
            { label: "10～30枚", value: "2" },
            { label: "30～50枚", value: "3" },
            { label: "50～100枚", value: "4" },
            { label: "100枚以上", value: "5" },
          ],
        },
        { id: "radio31", label: "布団袋", value: "2C02" },
        { id: "radio32", label: "クレープ用紙", value: "2C03" },
        { id: "radio33", label: "ハンガーボックス", value: "2C04" },
        { id: "radio34", label: "その他アート梱包資材", value: "2C05" },
      ],
      selectPayment: [
        { id: "39", label: "設定なし", value: "0" },
        { id: "radio40", label: "現金", value: "1" },
        { id: "radio41", label: "カード", value: "2" },
        { id: "radio42", label: "Pay支払い", value: "5" },
      ],
      selectedSelectTime: "0",
      selectedSelectItems: "0",
      selectedRadioItems: "",
      selectedMaterialItems: [],
      selectMaterialValue: "0",
      selectedLock: null,
      selectedPayment: null,
      selectValue: "",
      selectedSelectRequest: '',
      className: "",
      datetimes: {},
      request_list: [],
      free_or_paid: "free",
      editCollectionTel: "",
      editCollectionAddress: {},
    };
  },
  watch: {
    "$store.state.collection.kaishu_date": function (newVal) {
      this.$store.dispatch("collection/inputTime", "");
    },
    "$store.state.collection.is_autolock": function (newVal) {
      this.getRequests(newVal);
      this.resetRquest(newVal);
    },
    "$store.state.collection.itemForEdit.is_autolock": function (newVal) {
      this.getRequests(newVal);
      this.resetRquest(newVal);
    },
    "$store.state.collection.free_or_paid": function (newVal) {
      this.free_or_paid = newVal;
      this.getRequests(this.selectedLock);
      this.resetRquest(this.selectedLock);
    },
    "$store.state.collection.tel_list": function (newVal) {
      if (!this.selectValue) {
        const collection = this.$store.state.collection.itemForEdit.tel
          ? this.$store.state.collection.itemForEdit
          : this.$store.state.collection;
        this.selectValue = collection.tel || newVal[0];
        if (this.$store.state.collection.itemForEdit.tel) {
          this.$store.dispatch("collection/saveItemForEdit", {
            ...this.$store.state.collection.itemForEdit,
            tel: this.selectValue,
          });
        } else {
          this.$store.dispatch("collection/inputPhonenumber", this.selectValue);
        }
      }
    },
    "$store.state.collection.request_list": function (newVal) {
      this.request_list = newVal;
      this.getRequests(this.selectedLock);
      this.resetRquest(this.selectedLock);
    },
    "$store.state.collection.itemForEdit.collect_item": function (
      newVal,
      oldVal
    ) {
      this.resetCardbordNum(newVal || []);
    },
    "$store.state.collection.collect_item": function (newVal, oldVal) {
      this.resetCardbordNum(newVal || []);
    },
    "$store.state.collection.itemForEdit.cardbord_num": function (
      newVal,
      oldVal
    ) {
      this.resetCollectItem(newVal, oldVal);
    },
    "$store.state.collection.cardbord_num": function (newVal, oldVal) {
      this.resetCollectItem(newVal, oldVal);
    },
    "$store.state.collection.tel": function (newVal) {
      this.setDefaultPhoneType(newVal);
    },
    "$store.state.collection.itemForEdit.tel": function (newVal) {
      this.setDefaultPhoneType(newVal);
    },
    "$store.state.collection.itemForEdit.zipcode": function (value) {
      let state = this.$store.state;
      if (state.collection && state.collection.contract_id) {
        const contract_id = state.collection.contract_id;
        this.getAvailableDate(contract_id, value);
      }
    },
    "$store.state.collection.itemForEdit.address_code": function (value) {
      let state = this.$store.state;
      if (state.collection && state.collection.contract_id) {
        const contract_id = state.collection.contract_id;
        this.getAvailableDate(contract_id, state.collection.itemForEdit.zipcode);
      }
    },
  },
  methods: {
    resetCollectItem(newVal, oldVal) {
      const collection = this.$store.state.collection.itemForEdit.collect_item
        ? this.$store.state.collection.itemForEdit
        : this.$store.state.collection;
      const materialType = collection.collect_item;
      const material = newVal;
      if (newVal !== oldVal) {
        if (material > 0 && materialType.indexOf("2C01") == -1) {
          materialType.push("2C01");
        } else if (material == 0 && materialType.indexOf("2C01") > -1) {
          materialType.slice(materialType.indexOf("2C01"), 1);
        }
        var array = Object.keys(materialType).map(function (key) {
          return materialType[key];
        });
        this.selectedMaterialItems = array;
        if (Object.keys(this.$store.state.collection.itemForEdit).length > 0) {
          collection.collect_item = array;
          this.$store.dispatch("collection/saveItemForEdit", collection);
        } else {
          this.$store.dispatch("collection/inputMaterialType", array);
        }
      }
    },
    resetCardbordNum(newVal) {
      const collection = this.$store.state.collection.itemForEdit.collect_item
        ? this.$store.state.collection.itemForEdit
        : this.$store.state.collection;
      const material = collection.cardbord_num;
      if (material > 0 && newVal.indexOf("2C01") == -1) {
        if (Object.keys(this.$store.state.collection.itemForEdit).length > 0) {
          collection.cardbord_num = 0;
          this.$store.dispatch("collection/saveItemForEdit", collection);
        } else {
          this.$store.dispatch("collection/inputMaterial", 0);
        }
        this.selectMaterialValue = 0;
      }
    },
    getBranch() {
      const branch = this.$store.state.collection.branch.name
        ? this.$store.state.collection.branch
        : this.$store.state.collection.address_branch;
      return branch;
    },
    setInitialData(collection) {
      if (collection) {
        const defaultValue = {
          phone_number: "",
          phone_type: "1",
          datetime: "0",
          time: "0",
          request: "",
          is_autolock: 0,
          collect_item: [],
          cardbord_num: 0,
          payment: 0,
        };
        const store = useStore();
        this.getAvailableDate(
          store.state.collection.contract_id,
          collection.zipcode
        );

        const tel_list = store.state.collection.tel_list || [];
        const defaultTel = tel_list && tel_list.length > 0 ? tel_list[0] : ""
        this.request_list = store.state.collection.request_list || [];
        this.free_or_paid = store.state.collection.free_or_paid || "free";
        this.selectedSelectTime =
          collection.kaishu_time || defaultValue["datetime"];
        this.selectedSelectItems =
          collection.kaishu_date || defaultValue["datetime"];
        this.selectedRadioItems =
          collection.phone_type || defaultValue["phone_type"];
        this.selectValue = collection.tel || defaultTel;
        this.selectedLock =
          collection.is_autolock != null
            ? collection.is_autolock.toString()
            : null;
        this.getRequests(this.selectedLock);

        this.selectedSelectRequest =
          collection.request && collection.request.length > 0 ? collection.request[0] : null;
        this.selectedMaterialItems =
          collection.collect_item || defaultValue["collect_item"];
        this.selectMaterialValue =
          collection.cardbord_num || defaultValue["cardbord_num"];
        this.selectedPayment =
          collection.payment_method || defaultValue["payment"];

        store.dispatch("collection/inputPhonenumber", this.selectValue);
        store.dispatch("collection/inputPhonetype", this.selectedRadioItems);
        store.dispatch("collection/inputMaterial", this.selectMaterialValue);
        store.dispatch(
          "collection/inputMaterialType",
          this.selectedMaterialItems
        );
        store.dispatch("collection/inputRequest", [this.selectedSelectRequest]);
      }
      
    },
    inputAddress(address) {
      const address_list = this.address_list || [];
      const findAddress = address_list.filter(
        (i) => i.zipcode.replace("-", "") == address
      );
      if (Object.keys(this.$store.state.collection.itemForEdit).length > 0) {
        const address_02 =
          address == this.editCollectionAddress.zipcode
            ? this.editCollectionAddress.address_02
            : findAddress.length > 0
            ? findAddress[0]["address_02"]
            : "";
        const address_01 =
          address == this.editCollectionAddress.zipcode
            ? this.editCollectionAddress.address_01
            : findAddress.length > 0
            ? findAddress[0]["address_01"]
            : "";
        this.$store.dispatch("collection/saveItemForEdit", {
          ...this.$store.state.collection.itemForEdit,
          address_02: address_02 || "",
          address_01: address_01 || "",
        });
      } else {
        this.$store.dispatch("collection/resetAddressBook");
        this.$store.dispatch(
          "collection/inputAddress",
          findAddress.length > 0 ? findAddress[0]["address_02"] : ""
        );
        this.$store.dispatch(
          "collection/inputCity",
          findAddress.length > 0 ? findAddress[0]["address_01"] : ""
        );
      }

      let state = this.$store.state;

      if (address !== "上記以外の住所") {
        if (Object.keys(this.$store.state.collection.itemForEdit).length > 0) {
          this.validateResponse(address);
          this.$store.dispatch("collection/saveItemForEdit", {
            ...this.$store.state.collection.itemForEdit,
            zipcode: address,
          });
        } else {
          this.$store.dispatch("collection/inputZipcode", address);
        }

        this.getAvailableDate(state.collection.contract_id, address);
      } else {
        this.changeMessage(4);
        if (Object.keys(this.$store.state.collection.itemForEdit).length > 0) {
          this.$store.dispatch("collection/saveItemForEdit", {
            ...this.$store.state.collection.itemForEdit,
            kaishu_date: "",
            kaishu_time: "",
          });
        }
      }
      if (Object.keys(this.$store.state.collection.itemForEdit).length == 0) {
        this.$store.dispatch("collection/inputDatetime", "");
        this.$store.dispatch("collection/inputTime", "");
      }
    },
    getTimeOptions() {
      const collection = this.$store.state.collection.itemForEdit.kaishu_date
        ? this.$store.state.collection.itemForEdit
        : this.$store.state.collection;
      const store = useStore();
      const times = this.datetimes[collection.kaishu_date] || [];
      const options = [];
      for (var i = 0; i < times.length; i++) {
        if (i == 0 && !this.selectedSelectTime) {
          this.selectedSelectTime = times[i]["time"];
          if (
            Object.keys(this.$store.state.collection.itemForEdit).length > 0
          ) {
            this.$store.dispatch("collection/saveItemForEdit", {
              ...this.$store.state.collection.itemForEdit,
              kaishu_time: times[i]["time"],
            });
          } else {
            this.$store.dispatch("collection/inputTime", times[i]["time"]);
          }
        }
        options.push({ label: times[i]["time"], value: times[i]["time"] });
      }
      return options;
    },
    getTelOptions() {
      const tel_list = this.$store.state.collection.tel_list || [];

      let options = [];
      if (
        this.editCollectionTel &&
        tel_list.indexOf(this.editCollectionTel) < 0
      ) {
        options.push({
          label: this.editCollectionTel,
          value: this.editCollectionTel,
        });
      }
      for (var i = 0; i < tel_list.length; i++) {
        options.push({
          label: tel_list[i],
          value: tel_list[i],
        });
      }
      return [
        {
          id: "radio3",
          label: "登録済み電話番号から選ぶ<br/>",
          value: "1",
          selectItems: options,
        },
        { id: "radio4", label: "その他の電話番号", value: "2" },
      ];
    },
    async getAvailableDate(contract_id, zipcode) {
      const collection = this.$store.state.collection.itemForEdit
        ? this.$store.state.collection.itemForEdit
        : null;
      let dates = [];
      let datetimes = {};
      let addOriginalDate = false
      if (util.valid_zipcode(zipcode)) {
        const result = await apis.callAPI(
          "get",
          apis.endpoints.getAddress + "?zip_code=" + zipcode,
          this.$store.state.jwt
        );
        if (result && result.data.status === "success") {
          if (collection !== null && collection.branch_info) {
            const findAddress = (result.data.results || []).filter(
              (i) => i.branch_info.name == collection.branch_info.name && collection.address_code == i.jis_address_code
            );
            if (result.data.results.length == 0 || findAddress.length == 0) {
              addOriginalDate = false
            } else {
              addOriginalDate = true
            }
            if (collection.address_01 == this.editCollectionAddress.address_01 &&
              collection.address_02 == this.editCollectionAddress.address_02 && 
              collection.zipcode == this.editCollectionAddress.zipcode) {
                addOriginalDate = true
              }
          }
          
          const res = await apis.getAvailableDate(
            "collection",
            contract_id,
            zipcode.replace("-", "")
          );
          if (res) {
            if (res === "no_available_date") {
              console.log("当システムでご予約可能な日付がございません");
              store.dispatch("collection/saveNoAvailableDate", true);
            } else {
              store.dispatch("collection/saveNoAvailableDate", false);
              const available_datetime = res.available_datetime;

              for (var i = 0; i < available_datetime.length; i++) {
                if (i == 0 && !this.selectedSelectItems) {
                  this.selectedSelectItems = available_datetime[i]["date"];
                  if (collection) {
                    if (addOriginalDate) {
                      this.$store.dispatch("collection/saveItemForEdit", {
                        ...collection,
                        kaishu_date: available_datetime[i]["date"],
                      });
                    }
                  } else {
                    this.$store.dispatch(
                      "collection/inputDatetime",
                      available_datetime[i]["date"]
                    );
                  }
                }

                dates.push({
                  value: available_datetime[i]["date"],
                  label: util.getJapaneseDateMonth(
                    available_datetime[i]["date"]
                  ),
                });
                datetimes[available_datetime[i]["date"]] =
                  available_datetime[i]["times"];
              }
            }
          }
        }
      }

      //fill date and time from collection edit
      if (
        this.editCollectionAddress.kaishu_date &&
        addOriginalDate
      ) {
        this.selectedSelectItems = this.editCollectionAddress.kaishu_date;
        if (!datetimes[this.editCollectionAddress.kaishu_date]) {
          dates.push({
            value: this.editCollectionAddress.kaishu_date,
            label: util.getJapaneseDateMonth(
              this.editCollectionAddress.kaishu_date
            ),
          });
        }
        if (
          datetimes[this.editCollectionAddress.kaishu_date] &&
          datetimes[this.editCollectionAddress.kaishu_date].length > 0
        ) {
          const targetTime = this.editCollectionAddress.kaishu_time;
          const findTime = datetimes[
            this.editCollectionAddress.kaishu_date
          ].filter(function (el) {
            return el.time == targetTime;
          });
          if (findTime.length == 0) {
            datetimes[this.editCollectionAddress.kaishu_date].push({
              time: this.editCollectionAddress.kaishu_time,
            });
          }
        } else {
          datetimes[this.editCollectionAddress.kaishu_date] = [
            {
              time: this.editCollectionAddress.kaishu_time,
            },
          ];
        }
        this.$store.dispatch("collection/saveItemForEdit", {
          ...collection,
          kaishu_date: this.editCollectionAddress.kaishu_date,
          kaishu_time: this.editCollectionAddress.kaishu_time,
        });

      } else if (this.editCollectionAddress.kaishu_date) {
        this.$store.dispatch("collection/saveItemForEdit", {
          ...collection,
          kaishu_date: "",
          kaishu_time: "",
        });
      }

      this.datetimes = datetimes;
      this.selectItems = dates;
    },
    getRequests(is_autolock) {
      const is_free =
        this.free_or_paid == "free" ||
        this.$store.state.collection.itemForEdit.is_free
          ? 1
          : 0;
      let requests = [
        { id: "request1", label: "到着前に連絡を希望します", value: "2A02" },
        {
          id: "request2",
          label: "到着時、管理人（コンシェルジュ）の方への声掛けを希望します",
          value: "2A03",
        },
      ];
      if (is_free == 1 && is_autolock == "2") {
        requests.push({
          id: "request3",
          label:
            "不在でも引き取りを希望します【※玄関先に資材を出しておく必要があります】",
          value: "2A01",
        });
      }
      requests.push({ id: "request0", label: "要望なし", value: "0" });
      this.selectRequests = requests;
    },
    resetRquest(is_autolock) {
      const collection = this.$store.state.collection.itemForEdit.request
        ? this.$store.state.collection.itemForEdit
        : this.$store.state.collection;
      let newRequest = collection.request.filter(function (el) {
        return typeof el === "string";
      });
      if (is_autolock == "1" && newRequest.indexOf("2A01") >= 0) {
        newRequest = collection.request.filter(function (el) {
          return el !== "2A01";
        });
        if (Object.keys(this.$store.state.collection.itemForEdit).length > 0) {
          collection.request = newRequest;
          this.$store.dispatch("collection/saveItemForEdit", collection);
        } else {
          this.$store.dispatch("collection/inputRequest", newRequest);
        }
      }
      this.selectedSelectRequest = newRequest.length > 0 ? newRequest[0] : "";
    },
    setDefaultPhoneType(phoneNumber) {
      if (phoneNumber) {
        let telList = this.$store.state.collection.tel_list || [];
        let phoneType = 2;
        let findPhone = telList.filter((tel) => tel == phoneNumber);
        if (findPhone.length > 0 || this.editCollectionTel == phoneNumber) {
          phoneType = 1;
        }
        this.selectedRadioItems = phoneType;
        this.$store.dispatch(
          "collection/inputPhonetype",
          this.selectedRadioItems
        );
      }
    },
    async validateResponse(zipcode) {
      const state = this.$store.state
      
      const result = await apis.callAPI(
        "get",
        apis.endpoints.getAddress + "?zip_code=" + zipcode.replace("-", ""),
        state.jwt
      );
      if (result && result.data.status === "success") {
        let response = 0;
        const results = result.data.results;
        const findAddress = results.filter(
          (i) => i.zip_code.replace("-", "") == zipcode.replace("-", "")
        );
        
        if (findAddress.length > 0) {
          if (findAddress[0]["branch_info"]) {
            this.$store.dispatch(
              "collection/saveBranch",
              findAddress[0]["branch_info"]
            );
          }
          if (findAddress[0]["branch_info"]["is_fc"] == true) {
            response = 2;
          } else if (findAddress[0]["kaishu_available"] == 1) {
            response = 1;
          } else if (findAddress[0]["kaishu_available"] == 2) {
            response = 2;
          }
        }
        this.changeMessage(response);
        this.$store.dispatch("collection/saveValidationAddress", response);
      }
    }
  },
  created() {
    const store = useStore();
    if (store.state.collection.free_or_paid == "paid") {
      this.className = "red";
    }
  },
};
</script>