import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cbab292"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clearfix full-height" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_text_block = _resolveComponent("text-block")!
  const _component_container_block = _resolveComponent("container-block")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Logo, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$store.state.device === 'mobile')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.type === 'create')
              ? (_openBlock(), _createBlock(_component_text_block, {
                  key: 0,
                  title: "ご登録のメールアドレスに本登録用のURLを送付しました",
                  text: "メールに記載されたURLから、本登録手続きを進めてください。"
                }))
              : (_openBlock(), _createBlock(_component_text_block, {
                  key: 1,
                  title: "メールアドレスにURLを送付しました",
                  text: "メールに記載されたURLから、手続きを進めてください。"
                }))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.type === 'create')
              ? (_openBlock(), _createBlock(_component_container_block, {
                  key: 0,
                  title: "新規アカウント申込完了",
                  text: "ご登録のメールアドレスに本登録用のURLを送付しました。<br>メールに記載されたURLから、本登録手続きを進めてください。"
                }))
              : (_openBlock(), _createBlock(_component_container_block, {
                  key: 1,
                  text: "メールアドレスにURLを送付しました。<br>メールに記載されたURLから、手続きを進めてください。"
                }))
          ]))
    ]),
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Footer, {
          key: 1,
          className: "mt"
        }))
      : _createCommentVNode("", true)
  ]))
}