
import useCheckStoreForConfirmation from "@/js/useCheckStoreForConfirmation";
import { defineComponent, onMounted } from "vue";

import ContactPriceDesktop from "./partials/ContactPriceDesktop.vue";
import ContactPriceMobile from "./partials/ContactPriceMobile.vue";

export default defineComponent({
  components: { ContactPriceMobile, ContactPriceDesktop },
  setup() {
    onMounted(() => {
      useCheckStoreForConfirmation();
    });
  },
});
