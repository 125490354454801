
import { computed, defineComponent } from "vue";
import HorizontalLine from "./HorizontalLine.vue";
import SymbolMark from "./SymbolMark.vue";

export default defineComponent({
  components: { SymbolMark, HorizontalLine },
  props: {
    mark: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    noButton: {
      type: Boolean,
      default: false,
    },
    bgImage: {
      type: String,
      required: true,
    },
    opacity: {
      type: String,
      default: "1",
    },
    headline: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "",
    },
  },
  emits: ["clickDetail"],
  setup(props) {
    const style = computed(() => {
      return {
        "background-image": `url(${props.bgImage})`,
        opacity: props.opacity,
        height: props.height || "14.5rem",
        // "background-image": `url(${require("../../assets/mypage/furniture.png")})`,
      };
    });
    return {
      style,
    };
  },
});
