import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3729f12f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-datetime-container" }
const _hoisted_2 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item_block = _resolveComponent("item-block")!
  const _component_select_block = _resolveComponent("select-block")!
  const _component_radio_block = _resolveComponent("radio-block")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_item_block, {
      key: _ctx.item,
      src: _ctx.item.is_ekoraku == 1 ? _ctx.imageMap[1] : _ctx.imageMap[0],
      alt: "box_image",
      materialType: 
        _ctx.item.is_ekoraku == 1 ? [_ctx.materialTypeMap[1]] : [_ctx.materialTypeMap[0]]
      ,
      address: ['〒' + _ctx.item.zipcode, _ctx.item.address_01, _ctx.item.address_02],
      materials: _ctx.item.materials || {},
      disableToggle: true,
      materialTypeLabel: "配達資材",
      addressLabel: "配達先"
    }, null, 8, ["src", "materialType", "address", "materials"])),
    _createVNode(_component_select_block, {
      name: "delivery_date",
      title: "ご希望日",
      options: _ctx.selectItems,
      onChange: _ctx.selectDate,
      selected: _ctx.$store.state.delivery.delivery_date,
      placeholder: "選択してください",
      errorMsg: _ctx.errorMsgDate
    }, null, 8, ["options", "onChange", "selected", "errorMsg"]),
    _createVNode(_component_select_block, {
      name: "delivery_time",
      title: "ご希望時間帯",
      options: _ctx.getTimeOptions(),
      onChange: _ctx.selectTime,
      selected: _ctx.$store.state.delivery.delivery_time,
      placeholder: "選択してください",
      errorMsg: _ctx.errorMsgTime
    }, null, 8, ["options", "onChange", "selected", "errorMsg"]),
    _createVNode(_component_radio_block, {
      title: "オートロック",
      options: _ctx.selectLock,
      name: "delivery_lock",
      onChange: _ctx.inputLock,
      selected: _ctx.selectedLock,
      errorMsg: _ctx.errorMsgAutolock
    }, null, 8, ["options", "onChange", "selected", "errorMsg"]),
    (_ctx.$store.state.delivery.is_autolock == 2)
      ? (_openBlock(), _createBlock(_component_radio_block, {
          key: 0,
          title: "置き配指定",
          name: "delivery_placement",
          options: _ctx.selectPlacements,
          onChange: _ctx.inputPlacement,
          selected: _ctx.selectedPlacement,
          errorMsg: _ctx.errorMsgPlacement
        }, null, 8, ["options", "onChange", "selected", "errorMsg"]))
      : _createCommentVNode("", true),
    _createVNode(_component_select_block, {
      name: "request",
      title: "ご要望",
      options: _ctx.selectRequests,
      onChange: _ctx.selectRequest,
      selected: Array.isArray(_ctx.selectedRequest) && _ctx.selectedRequest.length > 0 ? _ctx.selectedRequest[0] : null
    }, null, 8, ["options", "onChange", "selected"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_button, {
        buttonText: "日時変更を確定する",
        color: "#FFFFFF",
        bgColor: "#0060B9",
        height: "6.8",
        onClick: _ctx.click
      }, null, 8, ["onClick"])
    ])
  ]))
}