
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import BaseButton from "@/components/BaseButton.vue";

import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import useRouteTo from "../../../js/useRouteTo";

export default defineComponent({
  components: { Logo, Footer, BaseButton },
  data: function () {
    return {
      collection: {},
      msg: "",
    };
  },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const submit = () => {
      routeTo("MyPage");
    };
    return {
      submit,
    };
  },
  created() {
    const store = useStore();
    this.collection = reactive({ ...store.state.collection });
    if (store.state.collection.free_or_paid == "paid") {
      this.msg =
        "ダンボールを回収した際に回収スタッフにお支払いください。現金のほか、クレジットカード払い、各種ペイ払いもご利用いただけます。 領収書も発行いたします。";
    }
  },
});
