
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import util from "@/js/util";

import BaseButton from "./../BaseButton.vue";
import ExclamationIcon from "./../furniture/ExclamationIcon.vue";

export default defineComponent({
  name: "ConfrmationCollection",
  components: {
    BaseButton,
    ExclamationIcon,
  },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    dataResult: {
      type: Object,
      required: true,
    },
    msg: {
      type: String,
      default: "",
    },
    boxClass: {
      type: String,
      default: "",
    },
  },
  emits: ["click"],
  data: function () {
    return {
      className: "",
    };
  },
  created() {
    const store = useStore();
    if (store.state.collection.free_or_paid == "paid") {
      this.className = "red";
    }
  },
  methods: {
    getZipcode(zipcode: any) {
      let textZipcode = zipcode;
      if (zipcode.indexOf("-") < 0) {
        textZipcode = zipcode.slice(0, 3) + "-" + zipcode.slice(3);
      }
      return textZipcode;
    },
    getMaterial(result: any) {
      const collect_item: any = result.collect_item;
      const materialItems = [
        {
          label: "ダンボール",
          value: "2C01",
        },
        { label: "布団袋", value: "2C02" },
        { label: "クレープ用紙", value: "2C03" },
        { label: "ハンガーボックス", value: "2C04" },
        { label: "その他アート梱包資材", value: "2C05" },
      ];

      const cardbords = [
        "",
        "10枚以下",
        "10～30枚",
        "30～50枚",
        "50～100枚",
        "100枚以上",
      ];

      let str = "";
      if (collect_item.length > 0) {
        for (let i = 0; i < collect_item.length; i++) {
          const findItem = materialItems.filter(
            (j) => j.value == collect_item[i]
          );
          if (findItem.length > 0) {
            str += findItem[0]["label"];
            if (
              result.cardbord_num != "" &&
              findItem[0]["label"] == "ダンボール"
            )
              str += " (" + cardbords[result.cardbord_num] + ")";
            if (collect_item[i + 1]) str += ", ";
          }
        }
        return str;
      }
      return "";
    },
    getRequestLabel(result: any) {
      let request_list = [
        { id: "request1", label: "到着前に連絡を希望します", value: "2A02" },
        {
          id: "request2",
          label: "到着時、管理人（コンシェルジュ）の方への声掛けを希望します",
          value: "2A03",
        },
        {
          id: "request3",
          label:
            "不在でも引き取りを希望します【※玄関先に資材を出しておく必要があります】",
          value: "2A01",
        },
        { id: "request0", label: "要望なし", value: "0" },
      ];
      request_list = request_list.filter(function (el: any) {
        return result.request.indexOf(el.value) >= 0;
      });
      let items = (request_list || []).map(function (el: any) {
        return el["label"];
      });
      if (items.length > 0) {
        return items.join(", ");
      }
      return "";
    },
    getPaymentMethod(value: any) {
      const methods: { [key: string]: any } = {
        "1": "現金",
        "2": "カード",
        "5": "Pay支払い",
      };
      return methods[value] ? methods[value] : "";
    },
  },
  setup(props) {
    const getJapaneseDate = util.getJapaneseDate;
    const store = useStore();
    const buttonWidth = computed(() => {
      return store.state.device == "mobile" ? "100%" : "34rem";
    });
    return {
      buttonWidth,
      getJapaneseDate,
    };
  },
});
