import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cfe5262a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-block" }
const _hoisted_2 = ["id", "name", "value"]
const _hoisted_3 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_label, { title: _ctx.title }, null, 8, ["title"]),
    _createElementVNode("div", {
      class: _normalizeClass({ 'radio-wrapper': true, inline: _ctx.options.length < 3 })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.filter((el) => el.value > 0), (item) => {
        return (_openBlock(), _createElementBlock("label", {
          key: item.id
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            id: item.id,
            name: _ctx.name,
            value: item.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedInput) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateItem && _ctx.updateItem(...args)))
          }, null, 40, _hoisted_2), [
            [_vModelRadio, _ctx.selectedInput]
          ]),
          _createElementVNode("span", null, _toDisplayString(item.label), 1)
        ]))
      }), 128))
    ], 2),
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errorMsg), 1))
      : _createCommentVNode("", true)
  ]))
}