import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8f5c5fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "goods pc" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  key: 0,
  class: "red preview"
}
const _hoisted_5 = {
  key: 0,
  class: "products-list"
}
const _hoisted_6 = { class: "container" }
const _hoisted_7 = {
  key: 1,
  class: "buttons-list container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_GoodsTabDesktop = _resolveComponent("GoodsTabDesktop")!
  const _component_DetailView = _resolveComponent("DetailView")!
  const _component_recommendation = _resolveComponent("recommendation")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Logo, {
      hasLink: true,
      hasMenu: true
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.preview)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, " プレビューを表示しています "))
          : _createCommentVNode("", true),
        _createVNode(_component_GoodsTabDesktop, {
          subItem: _ctx.dataResult.name,
          link: "GoodDetail"
        }, null, 8, ["subItem"]),
        _createVNode(_component_DetailView, {
          dataResult: _ctx.dataResult,
          products: [],
          bookedGoods: _ctx.bookedGoods,
          variant_id: _ctx.variant_id,
          onClickReserve: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickReserve')))
        }, null, 8, ["dataResult", "bookedGoods", "variant_id"])
      ]),
      (!_ctx.preview)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_recommendation, { products: _ctx.recommendedGoods }, null, 8, ["products"])
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.preview)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_base_button, {
              buttonText: "商品予約TOPへ",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('goTop')))
            }),
            _createVNode(_component_base_button, {
              buttonText: "ご予約済み商品",
              buttonColor: "#E5EFF8",
              class: "light-primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('goReserved')))
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Footer, { hasLink: true })
  ]))
}