import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "furniture" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Steps = _resolveComponent("Steps")!
  const _component_BookingScheduleView = _resolveComponent("BookingScheduleView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Steps, { items: _ctx.items }, null, 8, ["items"]),
    _createVNode(_component_BookingScheduleView, {
      msg: _ctx.msg,
      buttonTitle: "確認画面へ進む",
      onClick: _ctx.submit
    }, null, 8, ["msg", "onClick"])
  ]))
}