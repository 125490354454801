
import useCheckStoreForConfirmation from "@/js/useCheckStoreForConfirmation";
import { defineComponent, onMounted } from "vue";
import ContactPackingSuccessDesktop from "./partials/ContactPackingSuccessDesktop.vue";
import ContactPackingSuccessMobile from "./partials/ContactPackingSuccessMobile.vue";

export default defineComponent({
  components: { ContactPackingSuccessMobile, ContactPackingSuccessDesktop },
  // props: {

  // },
  setup() {
    onMounted(() => {
      // useCheckStoreForConfirmation();
    });
  },
});
