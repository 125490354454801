
import MypageInfoDatetimeLongBox from "@/components/mypage/desktop/MypageInfoDatetimeLongBox.vue";
import InfoAddressBlock from "@/components/mypage/InfoAddressBlock.vue";
import InfoPriceTabDesktop from "@/components/mypage/InfoPriceTabDesktop.vue";
import TitleBlock from "@/components/mypage/TitleBlock.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import util from "@/js/util";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    TitleBlock,
    InfoAddressBlock,
    MypageInfoDatetimeLongBox,
    InfoPriceTabDesktop,
    Logo,
    Footer,
  },
  props: {
    moving_info_list: {
      type: Array,
      required: true,
    },
  },
  setup() {
    // const store = useStore();
    // onMounted(() => {
    //   store.state.mypage.topbar = "お引越し詳細";
    // });
    const getDayOfWeek = util.getDayOfWeek;
    const removeZero = util.removeZero;
    return {
      getDayOfWeek,
      removeZero,
    };
  },
});
