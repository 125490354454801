
import { defineComponent, ref } from "vue";
import MaterialBlock from "./../MaterialBlock.vue";
import TextBlock from "./../TextBlock.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { TextBlock, MaterialBlock },
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    materialType: {
      type: Array,
      required: true,
    },
    address: {
      type: Array,
      required: true,
    },
    materials: {
      type: Object,
      required: true,
    },
    marginBottom: {
      type: String,
      default: "0",
    },
    disableToggle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["clickButton"],
  setup(props) {
    const store = useStore();
    const toggled = ref(store.state.device !== "mobile");
    const toggle = (e: any) => {
      if (
        !e.target.classList.contains("next-button") &&
        !props.disableToggle &&
        store.state.device === "mobile"
      ) {
        setTimeout(() => {
          toggled.value = !toggled.value;
        }, 0);
      }
    };
    return {
      toggled,
      toggle,
    };
  },
});
