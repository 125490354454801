
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

import Footer from "@/components/Footer.vue";
import BaseButton2 from "@/components/account/BaseButton2.vue";
import useRouteTo from "../../js/useRouteTo";

export default defineComponent({
  components: {
    Footer,
    BaseButton2,
  },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const buttonWidth = computed(() => {
      return store.state.device == "mobile" ? "100%" : "34rem";
    });
    return {
      buttonWidth,
      routeTo,
    };
  },
  methods: {
    scrollToElement(name: any) {
      const el: any = this.$refs[name];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
});
