
import ContactSuccess from "@/components/confirmation/ContactSuccess.vue";
import Steps from "@/components/furniture/Steps.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { ContactSuccess, Steps },
  data: function () {
    return {
      items: [
        { label: "基本情報", class: "current" },
        { label: "作業内容", class: "current" },
        { label: "料金確認", class: "current" },
        { label: "完了", class: "current" },
      ]
    };
  },
});
