<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="グループ_1426" data-name="グループ 1426" transform="translate(-41 -484)">
      <rect id="長方形_233" data-name="長方形 233" width="20" height="20" rx="2" transform="translate(41 484)" fill="#0060b9"/>
      <text id="Password" transform="translate(44 499)" fill="#fff" font-size="14" font-family="YuGothic-Bold, Yu Gothic" font-weight="700"><tspan x="0" y="0">◎</tspan></text>
    </g>
  </svg>
</template>

<script>
export default {
    name: 'YenIcon',
}
</script>

<style scoped>
</style>
