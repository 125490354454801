
import Balloon from "@/components/mypage/Balloon.vue";
import BaseLongBox from "@/components/mypage/BaseLongBox.vue";
import DatetimeShortBox from "@/components/mypage/DatetimeShortBox.vue";
import PriceDiv from "@/components/mypage/PriceDiv.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import TitleBlock from "@/components/mypage/TitleBlock.vue";
import ContentsToggleBlock from "@/components/mypage/ContentsToggleBlock.vue";
import BaseButton from "@/components/BaseButton.vue";
import { computed, defineComponent, inject, ref } from "vue";
import useRouteTo from "../../../js/useRouteTo";
import useGoTo from "../../../js/useGoTo";
import usePseudoAPICall from "../../../js/usePseudoAPICall";
import PriceModal from "./PriceModal.vue";
import util from "@/js/util";
import openAQC from "@/js/openAQC";

export default defineComponent({
  components: {
    Balloon,
    DatetimeShortBox,
    BaseLongBox,
    PriceDiv,
    Logo,
    Footer,
    TitleBlock,
    ContentsToggleBlock,
    BaseButton,
    PriceModal
  },
  props: {
    currentInfo: {
      type: Object,
      required: true,
    },
    done: {
      type: Boolean,
      required: true,
    },
    aqcQuestionnaire: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["click"],
  watch: {
    aqcQuestionnaire: function (val) {
      if (val) {
        const openHelp = () => {
          openAQC()
        };
        util.openAlert(this.$store, "アンケートに回答する", "", "※既にアンケートの入力がお済みの場合は回答不要です。", openHelp, "アンケートのお願い", true);
      }
    },
  },
  setup(props) {
    const { routeTo } = useRouteTo();
    const { goTo } = useGoTo();
    const { pseudoAPICall } = usePseudoAPICall();
    const wording = inject("wording");
    const accordion = ref(false);
    const transformOrigin = ref("48% 48%");

    const isPriceModalVisible = ref(false);
    const togglePriceModal = (value: boolean) => {
      isPriceModalVisible.value = value
    }

    const ukt_no = ref(localStorage.getItem("ukt_no") as string || "");

    const datetimeText = computed(() => {
      if (props.done) {
        return "引越し完了";
      } else {
        return "お引越作業日";
      }
    });
    const computeBGColor = computed(() => {
      if (props.done) {
        return "#555555";
      } else {
        return "#ffffff";
      }
    });
    const loadingRoute = (name: string) => {
      // API Call -> GET data
      // For now, pseudo api call
      pseudoAPICall(name);
    };
    const expand = (event: MouseEvent) => {
      transformOrigin.value = "48% " + event.clientY + "px";
      accordion.value = true;
      // routeTo(route);
    };
    const loadingExpand = (event: any, route: string) => {
      setTimeout(() => {
        expand(event);
        setTimeout(() => {
          pseudoAPICall(route);
        }, 200);
      }, 100);
    };
    const style = computed(() => {
      return {
        "transform-origin": transformOrigin.value,
        height: document.body.scrollHeight + "px",
      };
    });

    return {
      routeTo,
      goTo,
      wording,
      accordion,
      ukt_no,
      datetimeText,
      computeBGColor,
      loadingRoute,
      expand,
      loadingExpand,
      style,
      togglePriceModal,
      isPriceModalVisible
    };
  },
});
