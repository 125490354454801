
import useCheckStoreForConfirmation from "@/js/useCheckStoreForConfirmation";
import { defineComponent, onMounted } from "vue";
import ContactArrivalSuccessDesktop from "./partials/ContactArrivalSuccessDesktop.vue";
import ContactArrivalSuccessMobile from "./partials/ContactArrivalSuccessMobile.vue";

export default defineComponent({
  components: { ContactArrivalSuccessMobile, ContactArrivalSuccessDesktop },
  // props: {

  // },
  setup() {
    onMounted(() => {
      // useCheckStoreForConfirmation();
    });
  },
});
