import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41590b79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "item" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "info" }
const _hoisted_5 = {
  key: 0,
  class: "date"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "contents-block bound-no-gray",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.item.category_name), 1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.toggled ? `content-block item-block-sub collapse` : `item-block-sub content-block`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.option_list, (option) => {
        return (_openBlock(), _createElementBlock("div", { key: option }, [
          _createElementVNode("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(option.option_name) + " ", 1),
            (option.option_num)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, " : " + _toDisplayString(option.option_num) + _toDisplayString(option.option_unit), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (option.option_working_date)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, " 【日時】 " + _toDisplayString(_ctx.getJapaneseDate(option.option_working_date)) + " " + _toDisplayString(option.option_working_time), 1))
              : _createCommentVNode("", true),
            (option.option_working_place)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, " 【作業場所】" + _toDisplayString(option.option_working_place), 1))
              : _createCommentVNode("", true),
            (option.option_msg)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, "【備考】" + _toDisplayString(option.option_msg), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ], 2)
  ]))
}