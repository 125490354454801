
import ErrorMessageBlockFromServer from "@/components/ErrorMessageBlockFromServer.vue";
import Tabs from "@/components/goods/Tabs.vue";
// import TopBar from "@/components/mypage/TopBar.vue";
import TransitionBlock from "@/components/TransitionBlock.vue";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { TransitionBlock, Tabs, ErrorMessageBlockFromServer },
  props: {},
  setup() {
    const route = useRoute();
    const mypage = computed(() => {
      if (route.name === "MyPageInfo" || route.name === "MyPagePrice") {
        return true;
      } else {
        return false;
      }
    });
    const mypageTransition = computed(() => {
      if (route.meta.mypageTransition) {
        return route.meta.transition || "fade";
      } else {
        return "";
      }
    });
    const goods = computed(() => {
      if (route.path.startsWith("/goods")) {
        return true;
      } else {
        return false;
      }
    });
    const goodsTransition = computed(() => {
      if (route.meta.goodsTransition) {
        return route.meta.transition || "fade";
      } else {
        return "";
      }
    });
    return {
      mypage,
      mypageTransition,
      goods,
      goodsTransition,
    };
  },
});
