import moment from "moment";

const util = {
  // validation
  validateUkt: (tel: string) => {
    if (tel.match(/^[0-9]{7,10}$/)) {
      return true;
    } else {
      return false;
    }
  },
  validateTel: (tel: string) => {
    tel = tel ? tel.trim().replace(/-/g, "") : "";
    if (tel.match(/^[0-9]{10,14}$/)) {
      return true;
    } else {
      return false;
    }
  },
  valid_zipcode: (value: string) => {
    value = value ? value.trim().replace("-", "") : "";
    if (value && value.match(/^[0-9]{7}$/)) {
      return true;
    } else {
      return false;
    }
  },
  validateEmail: (value: string) => {
    const findText1 = /(\.@)/;
    const findText2 = /(\.\.)/;
    if (value.match(findText1)) {
      return "@の前に.を使用することはできません";
    } else if (value.match(findText2)) {
      return ".を続けて使用することはできません";
    } else if (/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+)$/.test(value)) {
      return "";
    } else {
      return "メールアドレスを正しい形式で入力してください";
    }
  },
  validatePassword: (value: string) => {
    const ratz = /[a-z]/;
    const rAtZ = /[A-Z]/;
    const r0t9 = /[0-9]/;
    const ge8 = /.{8,}/;
    return (
      ratz.test(value) &&
      rAtZ.test(value) &&
      r0t9.test(value) &&
      ge8.test(value)
    );
    // return /^([a-zA-Z0-9]{8,})$/.test(value);
  },

  getDayOfWeek: (date: string) => {
    // date: ex. 2022-01-01
    // return: ex. {}
    interface dayInterface {
      [key: number]: string;
    }
    const japanese: dayInterface = {
      0: "日",
      1: "月",
      2: "火",
      3: "水",
      4: "木",
      5: "金",
      6: "土",
    };
    const english: dayInterface = {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
    };
    const day = new Date(
      new Date(date).toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
    ).getDay();
    return {
      ja: japanese[day],
      en: english[day],
    };
  },

  getJapaneseDate: (date: string, isWeek = true) => {
    if (!date) {
      return "";
    }
    return (
      date.slice(0, 4) +
      "年" +
      util.removeZero(date.slice(5, 7)) +
      "月" +
      util.removeZero(date.slice(8, 10)) +
      "日" +
      `${isWeek ? "（" + util.getDayOfWeek(date).ja + "）" : ""}`
    );
  },

  getJapaneseDateMonth: (date: string) => {
    if (!date) {
      return "";
    }
    return (
      util.removeZero(date.slice(5, 7)) +
      "月" +
      util.removeZero(date.slice(8, 10)) +
      "日" +
      "（" +
      util.getDayOfWeek(date).ja +
      "）"
    );
  },

  getJapaneseMonth: (date: string) => {
    return date.slice(0, 4) + "年" + util.removeZero(date.slice(5, 7)) + "月";
  },

  getPrice: (number: string, currency = true) => {
    let val = new Intl.NumberFormat("ja-JP", {
      style: "currency",
      currency: "JPY",
    }).format(parseInt(number));
    if (!currency) {
      val = val.replace("￥", "").trim();
    }
    return val;
  },

  // convert date into yyyy-mm-dd
  formatDate: (dt: any) => {
    const y = dt.getFullYear();
    const m = ("00" + (dt.getMonth() + 1)).slice(-2);
    const d = ("00" + dt.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
  },

  formatDateTime: (dt: any, noTime: false) => {
    if (!noTime) {
      return new Date(dt).toLocaleString("ja-JP", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    } else {
      return new Date(dt).toLocaleString("ja-JP", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    }
  },

  // convert 01 to 1
  removeZero: (num: string) => {
    if (num) {
      return parseInt(num).toString();
    } else {
      return num;
    }
  },

  // convert zenkaku to hankaku (full-width -> half-width)
  zenkakuToHankaku: (zenkaku: string) => {
    if (!zenkaku) {
      return zenkaku;
    }
    return zenkaku.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  },

  // convert hankaku to zenkaku (half-width -> full-width)
  hankakuToZenkaku: (hankaku: string) => {
    if (!hankaku) {
      return hankaku;
    }
    return hankaku
      .replace(/[A-Za-z0-9]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
      })
      .replace(/-/g, "ー")
      .replace(/ /g, "　");
  },

  // allow only hankaku
  allowHankaku: (str: string) => {
    while (str.match(/[^A-Z^a-z\d-]/)) {
      str = str.replace(/[^A-Z^a-z\d-]/, "");
    }
    return str;
  },

  closeAlert: (store: any) => {
    store.dispatch("alert/setVisiable", false);
  },

  openAlert: (
    store: any,
    confirm: any,
    cancel: any,
    message: any,
    callbackFnc: any,
    title: any = "",
    showArrow: any = false
  ) => {
    store.dispatch("alert/setInitAlert", {
      confirm_button: confirm,
      cancel_button: cancel,
      msg: message,
      title: title,
      showArrow: showArrow,
      callback: callbackFnc,
    });
    store.dispatch("alert/setVisiable", true);
  },

  showAlert: (store: any, mode = "") => {
    let msg = "ただいま準備中です。お電話でお申し込みください。";
    if (mode === "delivery") {
      msg =
        "ただいま準備中です。ダンボールの追加や配達日の変更についてはお電話でお申し込みください。";
    }
    util.openAlert(store, "OK", "", msg, "", util.closeAlert);
  },

  getDiffYears: (fromDate: string) => {
    return moment().diff(fromDate, "years");
  },

  calculateTax: (price: any, taxRate: any) => {
    return price * (1 + taxRate);
  },

  convertNewLine(content: any) {
    if (content && content !== "") {
      return content.replace(/\n/g, "<br />");
    } else {
      return content;
    }
  },

  downloadFile: (filename: string, data: any, mimeType: string) => {
    // Create a Blob URL
    const url = URL.createObjectURL(data);

    // Create an anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;

    // Trigger a click event on the anchor
    a.click();

    // Clean up by revoking the Blob URL
    URL.revokeObjectURL(url);
  },
};

export default util;
