
import apis from "@/js/apis";
import useRouteTo from "@/js/useRouteTo";
import util from "@/js/util";
import { defineComponent, inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import artbotMsg from "@/js/artbotMsg";

import MyPageDesktop from "./partials/MyPageDesktop.vue";
import MyPageMobile from "./partials/MyPageMobile.vue";
import MyPageRakumoMobile from "./partials/MyPageRakumoMobile.vue";
import MyPageRakumoDesktop from "./partials/MyPageRakumoDesktop.vue";
import saveLocalStorage from "@/js/saveLocalStorage";

export default defineComponent({
  components: {
    MyPageMobile,
    MyPageRakumoMobile,
    MyPageDesktop,
    MyPageRakumoDesktop,
  },
  setup() {
    const { routeTo } = useRouteTo();
    const route = useRoute();
    const store = useStore();
    const phase = inject("phase") as number;
    const currentInfo = ref({
      ukt_no_with_hyphen: "",
      old_zipcode: "",
      old_address: "",
      new_zipcode: "",
      new_address: "",
      month: "",
      day: "",
      week: "",
      year: "",
      time: "",
      price: "",
      paymentMethod: "",
      deliveryYear: "",
      deliveryMonth: "",
      deliveryDay: "",
      deliveryWeek: "",
      noDelivery: false,
      collectionYear: "",
      collectionMonth: "",
      collectionDay: "",
      collectionWeek: "",
      noCollection: false,
      furnitureYear: "",
      furnitureMonth: "",
      furnitureDay: "",
      furnitureWeek: "",
      furnitureDone: false,
      hasFurniture: "",
      isFurnitureCanceled: false,
      over1year: false,
      option_category_list: {},
      hasOption: false,
      isRakumo: false,
      isTrunkroom: false,
      newer_than_93: false,
      artbot: "",
      payment: {
        totalContractAmount: 0,
        amountAlreadyDeposited: 0,
        paymentName: "",
        paymentAmount: 0,
        paymentName2: "",
        paymentAmount2: 0,
        paymentName3: "",
        paymentAmount3: 0,
      },
      isGoodsProhibited: false,
    });
    const done = ref(false);
    const newestNews = ref({});
    const aqc_questionnaire = ref(false);

    const goToRakumo = () => {
      window.open("https://www.the0123.com/option/option/");
    };

    onMounted(async () => {
      store.dispatch("startLoading", true);
      let contract_id: string;
      let detail: any;
      // check if contract_id is in query params
      if (route.query.contract_id) {
        contract_id = route.query.contract_id as string;
        detail = await saveLocalStorage(contract_id);
      } else {
        let url = new URL(window.location.href);
        let params = url.searchParams;
        if (params.get("contract_id")) {
          contract_id = (params.get("contract_id") as string) || "";
          if (contract_id) {
            detail = await saveLocalStorage(contract_id);
          }
        } else {
          // get selected number in local storage
          contract_id =
            (localStorage.getItem("selectedNumber") as string) || "";
          if (contract_id) {
            detail = await saveLocalStorage(contract_id);
          }
        }
      }

      if (!contract_id) {
        // if no selected number, redirect to menu
        routeTo("MyPageMenu");
      } else {
        // check if detail is not empty
        if (detail && detail.ukt_no) {
          // save contract detail in store
          store.dispatch("mypage/updateContractDetail", detail);

          currentInfo.value.ukt_no_with_hyphen =
            detail.ukt_no.slice(0, 4) + "-" + detail.ukt_no.slice(4);

          // check if trunkroom or not
          for (let option of detail.option_category_list) {
            if (option.service_kbn == 11) {
              currentInfo.value.isTrunkroom = true;
              break;
            }
          }

          // check if option_category_list is empty
          for (let option of detail.option_category_list) {
            if (option.service_kbn) {
              currentInfo.value.hasOption = true;
              break;
            }
          }

          // get main info from moving_info_list
          let moving_date = ""; // for artbot api
          const moving_info = detail.moving_info_list[0];
          for (let info of moving_info.old_address_info_list) {
            if (info.is_main) {
              moving_date = info.moving_date;
              currentInfo.value.old_zipcode = info.zipcode;
              currentInfo.value.old_address = info.address;
              currentInfo.value.month = util.removeZero(
                info.moving_date.slice(5, 7)
              );
              currentInfo.value.day = util.removeZero(
                info.moving_date.slice(8, 10)
              );
              currentInfo.value.year = info.moving_date.slice(0, 4);
              currentInfo.value.week = util.getDayOfWeek(info.moving_date).ja;
              currentInfo.value.time = info.visiting_time
                ? info.visiting_time
                : info.moving_time;
              break;
            }
          }
          let delivery_date = ""; // for artbot api
          for (let info of moving_info.new_address_info_list) {
            if (info.is_main) {
              delivery_date = info.delivery_date;
              currentInfo.value.new_zipcode = info.zipcode;
              currentInfo.value.new_address = info.address;
              // done.value = new Date(info.delivery_date) < new Date(new Date().toLocaleString('ja-JA', {timeZone: 'Asia/Tokyo'}));
              // currentInfo.value.month = info.moving_date.slice(5, 7);
              // currentInfo.value.day = info.moving_date.slice(8, 10);
              // currentInfo.value.week = util.getDayOfWeek(info.moving_date).ja;
              // currentInfo.value.time = info.moving_time;
              // done.value =
              //   new Date(info.moving_date).toLocaleDateString() <
              //   new Date(new Date().toLocaleString('ja-JA', {timeZone: 'Asia/Tokyo'})).toLocaleDateString();
              break;
            }
          }

          // check if today is newer than the latest date
          const latestDate =
            (localStorage.getItem("latest_date") as string) || "";
          if (latestDate) {
            let target = new Date(latestDate + "T00:00");
            target.setDate(target.getDate() + 1);
            if (
              new Date(
                new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
              ) >= target
            ) {
              done.value = true;

              // check if today is newer than 93 days after the latest moving_date
              target = new Date(latestDate + "T00:00");
              target.setDate(target.getDate() + 93);
              if (
                new Date(
                  new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
                ) >= target
              ) {
                currentInfo.value.newer_than_93 = true;

                // check if today is newer than 365 days after the latest moving_date
                target = new Date(latestDate + "T00:00");
                target.setFullYear(target.getFullYear() + 1);
                if (
                  new Date(
                    new Date().toLocaleString("ja-JA", {
                      timeZone: "Asia/Tokyo",
                    })
                  ) >= target
                ) {
                  currentInfo.value.over1year = true;
                }
              }
            }
          }

          // payment
          currentInfo.value.paymentMethod =
            detail.payment_method +
            (detail.has_other_payment ? "/その他支払方法あり" : "");
          currentInfo.value.price = detail.amount_paid_on_the_day
            ? util.getPrice(detail.amount_paid_on_the_day)
            : "-";
          currentInfo.value.payment.totalContractAmount =
            detail.total_contract_amount;
          currentInfo.value.payment.amountAlreadyDeposited =
            detail.amount_already_deposited;
          currentInfo.value.payment.paymentName = detail.payment_name;
          currentInfo.value.payment.paymentName2 = detail.payment_name_2;
          currentInfo.value.payment.paymentName3 = detail.payment_name_3;
          currentInfo.value.payment.paymentAmount = detail.payment_amount;
          currentInfo.value.payment.paymentAmount2 = detail.payment_amount_2;
          currentInfo.value.payment.paymentAmount3 = detail.payment_amount_3;

          // whether goods should be prohibited or not
          currentInfo.value.isGoodsProhibited = detail.is_goods_prohibited;
          localStorage.setItem(
            "is_goods_prohibited",
            JSON.stringify(detail.is_goods_prohibited)
          );
          window.dispatchEvent(
            new CustomEvent("is_goods_prohibited-changed", {
              detail: {
                is_goods_prohibited: detail.is_goods_prohibited,
              },
            })
          );

          // for delivery date
          if (detail.material_delivery_date) {
            // check if detail.material_delivery_date is valid
            if (
              new Date(detail.material_delivery_date).toString() !==
              "Invalid Date"
            ) {
              // 2022/8/1 update -> we don't have to do this on frontend
              // then, get the most recent date
              // const delivery_list = await apis.getAllDeliveries(contract_id);
              // let d_item = {} as any;
              // let today = new Date(util.formatDate(new Date(new Date().toLocaleString('ja-JA', {timeZone: 'Asia/Tokyo'}))));
              // for (let item of delivery_list) {
              //   if (!Object.keys(d_item).length) {
              //     d_item = item;
              //   } else if (new Date(d_item.delivery_date) < today) {
              //     if (
              //       new Date(d_item.delivery_date) < new Date(item.delivery_date)
              //     ) {
              //       d_item = item;
              //     }
              //   } else if (new Date(d_item.delivery_date) > today) {
              //     if (
              //       today <= new Date(item.delivery_date) &&
              //       new Date(item.delivery_date) < new Date(d_item.delivery_date)
              //     ) {
              //       d_item = item;
              //     }
              //   }
              //   if (new Date(d_item.delivery_date) == today) {
              //     break;
              //   }
              // }
              // currentInfo.value.deliveryYear = d_item.delivery_date.slice(0, 4);
              // currentInfo.value.deliveryMonth = util.removeZero(
              //   d_item.delivery_date.slice(5, 7)
              // );
              // currentInfo.value.deliveryDay = util.removeZero(
              //   d_item.delivery_date.slice(8, 10)
              // );
              // currentInfo.value.deliveryWeek = util.getDayOfWeek(
              //   d_item.delivery_date
              // ).ja;
              currentInfo.value.deliveryYear =
                detail.material_delivery_date.slice(0, 4);
              currentInfo.value.deliveryMonth = util.removeZero(
                detail.material_delivery_date.slice(5, 7)
              );
              currentInfo.value.deliveryDay = util.removeZero(
                detail.material_delivery_date.slice(8, 10)
              );
              currentInfo.value.deliveryWeek = util.getDayOfWeek(
                detail.material_delivery_date
              ).ja;
            }
          } else {
            currentInfo.value.noDelivery = true;
          }

          // for collecton date
          if (detail.material_collection_date) {
            if (
              new Date(detail.material_collection_date).toString() !==
              "Invalid Date"
            ) {
              currentInfo.value.collectionYear =
                detail.material_collection_date.slice(0, 4);
              currentInfo.value.collectionMonth = util.removeZero(
                detail.material_collection_date.slice(5, 7)
              );
              currentInfo.value.collectionDay = util.removeZero(
                detail.material_collection_date.slice(8, 10)
              );
              currentInfo.value.collectionWeek = util.getDayOfWeek(
                detail.material_collection_date
              ).ja;
            }
          } else {
            currentInfo.value.noCollection = true;
          }
          // save currentInfo.value.noCollection (hasCollection) in local storage
          localStorage.setItem(
            "hasCollection",
            JSON.stringify(!currentInfo.value.noCollection)
          );
          window.dispatchEvent(
            new CustomEvent("hasCollection-changed", {
              detail: {
                hasCollection: !currentInfo.value.noCollection,
              },
            })
          );

          // for furniture date
          if (detail.moving_furniture_date) {
            if (
              new Date(detail.moving_furniture_date).toString() !==
              "Invalid Date"
            ) {
              currentInfo.value.hasFurniture = "yes";
              currentInfo.value.furnitureYear =
                detail.moving_furniture_date.slice(0, 4);
              currentInfo.value.furnitureMonth = util.removeZero(
                detail.moving_furniture_date.slice(5, 7)
              );
              currentInfo.value.furnitureDay = util.removeZero(
                detail.moving_furniture_date.slice(8, 10)
              );
              currentInfo.value.furnitureWeek = util.getDayOfWeek(
                detail.moving_furniture_date
              ).ja;

              const target = new Date(detail.moving_furniture_date + "T00:00");
              target.setDate(target.getDate() + 1);
              currentInfo.value.furnitureDone =
                new Date(
                  new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
                ) >= target;
            } else {
              currentInfo.value.hasFurniture = "undecided";
            }
          } else {
            currentInfo.value.hasFurniture = "no";
          }
          currentInfo.value.isFurnitureCanceled =
            detail.moving_furniture_is_canceled;

          currentInfo.value.option_category_list = detail.option_category_list;

          // activate rakumo with phase3
          if (phase > 2) {
            currentInfo.value.isRakumo = detail.is_rakumo;
          }

          // save currentInfo in store
          store.dispatch("mypage/updateCurrentInfo", currentInfo);
          // save done in store
          store.dispatch("mypage/updateDone", done);

          window.dispatchEvent(
            new CustomEvent("ukt_no-localstorage-changed", {
              detail: {
                ukt_no: localStorage.getItem("ukt_no"),
              },
            })
          );

          // check if the newest news is already read
          newestNews.value = await apis.checkNewsFlag();

          // get artbot message
          if (phase > 1) {
            const query_material_delivery_date =
              new Date(detail.material_delivery_date).toString() !==
              "Invalid Date"
                ? "&material_delivery_date=" + detail.material_delivery_date
                : "";
            const query_moving_date =
              new Date(moving_date).toString() !== "Invalid Date"
                ? "&moving_date=" + moving_date
                : "";
            const query_delivery_date =
              new Date(delivery_date).toString() !== "Invalid Date"
                ? "&delivery_date=" + delivery_date
                : "";
            const res = await apis.callAPI(
              "get",
              apis.endpoints.getArtbot +
                "?contract_id=" +
                contract_id +
                query_material_delivery_date +
                query_moving_date +
                query_delivery_date +
                "&is_goods_prohibited=" +
                detail.is_goods_prohibited.toString(),
              store.state.jwt
            );
            if (res && res.data.status === "success") {
              currentInfo.value.artbot =
                artbotMsg[store.state.device][res.data.result.code] ||
                res.data.result.code;
            }
          } else {
            if (done.value) {
              if (store.state.device === "mobile") {
                currentInfo.value.artbot =
                  "この度はご利用ありがとうございました。";
              } else {
                currentInfo.value.artbot =
                  "この度はご利用ありがとうございました。また次回もよろしくお願いします！";
              }
            } else {
              if (store.state.device === "mobile") {
                currentInfo.value.artbot = "引越マイページへ<br>ようこそ！";
              } else {
                currentInfo.value.artbot =
                  "初めまして　僕の名前はアーボットです。「引越マイページ」へようこそ！";
              }
            }
          }

          // check AQC questionnaire
          let target_for_aqc = new Date(moving_date + "T00:00");
          if (
            new Date(
              new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
            ) >= target_for_aqc
          ) {
            aqc_questionnaire.value = false;
          } else {
            if (detail.is_aqc_read) {
              aqc_questionnaire.value = false;
            } else {
              aqc_questionnaire.value = true;
            }
          }
        }
      }
    });
    return {
      currentInfo,
      done,
      newestNews,
      goToRakumo,
      aqc_questionnaire,
    };
  },
});
