
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
    }
  },
  data: function () {
    return {
      params: {}
    };
  },

  setup(props) {
    const store = useStore();

    const computeClass = computed(() => {
      if (store.state.device == "mobile") {
        return "steps mobile";
      } else {
        return "steps pc";
      }
    });

    const styleWidth = computed(() => {
      if (store.state.device == "mobile") {
        return {};
      } else {
        return { width: ((90 - ( props.items.length * 6 )) / props.items.length) + 'rem' };
      }
    });


    return {
      computeClass,
      styleWidth
    };
  },
});
