
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import { defineComponent } from "vue";


export default defineComponent({
  components: {
    Logo,
    Footer,
  },
  setup() {
    console.log('setup')
  },
});
