import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "clearfix" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "addInformation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_text_block = _resolveComponent("text-block")!
  const _component_container_block = _resolveComponent("container-block")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Logo, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$store.state.device === 'mobile')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_text_block, {
              title: "ご契約情報の登録申請を承りました",
              text: "マイページにご契約情報が反映されるまで、最大２営業日お待たせすることがございます。 <br/><br/>反映でき次第お知らせいたします。ご利用開始まで、いましばらくお待ちください。"
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_container_block, {
              title: "ご契約情報の登録申請完了",
              text: "ご契約情報の登録申請を承りました。 <br/>マイページにご契約情報が反映されるまで、最大２営業日お待たせすることがございます。 <br/><br/>反映でき次第お知らせいたします。ご利用開始まで、いましばらくお待ちください。",
              width: "26.55vw"
            })
          ]))
    ]),
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Footer, {
          key: 1,
          className: "mt"
        }))
      : _createCommentVNode("", true)
  ]))
}