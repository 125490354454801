
import ContactDetail from "@/components/confirmation/desktop/ContactDetailArrival.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { defineComponent } from "vue";

export default defineComponent({
  components: { ContactDetail, Logo, Footer },
  props: {
    detail: {
      type: Object,
    },
  },
});
