
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    bgImage: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const style = computed(() => {
      return {
        "background-image": `url(${props.bgImage})`,
      };
    });
    return {
      style,
    };
  },
});
