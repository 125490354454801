import router from "@/router";
import store from "@/store";
import errors from "./errors";
import * as Sentry from "@sentry/vue";

const prefix = process.env.VUE_APP_API_ENDPOINT;
const apis = {
  get: (endpoint: string, jwt = "") => {
    const headers = new Headers();
    if (jwt) {
      headers.set("Authorization", "Bearer " + jwt);
    }
    return fetch(endpoint, {
      headers: headers,
    });
  },
  post: (endpoint: string, jwt = "", body: any, method = "POST") => {
    const headers = new Headers();
    if (jwt) {
      headers.set("Authorization", "Bearer " + jwt);
    }
    headers.set("Content-Type", "application/json");
    return fetch(endpoint, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    });
  },
  postForm: (endpoint: string, jwt = "", body: any) => {
    const headers = new Headers();
    if (jwt) {
      headers.set("Authorization", "Bearer " + jwt);
    }
    headers.set("Content-Type", "application/x-www-form-urlencoded");
    return fetch(endpoint, {
      method: "POST",
      // headers: headers,
      body: body,
    });
  },
  delete: async (endpoint: string, jwt = "", body = {}) => {
    store.dispatch("resetErrMsg");

    // check if online
    if (!navigator.onLine) {
      router.push({
        name: "ErrorOccurred",
        params: {
          title: "インターネットに接続されていません",
          showMsg: "no",
        },
      });
      return;
    }

    store.dispatch("startLoading", true);

    const headers = new Headers();
    if (jwt) {
      headers.set("Authorization", "Bearer " + jwt);
    }
    interface optionsIF {
      method: string;
      headers: Headers;
      body?: any;
    }
    const options = {
      method: "DELETE",
      headers: headers,
    } as optionsIF;
    if (Object.keys(body).length) {
      options.body = JSON.stringify(body);
      headers.set("Content-Type", "application/json");
    }
    const result = await fetch(endpoint, options)
      .then((res) => {
        console.log("api call: " + endpoint);
        console.log("res.status: " + res.status);
        if (res.status == 422) {
          return res.json();
        }
      })
      .then((data) => {
        console.log(data);
        console.log("responseのdata: " + JSON.stringify(data));
        if (data) {
          let errorCode = "";
          let errorMsg = "";
          if (data.status === "error") {
            try {
              errorCode = data.result.type;
              errorMsg = data.result.msg;
            } catch {
              try {
                errorCode = data.results[0].type;
                errorMsg = data.results[0].msg;
              } catch {
                try {
                  errorCode = data.results.type;
                  errorMsg = data.results.msg;
                } catch {
                  console.log("endpoint: " + endpoint);
                  console.log("body: " + JSON.stringify(body));
                  Sentry.captureMessage(
                    "data.result.typeでエラーコード取得できず: " +
                      endpoint +
                      ", DELETE, " +
                      JSON.stringify(body)
                  );
                  router.push({
                    name: "ErrorOccurred",
                    params: {
                      title: "予期せぬエラーが発生しました",
                      showMsg: "no",
                    },
                  });
                }
              }
            }
            if (errorCode in errors.errorPages) {
              router.push({
                name: "ErrorOccurred",
                params: { title: errors.errorPages[errorCode], showMsg: "no" },
              });
            } else if (errorCode in errors.errorPagesWithMsg) {
              Sentry.captureMessage(
                errorCode +
                  ": " +
                  errors.errorPagesWithMsg[errorCode] +
                  ", " +
                  endpoint
              );
              router.push({
                name: "ErrorOccurred",
                params: {
                  title: errors.errorPagesWithMsg[errorCode],
                  showMsg: "yes",
                },
              });
            } else if (errorCode in errors.errorMsgs) {
              store.dispatch("showErrMsg", errors.errorMsgs[errorCode]);
              store.dispatch("startLoading", false);
            } else if (!errorCode) {
              // エラーコードが取得できなかった時
              console.log("cannot get an error code");
            } else {
              console.log("endpoint: " + endpoint);
              console.log("body: " + JSON.stringify(body));
              Sentry.captureMessage(
                "エラーコードにないエラー: " +
                  errorCode +
                  ", " +
                  errorMsg +
                  ", " +
                  endpoint +
                  ", DELETE, " +
                  JSON.stringify(body)
              );
              router.push({
                name: "ErrorOccurred",
                params: {
                  title: "予期せぬエラーが発生しました",
                  showMsg: "no",
                },
              });
            }
          }
        } else {
          store.dispatch("startLoading", false);
          return "success";
        }
      })
      .catch((reason) => {
        Sentry.captureException(reason);
        router.push({
          name: "ErrorOccurred",
          params: {
            title: "予期せぬエラーが発生しました",
            showMsg: "no",
          },
        });
      });
    return result;
  },
  callAPI: async (
    method: string,
    endpoint: string,
    jwt = "",
    body = {},
    dl = false // for file download
  ) => {
    store.dispatch("resetErrMsg");

    // check if online
    if (!navigator.onLine) {
      router.push({
        name: "ErrorOccurred",
        params: {
          title: "インターネットに接続されていません",
          showMsg: "no",
        },
      });
      return;
    }

    if (store.state.device === "pc") {
      store.dispatch("startLoading", true);
    }
    let fn;
    if (method === "get") {
      fn = apis.get(endpoint, jwt);
    } else if (method === "post") {
      fn = apis.post(endpoint, jwt, body);
    } else if (method === "put") {
      fn = apis.post(endpoint, jwt, body, "PUT");
    } else {
      fn = apis.postForm(endpoint, jwt, body);
    }
    let status: number;
    const result = await fn
      .then((res) => {
        status = res.status;
        if (!dl) {
          return res.json();
        } else {
          return res.blob();
        }
      })
      .then((data) => {
        let errorCode = "";
        let errorMsg = "";

        if (data.status === "error") {
          try {
            errorCode = data.result.type;
            errorMsg = data.result.msg;
          } catch {
            try {
              errorCode = data.results[0].type;
              errorMsg = data.results[0].msg;
            } catch {
              try {
                errorCode = data.results.type;
                errorMsg = data.results.msg;
              } catch {
                console.log("endpoint: " + endpoint + " method: " + method);
                console.log("body: " + JSON.stringify(body));
                Sentry.captureMessage(
                  "data.result.typeでエラーコード取得できず: " +
                    endpoint +
                    ", " +
                    method +
                    ", " +
                    JSON.stringify(body)
                );
                router.push({
                  name: "ErrorOccurred",
                  params: {
                    title: "予期せぬエラーが発生しました",
                    showMsg: "no",
                  },
                });
              }
            }
          }
          if (errorCode === "contract.not.found") {
            localStorage.setItem("selectedNumber", "");
            localStorage.setItem("ukt_no", "");
            localStorage.setItem("moving_date", "");
            localStorage.setItem("latest_date", "");
            localStorage.setItem("moving_furniture_date", "");
            localStorage.setItem(
              "is_furniture_canceled",
              JSON.stringify(false)
            );

            router.push({
              name: "ErrorOccurred",
              params: { title: errors.errorPages[errorCode], showMsg: "no" },
            });
          } else if (errorCode in errors.errorPages) {
            router.push({
              name: "ErrorOccurred",
              params: { title: errors.errorPages[errorCode], showMsg: "no" },
            });
          } else if (errorCode in errors.errorPagesWithMsg) {
            Sentry.captureMessage(
              errorCode +
                ": " +
                errors.errorPagesWithMsg[errorCode] +
                ", " +
                endpoint
            );
            router.push({
              name: "ErrorOccurred",
              params: {
                title: errors.errorPagesWithMsg[errorCode],
                showMsg: "yes",
              },
            });
          } else if (errorCode in errors.errorMsgs) {
            store.dispatch("showErrMsg", errors.errorMsgs[errorCode]);
            store.dispatch("startLoading", false);
          } else if (!errorCode) {
            // エラーコードが取得できなかった時
            console.log("cannot get an error code");
          } else {
            console.log("endpoint: " + endpoint + " method: " + method);
            console.log("body: " + JSON.stringify(body));
            Sentry.captureMessage(
              "エラーコードにないエラー: " +
                errorCode +
                ", " +
                errorMsg +
                ", " +
                endpoint +
                ", " +
                method +
                ", " +
                JSON.stringify(body)
            );
            router.push({
              name: "ErrorOccurred",
              params: {
                title: "予期せぬエラーが発生しました",
                showMsg: "no",
              },
            });
          }
        }
        store.dispatch("startLoading", false);
        return {
          data: data,
          errorCode: errorCode,
          status: status,
        };
      })
      .catch((reason) => {
        Sentry.captureException(reason);
        router.push({
          name: "ErrorOccurred",
          params: {
            title: "予期せぬエラーが発生しました",
            showMsg: "no",
          },
        });
      });
    return result;
  },
  // get user info
  getUserInfo: async () => {
    const result = await apis.callAPI(
      "get",
      apis.endpoints.getUserInfo,
      store.state.jwt
    );
    if (result && result.data.status === "success") {
      return result.data.result;
    }
  },
  // get contract detail from contract_id
  getContractDetail: async (contract_id: string) => {
    const result = await apis.callAPI(
      "get",
      apis.endpoints.getContractDetail + contract_id,
      store.state.jwt
    );
    if (result && result.data.status === "success") {
      const data = result.data;

      if (data.result && data.result.moving_info_list) {
        // sort moving_info_list by moving_date
        data.result.moving_info_list = data.result.moving_info_list.sort(
          function (a: any, b: any) {
            return a.old_address_info_list[0].moving_date <
              b.old_address_info_list[0].moving_date
              ? -1
              : 1;
          }
        );
      }
      return data.result;
    }
  },
  // delete contact
  delContact: async (contract_id: string) => {
    const result = await apis.delete(
      apis.endpoints.delContract + contract_id,
      store.state.jwt
    );
    return result;
  },
  // check if there's contract info in store and if no, get contract detail
  checkIfContractDetailInStore: async () => {
    if (!Object.keys(store.state.mypage.contractDetail).length) {
      // get selected number in local storage
      const contract_id =
        (localStorage.getItem("selectedNumber") as string) || "";
      if (!contract_id) {
        // if no selected number, redirect to menu
        router.push({ name: "MyPageMenu" });
        return {};
      } else {
        const detail = await apis.getContractDetail(contract_id);
        if (detail) {
          // save contract detail in store
          store.dispatch("mypage/updateContractDetail", detail);
          if (detail.is_rakumo) {
            router.push({ name: "MyPage" });
            return {};
          } else {
            return detail;
          }
        } else {
          return {};
        }
      }
    } else {
      // check if is_rakumo
      if (store.state.mypage.contractDetail.is_rakumo) {
        router.push({ name: "MyPage" });
        return {};
      } else {
        return store.state.mypage.contractDetail;
      }
    }
  },
  //check if confirmation is already finished
  checkIfConfirmationIsDone: async () => {
    const result = await apis.callAPI(
      "get",
      apis.endpoints.checkIfConfirmationIsDone.replace(
        "{}",
        store.state.confirmation.contract_id
      ) +
        "?arrival_departure_val=" +
        store.state.confirmation.arrival_departure_val,
      store.state.jwt
    );
    if (result && result.data.status === "success") {
      return result.data.result.exist;
    } else {
      return;
    }
  },
  // confirm confirmation
  confirmConfirmation: async () => {
    const body = {
      ukt_no: store.state.confirmation.contractDetail.ukt_no,
      arrival_departure_val: store.state.confirmation.arrival_departure_val,
    };
    const result = await apis.callAPI(
      "post",
      apis.endpoints.confirmConfirmation,
      store.state.jwt,
      body
    );
    if (result && result.data.status === "success") {
      return "success";
    } else {
      return "failure";
    }
  },
  // get all news
  getAllNews: async () => {
    const result = await apis.callAPI("get", apis.endpoints.getAllNews);
    if (result && result.data.status === "success") {
      const data = result.data;
      store.dispatch("saveNews", data.results);
      return data.results;
    } else {
      return [];
    }
  },
  // check if the newest news is already read
  checkNewsFlag: async () => {
    // get the newest news of all news
    const allNews = await apis.getAllNews();
    if (allNews.length) {
      const newestNews = allNews[0];
      // check if the newest news is already read
      const result = await apis.callAPI(
        "get",
        apis.endpoints.checkNewsFlag.replace("{}", newestNews.id),
        store.state.jwt
      );
      if (result && result.data.status === "success") {
        const flag = result.data.result.is_read;
        if (!flag) {
          return newestNews;
        } else {
          return {};
        }
      } else {
        return {};
      }
    } else {
      return {};
    }
  },
  updateNewsFlag: async (id: string) => {
    store.dispatch("startLoading", true);
    const res = await apis.callAPI(
      "get",
      apis.endpoints.updateNewsFlag + id,
      store.state.jwt
    );
    if (res && res.data.status === "success") {
      return true;
    } else {
      return;
    }
  },
  // get available date
  getAvailableDate: async (
    which: string,
    contract_id: string,
    zipcode: string,
    address_id?: number,
    is_ekoraku?: number,
    delivery_date?: string
  ) => {
    let endpoints: string;
    interface bodyInterface {
      zip_code: string;
      address_id?: number;
      is_ekoraku?: number;
      delivery_date?: string;
    }
    const body = {
      zip_code: zipcode,
    } as bodyInterface;
    if (which === "delivery") {
      endpoints = apis.endpoints.getDeliveryAvailable.replace(
        "{}",
        contract_id
      );
      body.address_id = address_id;
      body.is_ekoraku = is_ekoraku;
      body.delivery_date = delivery_date;
    } else if (which === "furniture") {
      endpoints = apis.endpoints.getFurnitureAvailable.replace(
        "{}",
        contract_id
      );
    } else {
      endpoints = apis.endpoints.getCollectionAvailable.replace(
        "{}",
        contract_id
      );
    }
    let res: any;
    if (which === "furniture") {
      res = await apis.callAPI("get", endpoints, store.state.jwt);
    } else {
      res = await apis.callAPI("post", endpoints, store.state.jwt, body);
    }
    if (res && res.data.status === "success") {
      if (res && !res.data.result.available_datetime.length) {
        return "no_available_date";
      } else {
        return res.data.result;
      }
    } else {
      return;
    }
  },
  getAllDeliveries: async (contract_id: any) => {
    const result = await apis.callAPI(
      "get",
      apis.endpoints.getAllDeliveries.replace("{}", contract_id),
      store.state.jwt
    );
    if (result && result.data.status === "success") {
      return result.data.result;
    } else {
      return;
    }
  },
  getFileNumber: async (contract_id: string) => {
    const result = await apis.callAPI(
      "get",
      apis.endpoints.getMypocketNumber.replace("{}", contract_id),
      store.state.jwt
    );
    if (result && result.data.status === "success") {
      return result.data.result;
    } else {
      return;
    }
  },
  endpoints: {
    // account
    login: prefix + "login",
    register: prefix + "register",
    activate: prefix + "activate",
    forgetPassword: prefix + "password-recovery",
    checkToken: prefix + "check_token",
    resetPassword: prefix + "reset-password",
    editEmail: prefix + "users",
    editEmailActivate: prefix + "users/update_email",
    editPassword: prefix + "users",
    getUserInfo: prefix + "users/me",

    // contract
    postNumber: prefix + "ukt_no/check",
    getContracts: prefix + "contracts",
    getContractDetail: prefix + "contracts/",
    delContract: prefix + "contracts/",

    // confirmation
    checkIfConfirmationIsDone: prefix + "contracts/{}/confirm",
    confirmConfirmation: prefix + "notifications/confirm_moving_date",

    // news
    getAllNews: prefix + "announcements",
    checkNewsFlag: prefix + "announcements/{}/check_read",
    updateNewsFlag: prefix + "announcements/",

    // delivery
    getAllDeliveries: prefix + "contracts/{}/deliveries",
    getDeliveryAvailable: prefix + "contracts/{}/deliveries/available_date",
    updateDelivery: prefix + "contracts/{}/deliveries",

    // collection
    getCollection: prefix + "contracts/{}/collections",
    createCollection: prefix + "contracts/{}/collections",
    editCollection: prefix + "contracts/{}/collections",
    deleteCollection: prefix + "contracts/{}/collections",
    getCollectionAvailable: prefix + "contracts/{}/collections/available_date",

    // furniture
    getFurniture: prefix + "contracts/{}/furnitures",
    createFurniture: prefix + "contracts/{}/furnitures",
    getFurnitureAvailable: prefix + "contracts/{}/furnitures/available_date",

    // goods
    getRecommendedProducts: prefix + "recommended_products",
    getAllGoods: prefix + "goods",
    getGoodsDetail: prefix + "goods/{}",
    getGoodsDetailPreview: prefix + "goods/{}/preview",
    getBookedGoods: prefix + "goods/{}/order",
    orderGoods: prefix + "goods/{}/order",
    deleteGoods: prefix + "goods/{}/order",

    // artbot
    getArtbot: prefix + "abot_kun",

    // zipcode
    getAddress: prefix + "masters/zipcode",

    // inquiry
    getInquiryParams: prefix + "inquiry/{}/claim_params",
    postInquiry: prefix + "inquiry",

    // mypocket
    getMypocketList: prefix + "mypocket/{}",
    getMypocketNumber: prefix + "mypocket/{}/new_file_num",
    downloadMypocket: prefix + "mypocket/{}/files/",
    updateReceiptFlag: prefix + "mypocket/{}/files/receipt/",

    // infomail
    downloadInfomail: prefix + "info_mail/{}/files/",
  },
};

export default apis;
