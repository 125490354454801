
import BaseButton from "@/components/BaseButton.vue";
import EmailAndPassword from "@/components/account/EmailAndPassword.vue";
import { defineComponent, ref } from "vue";
import useRouteTo from "../../js/useRouteTo";
import ContainerBlock from "@/components/account/ContainerBlock.vue";
import InputBlock from "@/components/account/InputBlock.vue";
import LoginBlock from "@/components/account/LoginBlock.vue";
import LabelAndInput from "@/components/account/LabelAndInput.vue";

import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import { useRoute, useRouter } from "vue-router";
import apis from "../../js/apis";
import errors from "../../js/errors";
import util from "@/js/util";
// import { useStore } from "vuex";
import store from "@/store";
import checkDevice from "@/js/checkDevice";

export default defineComponent({
  components: {
    EmailAndPassword,
    BaseButton,
    ContainerBlock,
    InputBlock,
    LoginBlock,
    LabelAndInput,
    Logo,
    Footer,
  },
  setup() {
    const { routeTo } = useRouteTo();
    const route = useRoute();
    const router = useRouter();
    const email = ref("");
    const password = ref("");
    const errMsg1 = ref("");
    const errMsg2 = ref("");
    const forgetPasswordLink = ref(
      '<a href="/#/account/forgetpassword" class="bullet pink">【パスワードをお忘れの方はこちら】</a>'
    );
    const loginLink = ref(
      '<a href="/#/account/create" class="bullet pink">【はじめてご利用の方はこちら】</a>'
    );
    const helpLink = ref(
      '<a href="/#/account/help" class="bullet pink">【アカウント作成・ログインでお困りの方はこちら】</a>'
    );
    // const store = useStore();
    // const inputEmail = (email: string) => {
    //   store.dispatch("account/inputEmail", email.trim());
    // };
    // const inputPassword = (password: string) => {
    //   store.dispatch("account/inputPassword", password.trim());
    // };
    const submit = async () => {
      // validation
      // email validation
      if (email.value) {
        errMsg1.value = "";
      } else {
        errMsg1.value = "メールアドレスを入力してください";
      }
      // password validation
      if (password.value) {
        errMsg2.value = "";
      } else {
        errMsg2.value = "パスワードを入力してください";
      }

      if (!errMsg1.value && !errMsg2.value) {
        // start loading
        store.dispatch("startLoading", true);

        let line_user_id = "";
        // Get query params

        // this doesn't work on some iPhone
        let params = route.query.line_id;

        if (params) {
          line_user_id = params.toString();
        } else {
          let url = new URL(window.location.href);
          let iosParams = url.searchParams;
          line_user_id = iosParams.get("line_id") || "";
        }

        let is_pc;
        if (store.state.device === "pc") {
          is_pc = "1";
        } else {
          is_pc = "0";
        }

        // API Call -> POST email and password
        let formData = new FormData();
        formData.append("username", email.value);
        formData.append("password", password.value);
        formData.append("client_id", line_user_id);
        formData.append("is_pc", is_pc);

        const result = await apis.callAPI(
          "postForm",
          apis.endpoints.login,
          "",
          formData
        );

        if (result && result.data.status === "success") {
          let data = result.data;

          // clear local storagea
          localStorage.clear();

          // clear vuex store
          store.reset();

          // check if device is mobile or pc
          checkDevice();

          // save JWT in local storage and store
          const jwt = data.result.access_token;
          localStorage.setItem("jwt", jwt);
          store.dispatch("saveJwt", jwt);

          store.dispatch("startLoading", true);
          // get all contracts
          const result2 = await apis.callAPI(
            "get",
            apis.endpoints.getContracts,
            jwt
          );
          if (result2 && result2.data.status === "success") {
            data = result2.data;
            // check if there is at least one contract
            if (data.results.length) {
              // sort and save contracts in store
              // before sorting, replace null with "" for moving_date
              data.results.forEach((element: any) => {
                if (!element.moving_date) {
                  element.moving_date = "";
                }
              });
              const newData = data.results.sort(function (a: any, b: any) {
                return a.moving_date > b.moving_date ? -1 : 1;
              });
              store.dispatch("mypage/updateContracts", newData);

              // check if at least one contract is "already issued"
              let issued = false;
              let latestContract: any;
              for (let contract of newData) {
                if (contract.status == 1) {
                  issued = true;
                  latestContract = contract;
                  break;
                }
              }
              if (issued) {
                // check if the newest contract date is more than 180 days ago or not
                let target = new Date(latestContract.moving_date + "T00:00");
                target.setDate(target.getDate() + 180);
                if (
                  target <=
                  new Date(
                    new Date().toLocaleString("ja-JA", {
                      timeZone: "Asia/Tokyo",
                    })
                  )
                ) {
                  routeTo("MyPageMenu");
                } else {
                  // check if "selectedNumber" exists in localStorage
                  // -> 2022/07/19 edit
                  // when logging in with ACCOUNT:A before ACCOUNT:B's logout (use URL directly or after jwt expiration),
                  // if it tries to look at "selectedNumber" in localStorage, it causes the error
                  // because "selectedNumber" in localStorage is ACCOUNT:B's, not ACCOUNT:A's
                  // so it should not look at "selectedNumber" in localStorage

                  // if (!localStorage.getItem("selectedNumber")) {
                  // set the newest contract to "selectedNumber" before going to MyPage
                  localStorage.setItem(
                    "selectedNumber",
                    latestContract.contract_id
                  );
                  localStorage.setItem("ukt_no", latestContract.ukt_no);
                  localStorage.setItem(
                    "moving_date",
                    latestContract.moving_date
                  );
                  localStorage.setItem(
                    "latest_date",
                    latestContract.final_delivery_date
                  );
                  localStorage.setItem(
                    "latest_date",
                    latestContract.moving_furniture_date
                  );

                  // check if next is in query params
                  const next = route.query.next as string;
                  if (next) {
                    router.push(next);
                  } else {
                    routeTo("MyPage");
                  }
                }
              } else {
                // still processing and/or failed -> go to Menu
                console.log("still processing and/or failed");
                routeTo("MyPageMenu");
              }
            } else {
              // no contracts -> AddInformation
              routeTo("AddInformationFromLogin");
            }
          }
        }
      } else {
        window.scrollTo(0, 0);
      }
    };
    const forgetpassword = () => {
      routeTo("ForgotPassword");
    };
    const createAccount = () => {
      routeTo("CreateAccount");
    };
    const createLineAccount = () => {
      window.open(
        "https://liff.line.me/1655278311-ZrAQKq6K/2fb1172c297c49c39a6f3149944d87da",
        "_blank"
      );
    };
    return {
      email,
      password,
      errMsg1,
      errMsg2,
      // inputEmail,
      // inputPassword,
      submit,
      forgetpassword,
      createAccount,
      createLineAccount,
      loginLink,
      helpLink,
      forgetPasswordLink,
    };
  },
});
