
import { defineComponent, computed, inject, ref, onMounted } from "vue";
import Popup from "@/components/Popup.vue";
import FloatingQA from "@/components/FloatingQA.vue";

import logout from "@/js/logout";
import apis from "@/js/apis";
import { useStore } from "vuex";
import util from "@/js/util";
import { useRoute } from "vue-router";
import useRouteTo from "@/js/useRouteTo";
import saveLocalStorage from "@/js/saveLocalStorage";
import openAQC from "@/js/openAQC";

export default defineComponent({
  components: { Popup, FloatingQA },
  props: {
    hasLink: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { routeTo } = useRouteTo();
    const route = useRoute();
    const store = useStore();
    const ukt_no = ref("");
    const marginTop = ref("0px");
    const paddingTop = ref("0px");
    const articles = ref([]);
    const isModalVisible = ref(false);
    const phase = inject("phase") as number;
    const done = ref(false); // latest_date
    const doneMovingDate = ref(false); // moving_date for questionnaire
    const over3months = ref(false);
    const hasCollection = ref(false); // for collection routing
    const isGoodsProhibited = ref(false); // for goods
    const clause = inject("clause") as any;
    const links = inject("links");

    const links2 = ref([
      {
        name: "お引越し情報",
        link: "MyPageInfo",
      },
      {
        name: "オプションサービス",
        link: "MyPagePrice",
      },
    ]);

    const questionnaireText = ref("受付・営業担当者<br>アンケート");

    const questionnaireAlert = () => {
      util.openAlert(
        store,
        "",
        "",
        "引越日当日にお渡しするスマイルカードにご協力お願いいたします。",
        () => util.closeAlert(store)
      );
    };

    const clickQuestionnaire = async () => {
      if (doneMovingDate.value) {
        questionnaireAlert();
      } else {
        // AQC
        await openAQC();
      }
    };

    const isLogout = () => {
      util.openAlert(store, "OK", "Cancel", "ログアウトしますか?", logout);
    };

    const showAlert = util.showAlert;
    const collectionAlert1 = () => {
      util.openAlert(
        store,
        "OK",
        "",
        "お引越し後にお申し込みいただけます",
        () => util.closeAlert(store)
      );
    };
    const collectionAlert2 = () => {
      let msg =
        "お引越し日から３ヶ月が経過しましたので、資材回収をご希望の場合はお手数ではございますが、担当支店までご連絡お願いいたします。";
      if (
        Object.keys(store.state.mypage.contractDetail).length &&
        Object.keys(store.state.mypage.contractDetail.new_address_main_branch)
          .length
      ) {
        msg =
          msg +
          "<br/>" +
          store.state.mypage.contractDetail.new_address_main_branch.name +
          "支店<br/><a href='tel:" +
          store.state.mypage.contractDetail.new_address_main_branch.tel +
          "'>" +
          store.state.mypage.contractDetail.new_address_main_branch.tel;
        +"</a>";
      }
      util.openAlert(store, "OK", "", msg, () => util.closeAlert(store));
    };

    const style = computed(() => {
      return "bg-footer " + props.className;
    });
    const showModal = async () => {
      articles.value = await apis.getAllNews();
      isModalVisible.value = true;
    };
    const closeModal = () => {
      isModalVisible.value = false;
    };
    onMounted(() => {
      setTimeout(() => {
        const element = document.getElementsByClassName("app-main").item(0);
        const app = element?.firstElementChild;
        let pos = app?.clientHeight || 0;
        if (pos < window.innerHeight) {
          let appHeight = app?.clientHeight || 0;
          if (route.name == 'ErrorOccurred') {
            const elementHeader = document.getElementsByClassName("bg-header").item(0);
            const appHeader = elementHeader?.firstElementChild;
          
            paddingTop.value = (window.innerHeight - pos) - 30 + "px";
          } else {
            marginTop.value = window.innerHeight - appHeight + 60 + "px";
          }
          
        }
      }, 200);

      try {
        // get ukt_no
        ukt_no.value = (localStorage.getItem("ukt_no") as string) || "";

        // check if today is newer than the latest date
        const latestDate =
          (localStorage.getItem("latest_date") as string) || "";
        if (latestDate) {
          let target = new Date(latestDate + "T00:00");
          target.setDate(target.getDate() + 1);
          if (
            new Date(
              new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
            ) >= target
          ) {
            done.value = true;
            // check if today is newer than 93 days after the latest moving_date
            target = new Date(latestDate + "T00:00");
            target.setDate(target.getDate() + 93);
            if (
              new Date(
                new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
              ) >= target
            ) {
              over3months.value = true;
            }
          }
        }

        // check if today is newer than the moving date for AQC
        const movingDate =
          (localStorage.getItem("moving_date") as string) || "";
        if (movingDate) {
          let target = new Date(movingDate + "T00:00");
          if (
            new Date(
              new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
            ) >= target
          ) {
            doneMovingDate.value = true;
            questionnaireText.value = "作業品質アンケート";
          }
        }

        // get hasCollection
        hasCollection.value =
          JSON.parse(localStorage.getItem("hasCollection") as string) || false;
        // add event listener to detect hasCollection change
        window.addEventListener("hasCollection-changed", (event: any) => {
          hasCollection.value = event.detail.hasCollection || false;
        });

        // get is_goods_prohibited
        isGoodsProhibited.value =
          JSON.parse(localStorage.getItem("is_goods_prohibited") as string) ||
          false;
        window.addEventListener("is_goods_prohibited-changed", (event: any) => {
          isGoodsProhibited.value = event.detail.is_goods_prohibited || false;
        });
      } catch {
        console.log("localStorageへのアクセス失敗2");
      }
    });

    return {
      ukt_no,
      marginTop,
      paddingTop,
      links,
      isModalVisible,
      showModal,
      closeModal,
      collectionAlert1,
      collectionAlert2,
      style,
      logout,
      articles,
      links2,
      phase,
      showAlert,
      done,
      over3months,
      hasCollection,
      isGoodsProhibited,
      isLogout,
      questionnaireText,
      clickQuestionnaire,
      clause,
    };
  },
});
