
import Steps from "@/components/furniture/Steps.vue";
import BaseButton from "@/components/BaseButton.vue";

import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import useRouteTo from "../../../js/useRouteTo";

export default defineComponent({
  components: { Steps, BaseButton },
  data: function () {
    return {
      items: [
        { label: "住所選択", class: "current" },
        { label: "日程選択", class: "current" },
        { label: "内容確認", class: "current" },
        { label: "申込完了", class: "current" },
      ],
      furniture: {},
      msg: "",
    };
  },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const submit = () => {
      routeTo("BookingInfoFurniture");
    };
    return {
      submit,
    };
  },
  created() {
    const store = useStore();
    this.furniture = reactive({ ...store.state.furniture });
  },
});
