
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  data() {
    return {
      selectedItem: "",
    };
  },
  watch: {
    selected: function (val) {
      this.setSelectedItem(val);
    },
  },
  methods: {
    updateItem(event: any): void {
      this.$emit("change", event.target.value);
      this.setSelectedItem(event.target.value);
    },
    setSelectedItem(value: string): void {
      this.selectedItem = value;
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      const value: string = this.selected.toString() || "";
      this.setSelectedItem(value);
    });
  },
});
