
import BaseButton2 from "@/components/account/BaseButton2.vue";
import ContainerBlock from "@/components/account/ContainerBlock.vue";
import TextBlock from "@/components/account/TextBlock.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { defineComponent } from "vue";

export default defineComponent({
  components: { TextBlock, ContainerBlock, Logo, Footer },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup() {
    const click = () => {
      window.close();
    };
    return {
      click,
    };
  },
});
