import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingMobile = _resolveComponent("BookingMobile")!
  const _component_BookingDesktop = _resolveComponent("BookingDesktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_BookingMobile, { key: 0 }))
      : (_openBlock(), _createBlock(_component_BookingDesktop, { key: 1 }))
  ]))
}