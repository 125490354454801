import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/account/eye-open.png'
import _imports_1 from '../../assets/account/eye-close.png'


const _withScopeId = n => (_pushScopeId("data-v-7a410e00"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "inputmode", "placeholder", "disabled"]
const _hoisted_2 = ["type", "inputmode", "placeholder", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.type === 'password')
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          type: _ctx.computeType,
          inputmode: _ctx.inputmode,
          style: _normalizeStyle(_ctx.style),
          placeholder: _ctx.placeholder,
          class: _normalizeClass(_ctx.className),
          disabled: _ctx.disabled,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('input', $event.target.value)))
        }, null, 46, _hoisted_1))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          type: _ctx.computeType,
          inputmode: _ctx.inputmode,
          style: _normalizeStyle(_ctx.style),
          placeholder: _ctx.placeholder,
          value: _ctx.value,
          class: _normalizeClass(_ctx.className),
          disabled: _ctx.disabled,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input', $event.target.value)))
        }, null, 46, _hoisted_2)),
    _withDirectives(_createElementVNode("img", {
      src: _imports_0,
      alt: "eye-open",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args))),
      class: "hover-shining"
    }, null, 512), [
      [_vShow, _ctx.type === 'password' && _ctx.computeType === 'password']
    ]),
    _withDirectives(_createElementVNode("img", {
      src: _imports_1,
      alt: "eye-close",
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args))),
      class: "hover-shining"
    }, null, 512), [
      [_vShow, _ctx.type === 'password' && _ctx.computeType === 'text']
    ])
  ], 4))
}