import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_page_mobile = _resolveComponent("my-page-mobile")!
  const _component_my_page_rakumo_mobile = _resolveComponent("my-page-rakumo-mobile")!
  const _component_my_page_desktop = _resolveComponent("my-page-desktop")!
  const _component_my_page_rakumo_desktop = _resolveComponent("my-page-rakumo-desktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile' && !_ctx.currentInfo.isRakumo)
      ? (_openBlock(), _createBlock(_component_my_page_mobile, {
          key: 0,
          currentInfo: _ctx.currentInfo,
          done: _ctx.done,
          newestNews: _ctx.newestNews,
          aqcQuestionnaire: _ctx.aqc_questionnaire
        }, null, 8, ["currentInfo", "done", "newestNews", "aqcQuestionnaire"]))
      : (_ctx.$store.state.device === 'mobile' && _ctx.currentInfo.isRakumo)
        ? (_openBlock(), _createBlock(_component_my_page_rakumo_mobile, {
            key: 1,
            currentInfo: _ctx.currentInfo,
            done: _ctx.done,
            newestNews: _ctx.newestNews,
            onClick: _ctx.goToRakumo,
            aqcQuestionnaire: _ctx.aqc_questionnaire
          }, null, 8, ["currentInfo", "done", "newestNews", "onClick", "aqcQuestionnaire"]))
        : (_ctx.$store.state.device === 'pc' && !_ctx.currentInfo.isRakumo)
          ? (_openBlock(), _createBlock(_component_my_page_desktop, {
              key: 2,
              currentInfo: _ctx.currentInfo,
              done: _ctx.done,
              aqcQuestionnaire: _ctx.aqc_questionnaire
            }, null, 8, ["currentInfo", "done", "aqcQuestionnaire"]))
          : (_openBlock(), _createBlock(_component_my_page_rakumo_desktop, {
              key: 3,
              currentInfo: _ctx.currentInfo,
              done: _ctx.done,
              onClick: _ctx.goToRakumo,
              aqcQuestionnaire: _ctx.aqc_questionnaire
            }, null, 8, ["currentInfo", "done", "onClick", "aqcQuestionnaire"]))
  ]))
}