
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import util from "@/js/util";

import BaseButton from "./../BaseButton.vue";
import ExclamationIcon from "./ExclamationIcon.vue";

export default defineComponent({
  name: "ConfrmationFurniture",
  components: {
    BaseButton,
    ExclamationIcon
  },
  props: {
    success: {
      type: Boolean,
      default: false
    },
    buttonTitle: {
      type: String,
      default: ""
    },
    dataResult: {
      type: Object,
      required: true
    },
    msg: {
      type: String,
      default: "お申し込み頂いた後、お電話にて必ず最終確認させていただきます。"
    },
    boxClass: {
      type: String,
      default: ""
    }
  },
  emits: ["click"],
  data: function () {
    return {
      className: ''
    }
  },
  created() {
    if (this.$route.query?.paid) {
      this.className = "red";
    }
  },
  setup() {
    const getJapaneseDate = util.getJapaneseDate;
    const store = useStore();
    const buttonWidth = computed(() => {
      return store.state.device == 'mobile' ? '100%' : '34rem';
    });
    return {
      buttonWidth,
      getJapaneseDate
    }
  },
  methods: {
    getZipcode(zipcode: any) {
      let textZipcode = zipcode;
      if (zipcode.indexOf("-") < 0) {
        textZipcode = zipcode.slice(0, 3) + "-" + zipcode.slice(3);
      }
      return textZipcode;
    },
    getRequestLabel(result: any) {
      let request_list = [
        { id: "checkbox1", label: "洋服タンス", value: "洋服タンス" },
        { id: "checkbox2", label: "和服タンス", value: "和服タンス" },
        { id: "checkbox3", label: "整理ダンス", value: "整理ダンス" },
        { id: "checkbox4", label: "ベッド", value: "ベッド" },
        { id: "checkbox5", label: "本棚", value: "本棚" },
        { id: "checkbox6", label: "食器棚", value: "食器棚" },
        { id: "checkbox7", label: "ソファ", value: "ソファ" },
        { id: "checkbox8", label: "薄型TV", value: "薄型TV" },
        { id: "checkbox9", label: "机", value: "机" },
        { id: "checkbox10", label: "上記以外の家具", value: "上記以外の家具" }
      ];
      request_list = request_list.filter(function (el: any) {
        return result.moving_furniture.indexOf(el.value) >= 0;
      });
      let items = (request_list || []).map(function (el: any) {
        return el["label"];
      });
      if (items.length > 0) {
        return items.join(", ");
      }
      return "";
    },
  },
});
