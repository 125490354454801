import { useStore } from "vuex";
import useRouteTo from "./useRouteTo";

export default function () {
  const store = useStore();
  const { routeTo } = useRouteTo();

  // Instead of calling API, wait for 1~2 sec for now
  const pseudoAPICall = (name: string, query: any = {}) => {
    // start loading
    store.dispatch("startLoading", true);

    const time = (Math.random() + 1) * 1000;
    setTimeout(() => {
      routeTo(name, query);
      store.dispatch("startLoading", false);
    }, time);
  };
  return {
    pseudoAPICall,
  };
}
