
import BaseButton from "@/components/account/BaseButton.vue";
import EmailAndPassword from "@/components/account/EmailAndPassword.vue";
import InputBlock from "@/components/account/InputBlock.vue";
import TextBlock from "@/components/account/TextBlock.vue";
import { defineComponent, onMounted, ref } from "vue";
import useRouteTo from "../../js/useRouteTo";
import { useStore } from "vuex";
import util from "../../js/util";
import LabelAndInput from "@/components/account/LabelAndInput.vue";
import ContainerBlock from "@/components/account/ContainerBlock.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { useRoute } from "vue-router";
import apis from "@/js/apis";
import errors from "@/js/errors";

export default defineComponent({
  components: {
    TextBlock,
    EmailAndPassword,
    InputBlock,
    BaseButton,
    LabelAndInput,
    ContainerBlock,
    Logo,
    Footer,
  },
  setup() {
    const { routeTo } = useRouteTo();
    const route = useRoute();
    const store = useStore();
    const password = ref("");
    const errMsg1 = ref("");
    const status = ref("");
    const Token = ref("");
    const forgetpassword = () => {
      routeTo("ForgotPassword");
    };
    onMounted(() => {
      // Check if token is valid
      const token = route.query.token;
      if (token) {
        // API Call -> POST token
        const body = {
          token: token.toString(),
        };
        apis
          .post(apis.endpoints.checkToken, "", body)
          .then((res) => {
            console.log("res.status: " + res.status);
            return res.json();
          })
          .then((data) => {
            console.log(data);
            if (data.status === "error") {
              console.log("invalid token");
              status.value = "failure";
            } else {
              status.value = "success";
              Token.value = token.toString();
            }
          })
          .catch((reason) => {
            console.log(reason);
            routeTo("ErrorOccurred", {}, { title: reason });
          });
      } else {
        console.log("no token");
        status.value = "failure";
      }
    });
    const submit = async () => {
      // validation
      // password validation
      if (util.validatePassword(password.value)) {
        errMsg1.value = "";
        // password validation /
        if (password.value.indexOf('\\') >= 0) {
          errMsg1.value = "\\は使用できません";
        }
      } else {
        errMsg1.value =
          "小文字、大文字、数字を必ず含む8文字以上で入力してください";
      }
      

      if (!errMsg1.value) {
        // start loading
        store.dispatch("startLoading", true);

        // API Call -> POST token and password
        const body = {
          token: Token.value,
          new_password: password.value,
        };
        const result = await apis.callAPI(
          "post",
          apis.endpoints.resetPassword,
          "",
          body
        );
        if (result && result.data.status === "success") {
          routeTo("PasswordResetDone");
        }
      } else {
        // window.scrollTo(0, 0);
      }
    };
    return {
      password,
      errMsg1,
      status,
      forgetpassword,
      submit,
    };
  },
});
