
import { defineComponent, onBeforeMount } from "vue";
import InquiriesConfirmationDesktop from "./partials/InquiriesConfirmationDesktop.vue";
import InquiriesConfirmationMobile from "./partials/InquiriesConfirmationMobile.vue";
import { useStore } from "vuex";
import useRouteTo from "@/js/useRouteTo";
import apis from "@/js/apis";

export default defineComponent({
  components: { InquiriesConfirmationMobile, InquiriesConfirmationDesktop },
  setup() {
    const store = useStore();
    const { routeTo } = useRouteTo();

    const inquiry_type_object = {
      "契約内容の変更や追加について（オプション作業・お支払方法など）":
        "contract_update",
      "ご住所について（変更・修正）": "address_update",
      "お引越のご準備や当日作業について（荷造り・資材配達など）":
        "moving_preparation",
      "アフターサービスについて（家具移動サービス・資材回収など）":
        "after_service",
      領収書について: "receipt",
      その他: "other",
      引越マイページ退会希望: "withdrawal",
    } as any;

    const submit = async () => {
      const ukt_no = (localStorage.getItem("ukt_no") as string) || "";
      if (!ukt_no) {
        routeTo("MyPageMenu");
      } else {
        store.dispatch("startLoading", true);
        const result = await apis.callAPI(
          "post",
          apis.endpoints.postInquiry,
          store.state.jwt,
          {
            ukt_no: ukt_no,
            inquiry_type: inquiry_type_object[store.state.inquiry.inquiry_type],
            inquiry_content: store.state.inquiry.inquiry_text,
            tel: store.state.inquiry.inquiry_tel,
            email: store.state.inquiry.inquiry_email,
          }
        );
        if (result && result.data.status === "success") {
          store.dispatch("inquiry/resetInquiry");
          routeTo("MyPageInquiriesSuccess");
        }
      }
    };

    onBeforeMount(() => {
      if (!store.state.inquiry.inquiry_type) {
        routeTo("MyPageInquiriesForm");
      }
    });
    return {
      submit,
    };
  },
});
