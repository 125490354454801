import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

import App from "./App.vue";
import router from "./router";
import store from "./store";
// import liff from "@line/liff";
import "./index.scss"; // style
import "./css/transition.scss"; // transition & animation

// current phase
// 1 -> mypage, confirmation
// 2 -> delivery, collection
// 3 -> all the others which mean rakumo, furniture, goods
const phase = 3;

const liffSettings = {
  memoryState: "liff",
  memoryName: "art",
  postbackUrl: "{{ postback_url }}",
  liffId: "{{ liff_id }}",
  userId: "",
};

const links = [
  {
    name: "よくある質問",
    link: "https://www.the0123.com/concierge/faq/",
    target: "_blank",
    rel: "noopener noreferrer",
  },
];

const wording = {
  trunkroom:
    "トランクルームをご利用のお客様は支払い金額を表示することはできません。恐れ入りますが、お見積書をご確認いただくか担当店にお問合せをお願いいたします。",
};

const aqc_link =
  "https://customer-reviews.the0123.com/form/pub/art/qualitycard";

const clause = {
  clause_link: {
    name: "標準引越運送約款",
    link: "https://www.the0123.com/company/terms01.html",
  },
  clause_link_section: {
    name: "標準引越運送約款　第二章 第三条",
    link: "https://www.the0123.com/company/terms01.html#section02",
  },
  clause_link_2: {
    name: "標準貨物自動車利用運送（引越）約款",
    link: "https://www.the0123.com/company/terms02.html",
  },
  clause_link_2_section: {
    name: "標準貨物自動車利用運送（引越）約款　第二章 第三条",
    link: "https://www.the0123.com/company/terms02.html#section02",
  },
};

const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_ENDPOINT,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        // "localhost",
        process.env.VUE_APP_SENTRY_TRACKING_URL,
        /^\//,
      ],
    }),
    new CaptureConsoleIntegration({
      levels: ["error"],
    }) as any,
  ],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
});

app
  .use(store)
  .use(router)
  // app.config.globalProperties.liffSettings = {};
  .provide("phase", phase)
  .provide("liffSettings", liffSettings)
  .provide("links", links)
  .provide("wording", wording)
  .provide("aqc_link", aqc_link)
  .provide("clause", clause)
  .mount("#app");

// init LIFF
// liff
//   .init({ liffId: liffSettings.liffId })
//   .then(() => {
//     const context = liff.getContext();
//     if (context) {
//       // Get LINE-UserId.
//       liffSettings.userId = context.userId!;
//     }

//     // Render Vue
//     app.mount("#app");
//   })
//   .catch((error) => {
//     if (liff.isInClient()) {
//       console.error(error);
//     } else {
//       // for dev only (no effect to production)
//       console.warn(
//         "Here is not in LINE. That's why this liff-app does not work correctly."
//       );
//       app.mount("#app");
//     }
//   });

export default {
  phase: phase,
  aqc_link: aqc_link,
};
