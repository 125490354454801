
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import BaseButton from "@/components/BaseButton.vue";

import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import useRouteTo from "../../../js/useRouteTo";

export default defineComponent({
  components: { Logo, Footer, BaseButton },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const submit = () => {
      routeTo("MyPage");
    };
    return {
      submit,
    };
  },
});
