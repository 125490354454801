<script>
import { useStore } from "vuex";
import util from "@/js/util";

export default {
  data() {
    return {
      flickityOptions: {
        pageDots: false,
        wrapAround: true,
        prevNextButtons: true,
      },
      banners: [
        {
          id: 1,
          banner: "furniture/furniture.png",
        },
        {
          id: 2,
          banner: "furniture/furniture.png",
        },
        {
          id: 3,
          banner: "furniture/furniture.png",
        },
        {
          id: 4,
          banner: "furniture/furniture.png",
        },
        {
          id: 5,
          banner: "furniture/furniture.png",
        },
        {
          id: 6,
          banner: "furniture/furniture.png",
        },
        {
          id: 7,
          banner: "furniture/furniture.png",
        },
      ],
      selectSizes: [{ label: "Sサイズ（50×50×5mm）", value: 1 }],
      selectSheets: [{ label: "4枚入り", value: 1 }],
      selectedSelectSize: 1,
      selectedSelectSheet: 1,
      selectedQuantity: 1,
      selectedVariant: null,
      errorVariant: ''
    };
  },
  methods: {
    getImgUrl: function (images) {
      if (Array.isArray(images) && images.length > 0) {
        return images[0]['url']
      }
    },
    filterCurrency(value, tax_rate) {
      const price = util.calculateTax(value, tax_rate)
      return util.getPrice(price.toString(), true);
    },
  },
  mounted: function() {
    const defaultValue = {
      "size": 1,
      "sheet": 1,
      "quantity": 1,
    }
    const store = useStore();

    this.selectedSelectSize = store.state.goods.size || defaultValue['size']
    this.selectedSelectSheet = store.state.goods.sheet || defaultValue['sheet']
    this.selectedQuantity = store.state.goods.quantity || defaultValue['quantity']

    store.dispatch("goods/setSize", this.selectedSelectSize);
    store.dispatch("goods/setSheet", this.selectedSelectSheet);
    store.dispatch("goods/setQuantity", this.selectedQuantity);
  }
};
</script>
