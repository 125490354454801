
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
    year: {
      type: String,
      default: ""
    },
    week: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    top: {
      type: String,
      default: ""
    },
    left: {
      type: String,
      default: ""
    },
    right: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: "詳細を見る",
    },
    dateTitle: {
      type: String,
      default: "お引越作業日",
    },
  },
  emits: ["clickDetail"],
  setup(props) {
    const fullDate = computed(() => {
      let val = `${props.month}/${props.day}`;
      if (props.year) val = `${props.year}/${val}`;
      return val;
    });
    const style = computed(() => {
      return {
        top: props.top || "8rem",
        right: props.right || "8vw",
        width: props.width || "45.3vw",
      };
    });
    return {
      style,
      fullDate,
    };
  },
});
