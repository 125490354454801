
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    datetime: {
      type: String,
      required: true,
    },
  },
  // setup() {},
});
