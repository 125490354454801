
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      file_num: 0,
    };
  },
  mounted(){
    this.file_num = localStorage.getItem("file_num") ? parseInt(localStorage.getItem("file_num") as string) : 0;
    window.addEventListener("file_num-localstorage-changed", (event: any) => {
      this.file_num = event.detail.file_num || "";
    });
  },
});
