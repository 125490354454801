
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      ukt_no: null as any,
      file_num: 0,
    };
  },
  mounted() {
    window.addEventListener("ukt_no-localstorage-changed", (event: any) => {
      this.ukt_no = event.detail.ukt_no || "";
    });
    window.addEventListener("file_num-localstorage-changed", (event: any) => {
      this.file_num = event.detail.file_num || 0;
    });
  },
  emits: ["click", "clickInquiry", "clickMypocket"],
  created() {
    this.ukt_no = localStorage.getItem("ukt_no") || "";
    this.file_num = localStorage.getItem("file_num") ? parseInt(localStorage.getItem("file_num") as string) : 0;
  },
});
