import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e0f36c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = {
  key: 0,
  class: "main"
}
const _hoisted_4 = { class: "date" }
const _hoisted_5 = { class: "unit week" }
const _hoisted_6 = {
  key: 1,
  class: "main"
}
const _hoisted_7 = { class: "date" }
const _hoisted_8 = { class: "unit info" }
const _hoisted_9 = {
  key: 0,
  class: "main"
}
const _hoisted_10 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "div_container",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.type == 'info')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.year) + "/" + _toDisplayString(_ctx.month) + "/" + _toDisplayString(_ctx.day), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.week), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.type == 'price')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.price), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.text), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.text && _ctx.type != 'price')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.text) + "開始", 1)
        ]))
      : _createCommentVNode("", true)
  ], 4))
}