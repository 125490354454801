
import { defineComponent, onMounted, ref } from "vue";
import apis from "@/js/apis";
import useRouteTo from "@/js/useRouteTo";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import ListItemDesktop from "./partials/ListItemDesktop.vue";
import ListItemMobile from "./partials/ListItemMobile.vue";
import util from "@/js/util";
import saveLocalStorage from "@/js/saveLocalStorage";

export default defineComponent({
  components: { ListItemDesktop, ListItemMobile },
  setup() {
    const route = useRoute();
    const { routeTo } = useRouteTo();
    const store = useStore();
    const deliveryItems = ref({});
    // TODO: check if goNext works
    const goNext = (item: any) => {
      store.dispatch("delivery/selectItem", item);
      store.dispatch("delivery/inputDatetime", item.delivery_date);
      store.dispatch("delivery/inputTime", item.delivery_time);
      store.dispatch("delivery/inputLock", item.is_autolock);
      store.dispatch("delivery/inputPlacement", item.delivery_option);
      store.dispatch("delivery/inputRequest", item.request);
      routeTo("DeliverySelectDatetime", route.query);
    };

    onMounted(async () => {
      let contract_id: string;
      if (route.query.contract_id) {
        contract_id = route.query.contract_id as string;
        saveLocalStorage(contract_id);
      } else {
        contract_id = localStorage.getItem("selectedNumber") as string;
      }
      if (!contract_id) {
        routeTo("MyPageMenu");
      } else {
        store.dispatch("delivery/saveContractId", contract_id);
        const res = await apis.getAllDeliveries(contract_id);

        if (res) {
          interface itemInterface {
            [key: string]: Array<any>;
          }

          // change invalid date to empty string
          // res.delivery_list.forEach((el: any) => {
          //   if (new Date(el.delivery_date).toString() == "Invalid Date") {
          //     el.delivery_date = "";
          //     el.delivery_time = "";
          //   }
          // });

          // check if address length >= 30
          res.delivery_list.forEach((el: any) => {
            if (el.address_02.length >= 30) {
              el.address_02 = el.address_02.slice(0, 29) + "...";
              if (store.state.device === "mobile") {
                el.address_02 += "<br/>※一部表示されておりません";
              } else {
                el.address_02 +=
                  "<small style='font-size: 12px;font-weight:normal;color:#555555'>※一部表示されておりません</small>";
              }
            }
          });

          let new_delivery_items = {} as itemInterface;

          if (!res.delivery_list.length) {
            new_delivery_items["配達予定なし"] = [];
          } else {
            // if (store.state.device == "mobile") {
            // if mobile, grouping by date and time -> PC too
            for (let item of res.delivery_list) {
              let datetime_key: string;
              if (new Date(item.delivery_date).toString() == "Invalid Date") {
                datetime_key = "日程未定";
              } else {
                let date =
                  store.state.device == "mobile"
                    ? util.getJapaneseDate(item.delivery_date, true).slice(5)
                    : util.getJapaneseDate(item.delivery_date, true);
                datetime_key = date + " " + item.delivery_time;
              }
              if (Object.keys(new_delivery_items).includes(datetime_key)) {
                new_delivery_items[datetime_key].push(item);
              } else {
                new_delivery_items[datetime_key] = [item];
              }
            }
          }
          // } else {
          // if PC, grouping by address -> no longer
          // for (let item of res) {
          //   let address_key = item.address;
          //   if (Object.keys(new_delivery_items).includes(address_key)) {
          //     new_delivery_items[address_key].push(item);
          //   } else {
          //     new_delivery_items[address_key] = [item];
          //   }
          // }
          // }
          deliveryItems.value = new_delivery_items;
          store.dispatch("delivery/setDeliveries", new_delivery_items);

          // save branch info
          store.dispatch("delivery/setBranchInfo", res.branch_info);
        }
      }
    });
    return {
      deliveryItems,
      goNext,
    };
  },
});
