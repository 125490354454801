
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import useComputeClass from "../../js/useComputeClass";

export default defineComponent({
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "100%",
    },
    buttonColor: {
      type: String,
      default: "#0060b9",
    },
    buttonClass: {
      type: String,
      default: "",
    }
  },
  emits: ["click"],
  setup(props) {
    const store = useStore();
    const { computeClass } = useComputeClass();
    const style = computed(() => {
      return {
        width: props.width,
      };
    });
    
    const buttonStyle = computed(() => {
      return {
        backgroundColor: props.buttonColor,
      };
    });
    const buttonStyleClass = computed(() => {
      return props.buttonClass;
    });
    return {
      computeClass,
      style,
      buttonStyle,
      buttonStyleClass
    };
  },
});
