
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    margin: {
      type: String,
      default: "0",
    },
    fontSize: {
      type: String,
      default: "1.4rem",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change"],
  setup(props) {
    const styleMargin = computed(() => {
      return {
        margin: props.margin,
      };
    });
    const styleFont = computed(() => {
      return {
        fontSize: props.fontSize,
      };
    });
    return {
      styleMargin,
      styleFont,
    };
  },
});
