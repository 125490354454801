
import { defineComponent } from "vue";
import util from "@/js/util";

export default defineComponent({
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    filterCurrency(value: string) {
      return util.getPrice(value, false) + "円";
    },
  },
  emits: ["close"],
});
