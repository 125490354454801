import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionEditMobile = _resolveComponent("CollectionEditMobile")!
  const _component_CollectionEditDesktop = _resolveComponent("CollectionEditDesktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_CollectionEditMobile, {
          key: 0,
          onSubmitEdit: _ctx.submitEdit,
          onSubmitDelete: _ctx.submitDelete
        }, null, 8, ["onSubmitEdit", "onSubmitDelete"]))
      : (_openBlock(), _createBlock(_component_CollectionEditDesktop, {
          key: 1,
          onSubmitEdit: _ctx.submitEdit,
          onSubmitDelete: _ctx.submitDelete
        }, null, 8, ["onSubmitEdit", "onSubmitDelete"]))
  ]))
}