
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    errMsg: {
      type: String,
      default: "",
    },
  },
  // setup() {
  // },
});
