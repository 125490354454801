
import ConfirmationView from "@/components/collection/CollectionConfirmationView.vue";
import Steps from "@/components/furniture/Steps.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { ConfirmationView, Steps, Logo, Footer },
  emits: ["click"],
  data: function () {
    return {
      items: [
        { label: "1. お申込み内容のご入力", class: "current" },
        { label: "2. 入力内容のご確認", class: "current" },
        { label: "3. お申し込み完了" },
      ],
      collection: {},
      msg: "今回の回収は3,000円(税込)で承ります",
    };
  },
  watch: {
    "$store.state.collection.free_or_paid": function (value) {
      if (value == "paid") {
        this.msg = "今回の回収は3,000円(税込)で承ります";
      } else {
        this.msg = "今回の回収は無料でご利用いただけます";
      }
    },
  },
  created() {
    const store = useStore();
    this.collection = reactive({ ...store.state.collection });

    if (store.state.collection.free_or_paid == "paid") {
      this.msg = "今回の回収は3,000円(税込)で承ります";
    } else {
      this.msg = "今回の回収は無料でご利用いただけます";
    }
  },
});
