
import { defineComponent } from "vue";
import Flickity from "./Flickity.vue";

export default defineComponent({
  components: {
    Flickity,
  },
  props: {
    banners: {
      type: Array,
      required: true,
    },
  },
  watch: {
    "banners": function(val) {
      this.forceRerender()
    }
  },
  data() {
    return {
      renderComponent: true,
      flickityOptions: {
        pageDots: false,
        wrapAround: true,
        prevNextButtons: true,
        autoPlay: true
      }
    };
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    }
  }
});
