
import Balloon from "@/components/mypage/Balloon.vue";
import BaseLongBox from "@/components/mypage/BaseLongBox.vue";
import BaseShortBox from "@/components/mypage/BaseShortBox.vue";
import DatetimeShortBox from "@/components/mypage/DatetimeShortBox.vue";
import DeliveryDiv from "@/components/mypage/DeliveryDiv.vue";
import MenuButton from "@/components/mypage/MenuButton.vue";
import PriceDiv from "@/components/mypage/PriceDiv.vue";
import PriceModal from "./PriceModal.vue";

import { computed, defineComponent, inject, ref, toRefs, watch } from "vue";
import useRouteTo from "@/js/useRouteTo";
import useGoTo from "@/js/useGoTo";
import InfoBlock from "@/components/mypage/InfoBlock.vue";
import usePseudoAPICall from "@/js/usePseudoAPICall";
import PopupNews from "@/components/mypage/PopupNews.vue";
import apis from "@/js/apis";
import util from "@/js/util";
import { useStore } from "vuex";
import openAQC from "@/js/openAQC";

export default defineComponent({
  components: {
    Balloon,
    DatetimeShortBox,
    BaseLongBox,
    PriceDiv,
    DeliveryDiv,
    BaseShortBox,
    MenuButton,
    InfoBlock,
    PopupNews,
    PriceModal,
  },
  props: {
    currentInfo: {
      type: Object,
      required: true,
    },
    done: {
      type: Boolean,
      required: true,
    },
    newestNews: {
      type: Object,
      required: true,
    },
    aqcQuestionnaire: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    aqcQuestionnaire: function (val) {
      if (val) {
        const openHelp = () => {
          openAQC()
        };
        util.openAlert(
          this.$store,
          "アンケートに回答する",
          "",
          "※既にアンケートの入力がお済みの場合は回答不要です。",
          openHelp,
          "アンケートのお願い",
          true
        );
      }
    },
  },
  setup(props) {
    const { routeTo } = useRouteTo();
    const { goTo } = useGoTo();
    const { pseudoAPICall } = usePseudoAPICall();
    const store = useStore();
    const phase = inject("phase") as number;
    const wording = inject("wording");
    const accordion = ref(false);
    const transformOrigin = ref("50% 50%");

    const isPriceModalVisible = ref(false);
    const togglePriceModal = (value: boolean) => {
      isPriceModalVisible.value = value;
    };

    const collectionText = computed(() => {
      if (!props.done) {
        return "お引越し後に<br />ご利用いただけます";
      } else if (props.currentInfo.newer_than_93) {
        return "受付終了";
      } else if (props.currentInfo.noCollection) {
        return "申込受付中";
      } else {
        return "日程未定";
      }
    });

    const collectionButton = computed(() => {
      if (props.done && props.currentInfo.newer_than_93) {
        return true;
      } else {
        return false;
      }
    });

    const furnitureText = computed(() => {
      if (props.currentInfo.hasFurniture === "undecided") {
        return "日程未定";
      } else {
        return "お引越し後1年間<br>無料で受付中！";
      }
    });

    const { newestNews } = toRefs(props);

    const articles = ref([] as any);
    const newestNewsId = ref("");
    const isModalVisible = ref(false);
    const closeModal = async () => {
      // call API to update is_read flag
      const res = await apis.updateNewsFlag(newestNewsId.value);
      if (res) {
        isModalVisible.value = false;
      }
    };
    const clickNews = async () => {
      // call API to update is_read flag
      const res = await apis.updateNewsFlag(newestNewsId.value);
      if (res) {
        routeTo("NewsList");
      }
    };
    const datetimeText = computed(() => {
      if (props.done) {
        return "引越し完了";
      } else {
        return "お引越作業日";
      }
    });
    const computeBGColor = computed(() => {
      if (props.done) {
        return "#555555";
      } else {
        return "#ffffff";
      }
    });
    const loadingRoute = (name: string) => {
      // API Call -> GET data
      // For now, pseudo api call
      pseudoAPICall(name);
    };
    const expand = (event: MouseEvent) => {
      transformOrigin.value = "50% " + event.clientY + "px";
      accordion.value = true;
      // routeTo(route);
    };
    const loadingExpand = (event: any, route: string) => {
      // target.classList.add("bound");
      // store.state.loading = true;

      // const time = (Math.random() + 1) * 1000;
      // setTimeout(() => {
      //   store.state.loading = false;
      //   expand(event, route);
      // }, time);

      setTimeout(() => {
        expand(event);
        setTimeout(() => {
          pseudoAPICall(route);
        }, 200);
      }, 100);
    };
    const style = computed(() => {
      return {
        "transform-origin": transformOrigin.value,
        height: document.body.scrollHeight + "px",
      };
    });
    const showAlert = util.showAlert;
    const clickCollection = (event: any) => {
      if (phase >= 2) {
        if (props.done) {
          if (
            props.currentInfo.newer_than_93 &&
            props.currentInfo.noCollection
          ) {
            util.openAlert(
              store,
              "OK",
              "",
              "お引越し日から３ヶ月が経過しましたので、資材回収をご希望の場合はお手数ではございますが、担当支店までご連絡お願いいたします。<br/>" +
                store.state.mypage.contractDetail.new_address_main_branch.name +
                "支店<br/><a href='tel:" +
                store.state.mypage.contractDetail.new_address_main_branch.tel +
                "'>" +
                store.state.mypage.contractDetail.new_address_main_branch.tel +
                "</a>",
              () => util.closeAlert(store)
            );
          } else {
            loadingExpand(event, "CollectionDetail");
          }
        } else {
          util.openAlert(
            store,
            "OK",
            "",
            "お引越し後にお申し込みいただけます",
            () => util.closeAlert(store)
          );
        }
      } else {
        showAlert(store);
      }
    };
    const openRakumo = () => {
      window.open("https://www.the0123.com/rakumo/");
    };
    watch(newestNews, () => {
      if (Object.keys(newestNews.value).length) {
        // if not read yet, then show it
        articles.value.push(newestNews.value);
        newestNewsId.value = newestNews.value.id;
        isModalVisible.value = true;
      }
    });

    return {
      routeTo,
      goTo,
      wording,
      collectionText,
      collectionButton,
      furnitureText,
      accordion,
      isModalVisible,
      articles,
      closeModal,
      clickNews,
      datetimeText,
      computeBGColor,
      loadingRoute,
      expand,
      loadingExpand,
      style,
      phase,
      showAlert,
      clickCollection,
      openRakumo,
      togglePriceModal,
      isPriceModalVisible,
    };
  },
});
