
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import useComputeClass from "../js/useComputeClass";

export default defineComponent({
  props: {},
  setup() {
    const store = useStore();
    const { computeClass } = useComputeClass();
    const errMsg = computed(() => {
      return store.state.errMsgFromServer;
    });
    return {
      errMsg,
      computeClass,
    };
  },
});
