
import { useStore } from "vuex";
import { defineComponent, computed } from "vue";
import InputBlock from "./../collection/InputBlock.vue";
import Select from "./../furniture/Select.vue";

import util from "../../js/util";
import prefectures from "../../js/prefectures";
import apis from "@/js/apis";

export default defineComponent({
  components: {
    InputBlock,
    Select,
  },
  props: {
    selectedAddress: {
      type: String,
      default: "",
    },
    selectedZipcode: {
      type: String,
      default: "",
    },
    selectedCity: {
      type: String,
      default: "",
    },
    selectValue: {
      type: String,
      default: "",
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    validateZipcode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input", "input2", "input3", "input4", "validateAddress"],
  data: function () {
    return {
      items: prefectures,
      prefecture: "",
      address: "",
      api: "https://zipcloud.ibsnet.co.jp/api/search?zipcode=",
      inputAddress: "",
      zipcode: "",
      building: "",
      room: "",
      specific_area: "",
      addressList: [],
      selectedAddressCode: "",
      responseCode: 0,
      addressResult: [] as any,
      isError: false,
      selectSelectedValue: "",
    };
  },
  watch: {
    addressResult: function (val) {
      const state: any = this.$store.state;
      const furniture: any = state.furniture;
      this.setSpecificArea(furniture.zipcode);
    },
    "$store.state.furniture.zipcode": function (val) {
      this.resetAddressBlock();
    },
    selectValue: function (val) {
      this.selectSelectedValue = val;
    },
  },
  setup() {
    const store = useStore();
    const inputWidth = computed(() => {
      return store.state.device == "mobile" ? "100%" : "34.5rem";
    });
    return {
      inputWidth,
    };
  },
  methods: {
    setSpecificArea: function (zipcode: any) {
      const findAddress = this.addressResult.filter(
        (i: any) =>
          i.jis_address_code == this.selectedAddressCode &&
          i.zip_code == zipcode
      );
      if (findAddress.length > 0) {
        this.specific_area = findAddress[0]["specific_area"];
      } else {
        this.specific_area = "";
      }
      this.$store.dispatch(
        "furniture/inputAddressSpecificArea",
        this.specific_area
      );
      if (
        this.specific_area ||
        this.inputAddress ||
        this.room ||
        this.building
      ) {
        this.$emit(
          "input2",
          util.hankakuToZenkaku(
            `${this.specific_area ? this.specific_area : ""}${
              this.inputAddress
            }${this.room ? " " + this.room : ""}${
              this.building ? " " + this.building : ""
            }`
          )
        );
      }
    },
    resetAddressBlock: function () {
      this.building = "";
      this.room = "";
      this.inputAddress = "";
      this.specific_area = "";

      this.$store.dispatch("furniture/inputAddressRoomNumber", "");
      this.$store.dispatch("furniture/inputAddressApartmentName", "");
      this.$store.dispatch("furniture/inputAddressSpecificArea", "");
      this.$store.dispatch("furniture/inputAddressRest", "");
    },
    inputZipcode: async function (value: string) {
      let findZipcode = [];
      const state: any = this.$store.state;
      this.$emit("input", value);

      if (value.length >= 3 && this.$props.validateZipcode) {
        const state: any = this.$store.state;
        let address = state.furniture.address_list || [];
        if (address.length > 0) {
          findZipcode = address.filter(
            (i: any) => i.zipcode.slice(0, 3) == value.slice(0, 3)
          );
        }
        if (findZipcode.length == 0) {
          this.isError = true;
        } else {
          this.isError = false;
        }
      }

      this.zipcode = value;
      if (
        util.valid_zipcode(value) &&
        (findZipcode.length > 0 ||
          (findZipcode.length == 0 && !this.$props.validateZipcode))
      ) {
        value = value.trim().replace("-", "");

        const result = await apis.callAPI(
          "get",
          apis.endpoints.getAddress + "?zip_code=" + value,
          state.jwt
        );
        if (result && result.data.status === "success") {
          const results = result.data.results;
          let subItems: any = [];
          for (var j = 0; j < results.length; j++) {
            let addressLabel = `${results[j]["prefecture"]}${results[j]["municipality"]}${results[j]["specific_area"]}`;
            let addressLabelValue = `${results[j]["prefecture"]}${results[j]["municipality"]}`;
            let addressValue = results[j]["jis_address_code"];
            subItems.push({
              label: addressLabel,
              value: addressValue,
              labelValue: addressLabelValue,
            });
          }
          this.addressList = subItems;
          this.addressResult = results;

          if (results.length == 1) {
            this.selectSelectedValue = subItems[0]["value"];
            this.selectedAddressCode = subItems[0]["value"];
            this.$emit("input3", subItems[0]["labelValue"]);
            this.$emit("input4", subItems[0]["value"]);
            this.findResponse(this.selectedAddressCode, false);
          }
        }
      } else {
        this.prefecture = "";
        this.address = "";
        this.inputAddress = "";
        this.building = "";
        this.room = "";
        this.specific_area = "";
        this.addressList = [];
        this.addressResult = [];

        this.selectSelectedValue = "";
        this.selectedAddressCode = "";
        this.$emit("input2", "");
        this.$emit("input3", "");
        this.$emit("input4", "");
        this.$store.dispatch("furniture/inputDatetime", "");
        this.findResponse(this.selectedAddressCode, false);
      }
    },
    setAddress: function (value: string) {
      this.inputAddress = value.trim();
      this.$emit(
        "input2",
        util.hankakuToZenkaku(
          `${this.specific_area ? this.specific_area : ""}${this.inputAddress}${
            this.room ? " " + this.room : ""
          }${this.building ? " " + this.building : ""}`
        )
      );

      this.$store.dispatch("furniture/inputAddressRest", value.trim());
      this.findResponse(this.selectedAddressCode, false);
    },
    setBuilding: function (value: string) {
      this.building = value.trim();
      if (this.inputAddress) {
        this.$emit(
          "input2",
          util.hankakuToZenkaku(
            `${this.specific_area ? this.specific_area : ""}${
              this.inputAddress
            }${this.room ? " " + this.room : ""}${
              this.building ? " " + this.building : ""
            }`
          )
        );
      }

      this.$store.dispatch("furniture/inputAddressApartmentName", value.trim());
      this.findResponse(this.selectedAddressCode, false);
    },
    setRoom: function (value: string) {
      this.room = value.trim();
      if (this.inputAddress) {
        this.$emit(
          "input2",
          util.hankakuToZenkaku(
            `${this.specific_area ? this.specific_area : ""}${
              this.inputAddress
            }${this.room ? " " + this.room : ""}${
              this.building ? " " + this.building : ""
            }`
          )
        );
      }
      this.$store.dispatch("furniture/inputAddressRoomNumber", value.trim());
      this.findResponse(this.selectedAddressCode, false);
    },
    selectAddressCode: function (value: string) {
      const findAddress = this.addressResult.filter(
        (i: any) => i.jis_address_code == value.trim()
      );
      this.selectedAddressCode = value.trim();
      this.$emit("input4", value.trim());
      let addressLabel = "";

      if (findAddress.length > 0) {
        addressLabel = `${findAddress[0]["prefecture"]}${findAddress[0]["municipality"]}`;
        this.setSpecificArea(findAddress[0]["zip_code"]);
      }
      this.$emit("input3", addressLabel);
      this.findResponse(value.trim(), false);
    },
    findResponse: function (value: string, fromZipcode = true) {
      const findAddress = this.addressResult.filter(
        (i: any) => i.jis_address_code == value.trim()
      );
      let response = 4;
      if (findAddress.length > 0) {
        if (findAddress[0]["branch_info"]) {
          this.$store.dispatch(
            "furniture/saveBranch",
            findAddress[0]["branch_info"]
          );
        }
        if (findAddress[0]["branch_info"]["is_fc"] == true) {
          response = 2;
        } else if (findAddress[0]["kaishu_available"] == 1) {
          response = 1;
        } else if (findAddress[0]["kaishu_available"] == 2) {
          response = 2;
        } else if (this.inputAddress) {
          response = 0;
        }
      }

      this.$emit("validateAddress", response);
    },
  },
  mounted: function () {
    this.zipcode = this.$props.selectedZipcode;
    this.prefecture = this.$props.selectedCity;
    const formatedAddress = util
      .zenkakuToHankaku(this.$props.selectedAddress)
      .replace(/　/g, " ")
      .split(" ");
    const state: any = this.$store.state;
    this.selectedAddressCode = state.furniture.address_code;
    this.selectSelectedValue = this.selectedAddressCode;

    const findAddress = this.addressResult.filter(
      (i: any) => i.jis_address_code == this.selectedAddressCode
    );

    this.specific_area =
      findAddress.length > 0
        ? findAddress[0]["specific_area"]
        : formatedAddress[0];
    this.inputAddress = state.furniture.address_rest
      ? state.furniture.address_rest
      : formatedAddress[1];
    this.building =
      state.furniture.address_apartment_name ||
      state.furniture.address_room_number
        ? state.furniture.address_apartment_name
        : formatedAddress[3];
    this.room =
      state.furniture.address_apartment_name ||
      state.furniture.address_room_number
        ? state.furniture.address_room_number
        : formatedAddress[2];

    this.inputZipcode(this.zipcode);
    this.findResponse(this.selectedAddressCode, false);
  },
});
