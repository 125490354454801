
import { defineComponent } from "vue";
import ProductList from "./../ProductList.vue";
import ExclamationIcon from "../../furniture/ExclamationIcon.vue";
import PopupMessage from "./PopupMessage.vue";

export default defineComponent({
  components: {
    ProductList,
    ExclamationIcon,
    PopupMessage,
  },
  props: {
    msg: {
      type: String,
      default: "",
    },
    bookedGoods: {
      type: Object,
      required: true,
    },
  },
  emits: ["clickDelete"],
  data() {
    return {
      flickityOptions: {
        pageDots: true,
        wrapAround: true,
        prevNextButtons: false,
      },
    };
  },
  methods: {
    getImgUrl: function (images: any) {
      if (Array.isArray(images) && images.length > 0) {
        return images[0]["url"];
      }
    },
  },
});
