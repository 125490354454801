import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FurnitureDetailMobile = _resolveComponent("FurnitureDetailMobile")!
  const _component_FurnitureDetailDesktop = _resolveComponent("FurnitureDetailDesktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_FurnitureDetailMobile, {
          key: 0,
          furnitures: _ctx.new_furniture_list,
          status: _ctx.status,
          onClickNewApply: _ctx.clickNewApply
        }, null, 8, ["furnitures", "status", "onClickNewApply"]))
      : (_openBlock(), _createBlock(_component_FurnitureDetailDesktop, {
          key: 1,
          furnitures: _ctx.new_furniture_list,
          status: _ctx.status,
          onClickNewApply: _ctx.clickNewApply
        }, null, 8, ["furnitures", "status", "onClickNewApply"]))
  ]))
}