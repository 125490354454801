// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/mypage/mypage-info.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1[data-v-91268ef4]{color:#555;padding:4rem 0}.pc[data-v-91268ef4]{background-color:#fff;padding-bottom:3rem}.info-bg-image[data-v-91268ef4]{width:100%;height:23.89vw;max-height:39.1rem;min-height:34.1rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;z-index:1;margin-bottom:4.8rem;display:flex;align-items:center}.info-main-block[data-v-91268ef4]{position:relative;margin:0 auto}.columns[data-v-91268ef4]{display:grid;grid-gap:2rem;grid-template-columns:repeat(2,minmax(0,1fr));margin-bottom:4.8rem;padding-top:1rem}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
