
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import useRouteTo from "../../js/useRouteTo";
import apis from "@/js/apis";
import util from "@/js/util";

import Select from "./../furniture/Select.vue";
import RadioButton from "./../furniture/RadioButton.vue";
import BaseButton from "./../account/BaseButton.vue";
import CancelButton from "./CancelButton.vue";
import ExclamationIcon from "./../furniture/ExclamationIcon.vue";
import AddressBlock from "./AddressBlock.vue";
import Modal from "@/components/Modal.vue";

import CollectionMixin from "@/mixins/collection.vue";
import CollectionAddressMixin from "@/mixins/collectionAddress.vue";

export default defineComponent({
  name: "Booking",
  components: {
    Select,
    RadioButton,
    BaseButton,
    CancelButton,
    ExclamationIcon,
    AddressBlock,
    Modal,
  },
  props: {
    buttonTitle: {
      type: String,
      default: "",
    },
    button2Title: {
      type: String,
      default: "",
    },
    msg: {
      type: String,
      default: "",
    },
  },
  mixins: [CollectionMixin, CollectionAddressMixin],
  watch: {
    selected: function (val) {
      let state: any = this.$store.state;
      let collection = state.collection.itemForEdit;
      if (collection.zipcode.length == 0 || val != "上記以外の住所") {
        collection.address_01 = this.editCollectionAddress.address_01;
        collection.address_02 = this.editCollectionAddress.address_02;
        collection.kaishu_date = this.editCollectionAddress.kaishu_date;
        collection.kaishu_time = this.editCollectionAddress.kaishu_time;
        this.$store.dispatch("collection/saveItemForEdit", collection);
        this.validateResponse(val);
      } else {
        collection.address_01 = "";
        collection.address_02 = "";
        collection.kaishu_date = "";
        collection.kaishu_time = "";
        this.$store.dispatch("collection/saveItemForEdit", collection);
        this.changeMessage(4);
      }
    },
  },
  setup(props, context) {
    const store = useStore();
    const { routeTo } = useRouteTo();
    const route = useRoute();

    const errMsg = ref("");
    const errMsg1 = ref("");
    const errMsg2 = ref("");
    const errMsg3 = ref("");
    const errMsg4 = ref("");
    const errMsg5 = ref("");
    const errMsg6 = ref("");

    let selectedTime: any = store.state.collection.itemForEdit.kaishu_time;
    let selectedDate: any = store.state.collection.itemForEdit.kaishu_date;
    let phoneType: any = store.state.collection.itemForEdit.phone_type;
    let phoneNumber: any = store.state.collection.itemForEdit.tel;
    let selectedLock: any =
      store.state.collection.itemForEdit.is_autolock !== undefined
        ? store.state.collection.itemForEdit.is_autolock
        : 0;
    let materialType: any = store.state.collection.itemForEdit.collect_item;
    let material: any = store.state.collection.itemForEdit.cardbord_num;
    let payment: any = store.state.collection.itemForEdit.payment_method;
    let collection: any = store.state.collection.itemForEdit;
    let responseAddress: any = true;

    const inputZipcode = (zipcode: string) => {
      store.state.collection.itemForEdit.zipcode = zipcode;
      store.state.collection.itemForEdit.address_01 = "";
      store.state.collection.itemForEdit.address_02 = "";
      store.state.collection.itemForEdit.address_code = "";
      store.state.collection.itemForEdit.kaishu_date = "";
      store.state.collection.itemForEdit.kaishu_time = "";
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
    };

    const inputCity = (city: string) => {
      store.state.collection.itemForEdit.address_01 = city;
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
    };
    const inputAddress = (address: string) => {
      store.state.collection.itemForEdit.address_02 = address;
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
    };

    const selectTime = (time: string) => {
      selectedTime = time != "0" ? time : "";
      store.state.collection.itemForEdit.kaishu_time = selectedTime;
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
    };
    function selectDate(datetime: string) {
      store.state.collection.itemForEdit.kaishu_date = datetime;
      store.state.collection.itemForEdit.kaishu_time = "";
      selectedDate = datetime != "0" ? datetime : "";
      selectedTime = "";
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
    }
    const inputPhone = (item: string) => {
      store.state.collection.itemForEdit.phone_type = item;
      phoneType = item;
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
    };
    const selectPhone = (phone: string) => {
      store.state.collection.itemForEdit.tel = phone;
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
      phoneNumber = phone;
    };
    const inputMaterial = (item: string) => {
      store.state.collection.itemForEdit.collect_item = item;
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
      materialType = item;
    };
    const selectMaterial = (item: string) => {
      store.state.collection.itemForEdit.cardbord_num = item;
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
      material = item;
    };
    const inputLock = (item: number) => {
      store.state.collection.itemForEdit.is_autolock = item;
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
      selectedLock = item;
    };
    const selectRequest = (request: string) => {
      store.state.collection.itemForEdit.request = [request];
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
    };
    const inputPayment = (item: string) => {
      store.state.collection.itemForEdit.payment_method = item;
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
      payment = item;
    };

    const changeSelect = (item: string) => {
      store.state.collection.itemForEdit.address_code = item;
      store.dispatch(
        "collection/saveItemForEdit",
        store.state.collection.itemForEdit
      );
    };

    const validationAddressCode = (response: any) => {
      store.dispatch("collection/saveValidationAddress", response);
      if (response == 0) {
        responseAddress = true;
      } else {
        responseAddress = false;
      }
    };

    const submit = () => {
      if (
        store.state.collection.itemForEdit.address_01 &&
        ((store.state.collection.address_specific_area &&
          store.state.collection.address_rest) ||
          (!store.state.collection.address_specific_area &&
            store.state.collection.itemForEdit.address_02))
      ) {
        errMsg.value = "";
        store.dispatch("collection/setAddressDone", true);
      } else {
        errMsg.value = "入力してください";
      }

      if (store.state.collection.itemForEdit.kaishu_time) {
        errMsg1.value = "";
      } else {
        errMsg1.value = "選択してください";
      }

      if (store.state.collection.itemForEdit.kaishu_date) {
        errMsg2.value = "";
      } else {
        errMsg2.value = "選択してください";
      }

      if (
        store.state.collection.itemForEdit.kaishu_time &&
        store.state.collection.itemForEdit.kaishu_date
      ) {
        store.dispatch("collection/setDatetimeDone", true);
      }

      errMsg3.value = "";
      if (store.state.collection.itemForEdit.phone_type == 2) {
        if (store.state.collection.itemForEdit.tel) {
          if (util.validateTel(store.state.collection.itemForEdit.tel)) {
            errMsg3.value = "";
          } else {
            errMsg3.value = "正しい形式で入力してください";
          }
        } else {
          errMsg3.value = "連絡先電話番号を入力してください";
        }
      }

      if (selectedLock) {
        errMsg4.value = "";
      } else {
        errMsg4.value = "選択してください";
      }
      if (
        (payment && store.state.collection.itemForEdit.is_free == "0") ||
        store.state.collection.itemForEdit.is_free == "1"
      ) {
        errMsg5.value = "";
      } else {
        errMsg5.value = "選択してください";
      }

      errMsg6.value = "";
      if (
        store.state.collection.itemForEdit.collect_item.indexOf("2C01") >= 0
      ) {
        if (
          store.state.collection.itemForEdit.cardbord_num &&
          store.state.collection.itemForEdit.cardbord_num != 0
        ) {
          errMsg6.value = "";
        } else {
          errMsg6.value = "ダンボールの枚数を選択してください";
        }
      } else if (store.state.collection.itemForEdit.collect_item.length == 0) {
        errMsg6.value = "選択してください";
      }

      if (
        !errMsg.value &&
        !errMsg1.value &&
        !errMsg2.value &&
        !errMsg3.value &&
        !errMsg4.value &&
        !errMsg5.value &&
        !errMsg6.value &&
        store.state.collection.validation_address == 0
      ) {
        context.emit("click");
      } else {
        window.scrollTo(0, 0);
      }
    };

    const buttonWidth = computed(() => {
      return store.state.device == "mobile" ? "100%" : "34rem";
    });
    return {
      selectTime,
      selectDate,
      selectPhone,
      selectRequest,
      inputPhone,
      inputLock,
      inputMaterial,
      selectMaterial,
      inputPayment,
      changeSelect,
      submit,
      errMsg,
      errMsg1,
      errMsg2,
      errMsg3,
      errMsg4,
      errMsg5,
      errMsg6,
      buttonWidth,
      inputZipcode,
      inputCity,
      inputAddress,
      validationAddressCode,
    };
  },
  methods: {
    updateItem(item: string): void {
      this.selected = item;
      const collection: any = this.$store.state.collection.itemForEdit;
      collection.zipcode = item;
      if (item == this.editCollectionAddress.zipcode) {
        collection.address_01 = this.editCollectionAddress.address_01;
        collection.address_02 = this.editCollectionAddress.address_02;
        collection.kaishu_date = this.editCollectionAddress.kaishu_date;
        collection.kaishu_time = this.editCollectionAddress.kaishu_time;
      } else {
        collection.address_01 = "";
        collection.address_02 = "";
        collection.kaishu_date = "";
        collection.kaishu_time = "";
      }
      this.$store.dispatch("collection/saveItemForEdit", collection);
    },
    getAddressOptions(): any {
      const state: any = this.$store.state;
      const address = state.collection.address_list || [];
      const collection: any = this.editCollectionAddress;
      let options = [];
      if (collection.zipcode || collection.address_01) {
        options.push({
          id: "radio_edit",
          label:
            "<strong>〒" +
            collection["zipcode"] +
            "</strong> <br/><strong>" +
            `${collection["address_01"]}${collection["address_02"]}` +
            "</strong>",
          value: collection["zipcode"],
        });
      }
      for (var i = 0; i < address.length; i++) {
        let item: any = {
          id: "radio" + i,
          label:
            "<strong>〒" +
            address[i]["zipcode"] +
            "</strong> <br/><strong>" +
            `${address[i]["address_01"]}${address[i]["address_02"]}` +
            "</strong>",
          value: address[i]["zipcode"].replace("-", ""),
        };
        if (address[i]["address_list"]) {
          let subItems = [];
          for (var j = 0; j < address[i]["address_list"].length; j++) {
            subItems.push({
              label: `${address[i]["address_list"][j]["prefecture"]}${address[i]["address_list"][j]["municipality"]}${address[i]["address_list"][j]["specific_area"]}`,
              value: address[i]["address_list"][j]["jis_address_code"],
            });
          }
          item["selectItems"] = subItems;
        }
        options.push(item);
      }

      if (options.length > 0) {
        this.multipleZipcode = options.filter((el) => el.value.length == 0).length == 0
      } else {
        this.multipleZipcode = false;
      }
      
      options.push({
        id: "radio" + address.length,
        label: "<strong>上記以外の住所</strong>",
        value: "上記以外の住所",
      });
      return options;
    },
  },
  created() {
    const store = useStore();
    this.editCollectionAddress = {
      zipcode: store.state.collection.itemForEdit.zipcode,
      address_01: store.state.collection.itemForEdit.address_01,
      address_02: store.state.collection.itemForEdit.address_02,
      address_code: store.state.collection.itemForEdit.address_code,
      kaishu_date: store.state.collection.itemForEdit.kaishu_date,
      kaishu_time: store.state.collection.itemForEdit.kaishu_time,
    };
    this.setInitialData(store.state.collection.itemForEdit);
    this.editCollectionTel = store.state.collection.itemForEdit.tel;
    if (store.state.collection.itemForEdit.zipcode) {
      this.selected = store.state.collection.itemForEdit.zipcode;
      this.validateResponse(this.selected);
    }
    if (store.state.collection.itemForEdit.tel) {
      this.selectedRadioItems = "1";
    }
  },
  emits: ["click", "click2", "input", "input2"],
});
