import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35e32468"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "form-label",
  style: {"margin-bottom":"3.1rem"}
}
const _hoisted_2 = {
  class: "title",
  style: {"font-weight":"bold"}
}
const _hoisted_3 = {
  class: "form-input",
  style: {"margin":"3.2rem auto 0"}
}
const _hoisted_4 = {
  key: 0,
  class: "forgotPasswordLink",
  style: {"margin":"3.2rem auto 0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_symbol_mark = _resolveComponent("symbol-mark")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_symbol_mark, { mark: "◎" }),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.buttonText2)
        ? (_openBlock(), _createBlock(_component_base_button, {
            key: 0,
            buttonText: _ctx.buttonText2,
            width: _ctx.width,
            buttonColor: _ctx.buttonColor2,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click2'))),
            style: {"margin-bottom":"1.6rem"}
          }, null, 8, ["buttonText", "width", "buttonColor"]))
        : _createCommentVNode("", true),
      _createVNode(_component_base_button, {
        buttonText: _ctx.buttonText,
        width: _ctx.width,
        buttonColor: _ctx.buttonColor,
        buttonClass: _ctx.buttonClass,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click')))
      }, null, 8, ["buttonText", "width", "buttonColor", "buttonClass"])
    ]),
    (_ctx.linkText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('clickLink'))),
            class: "hover-shining text-underline"
          }, _toDisplayString(_ctx.linkText), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}