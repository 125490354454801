
import { computed, defineComponent, onMounted, ref } from "vue";
import util from "@/js/util";

export default defineComponent({
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    number: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      required: true,
    },
    sales_record_date: {
      type: String,
      required: true,
    },
  },
  emits: ["click", "delete"],
  setup(props, context) {
    const status = ref(-1);
    const promptMsg = [
      "マイページをご用意しています。しばらくお待ちください。",
      "",
      "ご契約情報が確認できませんでした。 <br/>お手数ですが、お問い合わせください。",
      "マイページをご利用いただくには、お問い合わせください。",
      "このご契約はキャンセルされました。",
    ];
    const titleMsg = [
      "ご契約情報（受付中）",
      "",
      "ご契約情報（登録失敗）",
      "ご契約情報（保留中）",
      "ご契約情報（キャンセル済み）",
    ];
    const className = computed(() => {
      if (
        (status.value === 1 && !props.sales_record_date) ||
        status.value === 0 ||
        status.value === 3 ||
        status.value === 4 ||
        (status.value === 1 &&
          props.sales_record_date &&
          util.getDiffYears(props.sales_record_date) >= 3)
      ) {
        return "disabled contract-block";
      }
      if (status.value === 2) {
        return "error contract-block";
      } else if (props.selected === true) {
        return "selected contract-block hover-shining";
      } else {
        return "contract-block hover-shining";
      }
    });
    const title = computed(() => {
      if (
        status.value === 0 ||
        status.value === 2 ||
        status.value === 3 ||
        status.value === 4
      ) {
        return titleMsg[status.value];
      } else if (props.selected === true) {
        return "表示中のご契約情報";
      } else {
        return "その他のご契約情報";
      }
    });
    const mgs = computed(() => {
      if (
        status.value === 0 ||
        status.value === 2 ||
        status.value === 3 ||
        status.value === 4
      ) {
        return promptMsg[status.value];
      } else {
        return `${util.getJapaneseMonth(props.date)} のお引越`;
      }
    });
    const clickDelete = () => {
      if (status.value === 2) {
        // if (
        //   confirm(
        //     "再度、すでに登録済みのお客様番号を申請する場合は、契約情報を追加するボタンからお願いいたします。一度このお客様番号を削除すると、同じお客様番号を、このシステムで受け付けることができなくなります。\r\n\r\n削除いたしますか？"
        //   )
        // ) {
        context.emit("delete");
        // }
      }
    };
    const click = () => {
      // if not status == 1, don't emit
      if (
        (status.value === 1 && !props.sales_record_date) ||
        status.value === 0 ||
        status.value === 2 ||
        status.value === 3 ||
        status.value === 4 ||
        (status.value === 1 &&
          props.sales_record_date &&
          util.getDiffYears(props.sales_record_date) >= 3)
      ) {
        console.log("no emit");
      } else {
        context.emit("click");
      }
    };
    onMounted(() => {
      // status==1で売上計上日がnullならば、保留にする
      if (props.status === 1 && !props.sales_record_date) {
        status.value = 3;
      } else {
        status.value = props.status;
      }
    });
    return {
      title,
      className,
      mgs,
      click,
      clickDelete,
    };
  },
});
