
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: ""
    },
  },
  setup(props) {
    const style = computed(() => {
      return props.className + ' price';
    });
    return {
      style
    };
  },
  emits: ['click']
});
