
import useCheckStoreForConfirmation from "@/js/useCheckStoreForConfirmation";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import useRouteTo from "../../js/useRouteTo";

import ContactOptionDesktop from "./partials/ContactOptionDesktop.vue";
import ContactOptionMobile from "./partials/ContactOptionMobile.vue";

export default defineComponent({
  components: { ContactOptionMobile, ContactOptionDesktop },
  setup() {
    const store = useStore();
    const { routeTo } = useRouteTo();
    const hasOption = ref(false);
    onMounted(async () => {
      const res = await useCheckStoreForConfirmation();
      if (res === "ok") {
        const detail = store.state.confirmation.contractDetail;
        for (let category of detail.option_category_list) {
          if (category.service_kbn) {
            hasOption.value = true;
            break;
          }
        }
      }
    });
    return {
      hasOption,
    };
  },
});
