
import InquiriesSuccess from "@/components/mypage/InquiriesSuccess.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { InquiriesSuccess },
  data: function () {
    return {
    };
  },
});
