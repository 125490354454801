
import Steps from "@/components/furniture/Steps.vue";
import BookingScheduleView from "@/components/collection/desktop/BookingScheduleView.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import ExclamationIcon from "../../../components/furniture/ExclamationIcon.vue";
import useRouteTo from "../../../js/useRouteTo";

import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { Steps, BookingScheduleView, ExclamationIcon, Logo, Footer },
  props: {
    address_list: {
      type: Object
    }
  },
  data: function () {
    return {
      items: [
        { label: "1. お申込み内容のご入力", class: "current" },
        { label: "2. 入力内容のご確認" },
        { label: "3. お申し込み完了" },
      ],
      msg: "今回の回収は無料でご利用いただけます",
    };
  },
  watch: {
  '$store.state.collection.free_or_paid': function(value) {
      if (value == "paid") {
        this.msg = "今回の回収は3,000円(税込)で承ります";
      } else {
        this.msg = "今回の回収は無料でご利用いただけます";
      }
    }
  },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();

    const submit = () => {
      routeTo("ConfirmationCollection");
    };
    return {
      submit,
    };
  },

  created() {
    const store = useStore();
    if (store.state.collection.free_or_paid == "paid") {
      this.msg = "今回の回収は3,000円(税込)で承ります";
    }
  },
});
