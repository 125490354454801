import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "furniture" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingEditView = _resolveComponent("BookingEditView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BookingEditView, {
      msg: _ctx.msg,
      buttonTitle: "この内容に変更する",
      button2Title: "予約取消",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submitEdit'))),
      onClick2: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submitDelete')))
    }, null, 8, ["msg"])
  ]))
}