
import { defineComponent, reactive, ref } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import Checkbox from "@/components/furniture/Checkbox.vue";
import useRouteTo from "../../../js/useRouteTo";
import { useStore } from "vuex";
import util from "@/js/util";

import { useRoute } from "vue-router";
import BranchTel from "./BranchTel.vue";

export default defineComponent({
  name: "CancelView",
  components: {
    BaseButton,
    Checkbox,
    BranchTel,
  },
  props: {
    detail: {
      type: Object,
    },
    hasOption: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      items: [{ id: "checkbox", label: "内容を確認しました", value: 1 }],
      items1: [{ id: "checkbox1", label: "内容を確認しました", value: 2 }],
      items2: [{ id: "checkbox2", label: "内容を確認しました", value: 3 }],
      items3: [{ id: "checkbox3", label: "内容を確認しました", value: 4 }],
      items4: [{ id: "checkbox4", label: "内容を確認しました", value: 5 }],
      selectedInputItems: [] as any,
      selectedInputItems1: [] as any,
      selectedInputItems2: [] as any,
      selectedInputItems3: [] as any,
      selectedInputItems4: [] as any,
      disabled: true,
      className1: "bg-white collapse",
      className2: "bg-white collapse",
      className3: "bg-white collapse",
      className4: "bg-white collapse",
      options: {
        construction: [""],
        date: "",
        special_job: [""],
        vehicle: "",
        amenities: [""],
      },
    };
  },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const route = useRoute();
    const click = () => {
      routeTo("ContactPrice", route.query);
    };
    return {
      click,
    };
  },
  watch: {
    hasOption: function (newVal, oldVal) {
      if (newVal) {
        this.className1 = "bg-white column collapse";
      }
    },
    $data: {
      handler: function (val, oldVal) {
        const state: any = this.$store.state;
        const allChecks = state.confirmation.is_rakumo
          ? val.selectedInputItems2.length > 0 &&
            val.selectedInputItems3.length > 0
          : val.selectedInputItems.length > 0 &&
            (val.selectedInputItems1.length > 0 || !this.hasOption) &&
            val.selectedInputItems2.length > 0 &&
            val.selectedInputItems3.length > 0 &&
            val.selectedInputItems4.length > 0;
        if (allChecks) {
          this.setDisable(false);
        } else {
          this.setDisable(true);
        }
      },
      deep: true,
    },
  },
  methods: {
    setDisable(value: boolean) {
      this.disabled = value;
    },
    inputItems(items: string[]) {
      this.$store.dispatch("confirmation/setValuables", items);
      this.selectedInputItems = items;
    },
    inputItems1(items: string[]) {
      if (items.length > 0) {
        this.className1 = "bg-white column";
      } else {
        this.className1 = "bg-white column collapse";
      }
      this.$store.dispatch("confirmation/setOptions", items);
      this.selectedInputItems1 = items;
    },
    inputItems2(items: string[]) {
      if (items.length > 0) {
        this.className2 = "bg-white column";
      } else {
        this.className2 = "bg-white column collapse";
      }
      this.$store.dispatch("confirmation/setOtherItems", items);
      this.selectedInputItems2 = items;
    },
    inputItems3(items: string[]) {
      if (items.length > 0) {
        this.className3 = "bg-white column";
      } else {
        this.className3 = "bg-white column collapse";
      }
      this.$store.dispatch("confirmation/setProgress", items);
      this.selectedInputItems3 = items;
    },
    inputItems4(items: string[]) {
      if (items.length > 0) {
        this.className4 = "bg-white column";
      } else {
        this.className4 = "bg-white column collapse";
      }
      this.$store.dispatch("confirmation/setDangerous", items);
      this.selectedInputItems4 = items;
    },
    getJapaneseDate(value: string) {
      return util.getJapaneseDate(value);
    },
  },
  mounted: function () {
    const store = useStore();
    this.selectedInputItems = store.state.confirmation.valuables;
    this.selectedInputItems1 = store.state.confirmation.options;
    this.selectedInputItems2 = store.state.confirmation.otherItems;
    this.selectedInputItems3 = store.state.confirmation.progress;
    this.selectedInputItems4 = store.state.confirmation.dangerous;

    this.className1 =
      this.selectedInputItems1.length > 0 || !this.hasOption
        ? "bg-white column"
        : "bg-white column collapse";
    this.className2 =
      this.selectedInputItems2.length > 0
        ? "bg-white column"
        : "bg-white column collapse";
    this.className3 =
      this.selectedInputItems3.length > 0
        ? "bg-white column"
        : "bg-white column collapse";
    this.className4 =
      this.selectedInputItems4.length > 0
        ? "bg-white column"
        : "bg-white column collapse";
    if (store.state.confirmation.is_rakumo) {
      this.disabled =
        this.selectedInputItems1.length == 0 ||
        this.selectedInputItems3.length == 0 ||
        this.selectedInputItems2.length == 0;
    } else {
      this.disabled =
        this.selectedInputItems.length == 0 ||
        this.selectedInputItems1.length == 0 ||
        this.selectedInputItems2.length == 0 ||
        this.selectedInputItems3.length == 0 ||
        this.selectedInputItems4.length == 0;
    }
  },
  emits: ["click"],
});
