
import { computed, defineComponent, onUpdated, ref } from "vue";
import { useRoute } from "vue-router";
import useRouteTo from "../js/useRouteTo";

export default defineComponent({
  setup() {
    const route = useRoute();
    const { routeTo } = useRouteTo();
    const ukt_no = ref("");
    const file_num = ref(0);
    const computeShow = computed(() => {
      if (
        route.name == "MyPage" ||
        route.path.startsWith("/account") ||
        route.path.startsWith("/confirmation")
      ) {
        return false;
      } else {
        return true;
      }
    });
    const click = () => {
      routeTo("MyPage");
    };
    const clickMyPocket = () => {
      routeTo("MyPageMyPocket");
    };
    const clickInquiry = () => {
      routeTo("MyPageInquiries");
    };
    onUpdated(() => {
      try {
        ukt_no.value = (localStorage.getItem("ukt_no") as string) || "";
        file_num.value = localStorage.getItem("file_num") ? parseInt(localStorage.getItem("file_num") as string) : 0;


        // add event listener to detect ukt_no change
        window.addEventListener("ukt_no-localstorage-changed", (event: any) => {
          ukt_no.value = event.detail.ukt_no || "";
        });
        window.addEventListener("file_num-localstorage-changed", (event: any) => {
          file_num.value = event.detail.file_num || 0;
        });
      } catch {
        console.log("localStorageへのアクセス失敗1");
      }
    });
    return {
      ukt_no,
      file_num,
      computeShow,
      click,
      clickMyPocket,
      clickInquiry
    };
  },
});
