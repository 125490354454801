
import { defineComponent } from "vue";
import { useStore } from "vuex";
import apis from "@/js/apis";

import RadioButton from "./../furniture/RadioButton.vue";
import ExclamationIcon from "./../furniture/ExclamationIcon.vue";
import AddressBlock from "./AddressBlock.vue";

export default defineComponent({
  name: "Booking",
  components: {
    RadioButton,
    ExclamationIcon,
    AddressBlock,
  },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    msg: {
      type: String,
      default: "",
    },
    selectedValue: {
      type: String,
      default: "",
    },
    erroMsg: {
      type: String,
      default: "",
    },
    address_list: {
      type: Object,
    },
  },
  data: function () {
    return {
      items: [
        {
          id: "radio1",
          label:
            "<strong>〒565-0846</strong> <br/><strong>大阪府吹田市千里山虹が丘１０−１ シエリアシティ千里山B棟７０５</strong>",
          value:
            "〒565-0846 大阪府吹田市千里山虹が丘１０−１ シエリアシティ千里山B棟７０５",
        },
        {
          id: "radio2",
          label: "<strong>上記以外の住所</strong>",
          value: "上記以外の住所",
        },
      ],
      selected:
        "〒565-0846 大阪府吹田市千里山虹が丘１０−１ シエリアシティ千里山B棟７０５",
      selectedZipcode: "",
      selectedAddress: "",
      selectedCity: "",
      selectedAddressCode: "",
      className: "",
      editCollectionAddress: {},
      multipleZipcode: false,
    };
  },
  created() {
    const store = useStore();
    if (store.state.collection.free_or_paid == "paid") {
      this.className = "red";
    }
    this.editCollectionAddress = {
      zipcode: store.state.collection.itemForEdit.zipcode,
      address_01: store.state.collection.itemForEdit.address_01,
      address_02: store.state.collection.itemForEdit.address_02,
      address_code: store.state.collection.itemForEdit.address_code,
      kaishu_date: store.state.collection.itemForEdit.kaishu_date,
      kaishu_time: store.state.collection.itemForEdit.kaishu_time,
    };
  },
  watch: {
    selected: async function (val) {
      if (val && val != "上記以外の住所") {
        this.selectedZipcode = "";
        this.selectedCity = "";
        this.selectedAddress = "";
      }
    },
    "$store.state.collection.free_or_paid": function (value) {
      if (value == "paid") {
        this.className = "red";
      } else {
        this.className = "";
      }
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    let selectZipcode = "";
    let selectAddess = "";
    let selectCity = "";
    let collection: any =
      Object.keys(store.state.collection.itemForEdit).length > 0
        ? store.state.collection.itemForEdit
        : null;

    const inputZipcode = (zipcode: string) => {
      if (Object.keys(store.state.collection.itemForEdit).length > 0) {
        store.state.collection.itemForEdit.zipcode = zipcode;
        store.state.collection.itemForEdit.address_01 = "";
        store.state.collection.itemForEdit.address_02 = "";
        store.dispatch(
          "collection/saveItemForEdit",
          store.state.collection.itemForEdit
        );
      } else {
        store.dispatch("collection/inputZipcode", zipcode);
      }

      selectZipcode = zipcode;
      if (
        selectZipcode.length > 0 &&
        selectAddess.length > 0 &&
        selectCity.length > 0
      ) {
        emit("redirect", store.state.collection);
      }
    };
    const inputCity = (city: string) => {
      if (Object.keys(store.state.collection.itemForEdit).length > 0) {
        store.state.collection.itemForEdit.address_01 = city;
        store.dispatch(
          "collection/saveItemForEdit",
          store.state.collection.itemForEdit
        );
      } else {
        store.dispatch("collection/inputCity", city);
      }

      selectCity = city;
      if (
        selectZipcode.length > 0 &&
        selectAddess.length > 0 &&
        selectCity.length > 0
      ) {
        emit("redirect", store.state.collection);
      }
    };
    const inputAddress = (address: string) => {
      if (Object.keys(store.state.collection.itemForEdit).length > 0) {
        store.state.collection.itemForEdit.address_02 = address;
        store.dispatch(
          "collection/saveItemForEdit",
          store.state.collection.itemForEdit
        );
      } else {
        store.dispatch("collection/inputAddress", address);
      }
      selectAddess = address;
    };
    const changeSelect = (item: string) => {
      if (Object.keys(store.state.collection.itemForEdit).length > 0) {
        store.state.collection.itemForEdit.address_code = item;
        store.dispatch(
          "collection/saveItemForEdit",
          store.state.collection.itemForEdit
        );
      } else {
        store.dispatch("collection/inputAddressCode", item);
      }
    };
    return {
      inputZipcode,
      inputAddress,
      inputCity,
      changeSelect,
    };
  },
  mounted: function () {
    const store = useStore();
    this.selected = this.$props.selectedValue;
    const collection =
      Object.keys(store.state.collection.itemForEdit).length > 0
        ? store.state.collection.itemForEdit
        : store.state.collection;
    this.selectedZipcode = collection.zipcode;
    this.selectedCity = collection.address_01;
    this.selectedAddress = collection.address_02;
    this.selectedAddressCode = collection.address_code;
  },
  methods: {
    async validateResponse(zipcode: string) {
      const state: any = this.$store.state
      
      const result = await apis.callAPI(
        "get",
        apis.endpoints.getAddress + "?zip_code=" + zipcode.replace("-", ""),
        state.jwt
      );
      if (result && result.data.status === "success") {
        let response = 0;
        const results = result.data.results;
        const findAddress = results.filter(
          (i: any) => i.zip_code.replace("-", "") == zipcode.replace("-", "")
        );
        
        if (findAddress.length > 0) {
          if (findAddress[0]["branch_info"]) {
            this.$store.dispatch(
              "collection/saveBranch",
              findAddress[0]["branch_info"]
            );
          }
          if (findAddress[0]["branch_info"]["is_fc"] == true) {
            response = 2;
          } else if (findAddress[0]["kaishu_available"] == 1) {
            response = 1;
          } else if (findAddress[0]["kaishu_available"] == 2) {
            response = 2;
          }
        }
        this.$emit("changeMessage", response);
      }
    },
    updateMessage(item: string): void {
      this.$emit("changeMessage", item);
    },
    updateItem(item: string): void {
      const state: any = this.$store.state;
      const collection: any =
        Object.keys(state.collection.itemForEdit).length > 0
          ? state.collection.itemForEdit
          : null;
      const editCollectionAddress: any = this.editCollectionAddress;
      if (collection) {
        collection.zipcode = item;
        if (item == editCollectionAddress.zipcode) {
          collection.address_01 = editCollectionAddress.address_01;
          collection.address_02 = editCollectionAddress.address_02;
        } else {
          collection.address_01 = "";
          collection.address_02 = "";
        }
        this.$store.dispatch("collection/saveItemForEdit", collection);
      }

      this.selected = item;
      this.$emit("change", item);

      if (item != "上記以外の住所") {
        this.$emit("changeMessage", 4);
        this.validateResponse(item)
      }
    },
    getAddressOptions(): any {
      // If registered addresses has one record without zipcode => no need to validation 3 digits
      // If there is no register addresses => no need to validate 3 digits

      const state: any = this.$store.state;
      const address = state.collection.address_list || [];
      const collection: any = this.editCollectionAddress;
      let options = [];

      if (collection.zipcode || collection.address_01) {
        options.push({
          id: "radio_edit",
          label:
            "<strong>〒" +
            collection["zipcode"] +
            "</strong> <br/><strong>" +
            `${collection["address_01"]}${collection["address_02"]}` +
            "</strong>",
          value: collection["zipcode"],
        });
      }
      for (var i = 0; i < address.length; i++) {
        let item: any = {
          id: "radio" + i,
          label:
            "<strong>〒" +
            address[i]["zipcode"] +
            "</strong> <br/><strong>" +
            `${address[i]["address_01"]}${address[i]["address_02"]}` +
            "</strong>",
          value: address[i]["zipcode"].replace("-", ""),
        };
        if (address[i]["address_list"]) {
          let subItems = [];
          for (var j = 0; j < address[i]["address_list"].length; j++) {
            subItems.push({
              label: `${address[i]["address_list"][j]["prefecture"]}${address[i]["address_list"][j]["municipality"]}${address[i]["address_list"][j]["specific_area"]}`,
              value: address[i]["address_list"][j]["jis_address_code"],
            });
          }
          item["selectItems"] = subItems;
        }
        options.push(item);
      }
      if (options.length > 0) {
        this.multipleZipcode = options.filter((el) => el.value.length == 0).length == 0
      } else {
        this.multipleZipcode = false;
      }
      options.push({
        id: "radio" + address.length,
        label: "<strong>上記以外の住所</strong>",
        value: "上記以外の住所",
      });
      return options;
    },
  },
  emits: ["change", "redirect", "changeMessage"],
});
