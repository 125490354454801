<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import apis from "@/js/apis";
import util from "@/js/util";
import deliveryConst from "@/js/deliveryConst";

import useRouteTo from "../js/useRouteTo";

export default {
  data() {
    return {
      selectItems: [],
      datetimes: {},
      selectedSelectTime: "0",
      selectedSelectItems: "0",
      free_or_paid: "free",
      selectRequests: [
        { id: "request1", label: "到着前に連絡を希望します", value: "1A01" },
        {
          id: "request2",
          label: "到着時、管理人（コンシェルジュ）の方への声掛けを希望します",
          value: "1A02",
        },
        { id: "request0", label: "要望なし", value: "0" },
      ],
      selectedRequest: null,
      selectLock: [
        { id: "radio12", label: "設定なし", value: "0" },
        { id: "radio10", label: "あり", value: "1" },
        { id: "radio11", label: "なし", value: "2" },
      ],
      selectedLock: null,
      selectPlacements: [
        { id: "radio20", label: "設定なし", value: "0" },
        { id: "radio20", label: "玄関先", value: "1" },
        { id: "radio21", label: "ガレージ", value: "2" },
        { id: "radio23", label: "希望しない", value: "3" },
      ],
      selectedPlacement: null,
    };
  },
  watch: {
    availableDate: function (newVal) {
      this.getAvailableDate(newVal);
    },
    "$store.state.delivery.item.delivery_date": function (newVal) {
      this.selectedSelectTime = null;
      this.$store.dispatch("delivery/inputTime", null);
    },
    "$store.state.delivery.delivery_date": function (newVal) {
      this.selectedSelectTime = null;
      this.$store.dispatch("delivery/inputTime", null);
    },
    "$store.state.delivery.placement": function (newVal) {
      this.selectedPlacement = newVal;
    },
  },
  methods: {
    getAvailableDate(available_datetime) {
      const delivery = this.$store.state.delivery.item
        ? this.$store.state.delivery.item
        : null;
      let dates = [];
      let datetimes = {};
      for (var i = 0; i < available_datetime.length; i++) {
        if (i == 0 && !this.selectedSelectItems && !delivery) {
          this.selectedSelectItems = available_datetime[i]["date"];
          this.$store.dispatch(
            "delivery/inputDatetime",
            available_datetime[i]["date"]
          );
        }
        dates.push({
          value: available_datetime[i]["date"],
          label: util.getJapaneseDateMonth(available_datetime[i]["date"]),
        });
        datetimes[available_datetime[i]["date"]] =
          available_datetime[i]["times"];
      }
      if (
        delivery &&
        delivery.delivery_date &&
        !datetimes[delivery.delivery_date]
      ) {
        if (new Date(delivery.delivery_date).toString() !== 'Invalid Date') {
          dates.push({
            value: delivery.delivery_date,
            label: util.getJapaneseDateMonth(delivery.delivery_date),
          });
          datetimes[delivery.delivery_date] = [
            {
              time: delivery.delivery_time,
            },
          ];
        }
      }
      if (
        delivery &&
        delivery.delivery_date &&
        datetimes[delivery.delivery_date]
      ) {
        const findTime = datetimes[delivery.delivery_date].filter(
          (i) => i.time == delivery.delivery_time
        );
        if (findTime.length == 0) {
          datetimes[delivery.delivery_date].push({
            time: delivery.delivery_time,
          });
        }
      }
      this.datetimes = datetimes;
      this.selectItems = dates;
    },
    getTimeOptions() {
      const delivery = this.$store.state.delivery;
      
      const times = this.datetimes[delivery.delivery_date] || [];
      const options = [];
      for (var i = 0; i < times.length; i++) {
        options.push({ label: times[i]["time"], value: times[i]["time"] });
      }
      return options;
    },
    setInitialData(delivery) {
      const defaultValue = {
        datetime: "",
        time: "",
        request: [],
        is_autolock: null,
        placement: "",
      };
      const store = useStore();

      this.selectedSelectTime = delivery.delivery_time || defaultValue["time"];
      this.selectedSelectItems =
        new Date(delivery.delivery_date).toString() !== 'Invalid Date' ? delivery.delivery_date : defaultValue["datetime"];
      this.selectedLock = delivery.is_autolock;
      this.selectedRequest = delivery.request || defaultValue["request"];
      this.selectedPlacement =
        this.selectedLock == 2
          ? delivery.delivery_option || delivery.placement
          : 0;

      store.dispatch("delivery/inputDatetime", this.selectedSelectItems);
      store.dispatch("delivery/inputTime", this.selectedSelectTime);
      store.dispatch("delivery/inputLock", this.selectedLock);
      store.dispatch("delivery/inputRequest", this.selectedRequest);
      store.dispatch("delivery/inputPlacement", this.selectedPlacement || 0);
    },
  },
  mounted: function () {
    const store = useStore();
    const delivery = store.state.delivery.item
      ? store.state.delivery.item
      : store.state.delivery;
    this.setInitialData(delivery);
  },
};
</script>
