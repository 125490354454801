
import { defineComponent } from "vue";
import ProductList from "./ProductList.vue";
import ExclamationIcon from "../furniture/ExclamationIcon.vue";
import util from "@/js/util";

export default defineComponent({
  components: {
    ProductList,
    ExclamationIcon,
  },
  props: {
    msg: {
      type: String,
      default: "",
    },
    total: {
      type: Number,
      default: 0,
    },
    bookedGoods: {
      type: Object,
      required: true,
    },
  },
  emits: ["clickDelete"],
  data() {
    return {
      flickityOptions: {
        pageDots: true,
        wrapAround: true,
        prevNextButtons: false,
      },
      banners: [
        {
          id: 1,
          banner: "goods/banner3.png",
          title: "人気No.1の万能マット！",
          intro: "湿気やカビ、地震からお家と家族を守ります。",
        },
        {
          id: 2,
          banner: "goods/banner1.png",
          title: "人気No.1の万能マット！",
          intro: "湿気やカビ、地震からお家と家族を守ります。",
        },
        {
          id: 3,
          banner: "goods/banner4.png",
          title: "人気No.1の万能マット！",
          intro: "湿気やカビ、地震からお家と家族を守ります。",
        },
      ],
      products: [
        {
          id: 1,
          category: "震度7クラス対応耐震マット",
          title: "プロセブン G-50",
          size: "Ｓサイズ (50×50×5mm)",
          pack: "４枚入り",
          price: "¥3,150",
          thumb: "goods/product.png",
          link: "/goods/detail",
          quantity: 1,
        },
        {
          id: 2,
          category: "洗濯機置き台",
          title: "かさあげくん",
          size: "105×64×105mm",
          pack: "４個入り",
          price: "¥2,980",
          thumb: "goods/product1.png",
          link: "/goods/detail",
          quantity: 1,
        },
        {
          id: 3,
          category: "耐震・抗菌など７つの効果",
          title: "マット７万能型",
          size: "600×600mm",
          pack: "１枚入り",
          price: "¥3,500",
          thumb: "goods/product4.png",
          link: "/goods/detail",
          quantity: 8,
        },
      ],
    };
  },
  methods: {
    getImgUrl: function (images: any) {
      if (Array.isArray(images) && images.length > 0) {
        return images[0]["url"];
      }
    },
    filterCurrency(value: string) {
      return util.getPrice(value, true);
    },
  },
});
