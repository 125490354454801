interface msgInterface {
  [key: string]: any;
}

const messages = {
  pc: {
    "greeting.01": "初めまして　僕の名前はアーボットです。「引越マイページ」へようこそ！",
    "greeting.02": "またお会いできましたね。新しい引越マイページができました！",
    "greeting.03": "ラクモのマイページへようこそ！",
    "greeting.04": "新しいラクモのマイページができました！",
    "deadline.notification.01": "明日はダンボールのお届け予定日です。引越し準備、頑張りましょう！",
    "deadline.notification.02": "「新生活快適グッズ購入」の申込期限が迫っています！お申込忘れはございませんか？",
    "feature.introduction.01": "お引越作業日の詳細から、引越基本情報が確認できます。",
    "feature.introduction.02": "ダンボールの配達日時が確認できます。もうチェックされましたか？",
    "feature.introduction.03": "新生活に必要なアレコレ、グッズ購入予約で引越当日に届きます！",
    "feature.introduction.04": "ダンボールの回収はお引越し完了後からお申込みができます！",
    "thank.you": "この度はご利用ありがとうございました。また次回もよろしくお願いします！",
  },
  mobile: {
    "greeting.01": "引越マイページへようこそ！",
    "greeting.02": "新しいマイページができました！",
    "greeting.03": "ラクモのマイページへようこそ！",
    "greeting.04": "新しいラクモのマイページができました！",
    "deadline.notification.01": "明日は、ダンボールのお届け予定日です！",
    "deadline.notification.02": "グッズ購入の事前申込の締切が間近です！",
    "feature.introduction.01": "まずはメニューボタンをタップしてみて！",
    "feature.introduction.02": "ダンボールの配達日時が確認できます。",
    "feature.introduction.03": "グッズ購入予約で引越当日に届きます！",
    "feature.introduction.04": "ダンボール回収はお引越し後からお申込み可能！",
    "thank.you": "この度はご利用ありがとうございました。",
  },
} as msgInterface;
export default messages;
