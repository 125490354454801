import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "furniture collection mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Steps = _resolveComponent("Steps")!
  const _component_BookingView = _resolveComponent("BookingView")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Steps, { items: _ctx.items }, null, 8, ["items"]),
    _createVNode(_component_BookingView, {
      msg: _ctx.msg,
      selectedValue: _ctx.selected,
      onChangeMessage: _ctx.changeMessage,
      onChange: _ctx.inputAddress,
      onRedirect: _ctx.redirect
    }, null, 8, ["msg", "selectedValue", "onChangeMessage", "onChange", "onRedirect"]),
    _createVNode(_component_Modal, {
      className: _ctx.classModalName,
      onClick: _ctx.hideModal,
      buttonText: _ctx.buttonModalTitle,
      title: _ctx.modalTitle,
      msg: _ctx.modalBody
    }, null, 8, ["className", "onClick", "buttonText", "title", "msg"])
  ]))
}