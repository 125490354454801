
import BaseButton from "@/components/BaseButton.vue";
import EmailAndPassword from "@/components/account/EmailAndPassword.vue";
import InputBlock from "@/components/account/InputBlock.vue";
import TextBlock from "@/components/account/TextBlock.vue";
import { defineComponent, onMounted, ref } from "vue";
import useRouteTo from "../../js/useRouteTo";
import { useStore } from "vuex";
import util from "../../js/util";
import LabelAndInput from "@/components/account/LabelAndInput.vue";
import ContainerBlock from "@/components/account/ContainerBlock.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import apis from "@/js/apis";

export default defineComponent({
  components: {
    TextBlock,
    EmailAndPassword,
    InputBlock,
    BaseButton,
    LabelAndInput,
    ContainerBlock,
    Logo,
    Footer,
  },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const passwordOld = ref("");
    const passwordNew = ref("");
    const errMsg1 = ref("");
    const errMsg2 = ref("");
    const status = ref("");
    const Token = ref("");
    const submit = async () => {
      // validation
      // passwordOld validation
      if (util.validatePassword(passwordOld.value)) {
        errMsg1.value = "";
      } else {
        errMsg1.value =
          "小文字、大文字、数字を必ず含む8文字以上で入力してください";
      }
      // passwordNew validation
      if (util.validatePassword(passwordNew.value)) {
        errMsg2.value = "";
        // password validation /
        if (passwordNew.value.indexOf('\\') >= 0) {
          errMsg2.value = "\\は使用できません";
        }
      } else {
        errMsg2.value =
          "小文字、大文字、数字を必ず含む8文字以上で入力してください";
      }

      if (!errMsg1.value && !errMsg2.value) {
        // start loading
        store.dispatch("startLoading", true);

        // API Call -> POST token and password
        const body = {
          password: passwordOld.value,
          new_password: passwordNew.value,
        };
        const result = await apis.callAPI(
          "put",
          apis.endpoints.editPassword,
          store.state.jwt,
          body
        );
        if (result && result.data.status === "success") {
          routeTo("EditPasswordDone");
        }
      } else {
        // window.scrollTo(0, 0);
      }
    };
    return {
      passwordOld,
      passwordNew,
      errMsg1,
      errMsg2,
      status,
      submit,
    };
  },
});
