
import BookingEditView from "@/components/collection/BookingEditView.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { BookingEditView },
  data: function () {
    return {
      msg: "今回の回収は無料でご利用いただけます",
    };
  },
  emits: ["submitEdit", "submitDelete"],
  created() {
    const state: any = this.$store.state;
    if (state.collection.itemForEdit.is_free == 1) {
      this.msg = "今回の回収は無料でご利用いただけます";
    } else {
      this.msg = "今回の回収は3,000円(税込)で承ります";
    }
  },
});
