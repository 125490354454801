
import Steps from "@/components/furniture/Steps.vue";
import BookingScheduleView from "@/components/furniture/desktop/BookingScheduleView.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import ExclamationIcon from "../../../components/furniture/ExclamationIcon.vue";
import useRouteTo from "../../../js/useRouteTo";

import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { Steps, BookingScheduleView, ExclamationIcon, Logo, Footer },
  props: {
    address_list: {
      type: Object,
    },
  },
  data: function () {
    return {
      items: [
        { label: "1. お申込み内容のご入力", class: "current" },
        { label: "2. 入力内容のご確認" },
        { label: "3. お申し込み完了" },
      ],
      msg: "お申し込み頂いた後、お電話にて必ず最終確認させていただきます。",
    };
  },
  setup() {
    const { routeTo } = useRouteTo();

    const submit = () => {
      routeTo("ConfirmationFurniture");
    };
    return {
      submit,
    };
  },
});
