
import useCheckStoreForConfirmation from "@/js/useCheckStoreForConfirmation";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ExclamationIcon from "./../../furniture/ExclamationIcon.vue";

export default defineComponent({
  components: { ExclamationIcon },
  setup() {
    const store = useStore();
    const branchName = ref("");
    const branchTel = ref("");
    onMounted(async () => {
      const res = await useCheckStoreForConfirmation();
      if (res == "ok") {
        const detail = store.state.confirmation.contractDetail;
        // get branch name and tel
        const arrival_departure_val =
          store.state.confirmation.arrival_departure_val;
        if (arrival_departure_val == "1") {
          branchName.value = detail.packing_branch.name;
          branchTel.value = detail.packing_branch.tel;
        } else if (
          arrival_departure_val == "2" ||
          arrival_departure_val == "4"
        ) {
          branchName.value = detail.departure_branch.name;
          branchTel.value = detail.departure_branch.tel;
        } else if (arrival_departure_val == "3") {
          branchName.value = detail.arrival_branch.name;
          branchTel.value = detail.arrival_branch.tel;
        } else {
          console.log("wrong arrival_departure_val");
        }
      }
    });
    return {
      branchName,
      branchTel,
    };
  },
});
