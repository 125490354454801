
import { defineComponent } from "vue";
import ExclamationIcon from "../furniture/ExclamationIcon.vue";
import NotifyPopup from "./NotifyPopup.vue";

export default defineComponent({
  components: { ExclamationIcon, NotifyPopup },
  props: {
    text: {
      type: String,
      default: "ダンボール追加をご希望の方はこちら＞",
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
});
