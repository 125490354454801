
import ContactDetail from "@/components/confirmation/desktop/ContactDetail.vue";
import Steps from "@/components/furniture/Steps.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { defineComponent } from "vue";

export default defineComponent({
  components: { ContactDetail, Steps, Logo, Footer },
  props: {
    detail: {
      type: Object,
    },
  },
  data: function () {
    return {
      items: [
        { label: "1. 基本情報", class: "current" },
        { label: "2. 作業内容" },
        { label: "3. 料金確認" },
        { label: "4. 完了" },
      ],
    };
  },
});
