import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactPackingMobile = _resolveComponent("ContactPackingMobile")!
  const _component_ContactPackingDesktop = _resolveComponent("ContactPackingDesktop")!

  return (_ctx.$store.state.device === 'mobile')
    ? (_openBlock(), _createBlock(_component_ContactPackingMobile, {
        key: 0,
        detail: _ctx.contractDetail
      }, null, 8, ["detail"]))
    : (_openBlock(), _createBlock(_component_ContactPackingDesktop, {
        key: 1,
        detail: _ctx.contractDetail
      }, null, 8, ["detail"]))
}