
import { defineComponent } from "vue";
import BaseLabel from "./BaseLabel.vue";

export default defineComponent({
  components: { BaseLabel },
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    selected: {
      type: [String, Array, Number],
      default: "",
    },
    errorMsg: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  data(props) {
    return {
      selectedInput: props.selected,
    };
  },
  methods: {
    updateItem(): void {
      this.$emit("change", this.selectedInput);
    },
    setSelectedItem(value: any): void {
      if (value) {
        if (!Array.isArray(this.selectedInput)) {
          this.selectedInput = value;
        } else {
          this.selectedInput.push(value);
        }
      }
    },
  },
  watch: {
    items: function (val) {
      this.setSelectedItem(this.selected || 1);
    },
    selected: function (val) {
      this.selectedInput = val;
    },
  },
});
