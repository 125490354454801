
import ContactPacking from "@/components/confirmation/ContactPacking.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { ContactPacking },
  props: {
    detail: {
      type: Object,
    },
  },
});
