
import { defineComponent, computed } from "vue";
import BaseButton from "./BaseButton.vue";
import ExclamationIcon from "./furniture/ExclamationIcon.vue";

export default defineComponent({
  components: {
    BaseButton,
    ExclamationIcon
  },
  props: {
    buttonText: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    msg: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: ''
    },
  },
  emits: ["click"],
  setup(props) {
    const style = computed(() => {
      return props.className + " modal-window";
    });
    return {
      style,
    };
  },
});
