
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import { defineComponent, onMounted, ref } from "vue";
import PocketItem from "@/components/mypage/PocketItem.vue";

export default defineComponent({
  components: {
    Logo,
    Footer,
    PocketItem,
  },
  props: {
    receipts: {
      type: Array,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    advertisements: {
      type: Array,
      required: true,
    },
    empty: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["clickDownloadMypocket", "clickDownloadInfomail", "clickOpen"],
});
