
import { defineComponent } from "vue";
import TitleBlock from "./TitleBlock.vue";

export default defineComponent({
  components: { TitleBlock },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  emits: ["click"],
  // setup() {

  // },
});
