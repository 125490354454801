<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import useRouteTo from "../js/useRouteTo";

export default {
  data: function () {
    return {
      selected: "",
      classModalName: "",
      buttonModalTitle: "確認したので、次へ",
      modalTitle: "もう一度ご確認ください",
      modalBody:
        "新築住居に引越された場合など、ご登録済みの住所が変更になることがあります。今一度、間違いがないかどうかご確認くださいませ。",
      selectedAddressCode: "",
      multipleZipcode: false,
    };
  },
  methods: {
    redirect(result) {
      if (result.zipcode) {
        this.classModalName = "active";
      }
    },
    successMessage() {
      this.buttonModalTitle = "確認したので、次へ";
      this.modalTitle = "もう一度ご確認ください";
      this.modalBody =
        "新築住居に引越された場合など、ご登録済みの住所が変更になることがあります。今一度、間違いがないかどうかご確認くださいませ。";
    },
    failedMessage() {
      this.buttonModalTitle = "";
      this.modalTitle = "サービス提供エリア外です";
      this.modalBody =
        "大変申し訳ございませんが、ご入力の住所はサービスの提供エリア外となっております。ご理解賜りますようお願い申し上げます。";
    },
    failedV2Message() {
      const branchInfo = this.$store.state.collection.branch;
      this.buttonModalTitle = "";
      this.modalTitle = "引越マイページ対応エリア外です";
      this.modalBody = `この住所への回収依頼は、引越マイページではお受けすることができません。<br/>大変お手数ではございますが、以下の番号までお問合せくださいますようお願い申し上げます。<br/><h2 class="red text-center text-underline">電話：<a class="red" href="tel:${branchInfo.tel}">${branchInfo.tel}</a></h2>`;
    },
    clearMessage() {
      this.classModalName = "";
    },
    setDefaultAddress(value) {
      if (this.$store.state.collection.address_rest) {
        this.selected = "上記以外の住所";
      } else {
        const address_list = this.$store.state.collection.address_list || [];
        const findAddress = address_list.filter(
          (i) => i.zipcode.replace("-", "") == value
        );
        this.selected = findAddress.length > 0 ? value : "上記以外の住所";
        if (findAddress.length > 0)
          this.$store.dispatch(
            "collection/inputAddressCode",
            findAddress[0].address_code
          );
      }
    },
    changeMessage(value) {
      this.classModalName = "active";
      if (value == 0) {
        this.successMessage();
      } else if (value == 1) {
        this.failedMessage();
      } else if (value == 2) {
        this.failedV2Message();
      } else if (value == 4) {
        this.clearMessage();
      }
    },
  },
  watch: {
    "$store.state.collection.zipcode": function (newVal) {
      this.setDefaultAddress(newVal);
    },
    "$store.state.collection.validation_address": function (newVal) {
      this.changeMessage(newVal);
    },
  },
  created() {
    const store = useStore();
    if (store.state.collection.zipcode) {
      this.setDefaultAddress(store.state.collection.zipcode);
    }
  },
};
</script>
