
import InquiriesSuccess from "@/components/mypage/desktop/InquiriesSuccess.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { InquiriesSuccess, Logo, Footer },
  data: function () {
    return {
    };
  },
});
