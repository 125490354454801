import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1994284"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", {
      class: "title",
      innerHTML: _ctx.title
    }, null, 8, _hoisted_1),
    _createElementVNode("p", {
      class: "text",
      innerHTML: _ctx.text
    }, null, 8, _hoisted_2)
  ]))
}