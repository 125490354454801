<script>
import { useStore } from "vuex";
import store from "@/store";
import apis from "@/js/apis";
import util from "@/js/util";

export default {
  data() {
    return {
      items: [
        { id: "checkbox1", label: "洋服タンス", value: "洋服タンス" },
        { id: "checkbox2", label: "和服タンス", value: "和服タンス" },
        { id: "checkbox3", label: "整理ダンス", value: "整理ダンス" },
        { id: "checkbox4", label: "ベッド", value: "ベッド" },
        { id: "checkbox5", label: "本棚", value: "本棚" },
        { id: "checkbox6", label: "食器棚", value: "食器棚" },
        { id: "checkbox7", label: "ソファ", value: "ソファ" },
        { id: "checkbox8", label: "薄型TV", value: "薄型TV" },
        { id: "checkbox9", label: "机", value: "机" },
        { id: "checkbox10", label: "上記以外の家具", value: "上記以外の家具" }
      ],
      selectItems: [
        { label: "12月24日(金)", value: "2022-12-24" },
        { label: "12月25日(土)", value: "2022-12-25" },
        { label: "12月26日(日)", value: "2022-12-26" },
        { label: "12月27日(月)", value: "2022-12-27" },
        { label: "12月28日(火)", value: "2022-12-28" },
        { label: "12月29日(水)", value: "2022-12-29" },
        { label: "12月30日(木)", value: "2022-12-30" },
        { label: "12月31日(金)", value: "2022-12-31" },
        { label: "01月01日(土)", value: "2022-01-01" },
        { label: "01月02日(日)", value: "2022-01-02" },
        { label: "01月03日(月)", value: "2022-01-03" },
        { label: "01月04日(火)", value: "2022-01-04" },
        { label: "01月05日(水)", value: "2022-01-05" },
        { label: "01月06日(木)", value: "2022-01-06" },
        { label: "01月07日(金)", value: "2022-01-07" },
        { label: "01月08日(土)", value: "2022-01-08" },
        { label: "01月09日(日)", value: "2022-01-09" },
        { label: "01月10日(月)", value: "2022-01-10" },
        { label: "01月11日(火)", value: "2022-01-11" },
        { label: "01月12日(水)", value: "2022-01-12" },
        { label: "01月13日(木)", value: "2022-01-13" },
        { label: "01月14日(金)", value: "2022-01-14" },
        { label: "01月15日(土)", value: "2022-01-15" },
      ],

      radioItems: [
        {
          id: "radio3",
          label: `登録済み電話番号から選ぶ<br/>`,
          value: 1,
          selectItems: [
            { label: "070−1234−5678", value: "070−1234−5678" },
            { label: "070−1234−5679", value: "070−1234−5679" },
          ],
        },
        { id: "radio4", label: "その他の電話番号", value: 2 },
      ],
      selectedInputItems: [],
      selectedSelectItems: null,
      selectedRadioItems: null,
      selectValue: "",
      selectedComment: null,
    };
  },
  watch: {
    "$store.state.furniture.tel_list": function (newVal) {
      if (!this.selectValue) {
        const furniture = this.$store.state.furniture;
        this.selectValue = furniture.phone_number || newVal[0];
        this.$store.dispatch("furniture/inputPhonenumber", this.selectValue);
      }
    },
  },
  methods: {
    inputAddress(address) {
      const address_list = this.$store.state.furniture.address_list || [];
      const findAddress = address_list.filter(
        (i) => i.zipcode.replace("-", "") == address
      );
      this.$store.dispatch("furniture/resetAddressBook");
      this.$store.dispatch(
        "furniture/inputAddress",
        findAddress.length > 0 ? findAddress[0]["address_02"] : ""
      );
      this.$store.dispatch(
        "furniture/inputCity",
        findAddress.length > 0 ? findAddress[0]["address_01"] : ""
      );

      let state = this.$store.state;

      if (address !== "上記以外の住所") {
        this.changeMessage(0);
        this.$store.dispatch("furniture/inputZipcode", address);
        this.getAvailableDate(state.furniture.contract_id, address);
      } else {
        this.changeMessage(4);
      }
      this.$store.dispatch("furniture/inputDatetime", "");
    },
    async getAvailableDate(contract_id, zipcode) {
      let dates = [];
      let datetimes = {};
      if (util.valid_zipcode(zipcode)) {
        const result = await apis.callAPI(
          "get",
          apis.endpoints.getAddress + "?zip_code=" + zipcode,
          this.$store.state.jwt
        );
        if (result && result.data.status === "success") {
          const res = await apis.getAvailableDate(
            "furniture",
            contract_id,
            zipcode.replace("-", "")
          );
          if (res) {
            if (res === "no_available_date") {
              console.log("当システムでご予約可能な日付がございません");
              store.dispatch("furniture/saveNoAvailableDate", true);
            } else {
              store.dispatch("furniture/saveNoAvailableDate", false);
              const available_datetime = res.available_datetime;

              for (var i = 0; i < available_datetime.length; i++) {
                if (new Date(available_datetime[i]["date"]).toString() !== 'Invalid Date') {
                  dates.push({
                    value: available_datetime[i]["date"],
                    label: util.getJapaneseDateMonth(
                      available_datetime[i]["date"]
                    ),
                  });
                  datetimes[available_datetime[i]["date"]] =
                    available_datetime[i]["times"];
                }
              }
            }
          }
        }
      }
      this.datetimes = datetimes;
      this.selectItems = dates;
    },
    getBranch() {
      const branch = this.$store.state.furniture.branch.name
        ? this.$store.state.furniture.branch
        : this.$store.state.furniture.address_branch;
      return branch;
    },
    getTelOptions() {
      const tel_list = this.$store.state.furniture.tel_list || [];

      let options = [];
      for (var i = 0; i < tel_list.length; i++) {
        options.push({
          label: tel_list[i],
          value: tel_list[i],
        });
      }
      return [
        {
          id: "radio3",
          label: "登録済み電話番号から選ぶ<br/>",
          value: "1",
          selectItems: options,
        },
        { id: "radio4", label: "その他の電話番号", value: "2" },
      ];
    },
  },
  mounted: function () {
    const defaultValue = {
      phone_number: "070−1234−5678",
      phone_type: 1,
      datetime: "",
    };
    const store = useStore();
    this.getAvailableDate(
      store.state.furniture.contract_id,
      store.state.furniture.zipcode
    );
    const tel_list = store.state.furniture.tel_list || [];
    const defaultTel = tel_list && tel_list.length > 0 ? tel_list[0] : ""
    this.selectedInputItems = store.state.furniture.moving_furniture;
    this.selectedSelectItems =
      store.state.furniture.datetime || defaultValue["datetime"];
    this.selectedRadioItems =
      store.state.furniture.phone_type || defaultValue["phone_type"];
    this.selectValue =
      store.state.furniture.phone_number || defaultTel;
    this.selectedComment = store.state.furniture.comment;
    store.dispatch("furniture/inputDatetime", this.selectedSelectItems);
    store.dispatch("furniture/inputPhonenumber", this.selectValue);
    store.dispatch("furniture/inputPhonetype", this.selectedRadioItems);
  },
};
</script>
