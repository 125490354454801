
import apis from "@/js/apis";
import useRouteTo from "@/js/useRouteTo";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

import ListingDesktop from "./partials/ListingDesktop.vue";
import ListingMobile from "./partials/ListingMobile.vue";

export default defineComponent({
  components: { ListingDesktop, ListingMobile },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const recommendedGoods = ref([]);
    const allGoods = ref([]);

    onMounted(async () => {
      // get recommended goods
      const result = await apis.callAPI(
        "get",
        apis.endpoints.getRecommendedProducts,
        store.state.jwt
      );

      // sample
      // const result = {
      //   data: {
      //     status: "success",
      //     results: [
      //       {
      //         product_id: 1,
      //         rank: 1,
      //         product: {
      //           id: 1,
      //           name: "name",
      //           subtitle: "subtitle",
      //           header_desc: "header_desc",
      //           html_desc:
      //             "<style>ul.goods-intro{margin-top:4rem;counter-reset:step}ul.goods-intro li{list-style:none;margin:1.6rem 0 4rem;height:64px}ul.goods-intro li h2{font-size:3.2rem}ul.goods-intro li p{color:#6f7070;font-size:1.4rem;font-weight:bold}ul.goods-intro li:before{content:'0' counter(step);counter-increment:step;width:64px;height:64px;line-height:5.5rem;border:1px solid #ddd;border-radius:100%;display:block;text-align:center;font-size:4rem;font-weight:bold;border-color:#0060b9;float:left;margin-right:1rem;margin-top:.5rem}ul.goods-intro li:after{content:'';position:absolute;top:12px;left:-5%;z-index:-1;width:0;height:0;border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:10px solid red;opacity:.4}@media screen and (min-width:320px){ul.goods-intro li{list-style:none;margin:1rem 0}ul.goods-intro li p{color:#6f7070;font-size:1.4rem;font-weight:bold}ul.goods-intro li:before{content:'0' counter(step);counter-increment:step;width:44px;height:44px;line-height:40px;border:1px solid #ddd;border-radius:100%;display:block;text-align:center;font-size:2.2rem;font-weight:bold;border-color:#0060b9;float:left;margin-right:1rem;margin-top:.5rem}ul.goods-intro li:after{content:'';position:absolute;top:12px;left:-5%;z-index:-1;width:0;height:0;border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:10px solid red;opacity:.4}}</style><h2>特別価格でご提供！<br />震度７クラスの地震にも安心。</h2><ul class='goods-intro'><li><h2>安心</h2><p>震度７クラスの耐震試験クリア！</p></li><li><h2>スッキリ</h2><p>底面に貼るだけ。これなら見た目もスッキリ。</p></li><li><h2>繰り返し使用可</h2><p>水洗いで繰り返し使用が可能。</p></li></ul>",
      //           html_table:
      //             "<style>table.goods-info{margin:4.3rem 0;width:100%;color:#707070;font-weight:bold;border-collapse:collapse}table.goods-info tr td{padding:.5rem 1rem;background-color:#eaeaea;font-size:1.2rem}table.goods-info tr>td:first-child{width:90px;background-color:#cadbed;font-size:1.4rem}table.goods-info tr:first-child{border-bottom:2px solid #fff}</style><table class='goods-info'><tr><td>材質</td><td>ウレタンエラストマー</td></tr><tr><td>耐震荷重</td><td>100kg（４枚）</td></tr></table>",
      //           status: "published",
      //           price: 1000,
      //           sku: "sku",
      //           memo: "memo",
      //           tax_id: 1,
      //           tax_rate: 0.1,
      //           parent_id: 1,
      //           published_at: "2022-02-21T00:00:00",
      //           product_type: "standalone, variant, parent",
      //           display_from: "2022-02-21T00:00:00",
      //           display_to: "2022-02-21T00:00:00",
      //           inventory_status: "in_stock, waiting, sold_out",
      //           images: [
      //             {
      //               order: 1,
      //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //             },
      //           ],
      //           attributes: [
      //             {
      //               name: "name",
      //               type: "label",
      //               options: [
      //                 {
      //                   name: "name",
      //                 },
      //               ],
      //             },
      //           ],
      //           variants: [
      //             {
      //               id: 1,
      //               name: "name",
      //               subtitle: "subtitle",
      //               header_desc: "content",
      //               html_desc: "content",
      //               html_table: "content",
      //               status: "draft, published, deleted",
      //               price: 1000,
      //               sku: "sku",
      //               memo: "memo",
      //               tax_id: 1,
      //               tax_rate: 0.1,
      //               parent_id: 1,
      //               published_at: "2022-02-21T00:00:00",
      //               product_type: "standalone, variant, parent",
      //               display_from: "2022-02-21T00:00:00",
      //               display_to: "2022-02-21T00:00:00",
      //               inventory_status: "in_stock, waiting, sold_out",
      //               images: [
      //                 {
      //                   order: 1,
      //                   url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //                 },
      //               ],
      //               attributes: [
      //                 {
      //                   name: "name",
      //                   type: "label",
      //                   options: [
      //                     {
      //                       name: "name",
      //                     },
      //                   ],
      //                 },
      //               ],
      //             },
      //           ],
      //         },
      //       },
      //       {
      //         product_id: 2,
      //         rank: 1,
      //         product: {
      //           id: 2,
      //           name: "name2",
      //           subtitle: "subtitle2",
      //           header_desc: "header_desc2",
      //           html_desc:
      //             "<style>ul.goods-intro{margin-top:4rem;counter-reset:step}ul.goods-intro li{list-style:none;margin:1.6rem 0 4rem;height:64px}ul.goods-intro li h2{font-size:3.2rem}ul.goods-intro li p{color:#6f7070;font-size:1.4rem;font-weight:bold}ul.goods-intro li:before{content:'0' counter(step);counter-increment:step;width:64px;height:64px;line-height:5.5rem;border:1px solid #ddd;border-radius:100%;display:block;text-align:center;font-size:4rem;font-weight:bold;border-color:#0060b9;float:left;margin-right:1rem;margin-top:.5rem}ul.goods-intro li:after{content:'';position:absolute;top:12px;left:-5%;z-index:-1;width:0;height:0;border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:10px solid red;opacity:.4}@media screen and (min-width:320px){ul.goods-intro li{list-style:none;margin:1rem 0}ul.goods-intro li p{color:#6f7070;font-size:1.4rem;font-weight:bold}ul.goods-intro li:before{content:'0' counter(step);counter-increment:step;width:44px;height:44px;line-height:40px;border:1px solid #ddd;border-radius:100%;display:block;text-align:center;font-size:2.2rem;font-weight:bold;border-color:#0060b9;float:left;margin-right:1rem;margin-top:.5rem}ul.goods-intro li:after{content:'';position:absolute;top:12px;left:-5%;z-index:-1;width:0;height:0;border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:10px solid red;opacity:.4}}</style><h2>特別価格でご提供！<br />震度７クラスの地震にも安心。</h2><ul class='goods-intro'><li><h2>安心</h2><p>震度７クラスの耐震試験クリア！</p></li><li><h2>スッキリ</h2><p>底面に貼るだけ。これなら見た目もスッキリ。</p></li><li><h2>繰り返し使用可</h2><p>水洗いで繰り返し使用が可能。</p></li></ul>",
      //           html_table:
      //             "<style>table.goods-info{margin:4.3rem 0;width:100%;color:#707070;font-weight:bold;border-collapse:collapse}table.goods-info tr td{padding:.5rem 1rem;background-color:#eaeaea;font-size:1.2rem}table.goods-info tr>td:first-child{width:90px;background-color:#cadbed;font-size:1.4rem}table.goods-info tr:first-child{border-bottom:2px solid #fff}</style><table class='goods-info'><tr><td>材質</td><td>ウレタンエラストマー</td></tr><tr><td>耐震荷重</td><td>100kg（４枚）</td></tr></table>",
      //           // status: "deleted",
      //           status: "published",
      //           price: 2000,
      //           sku: "sku",
      //           memo: "memo",
      //           tax_id: 1,
      //           tax_rate: 0.1,
      //           parent_id: 1,
      //           published_at: "2022-02-21T00:00:00",
      //           product_type: "standalone, variant, parent",
      //           display_from: "2022-02-21T00:00:00",
      //           display_to: "2022-02-21T00:00:00",
      //           inventory_status: "in_stock, waiting, sold_out",
      //           images: [
      //             {
      //               order: 1,
      //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //             },
      //           ],
      //           attributes: [
      //             {
      //               name: "name",
      //               type: "label",
      //               options: [
      //                 {
      //                   name: "name",
      //                 },
      //               ],
      //             },
      //           ],
      //           variants: [
      //             {
      //               id: 1,
      //               name: "name",
      //               subtitle: "subtitle",
      //               header_desc: "content",
      //               html_desc: "content",
      //               html_table: "content",
      //               status: "draft, published, deleted",
      //               price: 1000,
      //               sku: "sku",
      //               memo: "memo",
      //               tax_id: 1,
      //               tax_rate: 0.1,
      //               parent_id: 1,
      //               published_at: "2022-02-21T00:00:00",
      //               product_type: "standalone, variant, parent",
      //               display_from: "2022-02-21T00:00:00",
      //               display_to: "2022-02-21T00:00:00",
      //               inventory_status: "in_stock, waiting, sold_out",
      //               images: [
      //                 {
      //                   order: 1,
      //                   url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //                 },
      //               ],
      //               attributes: [
      //                 {
      //                   name: "name",
      //                   type: "label",
      //                   options: [
      //                     {
      //                       name: "name",
      //                     },
      //                   ],
      //                 },
      //               ],
      //             },
      //           ],
      //         },
      //       },
      //     ],
      //   } as any,
      // };

      if (result && result.data.status === "success") {
        recommendedGoods.value = (result.data.results || []).filter(
          (el: any) => el.product.status.indexOf("published") >= 0
        );
        // save it in store
        store.dispatch("goods/setRecommendedGoods", result.data.results);
      }

      // get all goods
      const result2 = await apis.callAPI(
        "get",
        apis.endpoints.getAllGoods,
        store.state.jwt
      );

      // sample
      // const result2 = {
      //   data: {
      //     status: "success",
      //     results: [
      //       {
      //         name: "name",
      //         products: [
      //           {
      //             id: 1,
      //             name: "name",
      //             subtitle: "subtitle",
      //             header_desc: "content",
      //             html_desc: "content",
      //             html_table: "content",
      //             status: "draft",
      //             // status: "published",
      //             price: 1000,
      //             sku: "sku",
      //             memo: "memo",
      //             tax_id: 1,
      //             tax_rate: 0.1,
      //             parent_id: 1,
      //             published_at: "2022-02-21T00:00:00",
      //             product_type: "standalone, variant, parent",
      //             display_from: "2022-02-21T00:00:00",
      //             display_to: "2022-02-21T00:00:00",
      //             inventory_status: "in_stock, waiting, sold_out",
      //             images: [
      //               {
      //                 order: 1,
      //                 url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //               },
      //             ],
      //             attributes: [
      //               {
      //                 name: "name",
      //                 type: "label",
      //                 options: [
      //                   {
      //                     name: "name",
      //                   },
      //                 ],
      //               },
      //             ],
      //             variants: [
      //               {
      //                 id: 1,
      //                 name: "name",
      //                 subtitle: "subtitle",
      //                 header_desc: "content",
      //                 html_desc: "content",
      //                 html_table: "content",
      //                 status: "draft, published, deleted",
      //                 price: 1000,
      //                 sku: "sku",
      //                 memo: "memo",
      //                 tax_id: 1,
      //                 tax_rate: 0.1,
      //                 parent_id: 1,
      //                 published_at: "2022-02-21T00:00:00",
      //                 product_type: "standalone, variant, parent",
      //                 display_from: "2022-02-21T00:00:00",
      //                 display_to: "2022-02-21T00:00:00",
      //                 inventory_status: "in_stock, waiting, sold_out",
      //                 images: [
      //                   {
      //                     order: 1,
      //                     url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //                   },
      //                 ],
      //                 attributes: [
      //                   {
      //                     name: "name",
      //                     type: "label",
      //                     options: [
      //                       {
      //                         name: "name",
      //                       },
      //                     ],
      //                   },
      //                 ],
      //               },
      //             ],
      //           },
      //           {
      //             id: 2,
      //             name: "name2",
      //             subtitle: "subtitle2",
      //             header_desc: "content2",
      //             html_desc: "content",
      //             html_table: "content",
      //             // status: "draft",
      //             status: "published",
      //             price: 1000,
      //             sku: "sku",
      //             memo: "memo",
      //             tax_id: 1,
      //             tax_rate: 0.1,
      //             parent_id: 1,
      //             published_at: "2022-02-21T00:00:00",
      //             product_type: "standalone, variant, parent",
      //             display_from: "2022-02-21T00:00:00",
      //             display_to: "2022-02-21T00:00:00",
      //             inventory_status: "in_stock, waiting, sold_out",
      //             images: [
      //               {
      //                 order: 1,
      //                 url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //               },
      //             ],
      //             attributes: [
      //               {
      //                 name: "name",
      //                 type: "label",
      //                 options: [
      //                   {
      //                     name: "name",
      //                   },
      //                 ],
      //               },
      //             ],
      //             variants: [
      //               {
      //                 id: 1,
      //                 name: "name",
      //                 subtitle: "subtitle",
      //                 header_desc: "content",
      //                 html_desc: "content",
      //                 html_table: "content",
      //                 status: "draft, published, deleted",
      //                 price: 1000,
      //                 sku: "sku",
      //                 memo: "memo",
      //                 tax_id: 1,
      //                 tax_rate: 0.1,
      //                 parent_id: 1,
      //                 published_at: "2022-02-21T00:00:00",
      //                 product_type: "standalone, variant, parent",
      //                 display_from: "2022-02-21T00:00:00",
      //                 display_to: "2022-02-21T00:00:00",
      //                 inventory_status: "in_stock, waiting, sold_out",
      //                 images: [
      //                   {
      //                     order: 1,
      //                     url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //                   },
      //                 ],
      //                 attributes: [
      //                   {
      //                     name: "name",
      //                     type: "label",
      //                     options: [
      //                       {
      //                         name: "name",
      //                       },
      //                     ],
      //                   },
      //                 ],
      //               },
      //             ],
      //           },
      //         ],
      //       },
      //     ],
      //   } as any,
      // };

      if (result2 && result2.data.status === "success") {
        let goodItems: any = [];
        for (var i = 0; i < result2.data.results.length; i++) {
          goodItems.push(result2.data.results[i]);
          goodItems[i].products = (
            result2.data.results[i].products || []
          ).filter((el: any) => el.status.indexOf("published") >= 0);
        }
        allGoods.value = goodItems;
      }
    });

    const click = () => {
      routeTo("GoodReserved");
    };

    return {
      recommendedGoods,
      allGoods,
      click,
    };
  },
});
