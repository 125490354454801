import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e101e44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      blue:
        _ctx.$store.state.device === 'pc' &&
        _ctx.$store.state.delivery.deliveries[_ctx.datetime] &&
        _ctx.$store.state.delivery.deliveries[_ctx.datetime].length == 0,
    })
  }, [
    _createTextVNode(_toDisplayString(_ctx.datetime), 1),
    (
        _ctx.$store.state.device === 'pc' &&
        _ctx.$store.state.delivery.deliveries[_ctx.datetime] &&
        _ctx.$store.state.delivery.deliveries[_ctx.datetime].length > 0
      )
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "の配達予定"))
      : _createCommentVNode("", true)
  ], 2))
}