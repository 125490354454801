
import { defineComponent } from "vue";
import BaseButton from "@/components/delivery/BaseButton.vue";
import useRouteTo from "../../../js/useRouteTo";

export default defineComponent({
  components: { BaseButton },
  setup() {
    const { routeTo } = useRouteTo();
    const goNext = () => {
      routeTo("MyPage");
    };
    return {
      goNext,
    };
  },
});
