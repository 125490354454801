
import CollectionDetailDesktop from "./partials/CollectionDetailDesktop.vue";
import CollectionDetailMobile from "./partials/CollectionDetailMobile.vue";

import { defineComponent, onMounted, ref } from "vue";
import useRouteTo from "@/js/useRouteTo";
import { useStore } from "vuex";
import useCollectionInit from "@/js/useCollectionInit";
import { useRoute } from "vue-router";
import util from "@/js/util";

export default defineComponent({
  components: { CollectionDetailDesktop, CollectionDetailMobile },
  setup() {
    const { routeTo } = useRouteTo();
    const route = useRoute();
    const store = useStore();
    interface Collection {
      kaishu_no: number;
      zipcode: string;
      address_01: string;
      address_02: string;
      address_code: string;
      kaishu_date: string;
      kaishu_time: string;
      tel: string;
      collect_item: string[];
      cardbord_num: number;
      is_autolock: number;
      request: string[];
      payment_method: number;
      is_free: number;
      is_canceled: number;
    }
    const collection_list = ref(Array<Collection>());
    const new_collection_list = ref({} as any);
    const over3months = ref(false);

    onMounted(async () => {
      store.dispatch("collection/resetCreate");
      store.dispatch("collection/resetEdit");
      store.dispatch("collection/saveAddressList", []);
      collection_list.value = (await useCollectionInit()) as any;

      // if no collection list, then go to welcome page
      if (collection_list.value && collection_list.value.length > 0) {
        // change invalid kaishu_date to empty string
        collection_list.value.forEach((el: any) => {
          if (new Date(el.kaishu_date).toString() == "Invalid Date") {
            el.kaishu_date = "";
            el.kaishu_time = "";
          }
        });

        // grouping by datetime
        for (let item of collection_list.value) {
          let date: string;
          let datetime: string;
          if (new Date(item.kaishu_date).toString() === "Invalid Date") {
            datetime = "お伺い日未定";
          } else {
            date =
              store.state.device === "mobile"
                ? util.getJapaneseDateMonth(item.kaishu_date)
                : util.getJapaneseDate(item.kaishu_date);
            datetime = date + " " + item.kaishu_time;
          }
          if (Object.keys(new_collection_list.value).includes(datetime)) {
            new_collection_list.value[datetime].push(item);
          } else {
            new_collection_list.value[datetime] = [item];
          }
        }

        // check if today is newer than 93 days after the latest moving_date
        const latestDate =
          (localStorage.getItem("latest_date") as string) || "";
        if (latestDate) {
          const target = new Date(latestDate + "T00:00");
          target.setDate(target.getDate() + 93);
          if (
            new Date(
              new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
            ) >= target
          ) {
            over3months.value = true;
          }
        }
      } else {
        routeTo("CollectionService");
      }
    });
    const clickEdit = (item: any) => {
      // save item in store for editting or deleting
      store.dispatch("collection/resetCreate");
      store.dispatch("collection/saveAddressList", []);
      store.dispatch("collection/saveItemForEdit", item);
      routeTo("EditCollection");
    };
    const clickNewApply = () => {
      store.dispatch("collection/resetCreate");
      store.dispatch("collection/resetEdit");
      store.dispatch("collection/saveAddressList", []);
      routeTo("BookingCollection");
    };
    return {
      new_collection_list,
      clickEdit,
      clickNewApply,
      over3months,
    };
  },
});
