
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

import Flickity from "./Flickity.vue";
import ProductGrid from "./ProductGrid.vue";

export default defineComponent({
  components: {
    Flickity,
    ProductGrid,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    allGoods: {
      type: Array,
      required: true,
    },
    recommendedGoods: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      flickityOptions: {
        pageDots: true,
        wrapAround: true,
        prevNextButtons: false,
        autoPlay: true
      }
    };
  },
  methods: {
    getImgUrl: function (images: any) {
      if (Array.isArray(images) && images.length > 0) {
        return images[0]['url']
      }
    },
  },
  setup() {
    const store = useStore();
    const className = computed(() => {
      return store.state.device == "mobile" ? "" : "container";
    });
    return {
      className,
    };
  },
});
