
import { defineComponent } from "vue";
import { useStore } from "vuex";

import BaseButton from "./../../BaseButton.vue";
import PriceLabel from "./../../furniture/PriceLabel.vue";
import ExclamationIcon from "@/components/furniture/ExclamationIcon.vue";
import util from "@/js/util";

export default defineComponent({
  name: "ConfrmationFurniture",
  components: {
    PriceLabel,
    BaseButton,
    ExclamationIcon,
  },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    button2Title: {
      type: String,
      default: "",
    },
    dataResult: {
      type: Object,
      required: true,
    },
    msg: {
      type: String,
      default: "",
    },
    boxClass: {
      type: String,
      default: "",
    },
  },
  emits: ["clickEdit", "clickNewApply"],
  data: function () {
    return {
      className: "",
      contentClass: "box bg-white",
      price: 3000,
    };
  },
  watch: {
    "dataResult.is_free": function (value) {
      if (value == 1) {
        this.className = "";
      } else {
        this.className = "red";
      }
    },
  },
  methods: {
    getPaymentMethod(value: any) {
      const methods: { [key: string]: any } = {
        "1": "現金",
        "2": "カード",
        "5": "Pay支払い",
      };
      return methods[value] ? methods[value] : "";
    },
    filterCurrency(value: string) {
      return util.getPrice(value, false) + "円（税込）";
    },
    getMaterial(result: any) {
      const collect_item = result.collect_item;
      const materialItems = [
        {
          label: "ダンボール",
          value: "2C01",
        },
        { label: "布団袋", value: "2C02" },
        { label: "クレープ用紙", value: "2C03" },
        { label: "ハンガーボックス", value: "2C04" },
        { label: "その他アート梱包資材", value: "2C05" },
      ];

      const cardbords = [
        "",
        "10枚以下",
        "10～30枚",
        "30～50枚",
        "50～100枚",
        "100枚以上",
      ];
      let str = "";
      if (collect_item.length > 0) {
        if (result.cardbord_num != "0")
          str = cardbords[result.cardbord_num] + ",";
        for (let i = 0; i < collect_item.length; i++) {
          const findItem = materialItems.filter(
            (j) => j.value == collect_item[i]
          );
          if (findItem.length > 0) str += findItem[0]["label"] + ", ";
        }
        return str;
      }
      return "";
    },
    getRequestLabel(result: any) {
      let request_list = [
        
        { id: "request1", label: "到着前に連絡を希望します", value: "2A02" },
        {
          id: "request2",
          label: "到着時、管理人（コンシェルジュ）の方への声掛けを希望します",
          value: "2A03",
        },
        {
          id: "request3",
          label:
            "不在でも引き取りを希望します【※玄関先に資材を出しておく必要があります】",
          value: "2A01",
        },
        { id: "request0", label: "要望なし", value: "0" },
      ];
      request_list = request_list.filter(function (el: any) {
        return result.request.indexOf(el.value) >= 0;
      });
      let items = (request_list || []).map(function (el: any) {
        return el["label"];
      });
      if (items.length > 0) {
        return items.join(", ");
      }
      return "";
    },
  },
  created() {
    if (this.dataResult.is_free !== 1) {
      this.className = "red";
    }
    if (
      new Date(this.dataResult.kaishu_date + "T00:00") <=
      new Date(new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" }))
    ) {
      this.contentClass += " opacity";
    }
  },
});
