import { useRoute } from "vue-router";
import { useStore } from "vuex";
import apis from "./apis";
import saveLocalStorage from "./saveLocalStorage";
import useRouteTo from "./useRouteTo";

export default async function () {
  const { routeTo } = useRouteTo();
  const route = useRoute();
  const store = useStore();

  let contract_id: string;
  if (route.query.contract_id) {
    contract_id = route.query.contract_id as string;
    saveLocalStorage(contract_id);
  } else {
    contract_id = (localStorage.getItem("selectedNumber") as string) || "";
  }
  store.dispatch("furniture/saveContractId", contract_id);
  if (!contract_id) {
    routeTo("MyPageMenu");
    return {};
  } else {
    // call API to get furniture info
    const result = await apis.callAPI(
      "get",
      apis.endpoints.getFurniture.replace("{}", contract_id),
      store.state.jwt
    );
    // const result = {
    //   data: {
    //     status: "success",
    //     result: {
    //       is_exist: true,
    //       furniture_moving_date: "2022-12-01",
    //       furniture_moving_time: "10:00-12:00",
    //       contact_tel: "090-1234-1234",
    //       zip_code: "123-1234",
    //       address: "東京都千代田区１－１－１",
    //       address_code: "12345",
    //       moving_furniture: ["3C01", "3C02", "3C99"],
    //       other_furniture: "冷蔵庫",
    //       is_accepting: false,
    //       is_canceled: false,
    //       branch_info: {
    //         name: "大阪",
    //         tel: "12312341234",
    //         is_fc: false,
    //       },
    //     },
    //   },
    // };
    if (result && result.data.status === "success") {
      store.dispatch("furniture/saveIsExist", result.data.result.is_exist);
      return result.data.result;
    }
    return {};
  }
}
