
import { defineComponent } from "vue";
import util from "@/js/util";

export default defineComponent({
  props: {
    products: {
      type: Array,
      required: true,
    }
  },
  methods: {
    getImgUrl: function (images: any) {
      if (Array.isArray(images) && images.length > 0) {
        return images[0]['url']
      }
    },
    filterCurrency(value: string, tax_rate: number) {
      const price = util.calculateTax(value, tax_rate)
      return util.getPrice(price.toString(), true);
    },
  },
});
