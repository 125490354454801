
import { defineComponent } from "vue";
import SymbolMark from "../mypage/SymbolMark.vue";
import BaseButton from "./BaseButton.vue";

export default defineComponent({
  components: { SymbolMark, BaseButton },
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonColor: {
      type: String,
      default: "#0060b9",
    },
    buttonClass: {
      type: String,
      default: "",
    },
    buttonText2: {
      type: String,
      required: false,
    },
    buttonColor2: {
      type: String,
      default: "#0060b9",
    },
    buttonClass2: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    linkText: {
      type: String,
      default: "",
    },
  },
  emits: ["click", "clickLink", "click2"],
  // setup() {

  // },
});
