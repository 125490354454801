
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import apis from "@/js/apis";
import util from "@/js/util";

import useRouteTo from "../../../js/useRouteTo";
import Select from "./../../furniture/Select.vue";
import RadioButton from "./../../furniture/RadioButton.vue";
import BaseButton from "./../../account/BaseButton.vue";
import ExclamationIcon from "./../../furniture/ExclamationIcon.vue";
import BookingView from "@/components/collection/BookingView.vue";
import Modal from "@/components/Modal.vue";
import CollectionMixin from "@/mixins/collection.vue";
import CollectionAddressMixin from "@/mixins/collectionAddress.vue";

export default defineComponent({
  name: "Booking",
  components: {
    Select,
    RadioButton,
    BaseButton,
    ExclamationIcon,
    BookingView,
    Modal,
  },
  mixins: [CollectionMixin, CollectionAddressMixin],
  props: {
    buttonTitle: {
      type: String,
      default: "",
    },
    msg: {
      type: String,
      default: "",
    },
    address_list: {
      type: Object,
    },
  },
  mounted: function () {
    const store = useStore();
    this.setInitialData(store.state.collection);
  },
  watch: {
    "$store.state.collection.zipcode": function (value) {
      let state: any = this.$store.state;
      if (state.collection && state.collection.contract_id) {
        const contract_id = state.collection.contract_id;
        this.getAvailableDate(contract_id, value);
      }
    },
  },
  setup(props, context) {
    const store = useStore();
    const { routeTo } = useRouteTo();
    const route = useRoute();

    const errMsg = ref("");
    const errMsg1 = ref("");
    const errMsg2 = ref("");
    const errMsg3 = ref("");
    const errMsg4 = ref("");
    const errMsg5 = ref("");
    const errMsg6 = ref("");

    let selectedTime: any = store.state.collection.kaishu_time;
    let selectedDate: any = store.state.collection.kaishu_date;
    let phoneType: any = store.state.collection.phone_type;
    let phoneNumber: any = store.state.collection.tel;
    let selectedLock: any = store.state.collection.is_autolock;
    let materialType: any = store.state.collection.collect_item;
    let material: any = store.state.collection.cardbord_num;
    let payment: any = store.state.collection.payment_method;
    let responseAddress: any = true;

    const hideModal = () => {
      routeTo("CollectionSchedule");
    };

    const validationAddressCode = (response: any) => {
      store.dispatch("collection/saveValidationAddress", response);
      if (response == 0) {
        responseAddress = true;
      } else {
        responseAddress = false;
      }
    };

    const selectTime = (time: string) => {
      store.dispatch("collection/inputTime", time);
      selectedTime = time != "0" ? time : "";
    };
    function selectDate(datetime: string) {
      store.dispatch("collection/inputDatetime", datetime);
      selectedDate = datetime != "0" ? datetime : "";
      selectedTime = "";
    }
    const inputPhone = (item: string) => {
      store.dispatch("collection/inputPhonetype", item);
      phoneType = item;
    };
    const selectPhone = (phone: string) => {
      store.dispatch("collection/inputPhonenumber", phone.trim());
      phoneNumber = phone;
    };
    const inputMaterial = (item: string) => {
      store.dispatch("collection/inputMaterialType", item);
      materialType = item;
    };
    const selectMaterial = (item: string) => {
      store.dispatch("collection/inputMaterial", item);
      material = item;
    };
    const inputLock = (item: number) => {
      store.dispatch("collection/inputLock", item);
      selectedLock = item;
    };
    const selectRequest = (request: string) => {
      store.dispatch("collection/inputRequest", [request]);
    };
    const inputPayment = (item: string) => {
      store.dispatch("collection/inputPayment", item);
      payment = item;
    };

    const submit = () => {
      if (
        store.state.collection.address_01 &&
        ((store.state.collection.address_specific_area &&
          store.state.collection.address_rest) ||
          (!store.state.collection.address_specific_area &&
            store.state.collection.address_02))
      ) {
        errMsg.value = "";
        store.dispatch("collection/setAddressDone", true);
      } else {
        errMsg.value = "入力してください";
      }

      if (store.state.collection.kaishu_time) {
        errMsg1.value = "";
      } else {
        errMsg1.value = "選択してください";
      }

      if (store.state.collection.kaishu_date) {
        errMsg2.value = "";
      } else {
        errMsg2.value = "選択してください";
      }

      if (
        store.state.collection.kaishu_time &&
        store.state.collection.kaishu_date
      ) {
        store.dispatch("collection/setDatetimeDone", true);
      }

      errMsg3.value = "";
      if (store.state.collection.tel) {
        if (util.validateTel(store.state.collection.tel)) {
          errMsg3.value = "";
        } else {
          errMsg3.value = "正しい形式で入力してください";
        }
      } else {
        errMsg3.value = "連絡先電話番号を入力してください";
      }

      if (selectedLock) {
        errMsg4.value = "";
      } else {
        errMsg4.value = "選択してください";
      }
      if (
        (payment && store.state.collection.free_or_paid == "paid") ||
        store.state.collection.free_or_paid == "free"
      ) {
        errMsg5.value = "";
      } else {
        errMsg5.value = "選択してください";
      }

      errMsg6.value = "";
      if (store.state.collection.collect_item.indexOf("2C01") >= 0) {
        if (
          store.state.collection.cardbord_num &&
          store.state.collection.cardbord_num != 0
        ) {
          errMsg6.value = "";
        } else {
          errMsg6.value = "ダンボールの枚数を選択してください";
        }
      } else if (store.state.collection.collect_item.length == 0) {
        errMsg6.value = "選択してください";
      }

      if (
        !errMsg.value &&
        !errMsg1.value &&
        !errMsg2.value &&
        !errMsg3.value &&
        !errMsg4.value &&
        !errMsg5.value &&
        !errMsg6.value &&
        responseAddress
      ) {
        context.emit("click");
      } else {
        window.scrollTo(0, 0);
      }
    };

    const buttonWidth = computed(() => {
      return store.state.device == "mobile" ? "100%" : "34rem";
    });
    return {
      selectTime,
      selectDate,
      selectPhone,
      selectRequest,
      inputPhone,
      inputLock,
      inputMaterial,
      selectMaterial,
      inputPayment,
      submit,
      errMsg,
      errMsg1,
      errMsg2,
      errMsg3,
      errMsg4,
      errMsg5,
      errMsg6,
      buttonWidth,
      hideModal,
      validationAddressCode,
    };
  },
  emits: ["click"],
});
