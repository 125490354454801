
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "6.8rem",
    },
    fontSize: {
      type: String,
      default: "1.6rem",
    },
    className: {
      type: String,
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  setup(props) {
    const style = computed(() => {
      return {
        width: props.width,
        height: props.height,
        fontSize: props.fontSize
      };
    });
    const classNames = computed(() => {
      if (props.disabled) {
        return props.className + " disabled";
      } else {
        return props.className + " hover-shining";
      }
    });
    return {
      style,
      classNames,
    };
  },
});
