
import { defineComponent } from "vue";
import BaseButton from "./../../account/BaseButton.vue";

export default defineComponent({
  name: "FurnitureService",
  components: {
    BaseButton,
  },
  emits: ["click"],
});
