
import BaseButton from "./BaseButton.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BaseButton,
  },
  props: {
    buttonText: {
      type: String,
    },
    msg: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    buttonCancel: {
      type: Boolean,
      default: false
    },
    showArrow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  emits: ["click", "close"],
});
