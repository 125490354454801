
import apis from "@/js/apis";
import useRouteTo from "@/js/useRouteTo";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import util from "@/js/util";

import DetailDesktop from "./partials/DetailDesktop.vue";
import DetailMobile from "./partials/DetailMobile.vue";

export default defineComponent({
  components: { DetailDesktop, DetailMobile },
  setup() {
    const { routeTo } = useRouteTo();
    const route = useRoute();
    const store = useStore();
    const goodsDetail = ref({}) as any;
    const recommendedGoods = ref([]);
    const ukt_no = ref("");
    const contract_id = ref("");
    const bookedGoods = ref([]);
    const variant_id = ref("");
    const preview = ref(false);

    onMounted(async () => {
      // check if preview mode or not
      if (route.path.includes("preview")) {
        preview.value = true;
      }

      if (route.query.variant_id) {
        variant_id.value = route.query.variant_id.toString();
      }

      // get ukt_no and contract_id
      ukt_no.value = (localStorage.getItem("ukt_no") as string) || "";
      contract_id.value =
        (localStorage.getItem("selectedNumber") as string) || "";

      // get recommended goods if they're not in store (only for live mode)
      if (!store.state.goods.recommendedGoods.length && !preview.value) {
        // call api
        const recommendedResult = await apis.callAPI(
          "get",
          apis.endpoints.getRecommendedProducts,
          store.state.jwt
        );

        // sample
        // const recommendedResult = {
        //   data: {
        //     status: "success",
        //     results: [
        //       {
        //         product_id: 1,
        //         rank: 1,
        //         product: {
        //           id: 1,
        //           name: "name",
        //           subtitle: "subtitle",
        //           header_desc: "header_desc",
        //           html_desc:
        //             "<style>ul.goods-intro{margin-top:4rem;counter-reset:step}ul.goods-intro li{list-style:none;margin:1.6rem 0 4rem;height:64px}ul.goods-intro li h2{font-size:3.2rem}ul.goods-intro li p{color:#6f7070;font-size:1.4rem;font-weight:bold}ul.goods-intro li:before{content:'0' counter(step);counter-increment:step;width:64px;height:64px;line-height:5.5rem;border:1px solid #ddd;border-radius:100%;display:block;text-align:center;font-size:4rem;font-weight:bold;border-color:#0060b9;float:left;margin-right:1rem;margin-top:.5rem}ul.goods-intro li:after{content:'';position:absolute;top:12px;left:-5%;z-index:-1;width:0;height:0;border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:10px solid red;opacity:.4}@media screen and (min-width:320px){ul.goods-intro li{list-style:none;margin:1rem 0}ul.goods-intro li p{color:#6f7070;font-size:1.4rem;font-weight:bold}ul.goods-intro li:before{content:'0' counter(step);counter-increment:step;width:44px;height:44px;line-height:40px;border:1px solid #ddd;border-radius:100%;display:block;text-align:center;font-size:2.2rem;font-weight:bold;border-color:#0060b9;float:left;margin-right:1rem;margin-top:.5rem}ul.goods-intro li:after{content:'';position:absolute;top:12px;left:-5%;z-index:-1;width:0;height:0;border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:10px solid red;opacity:.4}}</style><h2>特別価格でご提供！<br />震度７クラスの地震にも安心。</h2><ul class='goods-intro'><li><h2>安心</h2><p>震度７クラスの耐震試験クリア！</p></li><li><h2>スッキリ</h2><p>底面に貼るだけ。これなら見た目もスッキリ。</p></li><li><h2>繰り返し使用可</h2><p>水洗いで繰り返し使用が可能。</p></li></ul>",
        //           html_table:
        //             "<style>table.goods-info{margin:4.3rem 0;width:100%;color:#707070;font-weight:bold;border-collapse:collapse}table.goods-info tr td{padding:.5rem 1rem;background-color:#eaeaea;font-size:1.2rem}table.goods-info tr>td:first-child{width:90px;background-color:#cadbed;font-size:1.4rem}table.goods-info tr:first-child{border-bottom:2px solid #fff}</style><table class='goods-info'><tr><td>材質</td><td>ウレタンエラストマー</td></tr><tr><td>耐震荷重</td><td>100kg（４枚）</td></tr></table>",
        //           status: "published",
        //           price: 1000,
        //           sku: "sku",
        //           memo: "memo",
        //           tax_id: 1,
        //           tax_rate: 0.1,
        //           parent_id: 1,
        //           published_at: "2022-02-21T00:00:00",
        //           product_type: "standalone, variant, parent",
        //           display_from: "2022-02-21T00:00:00",
        //           display_to: "2022-02-21T00:00:00",
        //           inventory_status: "in_stock, waiting, sold_out",
        //           images: [
        //             {
        //               order: 1,
        //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
        //             },
        //           ],
        //           attributes: [
        //             {
        //               name: "name",
        //               type: "label",
        //               options: [
        //                 {
        //                   name: "name",
        //                 },
        //               ],
        //             },
        //           ],
        //           variants: [
        //             {
        //               id: 1,
        //               name: "name",
        //               subtitle: "subtitle",
        //               header_desc: "content",
        //               html_desc: "content",
        //               html_table: "content",
        //               status: "draft, published, deleted",
        //               price: 1000,
        //               sku: "sku",
        //               memo: "memo",
        //               tax_id: 1,
        //               tax_rate: 0.1,
        //               parent_id: 1,
        //               published_at: "2022-02-21T00:00:00",
        //               product_type: "standalone, variant, parent",
        //               display_from: "2022-02-21T00:00:00",
        //               display_to: "2022-02-21T00:00:00",
        //               inventory_status: "in_stock, waiting, sold_out",
        //               images: [
        //                 {
        //                   order: 1,
        //                   url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
        //                 },
        //               ],
        //               attributes: [
        //                 {
        //                   name: "name",
        //                   type: "label",
        //                   options: [
        //                     {
        //                       name: "name",
        //                     },
        //                   ],
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //       },
        //       {
        //         product_id: 2,
        //         rank: 1,
        //         product: {
        //           id: 2,
        //           name: "name2",
        //           subtitle: "subtitle2",
        //           header_desc: "header_desc2",
        //           html_desc:
        //             "<style>ul.goods-intro{margin-top:4rem;counter-reset:step}ul.goods-intro li{list-style:none;margin:1.6rem 0 4rem;height:64px}ul.goods-intro li h2{font-size:3.2rem}ul.goods-intro li p{color:#6f7070;font-size:1.4rem;font-weight:bold}ul.goods-intro li:before{content:'0' counter(step);counter-increment:step;width:64px;height:64px;line-height:5.5rem;border:1px solid #ddd;border-radius:100%;display:block;text-align:center;font-size:4rem;font-weight:bold;border-color:#0060b9;float:left;margin-right:1rem;margin-top:.5rem}ul.goods-intro li:after{content:'';position:absolute;top:12px;left:-5%;z-index:-1;width:0;height:0;border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:10px solid red;opacity:.4}@media screen and (min-width:320px){ul.goods-intro li{list-style:none;margin:1rem 0}ul.goods-intro li p{color:#6f7070;font-size:1.4rem;font-weight:bold}ul.goods-intro li:before{content:'0' counter(step);counter-increment:step;width:44px;height:44px;line-height:40px;border:1px solid #ddd;border-radius:100%;display:block;text-align:center;font-size:2.2rem;font-weight:bold;border-color:#0060b9;float:left;margin-right:1rem;margin-top:.5rem}ul.goods-intro li:after{content:'';position:absolute;top:12px;left:-5%;z-index:-1;width:0;height:0;border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:10px solid red;opacity:.4}}</style><h2>特別価格でご提供！<br />震度７クラスの地震にも安心。</h2><ul class='goods-intro'><li><h2>安心</h2><p>震度７クラスの耐震試験クリア！</p></li><li><h2>スッキリ</h2><p>底面に貼るだけ。これなら見た目もスッキリ。</p></li><li><h2>繰り返し使用可</h2><p>水洗いで繰り返し使用が可能。</p></li></ul>",
        //           html_table:
        //             "<style>table.goods-info{margin:4.3rem 0;width:100%;color:#707070;font-weight:bold;border-collapse:collapse}table.goods-info tr td{padding:.5rem 1rem;background-color:#eaeaea;font-size:1.2rem}table.goods-info tr>td:first-child{width:90px;background-color:#cadbed;font-size:1.4rem}table.goods-info tr:first-child{border-bottom:2px solid #fff}</style><table class='goods-info'><tr><td>材質</td><td>ウレタンエラストマー</td></tr><tr><td>耐震荷重</td><td>100kg（４枚）</td></tr></table>",
        //           status: "published",
        //           price: 2000,
        //           sku: "sku",
        //           memo: "memo",
        //           tax_id: 1,
        //           tax_rate: 0.1,
        //           parent_id: 1,
        //           published_at: "2022-02-21T00:00:00",
        //           product_type: "standalone, variant, parent",
        //           display_from: "2022-02-21T00:00:00",
        //           display_to: "2022-02-21T00:00:00",
        //           inventory_status: "in_stock, waiting, sold_out",
        //           images: [
        //             {
        //               order: 1,
        //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
        //             },
        //           ],
        //           attributes: [
        //             {
        //               name: "name",
        //               type: "label",
        //               options: [
        //                 {
        //                   name: "name",
        //                 },
        //               ],
        //             },
        //           ],
        //           variants: [
        //             {
        //               id: 1,
        //               name: "name",
        //               subtitle: "subtitle",
        //               header_desc: "content",
        //               html_desc: "content",
        //               html_table: "content",
        //               status: "draft, published, deleted",
        //               price: 1000,
        //               sku: "sku",
        //               memo: "memo",
        //               tax_id: 1,
        //               tax_rate: 0.1,
        //               parent_id: 1,
        //               published_at: "2022-02-21T00:00:00",
        //               product_type: "standalone, variant, parent",
        //               display_from: "2022-02-21T00:00:00",
        //               display_to: "2022-02-21T00:00:00",
        //               inventory_status: "in_stock, waiting, sold_out",
        //               images: [
        //                 {
        //                   order: 1,
        //                   url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
        //                 },
        //               ],
        //               attributes: [
        //                 {
        //                   name: "name",
        //                   type: "label",
        //                   options: [
        //                     {
        //                       name: "name",
        //                     },
        //                   ],
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //       },
        //     ],
        //   } as any,
        // };

        if (recommendedResult && recommendedResult.data.status === "success") {
          recommendedGoods.value = recommendedResult.data.results;
        }
      } else {
        recommendedGoods.value = store.state.goods.recommendedGoods;
      }

      // get goods detail
      let result: any;
      // if preview, no need to add jwt
      if (preview.value) {
        result = await apis.callAPI(
          "get",
          apis.endpoints.getGoodsDetailPreview.replace(
            "{}",
            route.params.goods_id.toString()
          )
        );
      } else {
        result = await apis.callAPI(
          "get",
          apis.endpoints.getGoodsDetail.replace(
            "{}",
            route.params.goods_id.toString()
          ) +
            "?contract_id=" +
            contract_id.value,
          store.state.jwt
        );
      }

      // sample
      // result = {
      //   data: {
      //     status: "success",
      //     result: {
      //       id: 1,
      //       name: "name",
      //       subtitle: "subtitle",
      //       header_desc: "header_desc",
      //       html_desc:
      //         "<style>ul.goods-intro{margin-top:4rem;counter-reset:step}ul.goods-intro li{list-style:none;margin:1.6rem 0 4rem;height:64px}ul.goods-intro li h2{font-size:3.2rem}ul.goods-intro li p{color:#6f7070;font-size:1.4rem;font-weight:bold}ul.goods-intro li:before{content:'0' counter(step);counter-increment:step;width:64px;height:64px;line-height:5.5rem;border:1px solid #ddd;border-radius:100%;display:block;text-align:center;font-size:4rem;font-weight:bold;border-color:#0060b9;float:left;margin-right:1rem;margin-top:.5rem}ul.goods-intro li:after{content:'';position:absolute;top:12px;left:-5%;z-index:-1;width:0;height:0;border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:10px solid red;opacity:.4}@media screen and (min-width:320px){ul.goods-intro li{list-style:none;margin:1rem 0}ul.goods-intro li p{color:#6f7070;font-size:1.4rem;font-weight:bold}ul.goods-intro li:before{content:'0' counter(step);counter-increment:step;width:44px;height:44px;line-height:40px;border:1px solid #ddd;border-radius:100%;display:block;text-align:center;font-size:2.2rem;font-weight:bold;border-color:#0060b9;float:left;margin-right:1rem;margin-top:.5rem}ul.goods-intro li:after{content:'';position:absolute;top:12px;left:-5%;z-index:-1;width:0;height:0;border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:10px solid red;opacity:.4}}</style><h2>特別価格でご提供！<br />震度７クラスの地震にも安心。</h2><ul class='goods-intro'><li><h2>安心</h2><p>震度７クラスの耐震試験クリア！</p></li><li><h2>スッキリ</h2><p>底面に貼るだけ。これなら見た目もスッキリ。</p></li><li><h2>繰り返し使用可</h2><p>水洗いで繰り返し使用が可能。</p></li></ul>",
      //       html_table:
      //         "<style>table.goods-info{margin:4.3rem 0;width:100%;color:#707070;font-weight:bold;border-collapse:collapse}table.goods-info tr td{padding:.5rem 1rem;background-color:#eaeaea;font-size:1.2rem}table.goods-info tr>td:first-child{width:90px;background-color:#cadbed;font-size:1.4rem}table.goods-info tr:first-child{border-bottom:2px solid #fff}</style><table class='goods-info'><tr><td>材質</td><td>ウレタンエラストマー</td></tr><tr><td>耐震荷重</td><td>100kg（４枚）</td></tr></table>",
      //       status: "published", // "draft, published, deleted"
      //       price: 1000,
      //       sku: "sku",
      //       memo: "memo",
      //       tax_id: 1,
      //       tax_rate: 0.1,
      //       parent_id: 1,
      //       published_at: "2022-02-21T00:00:00",
      //       product_type: "standalone, variant, parent",
      //       display_from: "2022-02-21T00:00:00",
      //       display_to: "2022-02-21T00:00:00",
      //       inventory_status: "in_stock, waiting, sold_out",
      //       images: [
      //         {
      //           order: 1,
      //           url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //         },
      //         {
      //           order: 2,
      //           url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //         },
      //         {
      //           order: 3,
      //           url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //         },
      //       ],
      //       attributes: [
      //         {
      //           name: "サイズ",
      //           type: "label",
      //           options: [
      //             {
      //               name: "Sサイズ（50×50×5mm）",
      //             },
      //             {
      //               name: "Mサイズ（70×70×5mm）",
      //             },
      //             {
      //               name: "Lサイズ（90×90×5mm）",
      //             },
      //           ],
      //         },
      //         {
      //           name: "枚数",
      //           type: "label",
      //           options: [
      //             {
      //               name: "4枚入り",
      //             },
      //             {
      //               name: "8枚入り",
      //             },
      //             {
      //               name: "12枚入り",
      //             },
      //           ],
      //         },
      //       ],
      //       variants: [
      //         {
      //           id: 1,
      //           name: "nameS4",
      //           subtitle: "subtitle",
      //           header_desc: "header_desc",
      //           html_desc: "html_desc",
      //           html_table: "html_table",
      //           status: "published",
      //           price: 1000,
      //           sku: "sku",
      //           memo: "memo",
      //           tax_id: 1,
      //           tax_rate: 0.1,
      //           parent_id: 1,
      //           published_at: "2022-02-21T00:00:00",
      //           product_type: "standalone, variant, parent",
      //           display_from: "2022-02-21T00:00:00",
      //           display_to: "2022-02-21T00:00:00",
      //           inventory_status: "in_stock, waiting, sold_out",
      //           images: [
      //             {
      //               order: 1,
      //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //             },
      //             {
      //               order: 2,
      //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //             },
      //             {
      //               order: 3,
      //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //             },
      //           ],
      //           attributes: [
      //             {
      //               name: "サイズ",
      //               type: "label",
      //               value: "Sサイズ（50×50×5mm）",
      //             },
      //             {
      //               name: "枚数",
      //               type: "label",
      //               value: "4枚入り",
      //             },
      //           ],
      //         },
      //         {
      //           id: 2,
      //           name: "nameS8",
      //           subtitle: "subtitleS8",
      //           header_desc: "header_descS8",
      //           html_desc: "html_descS8",
      //           html_table: "html_tableS8",
      //           status: "published",
      //           price: 1100,
      //           sku: "sku",
      //           memo: "memo",
      //           tax_id: 1,
      //           tax_rate: 0.1,
      //           parent_id: 1,
      //           published_at: "2022-02-21T00:00:00",
      //           product_type: "standalone, variant, parent",
      //           display_from: "2022-02-21T00:00:00",
      //           display_to: "2022-02-21T00:00:00",
      //           inventory_status: "in_stock, waiting, sold_out",
      //           images: [
      //             {
      //               order: 1,
      //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //             },
      //           ],
      //           attributes: [
      //             {
      //               name: "サイズ",
      //               type: "label",
      //               value: "Sサイズ（50×50×5mm）",
      //             },
      //             {
      //               name: "枚数",
      //               type: "label",
      //               value: "8枚入り",
      //             },
      //           ],
      //         },
      //         {
      //           id: 3,
      //           name: "nameS12",
      //           subtitle: "subtitleS12",
      //           header_desc: "header_descS12",
      //           html_desc: "html_descS12",
      //           html_table: "html_tableS12",
      //           status: "published",
      //           price: 1200,
      //           sku: "sku",
      //           memo: "memo",
      //           tax_id: 1,
      //           tax_rate: 0.1,
      //           parent_id: 1,
      //           published_at: "2022-02-21T00:00:00",
      //           product_type: "standalone, variant, parent",
      //           display_from: "2022-02-21T00:00:00",
      //           display_to: "2022-02-21T00:00:00",
      //           inventory_status: "in_stock, waiting, sold_out",
      //           images: [
      //             {
      //               order: 1,
      //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //             },
      //           ],
      //           attributes: [
      //             {
      //               name: "サイズ",
      //               type: "label",
      //               value: "Sサイズ（50×50×5mm）",
      //             },
      //             {
      //               name: "枚数",
      //               type: "label",
      //               value: "12枚入り",
      //             },
      //           ],
      //         },
      //         {
      //           id: 4,
      //           name: "nameM4",
      //           subtitle: "subtitleM4",
      //           header_desc: "header_descM4",
      //           html_desc: "html_descM4",
      //           html_table: "html_tableM4",
      //           status: "published",
      //           price: 2000,
      //           sku: "sku",
      //           memo: "memo",
      //           tax_id: 1,
      //           tax_rate: 0.1,
      //           parent_id: 1,
      //           published_at: "2022-02-21T00:00:00",
      //           product_type: "standalone, variant, parent",
      //           display_from: "2022-02-21T00:00:00",
      //           display_to: "2022-02-21T00:00:00",
      //           inventory_status: "in_stock, waiting, sold_out",
      //           images: [
      //             {
      //               order: 1,
      //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //             },
      //           ],
      //           attributes: [
      //             {
      //               name: "サイズ",
      //               type: "label",
      //               value: "Mサイズ（70×70×5mm）",
      //             },
      //             {
      //               name: "枚数",
      //               type: "label",
      //               value: "4枚入り",
      //             },
      //           ],
      //         },
      //         {
      //           id: 5,
      //           name: "nameM8",
      //           subtitle: "subtitleM8",
      //           header_desc: "header_descM8",
      //           html_desc: "html_descM8",
      //           html_table: "html_tableM8",
      //           status: "published",
      //           price: 2100,
      //           sku: "sku",
      //           memo: "memo",
      //           tax_id: 1,
      //           tax_rate: 0.1,
      //           parent_id: 1,
      //           published_at: "2022-02-21T00:00:00",
      //           product_type: "standalone, variant, parent",
      //           display_from: "2022-02-21T00:00:00",
      //           display_to: "2022-02-21T00:00:00",
      //           inventory_status: "in_stock, waiting, sold_out",
      //           images: [
      //             {
      //               order: 1,
      //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //             },
      //           ],
      //           attributes: [
      //             {
      //               name: "サイズ",
      //               type: "label",
      //               value: "Mサイズ（70×70×5mm）",
      //             },
      //             {
      //               name: "枚数",
      //               type: "label",
      //               value: "8枚入り",
      //             },
      //           ],
      //         },
      //         {
      //           id: 6,
      //           name: "nameL4",
      //           subtitle: "subtitleL4",
      //           header_desc: "header_descL4",
      //           html_desc: "html_descL4",
      //           html_table: "html_tableL4",
      //           status: "published",
      //           price: 3000,
      //           sku: "sku",
      //           memo: "memo",
      //           tax_id: 1,
      //           tax_rate: 0.1,
      //           parent_id: 1,
      //           published_at: "2022-02-21T00:00:00",
      //           product_type: "standalone, variant, parent",
      //           display_from: "2022-02-21T00:00:00",
      //           display_to: "2022-02-21T00:00:00",
      //           inventory_status: "in_stock, waiting, sold_out",
      //           images: [
      //             {
      //               order: 1,
      //               url: "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
      //             },
      //           ],
      //           attributes: [
      //             {
      //               name: "サイズ",
      //               type: "label",
      //               value: "Lサイズ（90×90×5mm）",
      //             },
      //             {
      //               name: "枚数",
      //               type: "label",
      //               value: "4枚入り",
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   } as any,
      // };

      if (result && result.data.status === "success") {
        // if not preview mode and status is not published, go back to GoodListing
        if (!preview.value && result.data.result.status !== "published") {
          routeTo("GoodListing");
        } else {
          goodsDetail.value = result.data.result;

          // get Booked Goods if not preview
          if (!preview.value) {
            // get booked goods
            const result2 = await apis.callAPI(
              "get",
              apis.endpoints.getBookedGoods.replace("{}", contract_id.value),
              store.state.jwt
            );
            if (result2 && result2.data.status === "success") {
              bookedGoods.value = result2.data.result.booking_contents;
            }
          }
        }
      }
    });

    const goTop = () => {
      routeTo("GoodListing");
    };

    const goReserved = () => {
      routeTo("GoodReserved");
    };

    const clickReserve = async () => {
      // check if preview
      if (preview.value) {
        util.openAlert(store, "OK", "", "プレビューのため動作しません", () =>
          util.closeAlert(store)
        );
      } else {
        const moving_date =
          (localStorage.getItem("moving_date") as string) || "";

        // check if ukt_no and contract_id and moving_date are not null or empty
        if (!ukt_no.value || !contract_id.value || !moving_date) {
          routeTo("MyPageMenu");
        } else {
          // check if today is more than one day before moving_date
          let the_day_before_moving_date = new Date(moving_date + "T00:00");
          the_day_before_moving_date.setDate(
            the_day_before_moving_date.getDate() - 1
          );
          if (
            new Date(
              new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
            ) >= the_day_before_moving_date
          ) {
            util.openAlert(
              store,
              "OK",
              "",
              "直近の変更に関してはお手数ですが担当支店にご連絡をお願いいたします<br/>" +
                goodsDetail.value.branch_info.name +
                "支店<br/><a href='tel:"+goodsDetail.value.branch_info.tel+"'>" +
                goodsDetail.value.branch_info.tel + "</a>",
              () => util.closeAlert(store)
            );
          } else {
            store.dispatch("startLoading", true);
            // call api
            const result = await apis.callAPI(
              "post",
              apis.endpoints.orderGoods.replace("{}", contract_id.value),
              store.state.jwt,
              {
                order_list: store.state.goods.order_list,
              }
            );

            // sample
            // const result = {
            //   data: {
            //     status: "success",
            //     result: {
            //       msg: "message",
            //     },
            //   },
            // };

            if (result && result.data.status === "success") {
              // reset store
              store.dispatch("goods/resetOrderList");
              routeTo("GoodReserved", {}, { mode: "reserve" });
            }
          }
        }
      }
    };

    return {
      goodsDetail,
      recommendedGoods,
      goTop,
      goReserved,
      clickReserve,
      preview,
      bookedGoods,
      variant_id,
    };
  },
});
