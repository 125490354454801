import { useRouter } from "vue-router";

export default function () {
  const router = useRouter();
  const routeTo = (name: string, query: any = {}, params: any = {}) => {
    router.push({ name: name, query, params });
    // window.scrollTo(0, 0);
  };
  return {
    routeTo,
  };
}
