
import { defineComponent } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import PriceLabel from "../../components/furniture/PriceLabel.vue";
import util from "@/js/util";

export default defineComponent({
  components: {
    BaseButton,
    PriceLabel,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  emits: ["click"],
  methods: {
    filterCurrency(value: string, tax_rate: number) {
      const price = util.calculateTax(value, tax_rate);
      return util.getPrice(price.toString(), true);
    },
  },
});
