
import Steps from "@/components/furniture/Steps.vue";
import ContactOption from "@/components/confirmation/ContactOption.vue";

import { defineComponent } from "vue";

export default defineComponent({
  components: { Steps, ContactOption },
  props: {
    detail: {
      type: Object,
    },
    hasOption: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      items: [
        { label: "基本情報", class: "current" },
        { label: "作業内容", class: "current" },
        { label: "料金確認" },
        { label: "完了" },
      ],
    };
  },
});
