import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListItemMobile = _resolveComponent("ListItemMobile")!
  const _component_ListItemDesktop = _resolveComponent("ListItemDesktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_ListItemMobile, {
          key: 0,
          data: _ctx.deliveryItems,
          onClick: _ctx.goNext
        }, null, 8, ["data", "onClick"]))
      : (_openBlock(), _createBlock(_component_ListItemDesktop, {
          key: 1,
          data: _ctx.deliveryItems,
          onClick: _ctx.goNext
        }, null, 8, ["data", "onClick"]))
  ]))
}