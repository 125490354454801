import { computed } from "vue";
import { useStore } from "vuex";

export default function () {
  const store = useStore();
  const computeClass = computed(() => {
    if (store.state.device === "mobile") {
      return "mobile";
    } else {
      return "pc";
    }
  });
  return {
    computeClass,
  };
}
