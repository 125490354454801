
import { defineComponent } from "vue";
import InputBlock from "./InputBlock.vue";
import LabelAndInput from "./LabelAndInput.vue";
import SaveEmailCheckbox from "./SaveEmailCheckbox.vue";

export default defineComponent({
  components: { LabelAndInput, InputBlock, SaveEmailCheckbox },
  props: {
    title: {
      type: String,
      required: true,
    },
    mark: {
      type: Boolean,
      default: false,
    },
    label1: {
      type: String,
      default: "",
    },
    type1: {
      type: String,
      default: "text",
    },
    inputmode1: {
      type: String,
      default: "",
    },
    placeholder1: {
      type: String,
      default: "",
    },
    value1: {
      type: String,
      default: "",
    },
    width1: {
      type: String,
      default: "61.3vw",
    },
    margin1: {
      type: String,
      default: "1rem 2rem 0",
    },
    errMsg1: {
      type: String,
      default: "",
    },
    label2: {
      type: String,
      default: "",
    },
    type2: {
      type: String,
      default: "text",
    },
    inputmode2: {
      type: String,
      default: "",
    },
    placeholder2: {
      type: String,
      default: "",
    },
    width2: {
      type: String,
      default: "61.3vw",
    },
    margin2: {
      type: String,
      default: "1rem 2rem 0",
    },
    errMsg2: {
      type: String,
      default: "",
    },
    saveEmail: {
      type: Boolean,
      default: false,
    },
    saveEmailChecked: {
      type: Boolean,
      default: false,
    },
    marginSaveEmail: {
      type: String,
      default: "1rem 2rem 0",
    },
    fontSize: {
      type: String,
      default: "1.2rem",
    },
    tooltips: {
      type: String,
      default: '',
    },
    hint1: {
      type: String,
      default: '',
    },
    hint2: {
      type: String,
      default: '',
    },
    hint3: {
      type: String,
      default: '',
    },
    hint4: {
      type: String,
      default: '',
    },
  },
  emits: ["input1", "input2", "change"],
  // setup() {

  // },
});
