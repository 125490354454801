
import BaseButton from "@/components/delivery/BaseButton.vue";
import ItemBlock from "@/components/delivery/desktop/ItemBlock.vue";
import SelectBlock from "@/components/delivery/SelectBlock.vue";
import RadioBlock from "@/components/delivery/RadioBlock.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import deliveryConst from "@/js/deliveryConst";
import DeliveryMixin from "@/mixins/delivery.vue";

export default defineComponent({
  components: { ItemBlock, SelectBlock, RadioBlock, BaseButton, Logo, Footer },
  props: {
    availableDate: {
      type: Array,
      required: true,
    },
  },
  mixins: [DeliveryMixin],
  emits: ["click"],
  setup(props, context) {
    const store = useStore();
    const item = computed(() => {
      return store.state.delivery.item;
    });
    const imageMap = deliveryConst.imageMap;
    const materialTypeMap = deliveryConst.materialTypeMap;

    const errorMsgDate = ref("");
    const errorMsgTime = ref("");
    const errorMsgAutolock = ref("");
    const errorMsgPlacement = ref("");

    const selectTime = (value: string) => {
      store.dispatch("delivery/inputTime", value);
    };
    function selectDate(value: string) {
      store.dispatch("delivery/inputDatetime", value);
    }
    const inputLock = (item: number) => {
      store.dispatch("delivery/inputLock", item);
      store.dispatch("delivery/inputPlacement", 0);
    };
    const inputPlacement = (item: number) => {
      store.dispatch("delivery/inputPlacement", item);
    };
    function selectRequest(value: string) {
      store.dispatch("delivery/inputRequest", [value]);
    }

    const click = () => {
      // validation
      // check delivery_date
      if (!store.state.delivery.delivery_date) {
        errorMsgDate.value = "選択してください";
      } else {
        errorMsgDate.value = "";
      }
      // check delivery_time
      if (!store.state.delivery.delivery_time) {
        errorMsgTime.value = "選択してください";
      } else {
        errorMsgTime.value = "";
      }
      // check auto lock
      if (store.state.delivery.is_autolock) {
        errorMsgAutolock.value = "";
      } else {
        errorMsgAutolock.value = "選択してください";
      }
      // check placement
      if (
        store.state.delivery.is_autolock == 2 &&
        !store.state.delivery.placement
      ) {
        errorMsgPlacement.value = "選択してください";
      } else {
        errorMsgPlacement.value = "";
      }

      if (
        !errorMsgDate.value &&
        !errorMsgTime.value &&
        !errorMsgAutolock.value &&
        !errorMsgPlacement.value
      ) {
        context.emit("click");
      } else {
        window.scrollTo(0, 0);
      }
    };

    return {
      item,
      imageMap,
      materialTypeMap,
      selectTime,
      selectDate,
      inputLock,
      inputPlacement,
      selectRequest,
      click,
      errorMsgDate,
      errorMsgTime,
      errorMsgAutolock,
      errorMsgPlacement,
    };
  },
});
