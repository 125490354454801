
import { defineComponent, computed, onMounted, ref } from "vue";
import Header from "@/components/mypage/desktop/Header.vue";
import Popup from "@/components/Popup.vue";

import logout from "@/js/logout";
import apis from "@/js/apis";
import { useStore } from "vuex";
import util from "@/js/util";
import useRouteTo from "@/js/useRouteTo";

export default defineComponent({
  components: { Header, Popup },
  props: {
    hasLink: {
      type: Boolean,
      default: false,
    },
    hasIntro: {
      type: Boolean,
      default: false,
    },
    hasMenu: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
    active: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { routeTo } = useRouteTo();
    const ukt_no = ref("");
    const store = useStore();
    const articles = ref([]);
    const isModalVisible = ref(false);
    const moving_date = ref("");
    const style = computed(() => {
      return "bg-header " + props.className;
    });
    const getJapaneseDate = util.getJapaneseDate;
    const showModal = async () => {
      articles.value = await apis.getAllNews();
      isModalVisible.value = true;
    };
    const closeModal = () => {
      isModalVisible.value = false;
    };
    const isLogout = () => {
      util.openAlert(store, "OK", "Cancel", "ログアウトしますか?", logout)
    };
    // const showUnderline = (e: Event) => {
    //   let target = e.target as HTMLElement;
    //   target.classList.add("hover-underline");
    //   console.log("target.nextSibling");
    //   console.log(target.nextSibling);
    //   let sib = target.nextSibling as HTMLElement;
    //   console.log("sib");
    //   console.log(sib);
    //   sib.classList.add("underline");
    //   console.log("target.previousSibling");
    //   console.log(target.previousSibling);
    // };
    onMounted(async () => {
      try {
        ukt_no.value = (localStorage.getItem("ukt_no") as string) || "";
        // check if allNews is in store
        if (props.hasIntro) {
          if (!store.state.allNews.length) {
            // if not, get all news
            articles.value = await apis.getAllNews();
          } else {
            articles.value = store.state.allNews;
          }
        }

        // get currently selected contract's moving_date
        if (localStorage.getItem("moving_date")) {
          moving_date.value =
            util.getJapaneseMonth(
              localStorage.getItem("moving_date") as string
            ) + "のお引越し情報を表示中";
        }

        // add event listener to detect ukt_no change
        window.addEventListener("ukt_no-localstorage-changed", (event: any) => {
          ukt_no.value = event.detail.ukt_no || "";
        });
      } catch {
        console.log("localStorageへのアクセス失敗3");
      }
    });
    return {
      articles,
      isModalVisible,
      showModal,
      closeModal,
      moving_date,
      style,
      logout,
      getJapaneseDate,
      ukt_no,
      isLogout,
    };
  },
  // created() {
  //   const sampleData = [
  //     {
  //       id: 1,
  //       title: "◆コロナウイルス感染症に関するお知らせ",
  //       content:
  //         "<p>お客さま各位 いつもアート引越センターをご利用いただきありがとうございます。</p> <p>新型コロナウイルスの感染拡大に伴い、荷物をお受け取りいただく際、お客さまと弊社社員の接触を極力少なくするために、「受領印・サインの省略」や「ご自宅での非対面でのお受け取り」などを推奨しております。</p> <p>また、一部エリアで資材配達をはじめとするサービスの提供に遅れが発生する可能性がございます。 </p><p>お客さまにはご不便をお掛けしまして申し訳ございません。何卒ご理解を賜りますようお願い申し上げます。 詳細はこちらからご確認ください。</p>",
  //       start: "",
  //     },
  //     {
  //       id: 2,
  //       title: "降雪や強風による荷物のお届け遅延について",
  //       content:
  //         "<p>お客さま各位 いつもアート引越センターをご利用いただきありがとうございます。</p> <p>新型コロナウイルスの感染拡大に伴い、荷物をお受け取りいただく際、お客さまと弊社社員の接触を極力少なくするために、「受領印・サインの省略」や「ご自宅での非対面でのお受け取り」などを推奨しております。</p> <p>また、一部エリアで資材配達をはじめとするサービスの提供に遅れが発生する可能性がございます。 </p><p>お客さまにはご不便をお掛けしまして申し訳ございません。何卒ご理解を賜りますようお願い申し上げます。 詳細はこちらからご確認ください。</p>",
  //       start: "2022年1月7日",
  //     },
  //   ];
  //   this.articles = reactive({ ...sampleData });
  // },
});
