
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import useRouteTo from "../../js/useRouteTo";

export default defineComponent({
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const updateItem = (value: string) => {
      if (value === "one") {
        routeTo("GoodListing");
      } else {
        routeTo("GoodReserved");
      }
    };
    const style = computed(() => {
      if (store.state.goods.selected === "one") {
        return {
          transform: "translateX(0)",
        };
      } else if (store.state.goods.selected === "two") {
        return {
          transform: "translateX(100%)",
        };
      } else {
        return {};
      }
    });
    return {
      store,
      updateItem,
      style,
    };
  },
});
