
import ConfirmationView from "@/components/collection/CollectionConfirmationView.vue";
import Steps from "@/components/furniture/Steps.vue";
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { ConfirmationView, Steps },
  emits: ["click"],
  data: function () {
    return {
      items: [
        { label: "住所選択", class: "current" },
        { label: "日程選択", class: "current" },
        {
          label: "内容確認",
          class: "current",
        },
        { label: "申込完了" },
      ],
      collection: {},
      msg: "",
    };
  },
  watch: {
    "$store.state.collection.free_or_paid": function (value) {
      if (value == "paid") {
        this.msg = "今回の回収は3,000円(税込)で承ります";
      } else {
        this.msg = "";
      }
    },
  },
  created() {
    const store = useStore();
    this.collection = reactive({ ...store.state.collection });

    if (store.state.collection.free_or_paid == "paid") {
      this.msg = "今回の回収は3,000円(税込)で承ります";
    }
  },
});
