import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9494505c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 2,
  class: "no-option"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_backgroung_image_block = _resolveComponent("backgroung-image-block")!
  const _component_title_block = _resolveComponent("title-block")!
  const _component_contents_toggle_block = _resolveComponent("contents-toggle-block")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.hasBanner)
      ? (_openBlock(), _createBlock(_component_backgroung_image_block, {
          key: 0,
          bgImage: require('../../../assets/mypage/option_mobile.png')
        }, null, 8, ["bgImage"]))
      : _createCommentVNode("", true),
    _createVNode(_component_title_block, { title: "オプションサービス" }),
    (_ctx.hasOption)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.option_category_list, (item) => {
            return (_openBlock(), _createElementBlock("div", { key: item }, [
              _createVNode(_component_contents_toggle_block, { item: item }, null, 8, ["item"])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, "オプションのお申し込みはございません"))
  ]))
}