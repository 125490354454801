import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.computeClass)
  }, [
    _createElementVNode("div", {
      class: "wrapper",
      style: _normalizeStyle(_ctx.style)
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.buttonStyleClass),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
        style: _normalizeStyle(_ctx.buttonStyle)
      }, _toDisplayString(_ctx.buttonText), 7)
    ], 4)
  ], 2))
}