
import ConfirmationView from "@/components/collection/ConfirmationView.vue";
import ExclamationIcon from "@/components/furniture/ExclamationIcon.vue";
import BaseButton from "@/components/BaseButton.vue";
import util from "@/js/util";
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { ConfirmationView, ExclamationIcon, BaseButton },
  props: {
    msg: {
      type: Boolean,
      default: false,
    },
    collections: {
      type: Object,
    },
    over3months: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      collection: {
        zipcode: "",
        kaishu_date: "",
        kaishu_time: "",
        address_01: "",
        address_02: "",
        address_code: "",
        tel: "",
        request: [],
      },
      msg2: "",
    };
  },
  watch: {
    "$store.state.collection.free_or_paid": function (value) {
      if (value == "paid") {
        this.msg2 =
          "ダンボールを回収した際に回収スタッフにお支払いください。領収書も発行いたします。";
      } else {
        this.msg2 = "";
      }
    },
  },
  created() {
    const store = useStore();
    if (store.state.collection.free_or_paid == "paid") {
      this.msg2 =
        "ダンボールを回収した際に回収スタッフにお支払いください。領収書も発行いたします。";
    }
    const sampleData = {
      datetime: "11月30日(火)",
      time: "午前中",
      zipcode: "565-0846",
      city: "吹田市千里山虹が丘 大阪府",
      address: "１０−１ シエリアシティ千里山B棟７０５",
      phone_number: "070-1234-5678",
      request: "選択してください",
    };

    this.collection = reactive({ ...store.state.collection });
    store.dispatch("collection/inputZipcode", this.collection.zipcode);
    store.dispatch("collection/inputTime", this.collection.kaishu_time);
    store.dispatch("collection/inputDatetime", this.collection.kaishu_date);
    store.dispatch("collection/inputPhonenumber", this.collection.tel);
    store.dispatch("collection/inputPhonetype", 1);
    store.dispatch("collection/inputRequest", this.collection.request);
    store.dispatch("collection/inputAddress", this.collection.address_02);
    store.dispatch("collection/inputCity", this.collection.address_01);
  },
  emits: ["clickEdit", "clickNewApply"],
  setup(props, context) {
    const store = useStore();
    const click = (data: any) => {
      if (data.branch_info && data.branch_info.is_fc) {
        util.openAlert(store, "OK", "", "担当支店にお問合せください<br/>" +
              data.branch_info.name +
              "支店<br/><a href='tel:"+data.branch_info.tel+"'>" +
            data.branch_info.tel + "</a>", () => util.closeAlert(store))
      } else if (data.kaishu_date) {
        let the_day_before_kaishu_date = new Date(data.kaishu_date + " 15:00");
        the_day_before_kaishu_date.setDate(
          the_day_before_kaishu_date.getDate() - 1
        );
        if (
          new Date(
            new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
          ) >= the_day_before_kaishu_date
        ) {
          util.openAlert(store, "OK", "", "直近の変更に関してはお手数ですが担当支店にご連絡をお願いいたします<br/>" +
              data.branch_info.name +
              "支店<br/><a href='tel:"+data.branch_info.tel+"'>" +
              data.branch_info.tel +"</a>", () => util.closeAlert(store))
        } else {
          context.emit("clickEdit", data);
        }
      } else {
        context.emit("clickEdit", data);
      }
    };
    return {
      click,
    };
  },
});
