
import PaidCollectionView from "@/components/collection/PaidCollectionView.vue";
import { defineComponent } from "vue";
import useRouteTo from "../../../js/useRouteTo";

export default defineComponent({
  components: { PaidCollectionView },
  setup() {
    const { routeTo } = useRouteTo();
    const submit = () => {
      routeTo('BookingCollection');
    }
    return {
      submit
    }
  },
});
