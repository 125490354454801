import store from "@/store";
import saveLocalStorage from "./saveLocalStorage";
import useRouteTo from "./useRouteTo";
import globalVariable from "@/main";
import util from "./util";

export default async function () {
  const { routeTo } = useRouteTo();
  const aqc_link = globalVariable.aqc_link;

  let quotation_branch_id =
    (localStorage.getItem("quotation_branch_id") as string) || "";
  let name = (localStorage.getItem("name") as string) || "";
  let ukt_no = (localStorage.getItem("ukt_no") as string) || "";

  if (
    navigator.userAgent.includes("line") ||
    navigator.userAgent.includes("Line") ||
    navigator.userAgent.includes("LINE")
  ) {
    // if (navigator.userAgent.includes("iPhone")) {
    //   util.openAlert(
    //     store,
    //     "",
    //     "",
    //     "LINEブラウザでは開けません。お手数ですが外部ブラウザでご確認ください。",
    //     () => util.closeAlert(store)
    //   );
    // } else {
    if (!quotation_branch_id || !name || !ukt_no) {
      // get selected number in local storage
      const contract_id =
        (localStorage.getItem("selectedNumber") as string) || "";
      if (contract_id) {
        store.dispatch("startLoading", true);
        const detail = await saveLocalStorage(contract_id);
        if (detail && detail.ukt_no) {
          quotation_branch_id = detail.quotation_branch.branch_id;
          name = detail.name;
          ukt_no = detail.ukt_no;
        } else {
          routeTo("MyPageMenu");
        }
      } else {
        routeTo("MyPageMenu");
      }
    }
    const query =
      "?ShitenCd=" +
      quotation_branch_id +
      "&UkeNo=" +
      ukt_no +
      "&CstName=" +
      name.replaceAll(" ", "").replaceAll("　", "");
    const new_url = aqc_link + query;

    // window.open(new_url);
    location.href = new_url;
    // }
  } else {
    const windowReference = window.open() as Window;

    if (!quotation_branch_id || !name || !ukt_no) {
      // get selected number in local storage
      const contract_id =
        (localStorage.getItem("selectedNumber") as string) || "";
      if (contract_id) {
        store.dispatch("startLoading", true);
        const detail = await saveLocalStorage(contract_id);
        if (detail && detail.ukt_no) {
          quotation_branch_id = detail.quotation_branch.branch_id;
          name = detail.name;
          ukt_no = detail.ukt_no;
        } else {
          windowReference.close();
        }
      } else {
        windowReference.close();
      }
    }
    const query =
      "?ShitenCd=" +
      quotation_branch_id +
      "&UkeNo=" +
      ukt_no +
      "&CstName=" +
      name.replaceAll(" ", "").replaceAll("　", "");
    const new_url = aqc_link + query;

    windowReference.location.href = new_url;
  }
}
