
import util from "@/js/util";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const toggled = ref(store.state.device !== "mobile");
    const click = () => {
      setTimeout(() => {
        if (store.state.device === "mobile") toggled.value = !toggled.value;
      }, 0);
    };
    const getJapaneseDate = util.getJapaneseDate;
    return {
      toggled,
      click,
      getJapaneseDate,
    };
  },
});
