
import { defineComponent, inject, onMounted } from "vue";
import { useStore } from "vuex";
import HomeButton from "./components/HomeButton.vue";
import LoadingBlock from "./components/LoadingBlock.vue";
import checkDevice from "./js/checkDevice";
import Alert from "@/components/Alert.vue";

export default defineComponent({
  components: { LoadingBlock, HomeButton, Alert },
  setup() {
    const store = useStore();
    const phase = inject("phase");
    onMounted(() => {
      console.log(process.env.NODE_ENV);
      console.log("phase: " + phase);
      // check if device is mobile or pc
      checkDevice();

      // prevent pinch in and out
      document.body.addEventListener(
        "touchstart",
        function (e) {
          if (e.touches && e.touches.length > 1) {
            e.preventDefault();
          }
        },
        { passive: false }
      );
      document.body.addEventListener(
        "touchmove",
        function (e) {
          if (e.touches && e.touches.length > 1) {
            e.preventDefault();
          }
        },
        { passive: false }
      );

      // prevent double click expand
      document.body.addEventListener(
        "dblclick",
        function (e) {
          e.preventDefault();
        },
        { passive: false }
      );
    });
  },
});
