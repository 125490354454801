
import { defineComponent, inject, reactive, ref } from "vue";
import { useStore } from "vuex";
import Checkbox from "@/components/furniture/Checkbox.vue";
import BaseButton from "@/components/BaseButton.vue";
import util from "@/js/util";
import apis from "@/js/apis";
import useRouteTo from "@/js/useRouteTo";
import BranchTel from "./BranchTel.vue";
import PriceModal from "../../../views/mypage/partials/PriceModal.vue";

export default defineComponent({
  components: { Checkbox, BaseButton, BranchTel, PriceModal },
  props: {
    detail: {
      type: Object,
    },
  },

  data: function () {
    return {
      items: [{ id: "checkbox1", label: "上記の内容を確認しました", value: 1 }],
      selectedInputItems: [],
      disabled: true,
      price: {
        total: "",
        deposited: "",
        due: "",
        method: "",
      },
      payment: {} as any,
    };
  },
  created() {
    const sampleData = {
      total: "1,075,030円（税込）",
      deposited: "105,030円",
      due: "970,000円（税込）",
      method: "引越当日現金支払",
    };

    this.price = reactive({ ...sampleData });
  },
  setup() {
    const wording = inject("wording");
    const clause = inject("clause") as any;
    const { routeTo } = useRouteTo();

    const isPriceModalVisible = ref(false);
    const togglePriceModal = (value: boolean) => {
      isPriceModalVisible.value = value;
    };

    const click = async () => {
      // call API
      const result = await apis.confirmConfirmation();
      if (result == "success") {
        routeTo("ContactSuccess");
      }
    };
    return {
      wording,
      clause,
      click,
      isPriceModalVisible,
      togglePriceModal,
    };
  },
  methods: {
    inputItems(items: string[]) {
      this.disabled = items.length == 0;
      this.$store.dispatch("confirmation/setPrice", items);
    },
    filterCurrency(value: string) {
      return util.getPrice(value, false) + "円";
    },
  },
  mounted: function () {
    const store = useStore();
    this.selectedInputItems = store.state.confirmation.price;
    this.disabled = store.state.confirmation.price.length == 0;

    this.payment.totalContractAmount =
      this.$props.detail?.total_contract_amount;
    this.payment.amountAlreadyDeposited =
      this.$props.detail?.amount_already_deposited;
    this.payment.paymentName = this.$props.detail?.payment_name;
    this.payment.paymentName2 = this.$props.detail?.payment_name_2;
    this.payment.paymentName3 = this.$props.detail?.payment_name_3;
    this.payment.paymentAmount = this.$props.detail?.payment_amount;
    this.payment.paymentAmount2 = this.$props.detail?.payment_amount_2;
    this.payment.paymentAmount3 = this.$props.detail?.payment_amount_3;
  },
  emits: ["click"],
});
