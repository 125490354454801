import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fbc9de7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "mobile-container"
}
const _hoisted_2 = {
  key: 2,
  class: "pc-container container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_error_occurred_message = _resolveComponent("error-occurred-message")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Logo, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_error_occurred_message, {
            title: _ctx.title,
            text: _ctx.text,
            help: _ctx.showMsg === 'help'
          }, null, 8, ["title", "text", "help"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_error_occurred_message, {
            title: _ctx.title,
            text: _ctx.text,
            help: _ctx.showMsg === 'help'
          }, null, 8, ["title", "text", "help"])
        ])),
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Footer, {
          key: 3,
          className: "mt"
        }))
      : _createCommentVNode("", true)
  ]))
}