
import Footer from "@/components/Footer.vue";
import Logo from "@/components/Logo.vue";
import ErrorOccurredMessage from "@/components/ErrorOccurredMessage.vue";
import useRouteTo from "@/js/useRouteTo";
import { computed, defineComponent } from "vue";
import errors from "@/js/errors";

export default defineComponent({
  components: { Logo, Footer, ErrorOccurredMessage },
  props: {
    title: {
      type: String,
      default: "",
    },
    showMsg: {
      type: String,
      default: "no",
    },
  },
  setup(props) {
    const { routeTo } = useRouteTo();
    const text = computed(() => {
      if (props.showMsg === "yes") {
        return "少し時間を置いてから再度お試しください";
      } else {
        return "";
      }
    });
    return {
      text,
      routeTo,
    };
  },
});
