
import DetailView from "@/components/goods/DetailView.vue";
import BaseButton from "@/components/account/BaseButton.vue";
import Recommendation from "@/components/goods/Recommendation.vue";

import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { DetailView, Recommendation, BaseButton },
  props: {
    dataResult: {
      type: Object,
      required: true,
    },
    recommendedGoods: {
      type: Array,
      default() {
        return [];
      },
    },
    preview: {
      type: Boolean,
      default: false,
    },
    bookedGoods: {
      type: Object,
      default() {
        return {};
      },
    },
    variant_id: {
      type: String,
      default: "",
    },
  },
  emits: ["goTop", "goReserved", "clickReserve"],
  setup() {
    const store = useStore();
    onMounted(() => {
      store.state.goods.selected = "one";
    });
  },
});
