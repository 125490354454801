
import { defineComponent } from "vue";
import BaseLabel from "./BaseLabel.vue";

export default defineComponent({
  components: { BaseLabel },
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMsg: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  data() {
    return {
      selectedItem: "",
    };
  },
  watch: {
    selected: function (newVal, oldVal) {
      this.setSelectedItem(newVal);
    },
  },
  methods: {
    updateItem(event: any): void {
      this.$emit("change", event.target.value);
      this.setSelectedItem(event.target.value);
    },
    setSelectedItem(value: string): void {
      this.selectedItem = value;
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.setSelectedItem(this.selected || "");
    });
  },
});
