// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/bg_pattern.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".goods[data-v-f8f5c5fa]{background-color:#fff;padding:4rem 0;margin-top:-2rem;z-index:-1;position:relative}.goods .banner>p[data-v-f8f5c5fa]{color:#707070;margin:1rem 3rem;font-weight:700}.goods .banner>img[data-v-f8f5c5fa]{width:100%}.products-list[data-v-f8f5c5fa]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat;padding:1rem 0 4rem}.buttons-list[data-v-f8f5c5fa]{display:flex;justify-content:center;padding:6rem 0 2rem}.buttons-list>div[data-v-f8f5c5fa]{width:34rem}.buttons-list>div+div[data-v-f8f5c5fa]{margin-left:3rem}.preview[data-v-f8f5c5fa]{font-size:2.8rem}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
