
import BaseButton from "@/components/account/BaseButton.vue";
import BaseInput from "@/components/account/BaseInput.vue";
import { useStore } from "vuex";
import useRouteTo from "@/js/useRouteTo";
import { defineComponent, ref, onMounted } from "vue";
import util from "@/js/util";

export default defineComponent({
  components: {
    BaseButton,
    BaseInput
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const inquiryType = ref("");
    const inquiryTypeError = ref(false);
    const inquiryText = ref("");
    const inquiryTextError = ref(false);
    const inquiryTextErrorMsg = ref("入力してください");
    const inquiryTextMaxLength = ref(500);
    const inquiryTel = ref("");
    const inquiryTelError = ref(false);
    const inquiryEmail = ref("");
    const inquiryTelEmailError = ref(false);

    onMounted(() => {
      inquiryType.value = store.state.inquiry.inquiry_type;
      inquiryText.value = store.state.inquiry.inquiry_text;
      inquiryTel.value = store.state.inquiry.inquiry_tel;
      inquiryEmail.value = props.params.post_email;
    });
    const changeTel = (value: any) => {
      inquiryTel.value = value
    }

    const submit = async () => {
      inquiryTypeError.value = inquiryType.value.length == 0;
      inquiryTextError.value = inquiryText.value.length == 0 || inquiryText.value.length > inquiryTextMaxLength.value;
      inquiryTelEmailError.value = inquiryTel.value.length == 0;
      if (!inquiryTelEmailError.value) {
        if (inquiryTel.value && !util.validateTel(inquiryTel.value)) {
          inquiryTelError.value = true
        } else {
          inquiryTelError.value = false
        }
      } else {
        inquiryTelError.value = false
      }

      if (inquiryText.value.length == 0) {
        inquiryTextErrorMsg.value = "入力してください"
      } else if (inquiryText.value.length > inquiryTextMaxLength.value) {
        inquiryTextErrorMsg.value = "500文字以内で入力してください"
      }
      
      if (
        !inquiryTypeError.value &&
        !inquiryTextError.value &&
        !inquiryTelEmailError.value &&
        !inquiryTelError.value
      ) {
        await store.dispatch("inquiry/setInquiry", {
          inquiry_type: inquiryType.value,
          inquiry_text: inquiryText.value,
          inquiry_tel: inquiryTel.value,
          inquiry_email: props.params.post_email,
        });
        routeTo("MyPageInquiriesConfirmation");
      } else {
        window.scrollTo(0, 0);
      }
    };
    return {
      submit,
      inquiryType,
      inquiryText,
      inquiryTel,
      inquiryEmail,
      inquiryTypeError,
      inquiryTextError,
      inquiryTextMaxLength,
      inquiryTextErrorMsg,
      inquiryTelEmailError,
      inquiryTelError,
      changeTel
    };
  },
});
