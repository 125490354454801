
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import useRouteTo from "../../../js/useRouteTo";
import { useRoute } from "vue-router";

import Checkbox from "./../Checkbox.vue";
import Select from "./../Select.vue";
import RadioButton from "./../RadioButton.vue";
import TextareaBlock from "./../TextareaBlock.vue";
import ExclamationIcon from "./../ExclamationIcon.vue";
import BaseButton from "./../../account/BaseButton.vue";
import BookingView from "@/components/furniture/BookingView.vue";
import Modal from "@/components/Modal.vue";

import FurnitureMixin from "@/mixins/furniture.vue";
import FurnitureAddressMixin from "@/mixins/furnitureAddress.vue";

export default defineComponent({
  name: "Booking",
  components: {
    Checkbox,
    Select,
    RadioButton,
    TextareaBlock,
    BaseButton,
    ExclamationIcon,
    BookingView,
    Modal
  },
  props: {
    buttonTitle: {
      type: String,
      default: ""
    },
    msg: {
      type: String,
      default: ""
    },
    address_list: {
      type: Object,
    },
  },
  mixins: [FurnitureMixin, FurnitureAddressMixin],
  watch: {
    "$store.state.furniture.zipcode": function (value) {
      const state: any = this.$store.state;
      let furniture: any = state.furniture;
      
      if (furniture && furniture.contract_id) {
        const contract_id = furniture.contract_id;
        this.getAvailableDate(contract_id, value);
      }
    },
  },
  setup(props, context) {
    const store = useStore();
    const { routeTo } = useRouteTo();
    const route = useRoute();

    const errMsg = ref("");
    const errMsg1 = ref("");
    const errMsg2 = ref("");
    const errMsg3 = ref("");
    const errMsg4 = ref("");
    const textMaxLength = ref(500);
    let houseHoldGoods: any[] = store.state.furniture.moving_furniture;
    let houseOther: any = store.state.furniture.other_furniture;
    let selectedDate: any = store.state.furniture.datetime;
    let phoneType: any = store.state.furniture.phone_type;
    let phoneNumber: any = store.state.furniture.phone_number;
    let comment: any = store.state.furniture.comment;
    let responseAddress: any = true;

    const hideModal = () => {
      routeTo("FurnitureSchedule");
    };

    const validationAddressCode = (response: any) => {
      store.dispatch("furniture/saveValidationAddress", response);
      if (response == 0) {
        responseAddress = true;
      } else {
        responseAddress = false;
      }
    };

    const inputGoods = (items: string[]) => {
      store.dispatch("furniture/inputGoods", items);
      houseHoldGoods = items;
    };

    const inputOther = (item: string) => {
      store.dispatch("furniture/inputOther", item);
      houseOther = item;
    };

    function selectDate(datetime: string) {
      store.dispatch("furniture/inputDatetime", datetime);
      selectedDate = datetime != "0" ? datetime : "";
    };
    const inputPhone = (item: string) => {
      store.dispatch("furniture/inputPhonetype", item);
      phoneType = item;
    };
    const selectPhone = (phone: string) => {
      store.dispatch("furniture/inputPhonenumber", phone);
      phoneNumber = phone;
    };
    const inputComment = (item: string) => {
       if (item.length <= textMaxLength.value) {
        errMsg4.value = "";
        store.dispatch("furniture/inputComment", item);
        comment = item
      } else {
        errMsg4.value = "500文字以内で入力してください";
      }
    };
    const submit = () => {
      if (
        store.state.furniture.address_01 &&
        ((store.state.furniture.address_specific_area &&
          store.state.furniture.address_rest) ||
          (!store.state.furniture.address_specific_area &&
            store.state.furniture.address_02))
      ) {
        errMsg.value = "";
        store.dispatch("furniture/setAddressDone", true);
      } else {
        errMsg.value = "入力してください";
      }

      if (houseHoldGoods.length > 0) {
        errMsg1.value = "";
        if (houseHoldGoods.indexOf("上記以外の家具") >= 0 && !houseOther) {
          errMsg1.value = "上記以外の家具を入力してください";
        }
      } else {
        errMsg1.value = "選択してください";
      }

      if (store.state.furniture.datetime) {
        errMsg2.value = "";
        store.dispatch("furniture/setDatetimeDone", true);
      } else {
        errMsg2.value = "選択してください";
      }

      errMsg3.value = "";
      if (store.state.furniture.phone_number) {
        errMsg3.value = "";
      } else {
        errMsg3.value = "連絡先電話番号を入力してください";
      }

      if (!errMsg.value && !errMsg1.value && !errMsg2.value && !errMsg3.value && !errMsg4.value && responseAddress) {
        context.emit("click");
      } else {
        window.scrollTo(0, 0);
      }
    };

    const buttonWidth = computed(() => {
      return store.state.device == 'mobile' ? '100%' : '34rem';
    });

    return {
      inputGoods,
      selectDate,
      selectPhone,
      inputComment,
      inputPhone,
      inputOther,
      submit,
      errMsg,
      errMsg1,
      errMsg2,
      errMsg3,
      errMsg4,
      buttonWidth,
      hideModal,
      validationAddressCode,
      textMaxLength
    };
  },
  emits: ["click"],
});
