
import useCheckStoreForConfirmation from "@/js/useCheckStoreForConfirmation";
import util from "@/js/util";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ContactDetailArrivalDesktop from "./partials/ContactDetailArrivalDesktop.vue";
import ContactDetailArrivalMobile from "./partials/ContactDetailArrivalMobile.vue";

export default defineComponent({
  components: { ContactDetailArrivalMobile, ContactDetailArrivalDesktop },
  // props: {

  // },
  setup() {
    const store = useStore();
    const contractDetail = ref({} as any);
    const currentInfo = ref({
      delivery_list: [] as Array<deliveryList>,
    });
    interface deliveryList {
      datetime: string;
      delivery_date: string;
      delivery_time: string;
      visiting_time: string;
      address_list: Array<address>;
    }
    interface address {
      zipcode: string;
      address: string;
    }
    onMounted(async () => {
      const res = await useCheckStoreForConfirmation();
      if (res === "ok") {
        const detail = store.state.confirmation.contractDetail;
        // get delivery info from moving_info_list.new_address_info_list
        for (let moving_info of detail.moving_info_list) {
          for (let info of moving_info.new_address_info_list) {
            let is_new = true;
            const datetime = info.delivery_date + info.delivery_time;
            for (let data of currentInfo.value.delivery_list) {
              if (data.datetime == datetime) {
                data.address_list.push({
                  zipcode: info.zipcode,
                  address: info.address,
                });
                is_new = false;
                break;
              }
            }
            if (is_new) {
              currentInfo.value.delivery_list.push({
                datetime: datetime,
                delivery_date: info.delivery_date,
                delivery_time: info.delivery_time,
                visiting_time: info.visiting_time,
                address_list: [
                  {
                    zipcode: info.zipcode,
                    address: info.address,
                  },
                ],
              });
            }
          }
        }
        //save currentInfo in store
        store.dispatch("confirmation/updateCurrentInfo", currentInfo.value);

        contractDetail.value = { ...currentInfo.value, ...detail };
      }
    });
    return {
      contractDetail,
    };
  },
});
