import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-089d31ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirmation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Steps = _resolveComponent("Steps")!
  const _component_ContactOption = _resolveComponent("ContactOption")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Steps, { items: _ctx.items }, null, 8, ["items"]),
    _createVNode(_component_ContactOption, {
      detail: _ctx.detail,
      hasOption: _ctx.hasOption
    }, null, 8, ["detail", "hasOption"])
  ]))
}