
import BaseButton from "@/components/BaseButton.vue";
import EmailAndPassword from "@/components/account/EmailAndPassword.vue";
import TextBlock from "@/components/account/TextBlock.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { defineComponent, ref } from "vue";
import useRouteTo from "../../js/useRouteTo";
import LabelAndInput from "@/components/account/LabelAndInput.vue";
import InputBlock from "@/components/account/InputBlock.vue";
import ContainerBlock from "@/components/account/ContainerBlock.vue";
import TooltipsPopup from "@/components/account/TooltipsPopup.vue";
// import PrivacyPolicy from "@/components/account/PrivacyPolicy.vue";
import { useStore } from "vuex";
import apis from "@/js/apis";
import errors from "@/js/errors";
import util from "@/js/util";
// import { useStore } from "vuex";

export default defineComponent({
  components: {
    TextBlock,
    EmailAndPassword,
    BaseButton,
    LabelAndInput,
    InputBlock,
    ContainerBlock,
    Logo,
    Footer,
    TooltipsPopup,
    // PrivacyPolicy,
  },
  props: {
    next: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isModal = ref(false);
    const tooltips = ref(
      `<p><h3 class="blue">（1）お客様番号 </h3>ご契約情報を示す固有の10桁の番号です。お見積書の左上に記載されております（以下<span class="red">赤枠</span>部分） <br/><span class="pink">※弊社からお送りするメールなどに記載されている「受付時ID」とは異なりますので、ご注意ください。 </span></p><br/><p><h3 class="blue">（2）お電話番号 </h3><span class="pink">お見積書に記載されているお電話番号</span>をご入力ください（以下<span class="green">緑枠</span>部分）</p><br/><img style='width: 100%; margin-top: 1rem' src='${require("../../assets/addInformation1.png")}' />`
    );
    const { routeTo } = useRouteTo();
    const store = useStore();
    const num = ref("");
    const tel = ref("");
    const errMsg1 = ref("");
    const errMsg2 = ref("");
    const placeholder = ref("ハイフン(-)なし半角数字");
    const input = (value: string, target: string) => {
      let v = util.zenkakuToHankaku(value);
      if (typeof v === "string") {
        v = v.replace(/ー/g, "").replace(/−/g, "").replace(/-/g, "").trim();
      }
      if (target === "num") {
        num.value = v;
      } else {
        tel.value = v;
      }
    };
    if (store.state.device == "mobile") {
      tooltips.value = `<p><h3 class="blue">（1）お客様番号 </h3>ご契約情報を示す固有の10桁の番号です。お見積書の左上に記載されております（以下<span class="red">赤枠</span>部分） <br/><span class="pink">※弊社からお送りするメールなどに記載されている「受付時ID」とは異なりますので、ご注意ください。 </span><img style='width: 100%; margin-top: 1rem' src='${require("../../assets/addInformation.png")}' /></p><p><h3 class="blue">（2）お電話番号 </h3><span class="pink">お見積書に記載されているお電話番号</span>をご入力ください（以下<span class="green">緑枠</span>部分）</p><br/><img style='width: 100%; margin-top: 1rem' src='${require("../../assets/addInformation2.png")}' />`;
    }
    // const store = useStore();
    // const inputEmail = (email: string) => {
    //   store.dispatch("account/inputEmail", email.trim());
    // };
    // const inputPassword = (password: string) => {
    //   store.dispatch("account/inputPassword", password.trim());
    // };
    const submit = async () => {
      // validation
      // email validation
      if (num.value) {
        if (util.validateUkt(num.value)) {
          errMsg1.value = "";
        } else {
          errMsg1.value = "正しい形式で入力してください";
        }
      } else {
        errMsg1.value = "お客様番号を入力してください";
      }

      // password validation
      if (tel.value) {
        if (util.validateTel(tel.value)) {
          errMsg2.value = "";
        } else {
          errMsg2.value = "正しい形式で入力してください";
        }
      } else {
        errMsg2.value = "お電話番号を入力してください";
      }

      if (!errMsg1.value && !errMsg2.value) {
        // start loading
        store.dispatch("startLoading", true);

        // API Call -> POST number and tel
        const body = {
          ukt_no: num.value,
          tel: tel.value,
        };
        const result = await apis.callAPI(
          "post",
          apis.endpoints.postNumber,
          store.state.jwt,
          body
        );
        if (result && result.data.status === "success") {
          const exist = result.data.result.exist;
          if (exist == 1) {
            store.dispatch("startLoading", true);
            // if contract is already registered, go to the mypage after saving info on local storage
            // but first call API to get info to save
            const result = await apis.callAPI(
              "get",
              apis.endpoints.getContracts,
              store.state.jwt
            );
            if (result && result.data.status === "success") {
              for (let i of result.data.results) {
                if (i.ukt_no == num.value || i.ukt_no == "0" + num.value) {
                  localStorage.setItem("selectedNumber", i.contract_id);
                  localStorage.setItem("ukt_no", i.ukt_no);
                  localStorage.setItem("moving_date", i.moving_date);
                  localStorage.setItem("latest_date", i.final_delivery_date);
                  localStorage.setItem(
                    "moving_furniture_date",
                    i.moving_furniture_date
                  );
                  routeTo("MyPage");
                }
              }
            }
          } else if (exist == 3 || exist == 4) {
            // if contract is pending or canceled, go to the menu
            routeTo("MyPageMenu");
          } else {
            // else, go to AddInformationDone page
            routeTo(props.next);
          }
        }
      } else {
        window.scrollTo(0, 0);
      }
    };

    const showModal = () => {
      isModal.value = true;
    };
    const hideModal = () => {
      isModal.value = false;
    };

    return {
      num,
      tel,
      errMsg1,
      errMsg2,
      placeholder,
      input,
      // inputEmail,
      // inputPassword,
      submit,
      showModal,
      hideModal,
      isModal,
      tooltips,
    };
  },
});
