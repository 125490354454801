import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e6c9a21"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "required", "type", "value", "placeholder"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("input", {
      disabled: _ctx.disabled,
      required: _ctx.required,
      style: _normalizeStyle(_ctx.style),
      type: _ctx.type,
      value: _ctx.value,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputValue && _ctx.inputValue(...args))),
      placeholder: _ctx.placeholder
    }, null, 44, _hoisted_1),
    (_ctx.hint)
      ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.hint), 1))
      : _createCommentVNode("", true)
  ], 4))
}