import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contact_detail_arrival_mobile = _resolveComponent("contact-detail-arrival-mobile")!
  const _component_contact_detail_arrival_desktop = _resolveComponent("contact-detail-arrival-desktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_contact_detail_arrival_mobile, {
          key: 0,
          detail: _ctx.contractDetail
        }, null, 8, ["detail"]))
      : (_openBlock(), _createBlock(_component_contact_detail_arrival_desktop, {
          key: 1,
          detail: _ctx.contractDetail
        }, null, 8, ["detail"]))
  ]))
}