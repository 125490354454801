import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a98d81b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full-height clearfix" }
const _hoisted_2 = {
  key: 0,
  class: "login"
}
const _hoisted_3 = { class: "form" }
const _hoisted_4 = { class: "button-wrapper" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "form-pc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_text_block = _resolveComponent("text-block")!
  const _component_email_and_password = _resolveComponent("email-and-password")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_label_and_input = _resolveComponent("label-and-input")!
  const _component_input_block = _resolveComponent("input-block")!
  const _component_container_block = _resolveComponent("container-block")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Logo, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$store.state.device === 'mobile')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_text_block, {
              title: "パスワード変更",
              text: "パスワードをご入力ください。"
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_email_and_password, {
                label1: "現在のパスワード",
                type1: "password",
                placeholder1: "入力してください",
                onInput1: _cache[0] || (_cache[0] = ($event: any) => (_ctx.passwordOld = $event)),
                errMsg1: _ctx.errMsg1,
                label2: "新しいパスワード",
                type2: "password",
                placeholder2: "小文字、大文字、数字を含む8文字以上",
                onInput2: _cache[1] || (_cache[1] = ($event: any) => (_ctx.passwordNew = $event)),
                errMsg2: _ctx.errMsg2,
                margin1: "0rem 2rem 2rem",
                width1: "100%",
                margin2: "2rem 2rem 0rem",
                width2: "100%"
              }, null, 8, ["errMsg1", "errMsg2"]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_base_button, {
                  buttonText: "送信",
                  onClick: _ctx.submit
                }, null, 8, ["onClick"])
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_container_block, {
              title: "パスワード変更",
              text: "新しいパスワードをご入力ください。"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_input_block, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_label_and_input, {
                        label: "現在のパスワード",
                        type: "password",
                        placeholder: "入力してください",
                        margin: "0 0 5.4rem",
                        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.passwordOld = $event)),
                        errMsg: _ctx.errMsg1
                      }, null, 8, ["errMsg"]),
                      _createVNode(_component_label_and_input, {
                        label: "新しいパスワード",
                        type: "password",
                        placeholder: "小文字、大文字、数字を含む8文字以上",
                        margin: "0 0 3.7rem",
                        onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.passwordNew = $event)),
                        errMsg: _ctx.errMsg2
                      }, null, 8, ["errMsg"]),
                      _createVNode(_component_base_button, {
                        buttonText: "送信",
                        width: "34rem",
                        onClick: _ctx.submit
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]))
    ]),
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Footer, {
          key: 1,
          className: "mt"
        }))
      : _createCommentVNode("", true)
  ]))
}