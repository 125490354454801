import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactPriceMobile = _resolveComponent("ContactPriceMobile")!
  const _component_ContactPriceDesktop = _resolveComponent("ContactPriceDesktop")!

  return (_ctx.$store.state.device === 'mobile')
    ? (_openBlock(), _createBlock(_component_ContactPriceMobile, {
        key: 0,
        detail: _ctx.$store.state.confirmation.contractDetail
      }, null, 8, ["detail"]))
    : (_openBlock(), _createBlock(_component_ContactPriceDesktop, {
        key: 1,
        detail: _ctx.$store.state.confirmation.contractDetail
      }, null, 8, ["detail"]))
}