
import { defineComponent } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import util from "@/js/util";

export default defineComponent({
  components: {
    BaseButton,
  },
  props: {
    file: {
      type: Object,
      require: true,
    },
    download: {
      type: Boolean,
      default: false,
    },
    advertise: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["clickDownload", "clickOpen"],
  setup(props, context) {
    const formatDateTime = util.formatDateTime;
    const click = (download: boolean, file: any) => {
      if (download) {
        context.emit("clickDownload", [
          file.bucket_file_key,
          file.title,
          file.content_type,
        ]);
      } else {
        context.emit("clickOpen", [file.link, file.id]);
      }
    };

    return {
      click,
      formatDateTime,
    };
  },
});
