
import { defineComponent } from "vue";
import BaseLabel from "./BaseLabel.vue";
import ExclamationIcon from "@/components/furniture/ExclamationIcon.vue";

export default defineComponent({
  components: { BaseLabel, ExclamationIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    materials: {
      type: Object,
      required: true,
    },
    disableToggle: {
      type: Boolean,
      default: false,
    },
    dataResult: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    failedMessage() {
      return {
        title: "サービス提供エリア外です",
        content:
          "大変申し訳ございませんが、この住所はサービスの提供エリア外となっております。ご理解賜りますようお願い申し上げます。",
      };
    },
    failedV2Message() {
      const branchInfo = this.dataResult.branch_info;
      return {
        title: "引越マイページ対応エリア外です",
        content: `この住所への配達依頼は、引越マイページではお受けすることができません。<br/>大変お手数ではございますが、以下の番号までお問合せくださいますようお願い申し上げます。<br/><h2 class="red text-center text-underline">電話：<a class="red" href="tel:${branchInfo.tel}">${branchInfo.tel}</a></h2>`,
      };
    },
    getMessage(option = 0) {
      if (option == 1) {
        return this.failedMessage();
      } else {
        return this.failedV2Message();
      }
    },
    getDeliveryOption(option = 0) {
      const delivery = ["設定なし", "玄関先", "ガレージ", "希望しない"];
      return delivery[option];
    },
    getRequestLabel(result: any) {
      const selectRequests = [
        { id: "request1", label: "到着前に連絡を希望します", value: "1A01" },
        {
          id: "request2",
          label: "到着時、管理人（コンシェルジュ）の方への声掛けを希望します",
          value: "1A02",
        },
      ];
      const request_list = selectRequests.filter(function (el: any) {
        return result.request.indexOf(el.value) >= 0;
      });
      let items = (request_list || []).map(function (el: any) {
        return el["label"];
      });
      if (items.length > 0) {
        return items.join(", ");
      }
      return "";
    },
  },
});
