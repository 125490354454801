
import { defineComponent } from "vue";
import BaseButton from "./../account/BaseButton.vue";
import Card from "./Card.vue";

export default defineComponent({
  name: "FurnitureService",
  components: {
    BaseButton,
    Card,
  },
  emits: ["click"],
});
