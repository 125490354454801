import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bdacf03"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs container" }
const _hoisted_2 = ["href", "target", "rel"]
const _hoisted_3 = {
  key: 0,
  class: "floaty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
      return _renderSlot(_ctx.$slots, "default", {
        key: item.label
      }, () => [
        (item.target)
          ? (_openBlock(), _createElementBlock("a", {
              key: item.label,
              href: item.link,
              target: item.target,
              rel: item.rel,
              class: "tab-item"
            }, _toDisplayString(item.label), 9, _hoisted_2))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              class: _normalizeClass(["tab-item", { active: item.label == _ctx.active }]),
              to: { name: item.link }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label) + " ", 1),
                (item.floaty && _ctx.file_num > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.file_num), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["class", "to"]))
      ], true)
    }), 128))
  ]))
}