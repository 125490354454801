
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    buttonText: {
      type: String,
      default: "配達日時変更",
    },
    height: {
      type: String,
      default: "6",
    },
    color: {
      type: String,
      default: "#0060b9",
    },
    bgColor: {
      type: String,
      default: "#e5eff8",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  setup(props) {
    const style = computed(() => {
      let opacity = 1.0;
      if (props.disabled) {
        opacity = 0.5;
      }
      return {
        height: props.height + "rem",
        "background-color": props.bgColor,
        "--color": props.color,
        opacity: opacity,
      };
    });
    return {
      style,
    };
  },
});
