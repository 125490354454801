
import { defineComponent, ref } from "vue";
import BaseButton from "./../account/BaseButton.vue";
import useRouteTo from "../../js/useRouteTo";
import Select from "@/components/goods/SelectAttribute.vue";
import ProductSlider from "@/components/goods/ProductSlider.vue";

import { useStore } from "vuex";

import GoodsMixin from "@/mixins/goods.vue";
import util from "@/js/util";

export default defineComponent({
  components: {
    BaseButton,
    Select,
    ProductSlider
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    dataResult: {
      type: Object,
      required: true,
    },
    bookedGoods: {
      type: Object,
      default() {
        return {};
      },
    },
    variant_id: {
      type: String,
      default: "",
    },
  },
  mixins: [GoodsMixin],
  emits: ["clickReserve"],
  data() {
    return {
      selectedAttributes: [] as any[],
      quantity: Array.from({length:99}, (u: any, i: any) => ({ name: i+1})),
      buttonTitle: '商品を予約する',
      attributes: [] as any[]
    }
  },
  watch: {
    bookedGoods: function (val) {
      this.refillBooked(val, this.selectedVariant)
    },
    changeData: {
      handler: function(val) {
        const {dataResult, variant_id, bookedGoods} = val
        if (dataResult) {
          this.$store.dispatch("goods/setQuantity", 1);
          this.selectedQuantity = 1

          if (dataResult.attributes && dataResult.attributes.length > 0) {
            for(var i = 0; i < dataResult.attributes.length; i++) {
              this.selectAttributes(dataResult.attributes[i], dataResult.attributes[i]["options"][0]['name'], variant_id)
              this.attributes[dataResult.attributes[i]['name']] = this.setSelectedAttribute(variant_id, bookedGoods, dataResult.attributes[i])
            }
          } else {
            this.selectedVariant = dataResult
            this.checkInventory(this.selectedVariant)
            this.$store.dispatch("goods/setItem", this.selectedVariant);
          }
        }
      },
      deep: true
    },
  },
  computed: {
    changeData() {
      const { dataResult, variant_id, bookedGoods } = this
      return {
        dataResult,
        variant_id,
        bookedGoods
      }
    }
  },
  
  methods: {
    checkInventory: function (val: any) {
      if (val.inventory_status == "waiting") {
        this.errorVariant = '入荷待ちの商品です'
      } else if (val.inventory_status == "sold_out") {
        this.errorVariant = '在庫切れの商品です'
      } else {
        this.errorVariant = ''
      }
    },
    refillBooked(items: any[], product: any) {
      let selectedProduct = items.filter((c: any) => c.product.id == product.id)
      if (selectedProduct.length > 0) {
        this.$store.dispatch("goods/setQuantity", selectedProduct[0].lots);
        this.selectedQuantity = selectedProduct[0].lots
        this.buttonTitle = '商品数を変更する'
      } else {
        this.buttonTitle = '商品を予約する'
      }
    },
    findVariants(label: any, attribute: any, variants: any) {
      let items: any = []
      for(var i = 0; i < variants.length ; i++) {
        let result = variants[i].attributes.filter((c: any) => c.name == label && c.value == attribute)
        if (result && result.length > 0) {
          if (variants[i].status == "published")
            items.push(variants[i])
        }
      }
      return items
    },
    selectAttributes(attribute: any, value: any, variant_id: any) {
      this.selectedAttributes[attribute['name']] = value
      const selectedKeys = Object.keys(this.selectedAttributes)
      let selectedItem: any = this.dataResult.variants
      
      if (variant_id) {
        selectedItem = selectedItem.filter((c: any) => c.id == variant_id)
      } else {
        for(var j = 0; j < selectedKeys.length; j++) {
          let key: any = selectedKeys[j]
          selectedItem = this.findVariants(key, this.selectedAttributes[key], selectedItem)
        }
      }

      if (selectedItem.length == 0) {
        this.errorVariant = '現在この商品は取り扱っておりません'
        this.selectedVariant = null
        this.$store.dispatch("goods/setItem", {});
      } else {
        this.errorVariant = ''
        this.selectedVariant = selectedItem[0]
        this.checkInventory(this.selectedVariant)
        this.$store.dispatch("goods/setItem", this.selectedVariant);
      }
      this.refillBooked(this.bookedGoods, this.selectedVariant)
    },
    setSelectedAttribute(variant_id: any, bookedGoods: any, attribute: any) {
      let index: any = 0
      if (variant_id && bookedGoods) {
        let selectedVariant: any = bookedGoods.filter((c: any) => c.product.id == variant_id)
        if (selectedVariant.length > 0) {
          let selectedAttirbute: any = selectedVariant[0].product.attributes.filter((c: any) => c.name == attribute.name && c.type == attribute.label)
          if (selectedAttirbute.length > 0) {
            index = attribute.options.findIndex((c: any) => c.name == selectedAttirbute[0].value)
          }
        }
      }
      
      return index
    }
  },
  setup(props, context) {
    const store = useStore();
    const { routeTo } = useRouteTo();

    const errMsg1 = ref("");
    const errMsg2 = ref("");
    const errMsg3 = ref("");

    let selectedSize: any = store.state.goods.size;
    let selectedSheet: any = store.state.goods.sheet;
    let selectedQuantity: any = store.state.goods.quantity;

    const selectSize = (item: string) => {
      store.dispatch("goods/setSize", item);
      selectedSize = item;
    };
    const selectSheet = (item: string) => {
      store.dispatch("goods/setSheet", item);
      selectedSheet = item;
    };
    const selectQuantity = (item: number) => {
      store.dispatch("goods/setQuantity", item);
      selectedQuantity = item;
    };

    const updateItem = () => {
      if (selectedSize) {
        errMsg1.value = "";
      } else {
        errMsg1.value = "選択してください";
      }
      if (selectedSheet) {
        errMsg2.value = "";
      } else {
        errMsg2.value = "選択してください";
      }

      if (selectedQuantity > 0) {
        errMsg3.value = "";
      } else {
        errMsg3.value = "選択してください";
      }

      if (!errMsg1.value && !errMsg2.value && !errMsg3.value) {
        store.dispatch("goods/resetOrderList");
        let order_list: any[] = store.state.goods.order_list
        const findIndexCurrentProduct = order_list.findIndex((el: any) => el.product_id == store.state.goods.item.id)
        if (findIndexCurrentProduct >= 0) {
          order_list[findIndexCurrentProduct]["lots"] = store.state.goods.quantity * 1
        } else {
          order_list.push({
            "product_id": store.state.goods.item.id,
            "lots": store.state.goods.quantity * 1
          })
        }
        store.dispatch("goods/setOrderList", order_list);
        context.emit("clickReserve");
      } else {
        window.scrollTo(0, 0);
      }
    };
    return {
      updateItem,
      selectSize,
      selectSheet,
      selectQuantity,
      errMsg1,
      errMsg2,
      errMsg3,
    };
  },
});
