
import { computed, defineComponent } from "vue";
import BaseLabel from "./BaseLabel.vue";

export default defineComponent({
  components: { BaseLabel },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    marginBottom: {
      type: String,
      default: "2",
    },
  },
  setup(props) {
    const style = computed(() => {
      return {
        "margin-bottom": props.marginBottom + "rem",
      };
    });
    return {
      style,
    };
  },
});
