
import apis from "@/js/apis";
import useRouteTo from "@/js/useRouteTo";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import util from "@/js/util";

import ReservedDesktop from "./partials/ReservedDesktop.vue";
import ReservedMobile from "./partials/ReservedMobile.vue";

export default defineComponent({
  components: { ReservedDesktop, ReservedMobile },
  props: {
    mode: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const contract_id = ref("");
    const bookedGoods = ref({}) as any;
    const reserveFlag = ref(false);
    const deleteFlag = ref(false);
    const selectedGoodId: any = ref(null);

    const isDelete = () => {
      util.openAlert(store, "OK", "Cancel", "削除してよろしいですか？", confirmDelete)
    };

    onMounted(async () => {
      contract_id.value = localStorage.getItem("selectedNumber") as string;
      // check if contract_id is not null or empty
      if (!contract_id.value) {
        routeTo("MyPageMenu");
      } else {
        // get booked goods
        const result = await apis.callAPI(
          "get",
          apis.endpoints.getBookedGoods.replace("{}", contract_id.value),
          store.state.jwt
        );

        // sample
        // const result = {
        //   data: {
        //     status: "success",
        //     result: {
        //       payment_amount_with_tax: 880,
        //       booking_contents: [
        //         {
        //           price_amount: 1600,
        //           tax_rate: 0.1,
        //           lots: 2,
        //           product: {
        //             id: 1,
        //             product_type: "standalone",
        //             parent_id: "",
        //             name: "name",
        //             subtitle: "subtitle",
        //             image_url:
        //               "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
        //             attributes: [
        //               {
        //                 name: "サイズ",
        //                 type: "label",
        //                 value: "Ｓサイズ（50×50×5mm）",
        //               },
        //               {
        //                 name: "枚数",
        //                 type: "label",
        //                 value: "4枚入り",
        //               },
        //             ],
        //           },
        //         },
        //       ],
        //     },
        //   } as any,
        // };

        if (result && result.data.status === "success") {
          bookedGoods.value = result.data.result;
          // check if coming after ordering
          if (props.mode == "reserve") {
            reserveFlag.value = true;
            setTimeout(() => {
              reserveFlag.value = false;
            }, 500);
          }
        }
      }
    });

    const confirmDelete = async () => {
      util.closeAlert(store)
      const goods_id = selectedGoodId.value
      // call api
      const result = await apis.delete(
        apis.endpoints.deleteGoods.replace("{}", contract_id.value),
        store.state.jwt,
        {
          product_ids: [goods_id],
        }
      );

      // sample
      // const result = "success";

      if (result === "success") {
        // get booked goods
        const result = await apis.callAPI(
          "get",
          apis.endpoints.getBookedGoods.replace("{}", contract_id.value),
          store.state.jwt
        );

        // sample
        // const result = {
        //   data: {
        //     status: "success",
        //     result: {
        //       payment_amount_with_tax: 880,
        //       booking_contents: [
        //         {
        //           price_amount: 1600,
        //           tax_rate: 0.1,
        //           lots: 2,
        //           product: {
        //             id: 1,
        //             name: "name",
        //             subtitle: "subtitle",
        //             image_url:
        //               "https://image.mrtist.dev.resola.net/product_images/440f6cbce0574fbe809c446f20f1309a",
        //             attributes: [
        //               {
        //                 name: "サイズ",
        //                 type: "label",
        //                 value: "Ｓサイズ（50×50×5mm）",
        //               },
        //               {
        //                 name: "枚数",
        //                 type: "label",
        //                 value: "4枚入り",
        //               },
        //             ],
        //           },
        //         },
        //       ],
        //     },
        //   } as any,
        // };

        if (result && result.data.status === "success") {
          bookedGoods.value = result.data.result;
          // show a message or a popup
          deleteFlag.value = true;
          reserveFlag.value = false;
          setTimeout(() => {
            deleteFlag.value = false;
          }, 500);
          window.scrollTo(0, 0);
        }
        selectedGoodId.value = null
      }
    };

    const clickDelete = async (goods_id: number) => {
      const ukt_no = (localStorage.getItem("ukt_no") as string) || "";
      const moving_date = (localStorage.getItem("moving_date") as string) || "";
      // check if ukt_no and moving_date are not empty
      if (!ukt_no || !moving_date) {
        routeTo("MyPageMenu");
      } else {
        // check if today is more than one day before moving_date
        let the_day_before_moving_date = new Date(moving_date + "T00:00");
        the_day_before_moving_date.setDate(
          the_day_before_moving_date.getDate() - 1
        );
        if (
          new Date(
            new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
          ) >= the_day_before_moving_date
        ) {
          util.openAlert(store, "OK", "", "直近の変更に関してはお手数ですが担当支店にご連絡をお願いいたします。または当日作業スタッフに直接お申し出ください。<br/>" +
              bookedGoods.value.branch_info.name +
              "支店<br/><a href='tel:"+bookedGoods.value.branch_info.tel+"'>" +
              bookedGoods.value.branch_info.tel + "</a>", () => util.closeAlert(store))
        } else {
          selectedGoodId.value = goods_id
          isDelete()
        }
      }
    };

    const click = () => {
      routeTo("GoodListing");
    };

    return {
      bookedGoods,
      clickDelete,
      click,
      reserveFlag,
      deleteFlag
    };
  },
});
