
import ContactPacking from "@/components/confirmation/desktop/ContactPacking.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { defineComponent } from "vue";

export default defineComponent({
  components: { ContactPacking, Logo, Footer },
  props: {
    detail: {
      type: Object,
    },
  },
});
