
import BaseButton from "@/components/BaseButton.vue";
import ContractBlock from "@/components/mypage/ContractBlock.vue";
import TooltipsPopup from "@/components/account/TooltipsPopup.vue";
import { defineComponent, onMounted, ref, inject } from "vue";
import useRouteTo from "@/js/useRouteTo";
import logout from "@/js/logout";
import useGoTo from "@/js/useGoTo";
import { useStore } from "vuex";
import util from "@/js/util";
import saveLocalStorage from "@/js/saveLocalStorage";
import openAQC from "@/js/openAQC";

export default defineComponent({
  components: { ContractBlock, BaseButton, TooltipsPopup },
  props: {
    contractData: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      default: "",
    },
  },
  emits: ["click", "delete"],
  setup() {
    const store = useStore();
    const clause = inject("clause") as any;
    const isModal = ref(false);
    const tooltips = ref(
      `<p><h3 class="blue">（1）お客様番号 </h3>ご契約情報を示す固有の10桁の番号です。お見積書の左上に記載されております（以下<span class="red">赤枠</span>部分） <br/><span class="pink">※弊社からお送りするメールなどに記載されている「受付時ID」とは異なりますので、ご注意ください。 </span><img style='width: 100%; margin-top: 1rem' src='${require("../../../assets/addInformation.png")}' /></p><p><h3 class="blue">（2）お電話番号 </h3><span class="pink">お見積書に記載されているお電話番号</span>をご入力ください（以下<span class="green">緑枠</span>部分）</p><br/><img style='width: 100%; margin-top: 1rem' src='${require("../../../assets/addInformation2.png")}' />`
    );
    const done = ref(false);
    const questionnaireText = ref("受付・営業担当者アンケート");

    const { routeTo } = useRouteTo();
    const { goTo } = useGoTo();

    const showModal = () => {
      isModal.value = true;
    };
    const hideModal = () => {
      isModal.value = false;
    };

    const isLogout = () => {
      util.openAlert(store, "OK", "Cancel", "ログアウトしますか?", logout);
    };

    const questionnaireAlert = () => {
      util.openAlert(
        store,
        "",
        "",
        "引越日当日にお渡しするスマイルカードにご協力お願いいたします。",
        () => util.closeAlert(store)
      );
    };

    const clickQuestionnaire = async () => {
      if (done.value) {
        questionnaireAlert();
      } else {
        // AQC
        await openAQC();
      }
    };

    const openLink = (link: string) => {
      window.open(link);
    };

    onMounted(() => {
      try {
        // check if today is newer than the moving date
        const moving_date =
          (localStorage.getItem("moving_date") as string) || "";
        if (moving_date) {
          let target = new Date(moving_date + "T00:00");
          if (
            new Date(
              new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
            ) >= target
          ) {
            done.value = true;
            questionnaireText.value = "作業品質アンケート";
          }
        }
      } catch {
        console.log("can't access localstorage");
      }
    });

    return {
      routeTo,
      goTo,
      logout,
      showModal,
      hideModal,
      isModal,
      tooltips,
      questionnaireText,
      clickQuestionnaire,
      isLogout,
      openLink,
      clause,
    };
  },
});
