
import util from "@/js/util";
import { computed, defineComponent } from "vue";
import ContentsBlock from "./ContentsBlock.vue";

export default defineComponent({
  components: { ContentsBlock },
  props: {
    type: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const computedItem = computed(() => {
      if (props.type === "old") {
        let obj = {
          住所: "〒" + props.item.zipcode + "<br>" + props.item.address,
        } as any;
        if (props.item.packing_date) {
          obj["梱包作業日（荷造り）"] =
            util.getJapaneseDate(props.item.packing_date) +
            " " +
            props.item.packing_time;
        } else if (props.item.bf_packing_date) {
          obj["前日積込み作業日（よい積み）"] =
            util.getJapaneseDate(props.item.bf_packing_date) +
            " " +
            props.item.bf_packing_time;
        }
        return obj;

        // return {
        //   住所: "〒" + props.item.zipcode + "<br>" + props.item.address,
        //   "梱包作業日（荷造り）":
        //     util.getJapaneseDate(props.item.packing_date) +
        //     " " +
        //     props.item.packing_time,
        // };
      } else {
        let visiting_time = "";
        if (props.item.visiting_time) {
          visiting_time = props.item.visiting_time;
        } else {
          visiting_time = props.item.delivery_time;
        }
        return {
          住所: "〒" + props.item.zipcode + "<br>" + props.item.address,
          届け日:
            util.getJapaneseDate(props.item.delivery_date) +
            " " +
            visiting_time,
          "解梱作業日（荷解き）":
            util.getJapaneseDate(props.item.unpacking_date) +
            " " +
            props.item.unpacking_time,
        };
      }
    });
    return {
      computedItem,
    };
  },
});
