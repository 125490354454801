import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_information_mobile = _resolveComponent("information-mobile")!
  const _component_information_desktop = _resolveComponent("information-desktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_information_mobile, {
          key: 0,
          moving_info_list: _ctx.detail.moving_info_list || []
        }, null, 8, ["moving_info_list"]))
      : (_openBlock(), _createBlock(_component_information_desktop, {
          key: 1,
          moving_info_list: _ctx.detail.moving_info_list || []
        }, null, 8, ["moving_info_list"]))
  ]))
}