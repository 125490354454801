import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionDetailMobile = _resolveComponent("CollectionDetailMobile")!
  const _component_CollectionDetailDesktop = _resolveComponent("CollectionDetailDesktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_CollectionDetailMobile, {
          key: 0,
          collections: _ctx.new_collection_list,
          over3months: _ctx.over3months,
          onClickEdit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickEdit($event))),
          onClickNewApply: _ctx.clickNewApply
        }, null, 8, ["collections", "over3months", "onClickNewApply"]))
      : (_openBlock(), _createBlock(_component_CollectionDetailDesktop, {
          key: 1,
          collections: _ctx.new_collection_list,
          over3months: _ctx.over3months,
          onClickEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickEdit($event))),
          onClickNewApply: _ctx.clickNewApply
        }, null, 8, ["collections", "over3months", "onClickNewApply"]))
  ]))
}