
import ContactDetail from "@/components/confirmation/ContactDetailArrival.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { ContactDetail },
  props: {
    detail: {
      type: Object,
    },
  },
});
