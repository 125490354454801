
import ConfirmationView from "@/components/furniture/desktop/FurnitureLineView.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import BaseButton from "@/components/BaseButton.vue";

import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { ConfirmationView, Logo, Footer, BaseButton },
  props: {
    furnitures: {
      type: Object,
    },
    status: {
      type: String,
      default: ""
    },
  },
  data: function () {
    return {
      furniture: {},
      msg: "",
    };
  },
  emits: ["clickEdit", "clickNewApply"],
  created() {
    const store = useStore();
  },
  setup(props, context) {
    const click = (data: any) => {
      console.log("click")
    };
    return {
      click,
    };
  },
});
