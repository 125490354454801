
import BaseButton from "@/components/BaseButton.vue";
import EmailAndPassword from "@/components/account/EmailAndPassword.vue";
import InputBlock from "@/components/account/InputBlock.vue";
import { defineComponent, ref } from "vue";
import useRouteTo from "../../js/useRouteTo";
import { useStore } from "vuex";
import util from "../../js/util";
import ErrorMessageBlock from "@/components/ErrorMessageBlock.vue";
import LabelAndInput from "@/components/account/LabelAndInput.vue";
import PrivacyPolicy from "@/components/account/PrivacyPolicy.vue";
import ContainerBlock from "@/components/account/ContainerBlock.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { useRoute } from "vue-router";
import apis from "../../js/apis";
import errors from "@/js/errors";

export default defineComponent({
  components: {
    EmailAndPassword,
    InputBlock,
    BaseButton,
    ErrorMessageBlock,
    LabelAndInput,
    PrivacyPolicy,
    ContainerBlock,
    Logo,
    Footer,
  },
  setup() {
    const { routeTo } = useRouteTo();
    const route = useRoute();
    const store = useStore();
    const email = ref("");
    const password = ref("");
    const privacyPolicy = ref(false);
    const errMsg1 = ref("");
    const errMsg2 = ref("");
    const errMsg3 = ref("");
    const loginLink = ref(
      '<a href="/#/account/login" class="bullet pink">【アカウントをお持ちの方はこちら】</a>'
    );
    const helpLink = ref(
      '<a href="/#/account/help" class="bullet pink">【アカウント作成・ログインでお困りの方はこちら】</a>'
    );

    // const store = useStore();
    // const inputEmail = (email: string) => {
    //   store.dispatch("account/inputEmail", email.trim());
    // };
    // const inputPassword = (password: string) => {
    //   store.dispatch("account/inputPassword", password.trim());
    // };
    const login = () => {
      routeTo("Login");
    };

    const help = () => {
      routeTo("Help");
    };

    const submit = async () => {
      console.log("click");

      // validation
      // email validation
      if (!util.validateEmail(email.value)) {
        errMsg1.value = "";
      } else {
        errMsg1.value = util.validateEmail(email.value);
      }
      // password validation
      if (util.validatePassword(password.value)) {
        errMsg2.value = "";
        // password validation /
        if (password.value.indexOf('\\') >= 0) {
          errMsg2.value = "\\は使用できません";
        }
      } else {
        errMsg2.value =
          "小文字、大文字、数字を必ず含む8文字以上で入力してください";
      }
      
      // privacy policy validation
      if (privacyPolicy.value) {
        errMsg3.value = "";
      } else {
        errMsg3.value = "プライバシーポリシーに同意してください";
      }

      if (!errMsg1.value && !errMsg2.value && !errMsg3.value) {
        // start loading
        store.dispatch("startLoading", true);

        let line_user_id = "";
        // Get query params

        // this doesn't work on some iPhone
        let params = route.query.line_id;

        if (params) {
          line_user_id = params.toString();
        } else {
          let url = new URL(window.location.href);
          let iosParams = url.searchParams;
          line_user_id = iosParams.get("line_id") || "";
        }

        // API Call -> POST email and password
        const body = {
          email: email.value,
          password: password.value,
          line_user_id: line_user_id,
        };
        const result = await apis.callAPI(
          "post",
          apis.endpoints.register,
          "",
          body
        );
        if (result && result.data.status === "success") {
          routeTo("SendEmailForCreate");
        } else if (result && result.data.status === "error") {
          console.log(
            email.value + ", " + result.status.toString() + ": ",
            JSON.stringify(result.data)
          );
        }
      } else {
        // window.scrollTo(0, 0);
      }
    };
    return {
      email,
      password,
      privacyPolicy,
      errMsg1,
      errMsg2,
      errMsg3,
      // inputEmail,
      // inputPassword,
      submit,
      login,
      loginLink,
      helpLink,
    };
  },
});
