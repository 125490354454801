
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

import BaseButton from "./../BaseButton.vue";
import PriceLabel from "./../furniture/PriceLabel.vue";
import ExclamationIcon from "./../furniture/ExclamationIcon.vue";
import util from "@/js/util";

export default defineComponent({
  name: "ConfrmationFurniture",
  components: {
    PriceLabel,
    BaseButton,
    ExclamationIcon,
  },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    button2Title: {
      type: String,
      default: "",
    },
    dataResult: {
      type: Object,
      required: true,
    },
    msg: {
      type: String,
      default: "",
    },
    boxClass: {
      type: String,
      default: "",
    },
  },
  emits: ["clickEdit"],
  data: function () {
    return {
      className: "",
      contentClass: "content",
      price: 3000,
    };
  },
  watch: {
    "dataResult.is_free": function (value) {
      if (value == 1) {
        this.className = "";
      } else {
        this.className = "red";
      }
    },
  },
  created() {
    if (this.dataResult.is_free !== 1) {
      this.className = "red";
    }
    if (
      new Date(this.dataResult.kaishu_date + "T00:00") <=
      new Date(new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" }))
    ) {
      this.contentClass += " opacity";
    }
  },
  methods: {
    filterCurrency(value: string) {
      return util.getPrice(value, false) + "円（税込）";
    },
    getPaymentMethod(value: any) {
      const methods: { [key: string]: any } = {
        "1": "現金",
        "2": "カード",
        "5": "Pay支払い",
      };
      return methods[value] ? methods[value] : "";
    },
    getRequestLabel(result: any) {
      let request_list = [
        
        { id: "request1", label: "到着前に連絡を希望します", value: "2A02" },
        {
          id: "request2",
          label: "到着時、管理人（コンシェルジュ）の方への声掛けを希望します",
          value: "2A03",
        },
        {
          id: "request3",
          label:
            "不在でも引き取りを希望します【※玄関先に資材を出しておく必要があります】",
          value: "2A01",
        },
        { id: "request0", label: "要望なし", value: "0" },
      ];
      request_list = request_list.filter(function (el: any) {
        return result.request.indexOf(el.value) >= 0;
      });
      let items = (request_list || []).map(function (el: any) {
        return el["label"];
      });
      if (items.length > 0) {
        return items.join(", ");
      }
      return "";
    },
  },
  setup() {
    const store = useStore();
    const buttonWidth = computed(() => {
      return store.state.device == "mobile" ? "100%" : "26.55vw";
    });
    return {
      buttonWidth,
    };
  },
});
