
import { defineComponent, inject } from "vue";
import { useStore } from "vuex";
import Checkbox from "@/components/furniture/Checkbox.vue";
import BaseButton from "@/components/BaseButton.vue";
import useRouteTo from "../../../js/useRouteTo";
import { useRoute } from "vue-router";
import BranchTel from "./BranchTel.vue";
import util from "@/js/util";

export default defineComponent({
  components: { Checkbox, BaseButton, BranchTel },
  props: {
    contact: {
      type: Object,
    },
  },
  data: function () {
    return {
      disabled: true,
      checkboxes: [
        { id: "checkbox0", label: "上記の内容を確認しました", value: 1 },
      ] as any,
    };
  },
  watch: {
    "contact.moving_info_list": function (newVal, oldVal) {
      let items = [
        { id: "checkbox0", label: "上記の内容を確認しました", value: 1 },
      ];
      for (var i = 0; i < newVal.length; i++) {
        items.push({
          id: "checkbox" + (i + 1),
          label: "上記の内容を確認しました",
          value: 1,
        });
      }
      this.checkboxes = items;
    },
  },
  setup() {
    const { routeTo } = useRouteTo();
    const route = useRoute();

    const clause = inject("clause") as any;

    const click = () => {
      routeTo("ContactOption", route.query);
    };
    const getJapaneseDate = util.getJapaneseDate;
    return {
      clause,
      click,
      getJapaneseDate,
    };
  },
  methods: {
    inputItems(items: string[], index: number) {
      let selected = this.selectedInputItems;
      selected[index] = Array.from(items);
      const findUnchecked = selected.filter((i: any) => i.length == 0);
      this.disabled = findUnchecked.length > 0;

      this.$store.dispatch("confirmation/setConfirmation", selected);
    },
  },
  computed: {
    selectedInputItems() {
      const store = useStore();
      const length =
        this.contact && this.contact.moving_info_list
          ? this.contact.moving_info_list.length + 1
          : 1;
      let selected = store.state.confirmation.confirmation;
      if (selected.length != length) {
        selected = new Array(length).fill([]);
      }
      return selected;
    },
  },
  mounted: function () {
    const store = useStore();
    let selected = store.state.confirmation.confirmation;
    const findUnchecked = selected.filter((i: any) => i);
    this.disabled = findUnchecked.length == 0;
  },
  emits: ["click"],
});
