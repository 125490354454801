import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

// For wrapper component for transition
import Main from "../views/Main.vue";

// For account
import CreateAccount from "../views/account/CreateAccount.vue";
import CreateAccountDone from "../views/account/CreateAccountDone.vue";
import Login from "../views/account/Login.vue";
import AddInformation from "../views/account/AddInformation.vue";
import AddInformationDone from "../views/account/AddInformationDone.vue";
import ForgotPassword from "../views/account/ForgotPassword.vue";
import PasswordReset from "../views/account/PasswordReset.vue";
import PasswordResetDone from "../views/account/PasswordResetDone.vue";
import SendEmail from "../views/account/SendEmail.vue";
import EditEmail from "../views/account/EditEmail.vue";
import EditEmailDone from "../views/account/EditEmailDone.vue";
import EditPassword from "../views/account/EditPassword.vue";
import MyProfile from "../views/account/MyProfile.vue";
import Help from "../views/account/Help.vue";

// For mypage
import MyPageMain from "../views/mypage/Main.vue";
import MyPage from "../views/mypage/MyPage.vue";
import MyPageInfo from "../views/mypage/Information.vue";
import MyPagePrice from "../views/mypage/Price.vue";
import MyPageMenu from "../views/mypage/Menu.vue";
import NewsList from "../views/mypage/NewsList.vue";
import MyPageInquiries from "../views/mypage/Inquiries.vue";
import MyPageInquiriesForm from "../views/mypage/InquiriesForm.vue";
import MyPageInquiriesConfirmation from "../views/mypage/InquiriesConfirmation.vue";
import MyPageInquiriesSuccess from "../views/mypage/InquiriesSuccess.vue";
import MyPocket from "../views/mypage/MyPocket.vue";

// For furniture
import FurnitureService from "../views/furniture/Service.vue";
import BookingFurniture from "../views/furniture/Booking.vue";
import ConfirmationFurniture from "../views/furniture/Confirmation.vue";
import SuccessFurniture from "../views/furniture/Success.vue";
import BookingInfoFurniture from "../views/furniture/BookingInformation.vue";
import BookingSchedule from "../views/furniture/BookingSchedule.vue";

// For goods
import GoodMain from "../views/goods/Main.vue";
import GoodListing from "../views/goods/Listing.vue";
import GoodDetail from "../views/goods/Detail.vue";
import GoodReserved from "../views/goods/Reserved.vue";

// For delivery
import DeliveryListItem from "../views/delivery/ListItem.vue";
import DeliverySuccess from "../views/delivery/Success.vue";
import DeliverySelectDatetime from "../views/delivery/SelectDatetime.vue";

// For collection
import CollectionService from "../views/collection/Service.vue";
import BookingCollection from "../views/collection/Booking.vue";
import ConfirmationCollection from "../views/collection/Confirmation.vue";
import SuccessCollection from "../views/collection/Success.vue";
import CollectionSchedule from "../views/collection/BookingSchedule.vue";
import CollectionDetail from "../views/collection/CollectionDetail.vue";
import CollectionEdit from "../views/collection/CollectionEdit.vue";
import CancelSuccess from "../views/collection/CancelSuccess.vue";
import EditSuccess from "../views/collection/EditSuccess.vue";

//For paid collection
import CollectionPaidService from "../views/collection/PaidService.vue";

// For confirmation
import ContactSwitch from "../views/confirmation/ContactSwitch.vue";
import ContactDetail from "../views/confirmation/ContactDetail.vue";
import ContactOption from "../views/confirmation/ContactOption.vue";
import ContactPrice from "../views/confirmation/ContactPrice.vue";
import ContactSuccess from "../views/confirmation/ContactSuccess.vue";
import ContactDetailArrival from "../views/confirmation/ContactDetailArrival.vue";
import ContactArrivalSuccess from "../views/confirmation/ContactArrivalSuccess.vue";
import ContactPacking from "../views/confirmation/ContactPacking.vue";
import ContactPackingSuccess from "../views/confirmation/ContactPackingSuccess.vue";

// For error
import ErrorOccurred from "../views/ErrorOccurred.vue";
import apis from "@/js/apis";
import store from "@/store/index";
import globalVariable from "@/main";
import HealthCheck from "../views/HealthCheck.vue";

const routes: Array<RouteRecordRaw> = [
  // For account
  {
    path: "/account",
    name: "AccountMain",
    component: Main,
    children: [
      {
        path: "create",
        name: "CreateAccount",
        component: CreateAccount,
        meta: {
          title: "アカウント作成",
        },
      },
      {
        path: "create/sentemail",
        name: "SendEmailForCreate",
        component: SendEmail,
        props: {
          type: "create",
        },
        meta: {
          title: "アカウント作成",
        },
      },
      {
        path: "create/activate",
        name: "CreateAccountDone",
        component: CreateAccountDone,
        meta: {
          title: "アカウント作成",
        },
      },
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          title: "ログイン",
        },
      },
      {
        path: "add_information",
        name: "AddInformationFromLogin",
        component: AddInformation,
        props: {
          next: "AddInformationDone",
        },
        meta: {
          title: "契約情報追加",
          requiresAuth: true,
        },
      },
      {
        path: "add_information/done",
        name: "AddInformationDone",
        component: AddInformationDone,
        meta: {
          title: "契約情報追加",
        },
      },
      {
        path: "forgetpassword",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: {
          title: "パスワードをお忘れの方へ",
        },
      },
      {
        path: "forgetpassword/sentemail",
        name: "SendEmailForPasswordReset",
        component: SendEmail,
        props: {
          type: "forgetpassword",
        },
        meta: {
          title: "パスワードをお忘れの方へ",
        },
      },
      {
        path: "resetpassword",
        name: "PasswordReset",
        component: PasswordReset,
        meta: {
          title: "パスワード変更",
        },
      },
      {
        path: "resetpassword/done",
        name: "PasswordResetDone",
        component: PasswordResetDone,
        props: {
          type: "reset",
        },
        meta: {
          title: "パスワード変更",
        },
      },
      {
        path: "help",
        name: "Help",
        component: Help,
        meta: {
          title: "アカウント作成／ログインでお困りの方へ",
        },
      },
    ],
  },
  // For mypage
  {
    path: "/mypage",
    name: "MyPageMain",
    component: Main,
    meta: {
      title: "引越マイページ",
      requiresAuth: true,
      phase: 1,
    },
    children: [
      {
        path: "",
        name: "MyPage",
        component: MyPage,
        meta: {
          title: "引越マイページ",
        },
        alias: "/",
      },
      {
        path: "menu",
        name: "MyPageMenu",
        component: MyPageMenu,
        meta: {
          title: "メニュー",
        },
      },
      {
        path: "menu/add_information",
        name: "AddInformationFromMenu",
        component: AddInformation,
        props: {
          next: "AddInformationFromMenuDone",
        },
        meta: {
          title: "契約情報追加",
        },
      },
      {
        path: "menu/add_information/done",
        name: "AddInformationFromMenuDone",
        component: AddInformationDone,
        meta: {
          title: "契約情報追加",
        },
      },
      {
        path: "info",
        name: "MyPageInfo",
        component: MyPageInfo,
        meta: {
          title: "お引越し情報",
        },
      },
      {
        path: "inquiries",
        name: "MyPageInquiries",
        component: MyPageInquiries,
        meta: {
          title: "各種問い合わせ",
        },
      },
      {
        path: "create-inquiries",
        name: "MyPageInquiriesForm",
        component: MyPageInquiriesForm,
        meta: {
          title: "フォームからのお問い合わせ",
        },
      },
      {
        path: "inquiries-confirm",
        name: "MyPageInquiriesConfirmation",
        component: MyPageInquiriesConfirmation,
        meta: {
          title: "フォームからのお問い合わせ",
        },
      },
      {
        path: "inquiries-success",
        name: "MyPageInquiriesSuccess",
        component: MyPageInquiriesSuccess,
        meta: {
          title: "フォームからのお問い合わせ",
        },
      },
      {
        path: "mypocket",
        name: "MyPageMyPocket",
        component: MyPocket,
        meta: {
          title: "マイポケット",
        },
      },
      {
        path: "option",
        name: "MyPagePrice",
        component: MyPagePrice,
        meta: {
          title: "オプションサービス",
        },
      },
      {
        path: "news",
        name: "NewsList",
        component: NewsList,
        meta: {
          title: "すべてのお知らせ",
        },
      },
      {
        path: "profile",
        name: "MyProfile",
        component: MyProfile,
        meta: {
          title: "アカウント情報の確認・変更",
        },
      },
      {
        path: "edit/email",
        name: "EditEmail",
        component: EditEmail,
        meta: {
          title: "メールアドレス変更",
          requiresAuth: true,
        },
      },
      {
        path: "edit/email/sentemail",
        name: "SendEmailForEditEmail",
        component: SendEmail,
        props: {
          type: "editemail",
        },
        meta: {
          title: "メールアドレス変更",
        },
      },
      {
        path: "edit/email/activate",
        name: "EditEmailDone",
        component: EditEmailDone,
        meta: {
          title: "メールアドレス変更",
          requiresAuth: true,
        },
      },
      {
        path: "edit/password",
        name: "EditPassword",
        component: EditPassword,
        meta: {
          title: "パスワード変更",
          requiresAuth: true,
        },
      },
      {
        path: "edit/password/done",
        name: "EditPasswordDone",
        component: PasswordResetDone,
        props: {
          type: "edit",
        },
        meta: {
          title: "パスワード変更",
        },
      },
    ],
  },
  // For furniture
  {
    path: "/furniture",
    name: "FurnitureMain",
    component: Main,
    meta: {
      title: "家具移動サービス",
      requiresAuth: true,
      phase: 3,
    },
    children: [
      {
        path: "welcome",
        name: "FurnitureService",
        component: FurnitureService,
        meta: {
          title: "家具移動サービス",
        },
      },
      {
        path: "booking",
        name: "BookingFurniture",
        component: BookingFurniture,
        meta: {
          title: "家具移動サービスのお手続き",
        },
      },
      {
        path: "schedule",
        name: "BookingScheduleFurniture",
        component: BookingSchedule,
        meta: {
          title: "家具移動サービスのお手続き",
        },
      },
      {
        path: "confirmation",
        name: "ConfirmationFurniture",
        component: ConfirmationFurniture,
        meta: {
          title: "家具移動サービスのお手続き",
        },
      },
      {
        path: "success",
        name: "SuccessFurniture",
        component: SuccessFurniture,
        meta: {
          title: "家具移動サービスのお手続き",
        },
      },
      {
        path: "",
        name: "BookingInfoFurniture",
        component: BookingInfoFurniture,
        meta: {
          title: "家具移動サービス予約確認",
        },
      },
    ],
  },
  // For goods
  {
    path: "/goods",
    name: "GoodMain",
    component: Main,
    meta: {
      title: "グッズ購入",
      phase: 3,
    },
    children: [
      {
        path: "",
        name: "GoodListing",
        component: GoodListing,
        meta: {
          title: "グッズ購入",
          requiresAuth: true,
        },
      },
      {
        path: "detail/:goods_id(\\d+)",
        name: "GoodDetail",
        component: GoodDetail,
        meta: {
          title: "グッズ購入",
          requiresAuth: true,
        },
      },
      {
        path: "preview/detail/:goods_id(\\d+)",
        name: "GoodDetailPreview",
        component: GoodDetail,
        meta: {
          title: "グッズ購入プレビュー",
        },
      },
      {
        path: "reserved",
        name: "GoodReserved",
        component: GoodReserved,
        props: true,
        meta: {
          title: "グッズ購入",
          requiresAuth: true,
        },
      },
    ],
  },
  // For delivery
  {
    path: "/delivery",
    name: "DeliveryMain",
    component: Main,
    meta: {
      title: "ダンボール配達",
      requiresAuth: true,
      phase: 2,
    },
    children: [
      {
        path: "",
        name: "DeliveryListItem",
        component: DeliveryListItem,
        meta: {
          title: "ダンボール配達",
        },
      },
      {
        path: "select_datetime",
        name: "DeliverySelectDatetime",
        component: DeliverySelectDatetime,
        meta: {
          title: "ダンボール配達の日時変更",
        },
      },
      {
        path: "success",
        name: "DeliverySuccess",
        component: DeliverySuccess,
        props: {
          success: true,
        },
        meta: {
          title: "ダンボール配達",
        },
      },
    ],
  },
  // For collection
  {
    path: "/collection",
    name: "CollectionMain",
    component: Main,
    meta: {
      title: "ダンボール回収サービス",
      requiresAuth: true,
      phase: 2,
    },
    children: [
      {
        path: "welcome",
        name: "CollectionService",
        component: CollectionService,
        meta: {
          title: "ダンボール回収サービス",
        },
      },
      {
        path: "booking",
        name: "BookingCollection",
        component: BookingCollection,
        meta: {
          title: "ダンボール回収サービスのお手続き",
        },
      },
      {
        path: "schedule",
        name: "CollectionSchedule",
        component: CollectionSchedule,
        meta: {
          title: "ダンボール回収サービスのお手続き",
        },
      },
      {
        path: "confirmation",
        name: "ConfirmationCollection",
        component: ConfirmationCollection,
        meta: {
          title: "ダンボール回収サービスのお手続き",
        },
      },
      {
        path: "success",
        name: "SuccessCollection",
        component: SuccessCollection,
        meta: {
          title: "ダンボール回収サービスのお手続き",
        },
      },
      {
        path: "",
        name: "CollectionDetail",
        component: CollectionDetail,
        meta: {
          title: "ダンボール回収サービスの予約確認",
        },
      },
      {
        path: "edit",
        name: "EditCollection",
        component: CollectionEdit,
        meta: {
          title: "予約内容変更・取消",
        },
      },
      {
        path: "edit/success",
        name: "EditCollectionSuccess",
        component: EditSuccess,
        props: {
          msg: true,
        },
        meta: {
          title: "ダンボール回収予定",
        },
      },
      {
        path: "cancel",
        name: "CancelSuccess",
        component: CancelSuccess,
        meta: {
          title: "ダンボール回収予定",
        },
      },
      //For paid collection
      {
        path: "paid",
        name: "CollectionPaidService",
        component: CollectionPaidService,
        meta: {
          title: "ダンボール回収サービス",
        },
      },
    ],
  },
  // For confirmation
  {
    path: "/confirmation",
    name: "ContactMain",
    component: Main,
    meta: {
      title: "契約内容の最終確認",
      requiresAuth: true,
      phase: 1,
    },
    children: [
      {
        path: "",
        name: "ContactSwitch",
        component: ContactSwitch,
        meta: {
          title: "契約内容の最終確認",
        },
      },
      {
        path: "detail",
        name: "ContactDetail",
        component: ContactDetail,
        meta: {
          title: "契約内容の最終確認",
        },
      },
      {
        path: "option",
        name: "ContactOption",
        component: ContactOption,
        meta: {
          title: "契約内容の最終確認",
        },
      },
      {
        path: "price",
        name: "ContactPrice",
        component: ContactPrice,
        meta: {
          title: "契約内容の最終確認",
        },
      },
      {
        path: "success",
        name: "ContactSuccess",
        component: ContactSuccess,
        meta: {
          title: "契約内容の最終確認",
        },
      },
      {
        path: "arrival_date",
        name: "ContactDetailArrival",
        component: ContactDetailArrival,
        meta: {
          title: "お届け日時の確認",
        },
      },
      {
        path: "arrival_date/success",
        name: "ContactArrivalSuccess",
        component: ContactArrivalSuccess,
        meta: {
          title: "お届け日時の確認",
        },
      },
      {
        path: "packing",
        name: "ContactPacking",
        component: ContactPacking,
        meta: {
          title: "梱包（箱詰め）作業のご案内",
        },
      },
      {
        path: "packing/success",
        name: "ContactPackingSuccess",
        component: ContactPackingSuccess,
        meta: {
          title: "梱包（箱詰め）作業のご案内",
        },
      },
    ],
  },
  // For error
  {
    path: "/error",
    name: "ErrorOccurred",
    component: ErrorOccurred,
    props: true,
    meta: {
      title: "エラー",
    },
  },
  // For health check
  {
    path: "/health",
    name: "HealthCheck",
    component: HealthCheck,
    props: true,
    meta: {
      title: "Health",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ top: 0 });
        }
      }, 200);
    });
  },
});

export default router;

router.beforeEach((to, from) => {
  if (!navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    // if (from.name !== "MyPageMenu") {
    store.dispatch("startLoading", true);
    // }
  }
  return true;
});

router.beforeResolve(async (to, from) => {
  // get query params
  const query: any = to.query;

  // check phase
  if (to.meta.phase) {
    const phase = to.meta.phase;
    if (typeof phase == "number" && phase > globalVariable.phase) {
      return { name: "MyPage", query: query };
    }
  }

  // check if cookie is enabled
  if (!navigator.cookieEnabled) {
    if (to.name && to.name !== "ErrorOccurred" && to.name !== "Help") {
      console.log("cookieが無効です");
      return {
        name: "ErrorOccurred",
        query: query,
        params: {
          title: "Cookieを有効にしてください",
          showMsg: "help",
        },
      };
    }
  } else {
    try {
      // check for is_goods_prohibited
      const is_goods_prohibited =
        JSON.parse(localStorage.getItem("is_goods_prohibited") as string) ||
        false;
      if (
        is_goods_prohibited &&
        to.name &&
        to.name.toString().startsWith("Good")
      ) {
        return { name: "MyPage", query: query };
      }

      const latestDate = (localStorage.getItem("latest_date") as string) || "";
      if (latestDate) {
        let done = false;
        let over3months = false;
        let over1year = false;
        let target = new Date(latestDate + "T00:00");
        target.setDate(target.getDate() + 1);
        if (
          new Date(
            new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
          ) >= target
        ) {
          done = true;
          // check if today is newer than 93 days after the latest moving_date
          target = new Date(latestDate + "T00:00");
          target.setDate(target.getDate() + 93);
          if (
            new Date(
              new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
            ) >= target
          ) {
            over3months = true;
            // check if today is newer than 365 days after the latest moving_date
            target = new Date(latestDate + "T00:00");
            target.setFullYear(target.getFullYear() + 1);
            if (
              new Date(
                new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
              ) >= target
            ) {
              over1year = true;
            }
          }
        }

        // for Delivery and Goods
        if (
          (done && to.name && to.name.toString().startsWith("Delivery")) ||
          (done &&
            to.name &&
            to.name.toString().startsWith("Good") &&
            !to.name.toString().includes("Preview"))
        ) {
          return { name: "MyPage", query: query };
        }

        // for Collection
        if (to.name && to.name.toString().includes("Collection")) {
          // get hasCollection
          const hasCollection = JSON.parse(
            (localStorage.getItem("hasCollection") as string) || "false"
          );

          if (!done || (over3months && !hasCollection)) {
            return { name: "MyPage", query: query };
          } else if (over3months && hasCollection) {
            if (
              new Date(
                new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
              ) >= target
            ) {
              over3months = true;
            }
          }
          if (
            (done && to.name && to.name.toString().startsWith("Delivery")) ||
            (done && to.name && to.name.toString().startsWith("Good"))
          ) {
            return { name: "MyPage", query: query };
          }
          if (to.name && to.name.toString().includes("Collection")) {
            // get hasCollection
            const hasCollection = JSON.parse(
              (localStorage.getItem("hasCollection") as string) || "false"
            );

            if (!done || (over3months && !hasCollection)) {
              return { name: "MyPage", query: query };
            } else if (over3months && hasCollection) {
              if (
                to.name === "CollectionService" ||
                to.name === "BookingCollection" ||
                to.name === "CollectionSchedule" ||
                to.name === "ConfirmationCollection" ||
                to.name === "SuccessCollection" ||
                to.name === "CollectionPaidService"
              ) {
                return { name: "CollectionDetail", query: query };
              }
            }
          }
        }

        // for Furniture
        if (to.name && to.name.toString().includes("Furniture")) {
          // get moving_furniture_date
          const moving_furniture_date =
            (localStorage.getItem("moving_furniture_date") as string) || "";
          let is_furniture_canceled: boolean;
          try {
            is_furniture_canceled = JSON.parse(
              localStorage.getItem("is_furniture_canceled") as string
            );
          } catch {
            is_furniture_canceled = false;
          }

          // if over1year -> MyPage
          // if moving_furniture_date is valid and today >= moving_furniture_date -> MyPage
          // if (
          //   over1year ||
          //   (new Date(moving_furniture_date).toString() != "Invalid Date" &&
          //     new Date(
          //       new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
          //     ) >= new Date(moving_furniture_date + "T00:00"))
          if (over1year || is_furniture_canceled) {
            return { name: "MyPage", query: query };
          }
          if (new Date(moving_furniture_date).toString() != "Invalid Date") {
            const target = new Date(moving_furniture_date);
            target.setDate(target.getDate() + 1);
            if (
              new Date(
                new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
              ) >= target
            ) {
              return { name: "MyPage", query: query };
            }
          }
          if (!done) {
            if (
              to.name === "BookingFurniture" ||
              to.name === "BookingScheduleFurniture" ||
              to.name === "ConfirmationFurniture" ||
              to.name === "SuccessFurniture" ||
              to.name === "BookingInfoFurniture"
            ) {
              return { name: "FurnitureService", query: query };
            }
          } else if (moving_furniture_date) {
            if (
              to.name === "FurnitureService" ||
              to.name === "BookingFurniture" ||
              to.name === "BookingScheduleFurniture" ||
              to.name === "ConfirmationFurniture" ||
              to.name === "SuccessFurniture"
            ) {
              return { name: "BookingInfoFurniture", query: query };
            }
          }
        }
      }

      // check if only authenticated users can access
      if (to.meta.requiresAuth) {
        // get query params
        const query: any = to.query;

        // check if this user is authenticated
        let jwt: string;
        try {
          jwt = (localStorage.getItem("jwt") as string) || "";
        } catch {
          jwt = "";
        }
        if (jwt) {
          let success = false;
          const response = await apis
            .get(apis.endpoints.getUserInfo, jwt)
            .then((res) => {
              const status = res.status;
              console.log("before resolve res.status: " + status);
              if (status == 200) {
                store.dispatch("saveJwt", jwt);
                success = true;
                return res.json();
              } else if (status >= 500) {
                throw new Error(res.status.toString());
              } else {
                return false;
              }
            })
            .then((data) => {
              if (data) {
                store.dispatch("account/setEmail", data.result.email);
              }
              return {
                name: "success",
              };
            })
            .catch((reason) => {
              return {
                name: "ErrorOccurred",
                query: {},
                params: { title: reason },
              };
            });
          if (response.name == "ErrorOccurred") {
            return response;
          }
          if (!success) {
            // invalid jwt -> redirect to login
            query.next = to.fullPath;
            return { name: "Login", query: query };
          }
        } else {
          // no jwt -> redirect to login
          query.next = to.fullPath;
          return { name: "Login", query: query };
        }
      }
    } catch {
      console.log("localStorageへのアクセス失敗");
      if (to.name && to.name !== "ErrorOccurred" && to.name !== "Help") {
        return {
          name: "ErrorOccurred",
          query: query,
          params: {
            title: "Cookieを有効にしてください",
            showMsg: "help",
          },
        };
      }
    }
  }
});

router.afterEach((to, from) => {
  store.dispatch("startLoading", false);
  store.dispatch("showErrMsg", "");
  document.title = to.meta.title;

  let transition;
  if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    // console.log("mobile");
    if (
      // -----
      // SLIDE
      // -----

      // Account
      (from.name === "CreateAccount" && to.name === "SendEmailForCreate") ||
      (from.name === "CreateAccountDone" && to.name === "CreateAccount") ||
      (from.name === "CreateAccountDone" && to.name === "Login") ||
      (from.name === "CreateAccount" && to.name === "Login") ||
      (from.name === "Login" && to.name === "CreateAccount") ||
      (from.name === "Login" && to.name === "ForgotPassword") ||
      (from.name === "ForgotPassword" &&
        to.name === "SendEmailForPasswordReset") ||
      (from.name === "PasswordReset" && to.name === "ForgotPassword") ||
      (from.name === "PasswordReset" && to.name === "PasswordResetDone") ||
      (from.name === "PasswordResetDone" && to.name === "Login") ||
      (from.name === "Login" && to.name === "AddInformationFromLogin") ||
      (from.name === "AddInformationFromLogin" &&
        to.name === "AddInformationDone") ||
      (from.name === "Login" && to.name === "Mypage") ||
      (from.name === "Login" && to.name === "MyPageMenu") ||
      (from.name === "Login" && to.name === "Help") ||
      (from.name === "CreateAccount" && to.name === "Help") ||
      // Mypage
      (from.name === "MyPageInfo" && to.name === "MyPagePrice") ||
      (from.name === "MyPageMenu" && to.name === "NewsList") ||
      (from.name === "MyPageMenu" && to.name === "AddInformationFromMenu") ||
      (from.name === "MyPageMenu" && to.name === "MyProfile") ||
      (from.name === "MyPageMenu" && to.name === "MyPageInquiries") ||
      (from.name === "MyPageInquiries" && to.name === "MyPageInquiriesForm") ||
      (from.name === "MyPageInquiriesForm" &&
        to.name === "MyPageInquiriesConfirmation") ||
      (from.name === "MyPageInquiriesConfirmation" &&
        to.name === "MyPageInquiriesSuccess") ||
      (from.name === "MyProfile" && to.name === "EditEmail") ||
      (from.name === "MyProfile" && to.name === "EditPassword") ||
      (from.name === "AddInformationFromMenu" &&
        to.name === "AddInformationFromMenuDone") ||
      // Furniture
      (from.name === "FurnitureService" && to.name === "BookingFurniture") ||
      (from.name === "BookingFurniture" && to.name === "BookingSchedule") ||
      (from.name === "BookingSchedule" &&
        to.name === "ConfirmationFurniture") ||
      (from.name === "ConfirmationFurniture" &&
        to.name === "SuccessFurniture") ||
      (from.name === "FurnitureService" &&
        to.name === "BookingInfoFurniture") ||
      (from.name === "BookingInfoFurniture" &&
        to.name === "BookingFurniture") ||
      // Goods
      (from.name === "GoodListing" && to.name === "GoodDetail") ||
      (from.name === "GoodListing" && to.name === "GoodReserved") ||
      (from.name === "GoodDetail" && to.name === "GoodReserved") ||
      // Delivery
      (from.name === "DeliveryListItem" &&
        to.name === "DeliverySelectDatetime") ||
      (from.name === "DeliverySelectDatetime" &&
        to.name === "DeliverySuccess") ||
      (from.name === "DeliverySuccess" && to.name === "MyPage") ||
      // Collection
      (from.name === "CollectionService" && to.name === "BookingCollection") ||
      (from.name === "BookingCollection" && to.name === "CollectionSchedule") ||
      (from.name === "CollectionSchedule" &&
        to.name === "ConfirmationCollection") ||
      (from.name === "ConfirmationCollection" &&
        to.name === "SuccessCollection") ||
      (from.name === "SuccessCollection" && to.name === "CollectionDetail") ||
      (from.name === "CollectionDetail" && to.name === "BookingCollection") ||
      (from.name === "CollectionDetail" && to.name === "EditCollection") ||
      (from.name === "EditCollection" && to.name === "EditCollectionSuccess") ||
      (from.name === "EditCollectionSuccess" &&
        to.name === "BookingCollection") ||
      (from.name === "EditCollection" && to.name === "CancelSuccess") ||
      (from.name === "CancelSuccess" && to.name === "BookingCollection") ||
      // Collection paid
      (from.name === "CollectionPaidService" &&
        to.name === "BookingCollection") ||
      // Confirmation
      (from.name === "ContactDetail" && to.name === "ContactOption") ||
      (from.name === "ContactOption" && to.name === "ContactPrice") ||
      (from.name === "ContactPrice" && to.name === "ContactSuccess") ||
      (from.name === "ContactPacking" && to.name === "ContactPackingSuccess") ||
      (from.name === "ContactDetailArrival" &&
        to.name === "ContactArrivalSuccess")
    ) {
      transition = "slide";
    } else if (
      // -----
      // SLIDE REVERSE
      // -----

      // Account
      (from.name === "SendEmailForCreate" && to.name === "CreateAccount") ||
      (from.name === "CreateAccount" && to.name === "CreateAccountDone") ||
      (from.name === "Login" && to.name === "CreateAccountDone") ||
      (from.name === "ForgotPassword" && to.name === "Login") ||
      (from.name === "SendEmailForPasswordReset" &&
        to.name === "ForgotPassword") ||
      (from.name === "ForgotPassword" && to.name === "PasswordReset") ||
      (from.name === "PasswordResetDone" && to.name === "PasswordReset") ||
      (from.name === "Login" && to.name === "PasswordResetDone") ||
      (from.name === "AddInformationFromLogin" && to.name === "Login") ||
      (from.name === "AddInformationDone" &&
        to.name === "AddInformationFromLogin") ||
      (from.name === "MyPage" && to.name === "Login") ||
      (from.name === "MyPageMenu" && to.name === "Login") ||
      (from.name === "Help" && to.name === "Login") ||
      (from.name === "Help" && to.name === "CreateAccount") ||
      // Mypage
      (from.name === "MyPagePrice" && to.name === "MyPageInfo") ||
      (from.name === "NewsList" && to.name === "MyPageMenu") ||
      (from.name === "AddInformationFromMenu" && to.name === "MyPageMenu") ||
      (from.name === "MyProfile" && to.name === "MyPageMenu") ||
      (from.name === "MyPageInquiries" && to.name === "MyPageMenu") ||
      (from.name === "MyPageInquiriesForm" && to.name === "MyPageInquiries") ||
      (from.name === "MyPageInquiriesConfirmation" &&
        to.name === "MyPageInquiriesForm") ||
      (from.name === "MyPageInquiriesSuccess" &&
        to.name === "MyPageInquiriesConfirmation") ||
      (from.name === "EditEmail" && to.name === "MyProfile") ||
      (from.name === "EditPassword" && to.name === "MyProfile") ||
      (from.name === "AddInformationFromMenuDone" &&
        to.name === "AddInformationFromMenu") ||
      // Furniture
      (from.name === "BookingFurniture" && to.name === "FurnitureService") ||
      (from.name === "BookingSchedule" && to.name === "BookingFurniture") ||
      (from.name === "ConfirmationFurniture" &&
        to.name === "BookingSchedule") ||
      (from.name === "SuccessFurniture" &&
        to.name === "ConfirmationFurniture") ||
      (from.name === "BookingInfoFurniture" &&
        to.name === "FurnitureService") ||
      (from.name === "BookingFurniture" &&
        to.name === "BookingInfoFurniture") ||
      // Goods
      (from.name === "GoodDetail" && to.name === "GoodListing") ||
      (from.name === "GoodReserved" && to.name === "GoodListing") ||
      (from.name === "GoodReserved" && to.name === "GoodDetail") ||
      // Delivery
      (from.name === "DeliverySelectDatetime" &&
        to.name === "DeliveryListItem") ||
      (from.name === "DeliverySuccess" &&
        to.name === "DeliverySelectDatetime") ||
      (from.name === "MyPage" && to.name === "DeliverySuccess") ||
      // Collection
      (from.name === "BookingCollection" && to.name === "CollectionService") ||
      (from.name === "CollectionSchedule" && to.name === "BookingCollection") ||
      (from.name === "ConfirmationCollection" &&
        to.name === "CollectionSchedule") ||
      (from.name === "SuccessCollection" &&
        to.name === "ConfirmationCollection") ||
      // (from.name === "BookingCollection" && to.name === "SuccessCollection") ||
      (from.name === "BookingCollection" && to.name === "CollectionDetail") ||
      (from.name === "EditCollection" && to.name === "CollectionDetail") ||
      (from.name === "EditCollectionSuccess" && to.name === "EditCollection") ||
      (from.name === "BookingCollection" &&
        to.name === "EditCollectionSuccess") ||
      (from.name === "CancelSuccess" && to.name === "EditCollection") ||
      (from.name === "BookingCollection" && to.name === "CancelSuccess") ||
      // Collection paid
      (from.name === "BookingCollection" &&
        to.name === "CollectionPaidService") ||
      // Confirmation
      (from.name === "ContactOption" && to.name === "ContactDetail") ||
      (from.name === "ContactPrice" && to.name === "ContactOption") ||
      (from.name === "ContactSuccess" && to.name === "ContactPrice") ||
      (from.name === "ContactPackingSuccess" && to.name === "ContactPacking") ||
      (from.name === "ContactArrivalSuccess" &&
        to.name === "ContactDetailArrival")
    ) {
      transition = "slide-reverse";
    } else if (
      // -----
      // ACCORDION
      // -----
      (from.name === "MyPage" && to.name === "MyPageInfo") ||
      (from.name === "MyPage" && to.name === "MyPagePrice") ||
      (from.name === "MyPage" && to.name === "DeliveryListItem") ||
      (from.name === "MyPage" && to.name === "CollectionService") ||
      (from.name === "MyPage" && to.name === "FurnitureService") ||
      (from.name === "MyPage" && to.name === "GoodListing") ||
      (from.name === "MyPage" && to.name === "MyPageMenu")
    ) {
      transition = "fade-mypage";
    } else {
      transition = "fade";
    }
  } else {
    // console.log("pc");
    transition = "";
  }
  console.log(transition);
  to.meta.transition = transition;

  if (
    (from.name === "MyPageInfo" && to.name === "MyPageInfo") ||
    (from.name === "MyPagePrice" && to.name === "MyPagePrice") ||
    (from.name === "MyPageInfo" && to.name === "MyPagePrice") ||
    (from.name === "MyPagePrice" && to.name === "MyPageInfo")
  ) {
    from.meta.mypageTransition = false;
    to.meta.mypageTransition = false;
  } else {
    from.meta.mypageTransition = true;
    to.meta.mypageTransition = true;
  }

  if (from.path.startsWith("/goods") && to.path.startsWith("/goods")) {
    from.meta.goodsTransition = false;
    to.meta.goodsTransition = false;
  } else {
    from.meta.goodsTransition = true;
    to.meta.goodsTransition = true;
  }
});
