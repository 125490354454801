
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  emits: ["close", "click"],
});
