
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "text",
    },
    inputmode: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "61.3vw",
    },
    className: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input"],
  setup(props) {
    const computeType = ref("");
    const style = computed(() => {
      return {
        width: props.width,
      };
    });
    const click = () => {
      if (computeType.value === "password") {
        computeType.value = "text";
      } else {
        computeType.value = "password";
      }
    };
    onMounted(() => {
      computeType.value = props.type;
    });
    return {
      computeType,
      style,
      click,
    };
  },
});
