
import { defineComponent, onMounted, ref } from "vue";
import PocketItem from "@/components/mypage/PocketItemMobile.vue";

export default defineComponent({
  components: {
    PocketItem,
  },
  props: {
    receipts: {
      type: Array,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    advertisements: {
      type: Array,
      required: true,
    },
    empty: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["clickDownloadMypocket", "clickDownloadInfomail", "clickOpen"],
});
