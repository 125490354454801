
import BaseButton from "@/components/account/BaseButton.vue";
import BaseButton2 from "@/components/account/BaseButton2.vue";
import ContainerBlock from "@/components/account/ContainerBlock.vue";
import TextBlock from "@/components/account/TextBlock.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import apis from "@/js/apis";
import useRouteTo from "@/js/useRouteTo";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { TextBlock, ContainerBlock, BaseButton, Logo, Footer },
  props: {},
  setup() {
    const { routeTo } = useRouteTo();
    const route = useRoute();
    const status = ref("");
    const login = () => {
      routeTo("Login");
    };
    const createAccount = () => {
      routeTo("CreateAccount");
    };
    onMounted(() => {
      // get token and send to backend for authentication
      const token = route.query.token;
      if (token) {
        // API Call -> POST token
        const body = {
          token: token.toString(),
        };
        apis
          .post(apis.endpoints.activate, "", body)
          .then((res) => {
            console.log("res.status: " + res.status);
            return res.json();
          })
          .then((data) => {
            if (data.status === "error") {
              console.log("invalid token");
              status.value = "failure";
            } else {
              status.value = "success";
            }
          })
          .catch((reason) => {
            console.log(reason);
            routeTo("ErrorOccurred", {}, { title: reason });
          });
      } else {
        console.log("no token");
        status.value = "failure";
      }
    });

    return {
      status,
      login,
      createAccount,
    };
  },
});
