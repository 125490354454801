
import { defineComponent, inject } from "vue";
export default defineComponent({
  props: {
    active: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      menu: [
        { label: "マイページTOP", link: "MyPage" },
        { label: "ご契約内容", link: "MyPageInfo" },
        { 
          label: "よくある質問",
          link: "https://www.the0123.com/concierge/faq/",
          target: "_blank",
          rel: "noopener noreferrer"
        },
        { label: "各種問い合わせ", link: "MyPageInquiries" },
        { label: "マイポケット", link: "MyPageMyPocket", floaty: 1 },
      ],
      file_num: 0,
    };
  },
  mounted(){
    this.file_num = localStorage.getItem("file_num") ? parseInt(localStorage.getItem("file_num") as string) : 0;
    window.addEventListener("file_num-localstorage-changed", (event: any) => {
      this.file_num = event.detail.file_num || "";
    });
  },
  setup() {
    const links = inject("links");
    console.log("links");
    console.log(links);
    return {
      links,
    };
  },
});
