import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b938db4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "articles" }
const _hoisted_3 = { class: "pre-formatted" }
const _hoisted_4 = { class: "pre-formatted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (article) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "body",
          key: article.id
        }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.getJapaneseDate(article.start, false)), 1),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_3, [
              _createElementVNode("strong", null, _toDisplayString(article.title), 1)
            ]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(article.content), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}