
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import Steps from "@/components/furniture/Steps.vue";
import BaseButton from "@/components/BaseButton.vue";

import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import useRouteTo from "../../../js/useRouteTo";

export default defineComponent({
  components: { Logo, Footer, Steps, BaseButton },
  data: function () {
    return {
      items: [
        { label: "1. お申込み内容のご入力", class: "current" },
        { label: "2. 入力内容のご確認", class: "current" },
        { label: "3. お申し込み完了", class: "current" },
      ],
      furniture: {},
    };
  },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const submit = () => {
      routeTo("BookingInfoFurniture");
    };
    return {
      submit,
    };
  },
  created() {
    const store = useStore();
    this.furniture = reactive({ ...store.state.collection });
  },
});
