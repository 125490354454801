
import apis from "@/js/apis";
import useRouteTo from "@/js/useRouteTo";
import useRedirectMypageForConfirmation from "@/js/useRedirectMypageForConfirmation";
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  // props: {

  // },
  setup() {
    const route = useRoute();
    const { routeTo } = useRouteTo();
    const store = useStore();

    onMounted(async () => {
      store.dispatch("startLoading", true);
      // get query params
      const query = route.query;
      // first, check if all three required params are in there
      const contract_id = query.contract_id?.toString();
      const arrival_departure_val = query.arrival_departure_val?.toString();
      const is_other_company_delivery =
        query.is_other_company_delivery?.toString();
      if (
        !contract_id ||
        !arrival_departure_val ||
        !is_other_company_delivery
      ) {
        console.log("no required params");
        routeTo(
          "ErrorOccurred",
          {},
          { title: "契約情報を取得できませんでした" }
        );
      } else {
        // then get contract detail
        const detail = await apis.getContractDetail(contract_id);
        // save contract detail in store
        store.dispatch("confirmation/updateContractDetail", detail);
        //save contract_id in store
        store.dispatch("confirmation/updateContractID", contract_id);
        //save arrival_departure_val in store
        store.dispatch(
          "confirmation/updateArrivalDepartureVal",
          arrival_departure_val
        );
        //save is_other_company_delivery in store
        store.dispatch(
          "confirmation/updateIsOtherCompanyDelivery",
          is_other_company_delivery
        );

        //check if confirmation is already finished
        const res = await apis.checkIfConfirmationIsDone();
        if (res == true) {
          await useRedirectMypageForConfirmation(contract_id, detail);
          routeTo("MyPageInfo");
        } else if (res == false) {
          // then
          if (arrival_departure_val === "1") {
            routeTo("ContactPacking", query);
          } else if (
            arrival_departure_val === "2" ||
            arrival_departure_val === "4"
          ) {
            routeTo("ContactDetail", query);
          } else if (arrival_departure_val === "3") {
            routeTo("ContactDetailArrival", query);
          } else {
            console.log("invalid arrival_departure_val");
            routeTo(
              "ErrorOccurred",
              {},
              { title: "契約情報を取得できませんでした" }
            );
          }
        }
      }
    });
  },
});
