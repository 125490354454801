import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4582b46"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "slider"
}
const _hoisted_2 = {
  key: 0,
  class: "thumbs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flickity = _resolveComponent("flickity")!

  return (_ctx.renderComponent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.$store.state.device === 'pc')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.banners, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "carousel-cell-thumb",
                  style: _normalizeStyle({ backgroundImage: 'url(' + item.url + ')' })
                }, null, 4))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          (_ctx.banners)
            ? (_openBlock(), _createBlock(_component_flickity, {
                key: 0,
                ref: "flickity",
                options: {..._ctx.flickityOptions, draggable: _ctx.$store.state.device !== 'pc'}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.banners, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "carousel-cell",
                      style: _normalizeStyle({ backgroundImage: 'url(' + item.url + ')' })
                    }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, "(" + _toDisplayString(index + 1) + "/" + _toDisplayString(_ctx.banners.length) + ")", 1)
                      ])
                    ], 4))
                  }), 128))
                ]),
                _: 1
              }, 8, ["options"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}