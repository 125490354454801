import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0061e0e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-backdrop" }
const _hoisted_2 = {
  class: "modal-header",
  id: "modalTitle"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  class: "modal-body",
  id: "modalDescription"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass({modal: true, pc: _ctx.$store.state.device == 'pc'}),
          role: "dialog",
          "aria-labelledby": "modalTitle",
          "aria-describedby": "modalDescription"
        }, [
          _createElementVNode("header", _hoisted_2, [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  innerHTML: _ctx.title
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
              "aria-label": "Close modal"
            })
          ]),
          _createElementVNode("section", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass({'no-arrow': _ctx.showArrow, body: true})
            }, [
              _createElementVNode("p", { innerHTML: _ctx.msg }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.buttonText)
                  ? (_openBlock(), _createBlock(_component_base_button, {
                      key: 0,
                      className: `${_ctx.showArrow ? '' : 'no-arrow'}`,
                      width: `${_ctx.$store.state.device == 'pc' ? '50%' : '100%'}`,
                      buttonText: _ctx.buttonText,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click')))
                    }, null, 8, ["className", "width", "buttonText"]))
                  : _createCommentVNode("", true),
                (_ctx.buttonCancel)
                  ? (_openBlock(), _createBlock(_component_base_button, {
                      key: 1,
                      className: `${_ctx.showArrow ? '' : 'no-arrow'} primary`,
                      width: `${_ctx.$store.state.device == 'pc' ? '50%' : '100%'}`,
                      buttonText: "キャンセル",
                      onClick: _ctx.close
                    }, null, 8, ["className", "width", "onClick"]))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ])
        ], 2)
      ])
    ]),
    _: 1
  }))
}