
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    half: {
      type: Boolean,
      defalut: false,
    },
  },
  setup(props) {
    const store = useStore();
    const computeClass = computed(() => {
      if (store.state.device === "mobile") {
        return "mobile";
      } else {
        if (props.half) {
          return "pc-half";
        } else {
          return "pc";
        }
      }
    });
    return {
      computeClass,
    };
  },
});
