
import apis from "@/js/apis";
import { defineComponent, onMounted, ref } from "vue";
import InformationDesktop from "./partials/InformationDesktop.vue";
import InformationMobile from "./partials/InformationMobile.vue";

export default defineComponent({
  components: { InformationMobile, InformationDesktop },
  setup() {
    const detail = ref({});
    onMounted(async () => {
      detail.value = await apis.checkIfContractDetailInStore();
    });
    return {
      detail,
    };
  },
});
