import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmationMobile = _resolveComponent("ConfirmationMobile")!
  const _component_ConfirmationDesktop = _resolveComponent("ConfirmationDesktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_ConfirmationMobile, {
          key: 0,
          onClick: _ctx.click
        }, null, 8, ["onClick"]))
      : (_openBlock(), _createBlock(_component_ConfirmationDesktop, {
          key: 1,
          onClick: _ctx.click
        }, null, 8, ["onClick"]))
  ]))
}