
import AddBox from "@/components/delivery/AddBox.vue";
import DateTimeBlock from "@/components/delivery/DateTimeBlock.vue";
import ItemBlock from "@/components/delivery/ItemBlock.vue";
import deliveryConst from "@/js/deliveryConst";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import util from "@/js/util";
export default defineComponent({
  components: { ItemBlock, DateTimeBlock, AddBox },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    data: {
      type: [Array, Object],
    },
  },
  emits: ["click"],
  setup(props, context) {
    const store = useStore();
    const imageMap = deliveryConst.imageMap;
    const materialTypeMap = deliveryConst.materialTypeMap;
    const click = (item: any) => {
      if (item.branch_info.is_fc) {
        util.openAlert(store, "OK", "",  "担当支店にお問合せください<br/>" +
            item.branch_info.name +
            "支店<br/><a href='tel:"+item.branch_info.tel+"'>" +
            item.branch_info.tel + "</a>", () => util.closeAlert(store))
      } else {
        if (item.delivery_date) {
          let the_day_before_delivery_date = new Date(
            item.delivery_date + " 15:00"
          );
          the_day_before_delivery_date.setDate(
            the_day_before_delivery_date.getDate() - 1
          );
          if (
            new Date(
              new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
            ) >= the_day_before_delivery_date
          ) {
            util.openAlert(store, "OK", "",  "直近の変更に関してはお手数ですが担当支店にご連絡をお願いいたします<br/>" +
                item.branch_info.name +
                "支店<br/><a href='tel:"+item.branch_info.tel+"'>" +
                item.branch_info.tel+"</a>", () => util.closeAlert(store))
          } else {
            context.emit("click", item);
          }
        } else {
          context.emit("click", item);
        }
      }
    };
    return {
      imageMap,
      materialTypeMap,
      click,
    };
  },
});
