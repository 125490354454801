import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49b7ac0b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "alert",
  role: "dialog",
  "aria-labelledby": "modalTitle",
  "aria-describedby": "modalDescription"
}
const _hoisted_2 = {
  class: "modal-body",
  id: "modalDescription"
}
const _hoisted_3 = { class: "body" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({container: true, 'alert-is-shown': _ctx.msg.length > 0})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", { innerHTML: _ctx.msg }, null, 8, _hoisted_4)
        ])
      ])
    ])
  ], 2))
}