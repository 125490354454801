
import BackgroungImageBlock from "@/components/mypage/BackgroungImageBlock.vue";
import ContentsToggleBlock from "@/components/mypage/ContentsToggleBlock.vue";
import InfoPriceTabDesktop from "@/components/mypage/InfoPriceTabDesktop.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import TitleBlock from "@/components/mypage/TitleBlock.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    TitleBlock,
    ContentsToggleBlock,
    BackgroungImageBlock,
    InfoPriceTabDesktop,
    Logo,
    Footer,
  },
  props: {
    option_category_list: {
      type: Array,
      required: true,
    },
    hasOption: {
      type: Boolean,
      required: true,
    },
  },
});
