
import { defineComponent } from "vue";
import { useStore } from "vuex";
import util from "@/js/util";

import Checkbox from "@/components/furniture/Checkbox.vue";
import BaseButton from "@/components/BaseButton.vue";
import useRouteTo from "../../js/useRouteTo";
import apis from "@/js/apis";
import BranchTel from "./BranchTel.vue";

export default defineComponent({
  components: { BaseButton, BranchTel, Checkbox },
  props: {
    contact: {
      type: Object,
    },
  },
  data: function () {
    return {
      items: [{ id: "checkbox1", label: "上記の内容を確認しました", value: 1 }],
      selectedInputItems: [],
      disabled: true,
    };
  },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const getJapaneseDate = util.getJapaneseDate;
    const click = async () => {
      store.dispatch("startLoading", true);
      // call API
      const result = await apis.confirmConfirmation();
      if (result == "success") {
        routeTo("ContactArrivalSuccess");
      }
    };
    return {
      click,
      getJapaneseDate,
    };
  },
  methods: {
    inputItems(items: string[]) {
      this.disabled = items.length == 0;
      this.$store.dispatch("confirmation/setConfirmation", items);
    },
  },
  mounted: function () {
    const store = useStore();
    this.selectedInputItems = store.state.confirmation.confirmation;
    this.disabled = store.state.confirmation.confirmation.length == 0;
  },
  emits: ["click"],
});
