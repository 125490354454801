
import ListingView from "@/components/goods/ListingView.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import BaseButton from "@/components/account/BaseButton.vue";

import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { ListingView, Logo, Footer, BaseButton },
  emits: ["click"],
  props: {
    allGoods: {
      type: Array,
      required: true,
    },
    recommendedGoods: {
      type: Array,
      required: true,
    }
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.state.goods.selected = "one";
    });
  },
});
