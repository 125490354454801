
import apis from "@/js/apis";
import useRouteTo from "@/js/useRouteTo";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import MenuDesktop from "./partials/MenuDesktop.vue";
import MenuMobile from "./partials/MenuMobile.vue";

export default defineComponent({
  components: { MenuMobile, MenuDesktop },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const contractData = ref([]);
    const selected = ref("");
    const selectContract = (
      item: any
      // contract_id: string,
      // ukt_no: string,
      // moving_date: string
    ) => {
      // save selected number in local storage
      localStorage.setItem("selectedNumber", item.contract_id);
      localStorage.setItem("ukt_no", item.ukt_no);
      localStorage.setItem("moving_date", item.moving_date);
      localStorage.setItem("latest_date", item.final_delivery_date);
      localStorage.setItem("moving_furniture_date", item.moving_furniture_date);
      routeTo("MyPage");
    };

    const deleteContract = async (item: any) => {
      const result = await apis.delContact(item.contract_id);
      if (result === "success") {
        store.dispatch("startLoading", true);
        getContactList();
      }
    };

    const getContactList = async () => {
      const result = await apis.callAPI(
        "get",
        apis.endpoints.getContracts,
        store.state.jwt
      );
      if (result && result.data.status === "success") {
        const data = result.data;
        // check if there is at least one contract (same as after logging in)
        if (data.results.length) {
          // sort and save contracts in store
          // before sorting, replace null with "" for moving_date
          data.results.forEach((element: any) => {
            if (!element.moving_date) {
              element.moving_date = "";
            }
          });
          const newData = data.results.sort(function (a: any, b: any) {
            return a.moving_date > b.moving_date ? -1 : 1;
          });
          store.dispatch("mypage/updateContracts", newData);
          contractData.value = newData;

          // check if there's at least one contract whose status == 2 (failure)
          for (let item of newData) {
            if (item.status == "2") {
              store.dispatch("mypage/updateHasFailureContract", true);
              break;
            }
          }

          // get selected contract number from local storage
          const selectedNumber = localStorage.getItem("selectedNumber");
          if (selectedNumber) {
            selected.value = selectedNumber;
          }
        } else {
          // no contracts -> AddInformation
          routeTo("AddInformationFromLogin");
        }
      }
    };

    onMounted(async () => {
      store.dispatch("startLoading", true);
      getContactList();
    });
    return {
      contractData,
      selected,
      selectContract,
      deleteContract,
    };
  },
});
