import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48d95688"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-block bound-no-gray" }
const _hoisted_2 = {
  class: "item-block-main",
  style: {}
}
const _hoisted_3 = { class: "item-block-main-image" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "item-block-main-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_material_block = _resolveComponent("material-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.src,
          alt: _ctx.alt
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_text_block, {
            title: "訪問先",
            items: _ctx.address
          }, null, 8, ["items"]),
          (_ctx.materials && _ctx.materials.length > 0)
            ? (_openBlock(), _createBlock(_component_material_block, {
                key: 0,
                title: "配達資材",
                materials: _ctx.materials,
                disableToggle: _ctx.disableToggle
              }, null, 8, ["materials", "disableToggle"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}