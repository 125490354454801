
import FurnitureDetailDesktop from "./partials/FurnitureDetailDesktop.vue";
import FurnitureDetailMobile from "./partials/FurnitureDetailMobile.vue";

import { defineComponent, onMounted, ref } from "vue";
import useRouteTo from "@/js/useRouteTo";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import util from "@/js/util";
import useFurnitureInit from "@/js/useFurnitureInit";

export default defineComponent({
  components: { FurnitureDetailDesktop, FurnitureDetailMobile },
  setup() {
    const { routeTo } = useRouteTo();
    const route = useRoute();
    const store = useStore();
    const furnitureInfo = ref({} as any);
    const new_furniture_list = ref({} as any);
    const status = ref("");
    onMounted(async () => {
      furnitureInfo.value = (await useFurnitureInit()) as any;

      if (Object.keys(furnitureInfo.value).length) {
        // check the status
        if (furnitureInfo.value.is_accepting) {
          status.value = "accepting";
        } else if (furnitureInfo.value.is_canceled) {
          status.value = "canceled";
        } else if (!furnitureInfo.value.is_exist) {
          // if no furnitureInfo, then go to welcome page
          routeTo("FurnitureService");
        } else {
          // prevent "上記以外の家具" from appearing twice
          if (furnitureInfo.value.other_furniture) {
            furnitureInfo.value.moving_furniture =
              furnitureInfo.value.moving_furniture.filter(
                (i: string) => i !== "3C99"
              );
          }

          // grouping by datetime
          let datetime: string;
          if (
            new Date(furnitureInfo.value.furniture_moving_date).toString() ===
            "Invalid Date"
          ) {
            datetime = "お伺い日未定";
          } else {
            datetime =
              store.state.device === "mobile"
                ? util.getJapaneseDateMonth(
                    furnitureInfo.value.furniture_moving_date
                  )
                : util.getJapaneseDate(
                    furnitureInfo.value.furniture_moving_date
                  );
            if (furnitureInfo.value.furniture_moving_time) {
              datetime += furnitureInfo.value.furniture_moving_time
            } else {
              datetime += 'のお伺い予定'
            }
          }
          if (Object.keys(new_furniture_list.value).includes(datetime)) {
            new_furniture_list.value[datetime].push(furnitureInfo.value);
          } else {
            new_furniture_list.value[datetime] = [furnitureInfo.value];
          }
        }
      }
    });
    const clickNewApply = () => {
      window.open("https://www.the0123.com/rakumo/");
    };
    return {
      clickNewApply,
      furnitureInfo,
      new_furniture_list,
      status,
    };
  },
});
