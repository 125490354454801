
import { defineComponent,  } from "vue";
import InquiriesSuccessDesktop from "./partials/InquiriesSuccessDesktop.vue";
import InquiriesSuccessMobile from "./partials/InquiriesSuccessMobile.vue";

export default defineComponent({
  components: { InquiriesSuccessMobile, InquiriesSuccessDesktop },
  setup() {
    console.log("setup")
  },
});
