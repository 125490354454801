
import { computed, defineComponent, ref } from "vue";
import BaseButton from "./BaseButton.vue";
import MaterialBlock from "./MaterialBlock.vue";
import TextBlock from "./TextBlock.vue";
import ExclamationIcon from "@/components/furniture/ExclamationIcon.vue";

import { useStore } from "vuex";

export default defineComponent({
  components: { TextBlock, MaterialBlock, BaseButton, ExclamationIcon },
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    materialType: {
      type: Array,
      required: true,
    },
    materialTypeLabel: {
      type: String,
      default: "お届け資材",
    },
    address: {
      type: Array,
      required: true,
    },
    addressLabel: {
      type: String,
      default: "お届け先",
    },
    materials: {
      type: Object,
      required: true,
    },
    marginBottom: {
      type: String,
      default: "0",
    },
    disableToggle: {
      type: Boolean,
      default: false,
    },
    dataResult: {
      type: Object,
      default: () => ({}),
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    deliveryDate: {
      type: String,
      default: "",
    },
    hasZipcode: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["clickButton"],
  methods: {
    failedMessage() {
      return {
        title: "サービス提供エリア外です",
        content:
          "大変申し訳ございませんが、この住所はサービスの提供エリア外となっております。ご理解賜りますようお願い申し上げます。",
      };
    },
    failedV2Message() {
      const branchInfo = this.dataResult.branch_info;
      return {
        title: "引越マイページ対応エリア外です",
        content: `この住所への配達依頼は、引越マイページではお受けすることができません。<br/>大変お手数ではございますが、以下の番号までお問合せくださいますようお願い申し上げます。<br/><h2 class="red text-center text-underline">電話：<a class="red" href="tel:${branchInfo.tel}">${branchInfo.tel}</a></h2>`,
      };
    },
    getMessage(option = 0) {
      if (option == 1) {
        return this.failedMessage();
      } else {
        return this.failedV2Message();
      }
    },
  },
  setup(props, context) {
    const store = useStore();
    const toggled = ref(store.state.device !== "mobile");
    const toggle = (e: any) => {
      if (
        !e.target.classList.contains("next-button") &&
        !props.disableToggle &&
        store.state.device === "mobile"
      ) {
        setTimeout(() => {
          toggled.value = !toggled.value;
        }, 0);
      }
    };
    const style = computed(() => {
      if (props.isProcessing || !props.hasZipcode) {
        return {
          opacity: 0.5,
        };
      } else {
        return {};
      }
    });
    const buttonText = computed(() => {
      if (props.isProcessing) {
        return "変更受付中";
      } else {
        return "配達日時変更";
      }
    });
    const click = () => {
      if (props.isProcessing || !props.hasZipcode) {
        console.log("no emit");
      } else {
        context.emit("clickButton");
      }
    };
    return {
      toggled,
      toggle,
      style,
      buttonText,
      click,
    };
  },
});
