import apis from "./apis";

export default async function (contract_id: string) {
  // if ((localStorage.getItem("selectedNumber") as string) !== contract_id) {
  const detail = await apis.getContractDetail(contract_id);
  if (detail) {
    localStorage.setItem("selectedNumber", contract_id);
    localStorage.setItem("ukt_no", detail.ukt_no);
    localStorage.setItem("name", detail.name);
    localStorage.setItem(
      "new_address_main_branch_id",
      detail.new_address_main_branch.branch_id
    );
    localStorage.setItem("quotation_branch_id", detail.quotation_branch.branch_id);
    // emit event to notify ukt_no change
    window.dispatchEvent(
      new CustomEvent("ukt_no-localstorage-changed", {
        detail: {
          ukt_no: detail.ukt_no,
        },
      })
    );

    // get moving_date
    for (const old_address of detail.moving_info_list[0]
      .old_address_info_list) {
      if (old_address.is_main) {
        localStorage.setItem("moving_date", old_address.moving_date);
        break;
      }
    }
    // get the latest moving (arriving) date
    let latestDate = "";
    for (const moving_info of detail.moving_info_list) {
      for (const item of moving_info.new_address_info_list) {
        if (!latestDate) {
          latestDate = item.delivery_date;
        } else if (new Date(latestDate) < new Date(item.delivery_date)) {
          latestDate = item.delivery_date;
        }
      }
    }
    localStorage.setItem("latest_date", latestDate);

    // get the furniture date and is_furniture_canceled
    localStorage.setItem("moving_furniture_date", detail.moving_furniture_date);
    localStorage.setItem(
      "is_furniture_canceled",
      JSON.stringify(detail.moving_furniture_is_canceled)
    );

    // get mypocket file number
    const file_num = await apis.getFileNumber(contract_id);
    try {
      if (file_num) {
        localStorage.setItem("file_num", file_num.num_of_new_files);
        window.dispatchEvent(
          new CustomEvent("file_num-localstorage-changed", {
            detail: {
              file_num: file_num.num_of_new_files,
            },
          })
        );
      }
    } catch {
      console.error("can't get file num");
    }
  }
  return detail;
  // }
}
