
import { defineComponent } from "vue";
import ExclamationIcon from "../furniture/ExclamationIcon.vue";

export default defineComponent({
  components: { ExclamationIcon },
  // props: {

  // },
  // setup() {

  // },
});
