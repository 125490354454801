
import { defineComponent, computed } from "vue";
import util from "@/js/util";
import Select from "./Select.vue";
import InputBlock from "./../collection/InputBlock.vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: [String, Array, Number],
      default: "",
    },
    selectValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "radio",
    },
  },
  components: {
    Select,
    InputBlock,
  },
  emits: ["change", "changeSelect", "input"],
  data(props) {
    return {
      inputValue: "",
      selectSelectedValue: "",
      selectedInput: Array.isArray(props.selected)
        ? props.selected
        : (props.selected || "").toString(),
    };
  },
  setup(props) {
    const store = useStore();
    const buttonWidth = computed(() => {
      return store.state.device == "mobile" ? "100%" : "19.13vw";
    });
    return {
      buttonWidth,
    };
  },
  methods: {
    updateItem(event: any): void {
      if (this.$props.name == "booking_radio_phone") {
        const selected: any = (this.$props.items || []).filter(
          (i: any) => i.value == event.target.value
        );
        if (selected.length > 0) {
          if (selected[0]["label"] == "その他の電話番号") {
            this.changeInput(this.inputValue);
          } else {
            this.changeSelect(
              this.selectSelectedValue
                ? this.selectSelectedValue
                : selected[0]["selectItems"][0]["value"]
            );
          }
        } else {
          this.$emit("change", this.selectedInput);
        }
      } else {
        this.$emit("change", this.selectedInput);
      }
    },
    changeSelect(item: string): void {
      this.$props.items.forEach((el: any) => {
        const selected = (el.selectItems || []).filter(
          (i: any) => i.label == item
        );
        if (selected.length > 0) {
          if (!Array.isArray(this.selectedInput)) {
            this.selectedInput = el["value"];
          } else {
            if (this.selectedInput.indexOf(el["value"]) < 0)
              this.selectedInput.push(el["value"]);
          }

          this.$emit("change", this.selectedInput);
        }
      });
      this.$emit("changeSelect", item);
      this.selectSelectedValue = item;
    },
    changeInput(item: string): void {
      let validate = true;
      if (this.$props.name == "booking_radio_phone") {
        if (typeof item === "string") {
          item = util.zenkakuToHankaku(item);
        } else {
          validate = false;
        }
      }
      if (validate) {
        this.$emit("changeSelect", item);
        this.inputValue = item;
        this.$props.items.forEach((el: any) => {
          const selected = (el.selectItems || []).filter(
            (i: any) => i.label == item
          );
          if (selected.length == 0) {
            if (!Array.isArray(this.selectedInput)) {
              this.selectedInput = el["value"];
            } else {
              if (this.selectedInput.indexOf(el["value"]) < 0)
                this.selectedInput.push(el["value"]);
            }
          }
          this.$emit("change", this.selectedInput);
        });
      }
    },
    setSelectedItem(value: any): void {
      if (value) {
        if (!Array.isArray(this.selectedInput) || Array.isArray(value)) {
          this.selectedInput = value;
        } else {
          this.selectedInput.push(value);
        }
      }
    },
  },
  watch: {
    selected: function (val) {
      this.setSelectedItem(val);
    },
    items: function (val) {
      this.setSelectedItem(this.selected || 1);
    },
    selectValue: function (val) {
      if (!Array.isArray(this.selected) && this.selected == 2)
        this.inputValue = val;
      else this.selectSelectedValue = val;
    },
  },
});
