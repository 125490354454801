
import ConfirmationView from "@/components/collection/desktop/ConfirmationView.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import BaseButton from "@/components/BaseButton.vue";
import util from "@/js/util";

import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { ConfirmationView, Logo, Footer, BaseButton },
  props: {
    collections: {
      type: Object,
    },
    over3months: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      items: [
        {
          label: "1. お申込み内容のご入力",
          class: "current",
          link: "BookingCollection",
        },
        {
          label: "2. 入力内容のご確認",
          class: "current",
          link: "ConfirmationCollection",
        },
        {
          label: "3. お申し込み完了",
          class: "current",
          link: "SuccessCollection",
        },
      ],
      collection: {},
      msg: "",
    };
  },
  emits: ["clickEdit", "clickNewApply"],
  watch: {
    "$store.state.collection.free_or_paid": function (value) {
      if (value == "paid") {
        this.msg =
          "ダンボールを回収した際に回収スタッフにお支払いください。領収書も発行いたします。";
      } else {
        this.msg = "";
      }
    },
  },
  created() {
    const store = useStore();
    this.collection = reactive({ ...store.state.collection });
    if (store.state.collection.free_or_paid == "paid") {
      this.msg =
        "ダンボールを回収した際に回収スタッフにお支払いください。領収書も発行いたします。";
    }
  },
  setup(props, context) {
    const store = useStore();
    const click = (data: any) => {
      if (data.branch_info && data.branch_info.is_fc) {
        util.openAlert(store, "OK", "", "担当支店にお問合せください<br/>" +
            data.branch_info.name +
            "支店<br/><a href='tel:"+data.branch_info.tel+"'>" +
            data.branch_info.tel + "</a>", () => util.closeAlert(store))
      } else if (data.kaishu_date) {
        let the_day_before_kaishu_date = new Date(data.kaishu_date + " 15:00");
        the_day_before_kaishu_date.setDate(
          the_day_before_kaishu_date.getDate() - 1
        );
        if (
          new Date(
            new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
          ) >= the_day_before_kaishu_date
        ) {
          util.openAlert(store, "OK", "", "直近の変更に関してはお手数ですが担当支店にご連絡をお願いいたします<br/>" +
                data.branch_info.name +
                "支店<br/><a href='tel:"+data.branch_info.tel+"'>" +
                data.branch_info.tel + "</a>", () => util.closeAlert(store))
        } else {
          context.emit("clickEdit", data);
        }
      } else {
        context.emit("clickEdit", data);
      }
    };
    return {
      click,
    };
  },
});
