
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    year: {
      type: String,
      default: ""
    },
    month: {
      type: String,
      default: ""
    },
    day: {
      type: String,
      default: ""
    },
    week: {
      type: String,
      default: ""
    },
    price: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const style = computed(() => {
      if (props.type === "info") {
        return {
          color: "#FFFFFF",
          "background-color": "#0060B9",
        };
      } else {
        return {
          color: "#005AB9",
          "background-color": "#FFFFFF",
        };
      }
    });
    return {
      style,
    };
  },
});
