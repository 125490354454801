
import BookingEditView from "@/components/collection/desktop/BookingEditView.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import ExclamationIcon from "../../../components/furniture/ExclamationIcon.vue";

import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { BookingEditView, ExclamationIcon, Logo, Footer },
  data: function () {
    return {
      msg: "今回の回収は無料でご利用いただけます",
    };
  },
  watch: {
    "$store.state.collection.free_or_paid": function (value) {
      if (value == "paid") {
        this.msg = "今回の回収は3,000円(税込)で承ります";
      } else {
        this.msg = "今回の回収は無料でご利用いただけます";
      }
    },
  },
  created() {
    const state: any = this.$store.state;
    if (state.collection.itemForEdit.is_free == 1) {
      this.msg = "今回の回収は無料でご利用いただけます";
    } else {
      this.msg = "今回の回収は3,000円(税込)で承ります";
    }
  },
  emits: ["submitEdit", "submitDelete"],
});
