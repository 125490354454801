import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4243d45c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "top"
}
const _hoisted_3 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_symbol_mark = _resolveComponent("symbol-mark")!
  const _component_horizontal_line = _resolveComponent("horizontal-line")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.className),
    style: _normalizeStyle(_ctx.style)
  }, [
    (!_ctx.blank)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.showTop)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  (_ctx.mark)
                    ? (_openBlock(), _createBlock(_component_symbol_mark, {
                        key: 0,
                        mark: _ctx.mark
                      }, null, 8, ["mark"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
                ]),
                (!_ctx.nodetail && !_ctx.buttonBottom && _ctx.buttonTitle)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "detail",
                      style: _normalizeStyle({ color: _ctx.buttonColor }),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickDetail', $event)))
                    }, _toDisplayString(_ctx.buttonTitle), 5))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.$store.state.device === 'mobile')
            ? (_openBlock(), _createBlock(_component_horizontal_line, { key: 1 }))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 6))
}