
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text",
    },
    width: {
      type: String,
      default: "2.4",
    },
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    maxlength: {
      type: Number,
      default: 1000
    }
  },
  emits: ["input"],
  setup(props) {
    const style = computed(() => {
      return {
        "width": props.width,
      };
    });
    return {
      style,
    };
  },
});
