import { useRoute } from "vue-router";
import { useStore } from "vuex";
import apis from "./apis";
import saveLocalStorage from "./saveLocalStorage";
import useRouteTo from "./useRouteTo";

export default async function () {
  const { routeTo } = useRouteTo();
  const route = useRoute();
  const store = useStore();

  let contract_id: string;
  if (route.query.contract_id) {
    contract_id = route.query.contract_id as string;
    saveLocalStorage(contract_id);
  } else {
    contract_id = (localStorage.getItem("selectedNumber") as string) || "";
  }
  store.dispatch("collection/saveContractId", contract_id);
  if (!contract_id) {
    routeTo("MyPageMenu");
  } else {
    // call API to get collection list
    const result = await apis.callAPI(
      "get",
      apis.endpoints.getCollection.replace("{}", contract_id),
      store.state.jwt
    );
    if (result && result.data.status === "success") {
      let collection_list = result.data.result.kaishu_list;

      // save request list
      // store.dispatch(
      //   "collection/saveRequestList",
      //   result.data.result.request_list
      // );

      // remove the canceled item
      collection_list = collection_list.filter(
        (item: any) => item.is_canceled == 0
      );

      // judge is_free value and save it in store
      if (!collection_list.length) {
        store.dispatch(
          "collection/saveFreeOrPaid",
          result.data.result.is_first_time_free ? "free" : "paid"
        );
      } else {
        store.dispatch("collection/saveFreeOrPaid", "paid");

        // check if there's at least one paid collection
        store.dispatch("collection/saveHasPaid", false);
        for (const item of collection_list) {
          if (!item.is_free) {
            store.dispatch("collection/saveHasPaid", true);
            break;
          }
        }

        // check if address length >= 30
        for (const item of collection_list) {
          if (item.address_02.length >= 30) {
            item.address_02 = item.address_02.slice(0, 29) + "...";
          }
        }
      }
      return collection_list;
    }
  }
}
