
import { defineComponent, ref, watch, toRefs } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    way: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      default: "",
    },
    wording: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const fontSize = ref(0);
    // const { price } = toRefs(props);
    // watch(price, async () => {
    //   setTimeout(() => {
    //     if (price) {
    //       const padding = store.state.device == 'pc' ? 60 : 0;
    //       const element = document.getElementsByClassName("price").item(0);
    //       const parent = document.getElementsByClassName("price-div").item(0);
    //       var style = element ? window.getComputedStyle(element, null).getPropertyValue('font-size') : '0';
    //       var size = parseFloat(style);
    //       const elementWidth = element?.clientWidth || 0
    //       const parentWidth = (parent?.clientWidth || 0) - padding
    //       if (elementWidth > parentWidth) {
    //         fontSize.value = (size - 10)
    //       }
    //     }
    //   }, 200);
    // });
    return {
      fontSize,
    };
  },
});
