import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02c15d9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "price-div" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "price"
}
const _hoisted_4 = {
  key: 1,
  class: "small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.price)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.price), 1))
        : _createCommentVNode("", true),
      (_ctx.wording)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.wording), 1))
        : _createCommentVNode("", true),
      (_ctx.price && _ctx.way)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass({text: true, 'text-center': _ctx.$store.state.device == 'mobile'}),
            style: _normalizeStyle({fontWeight: _ctx.$store.state.device == 'mobile' && `bold`, margin: _ctx.$store.state.device == 'mobile' && `10px 0`})
          }, _toDisplayString(_ctx.way), 7))
        : _createCommentVNode("", true)
    ])
  ]))
}