
import apis from "@/js/apis";
import useRouteTo from "@/js/useRouteTo";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import util from "@/js/util";

import CollectionEditDesktop from "./partials/CollectionEditDesktop.vue";
import CollectionEditMobile from "./partials/CollectionEditMobile.vue";

export default defineComponent({
  components: { CollectionEditMobile, CollectionEditDesktop },
  setup() {
    const { routeTo } = useRouteTo();
    const route = useRoute();
    const store = useStore();
    const item = ref({});
    const address_list = ref({} as any);

    const isDelete = () => {
      util.openAlert(store, "OK", "Cancel", "予約を取り消してよろしいですか？", confirmDelete)
    };

    onMounted(async () => {
      item.value = store.state.collection.itemForEdit;
      // check item in store
      if (!Object.keys(item.value).length) {
        routeTo("CollectionDetail");
      } else {
        // get user's tel
        const contract_id = store.state.collection.contract_id;
        const detail = await apis.getContractDetail(contract_id);
        // tel
        store.dispatch("collection/saveTelList", detail.tel_list);
      }
    });
    const submitEdit = async () => {
      // add "-" to zip code
      let zipcode = store.state.collection.itemForEdit.zipcode;
      if (zipcode.length == 7) {
        zipcode = zipcode.slice(0, 3) + "-" + zipcode.slice(3);
      }
      const body = {
        ukt_no: localStorage.getItem("ukt_no") as string,
        zipcode: zipcode,
        address_01: store.state.collection.itemForEdit.address_01,
        address_02: store.state.collection.itemForEdit.address_02,
        address_code: store.state.collection.itemForEdit.address_code,
        kaishu_date: store.state.collection.itemForEdit.kaishu_date,
        kaishu_time: store.state.collection.itemForEdit.kaishu_time,
        tel: store.state.collection.itemForEdit.tel,
        collect_item: store.state.collection.itemForEdit.collect_item,
        cardbord_num: store.state.collection.itemForEdit.cardbord_num,
        is_autolock: store.state.collection.itemForEdit.is_autolock,
        request: store.state.collection.itemForEdit.request.filter(
          (el: any) => el != "0"
        ),
        payment_method: store.state.collection.itemForEdit.payment_method,
        is_free: store.state.collection.itemForEdit.is_free,
        kaishu_no: store.state.collection.itemForEdit.kaishu_no,
      };

      if (store.state.device === "mobile") {
        store.dispatch("startLoading", true);
      }

      const result = await apis.callAPI(
        "put",
        apis.endpoints.editCollection.replace(
          "{}",
          store.state.collection.contract_id
        ),
        store.state.jwt,
        body
      );
      if (result && result.data.status === "success") {
        // clear store
        store.dispatch("collection/resetEdit");
        routeTo("EditCollectionSuccess");
      }
    };
    const confirmDelete = async () => {
      util.closeAlert(store)
      // loading
      if (store.state.device === "mobile") {
        store.dispatch("startLoading", true);
      }

      const result = await apis.delete(
        apis.endpoints.deleteCollection.replace(
          "{}",
          store.state.collection.contract_id
        ),
        store.state.jwt,
        {
          ukt_no: localStorage.getItem("ukt_no") as string,
          kaishu_no: store.state.collection.itemForEdit.kaishu_no,
        }
      );
      if (result === "success") {
        // clear store
        store.dispatch("collection/resetEdit");
        routeTo("CancelSuccess");
      }
    }
    const submitDelete = async () => {
      // confirm popup
      isDelete()
    };
    return {
      item,
      submitEdit,
      submitDelete,
    };
  },
});
