
import FurnitureView from "@/components/furniture/FurnitureView.vue";
import FurnitureDesktopView from "@/components/furniture/desktop/FurnitureView.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import { defineComponent, onMounted } from "vue";
import useRouteTo from "../../js/useRouteTo";
import { useStore } from "vuex";
import useFurnitureInit from "@/js/useFurnitureInit";
import util from "@/js/util";
export default defineComponent({
  components: { FurnitureView, FurnitureDesktopView, Logo, Footer },
  setup() {
    const store = useStore();
    const { routeTo } = useRouteTo();
    onMounted(async () => {
      const result = (await useFurnitureInit()) as any;
      if (Object.keys(result).length) {
        if (
          result.is_accepting ||
          result.is_canceled ||
          result.furniture_moving_date
        ) {
          routeTo("BookingInfoFurniture");
        }
      }
    });

    const submit = () => {
      store.dispatch("furniture/resetCreate");

      let latest_date = (localStorage.getItem("latest_date") as string) || "";
      if (new Date(latest_date).toString() !== "Invalid Date") {
        let target = new Date(latest_date + "T00:00");
        target.setDate(target.getDate() + 1);
        if (
          new Date(
            new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" })
          ) >= target
        ) {
          routeTo("BookingFurniture");
        } else {
          util.openAlert(store, "OK", "",  "お引越し後にお申し込みいただけます", () => util.closeAlert(store))
        }
      } else {
        util.openAlert(store, "OK", "",  "お引越し後にお申し込みいただけます", () => util.closeAlert(store))
      }
    };
    return {
      submit,
    };
  },
});
