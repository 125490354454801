
import { defineComponent } from "vue";

import PriceLabel from "./../../furniture/PriceLabel.vue";
import BaseButton from "./../../BaseButton.vue";
import util from "@/js/util";

export default defineComponent({
  name: "FurnitureLineView",
  components: {
    BaseButton,
    PriceLabel
  },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    button2Title: {
      type: String,
      default: "",
    },
    dataResult: {
      type: Object,
      required: true,
    },
    msg: {
      type: String,
      default: "",
    },
    boxClass: {
      type: String,
      default: "",
    },
  },
  emits: ["clickEdit", "clickNewApply"],
  data: function () {
    return {
      className: "",
      contentClass: "box bg-white",
      price: 3000,
    };
  },
  watch: {
    "dataResult.is_free": function (value) {
      if (value == 1) {
        this.className = "";
      } else {
        this.className = "red";
      }
    },
  },
  methods: {
    getPaymentMethod(value: any) {
      const methods: { [key: string]: any } = {
        "1": "現金",
        "2": "カード",
        "5": "Pay支払い",
      };
      return methods[value] ? methods[value] : "";
    },
    filterCurrency(value: string) {
      return util.getPrice(value, false) + "円（税込）";
    },
    getMaterial(result: any) {
      const collect_item = result.collect_item;
      const materialItems = [
        {
          label: "ダンボール",
          value: "2C01",
        },
        { label: "布団袋", value: "2C02" },
        { label: "クレープ用紙", value: "2C03" },
        { label: "ハンガーボックス", value: "2C04" },
        { label: "その他アート梱包資材", value: "2C05" },
      ];

      const cardbords = [
        "",
        "10枚以下",
        "10～30枚",
        "30～50枚",
        "50～100枚",
        "100枚以上",
      ];
      let str = "";
      if (collect_item.length > 0) {
        if (result.cardbord_num != "0")
          str = cardbords[result.cardbord_num] + ",";
        for (let i = 0; i < collect_item.length; i++) {
          const findItem = materialItems.filter(
            (j) => j.value == collect_item[i]
          );
          if (findItem.length > 0) str += findItem[0]["label"] + ", ";
        }
        return str;
      }
      return "";
    },
    getRequestLabel(result: any) {
      let request_list = [
        { id: "checkbox1", label: "洋服タンス", value: "3C01" },
        { id: "checkbox2", label: "和服タンス", value: "3C02" },
        { id: "checkbox3", label: "整理ダンス", value: "3C03" },
        { id: "checkbox4", label: "ベッド", value: "3C04" },
        { id: "checkbox5", label: "本棚", value: "3C05" },
        { id: "checkbox6", label: "食器棚", value: "3C06" },
        { id: "checkbox7", label: "ソファ", value: "3C07" },
        { id: "checkbox8", label: "薄型TV", value: "3C08" },
        { id: "checkbox9", label: "机", value: "3C09" },
        { id: "checkbox10", label: "上記以外の家具", value: "3C99" }
      ];
      request_list = request_list.filter(function (el: any) {
        return result.moving_furniture.indexOf(el.value) >= 0;
      });
      let items = (request_list || []).map(function (el: any) {
        return el["label"];
      });
      if (items.length > 0) {
        return items.join(", ");
      }
      return "";
    },
  },
  created() {
    
    if (this.dataResult.is_free !== 1) {
      this.className = "red";
    }
    if (
      new Date(this.dataResult.kaishu_date + "T00:00") <=
      new Date(new Date().toLocaleString("ja-JA", { timeZone: "Asia/Tokyo" }))
    ) {
      this.contentClass += " opacity";
    }
  },
});
