
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  methods: {
    close() {
      this.$emit("close");
    },
  },
  setup() {
    const store = useStore();
    const information = ref([
      {
        id: 1,
        title: "担当支店",
        content: "",
      },
      {
        id: 2,
        title: "電話番号",
        content: "",
      },
      {
        id: 3,
        title: "受付時間",
        content: "9:00〜19:00",
      },
      {
        id: 4,
        title: "注意事項",
        content:
          "定休日の場合は、お手数ですが、翌日にお掛け直しください。<br>(定休日の場合でも、最寄りの支店につながることがございます)",
      },
    ]);
    const modalClass = computed(() => {
      return store.state.device == "mobile"
        ? "modal mobile-container"
        : "modal container";
    });
    watch(
      () => store.state.delivery.branchInfo,
      () => {
        information.value[0].content =
          store.state.delivery.branchInfo.name + "支店";
        information.value[1].content = `<a href='tel:${store.state.delivery.branchInfo.tel}'>${store.state.delivery.branchInfo.tel}</a>`;
      }
    );
    return {
      information,
      modalClass,
    };
  },
});
