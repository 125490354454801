import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-387674ed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-block" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "mark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_label_and_input = _resolveComponent("label-and-input")!
  const _component_save_email_checkbox = _resolveComponent("save-email-checkbox")!
  const _component_input_block = _resolveComponent("input-block")!

  return (_openBlock(), _createBlock(_component_input_block, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
        (_ctx.mark)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_label_and_input, {
        label: _ctx.label1,
        type: _ctx.type1,
        inputmode: _ctx.inputmode1,
        placeholder: _ctx.placeholder1,
        width: _ctx.width1,
        margin: _ctx.margin1,
        value: _ctx.value1,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('input1', $event))),
        errMsg: _ctx.errMsg1,
        tooltips: _ctx.tooltips,
        hint: _ctx.hint1,
        hint2: _ctx.hint3
      }, null, 8, ["label", "type", "inputmode", "placeholder", "width", "margin", "value", "errMsg", "tooltips", "hint", "hint2"]),
      _createVNode(_component_label_and_input, {
        label: _ctx.label2,
        type: _ctx.type2,
        inputmode: _ctx.inputmode2,
        placeholder: _ctx.placeholder2,
        width: _ctx.width2,
        margin: _ctx.margin2,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input2', $event))),
        errMsg: _ctx.errMsg2,
        hint: _ctx.hint2,
        hint2: _ctx.hint4
      }, null, 8, ["label", "type", "inputmode", "placeholder", "width", "margin", "errMsg", "hint", "hint2"]),
      (_ctx.saveEmail)
        ? (_openBlock(), _createBlock(_component_save_email_checkbox, {
            key: 0,
            margin: _ctx.marginSaveEmail,
            fontSize: _ctx.fontSize,
            checked: _ctx.saveEmailChecked,
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change')))
          }, null, 8, ["margin", "fontSize", "checked"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}