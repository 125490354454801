import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12fcad99"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contents-block" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "pink"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (value, key) => {
      return _renderSlot(_ctx.$slots, "default", { key: key }, () => [
        (value.trim())
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(key), 1),
              _createElementVNode("div", {
                class: "content",
                innerHTML: value
              }, null, 8, _hoisted_4),
              (_ctx.getMessage(key))
                ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.getMessage(key)), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ], true)
    }), 128))
  ]))
}