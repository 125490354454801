
import { computed, defineComponent, ref } from "vue";
import ErrorMessageBlock from "../ErrorMessageBlock.vue";
import BaseInput from "./BaseInput.vue";
import TooltipsPopup from "./TooltipsPopup.vue";

export default defineComponent({
  components: { BaseInput, ErrorMessageBlock, TooltipsPopup },
  props: {
    device: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    inputmode: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "61.3vw",
    },
    margin: {
      type: String,
      default: "1rem 0 0 14.9vw",
    },
    errMsg: {
      type: String,
      default: "",
    },
    tooltips: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    hint2: {
      type: String,
      default: "",
    },
  },
  emits: ["input"],
  setup(props) {
    const isModal = ref(false);
    const style = computed(() => {
      return {
        margin: props.margin,
      };
    });
    const showModal = () => {
      isModal.value = true;
    };
    const hideModal = () => {
      isModal.value = false;
    };
    return {
      style,
      hideModal,
      showModal,
      isModal,
    };
  },
});
