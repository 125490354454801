
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    target: {
      type: String,
      default: ''
    },
    subItem: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const goods = ref(false);
    const reserved = ref(false);
    onMounted(() => {
      if (props.target === "goods") {
        goods.value = true;
      } else if (props.target === "reserved") {
        reserved.value = true;
      }
    });
    return {
      goods,
      reserved,
    };
  },
});
