
import { defineComponent } from "vue";
import BaseButton from "./../account/BaseButton.vue";
import Card from "./../furniture/Card.vue";

export default defineComponent({
  name: "CollectionService",
  components: {
    BaseButton,
    Card,
  },
  emits: ["click"],
});
