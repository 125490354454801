
import apis from "@/js/apis";
import useFurnitureInit from "@/js/useFurnitureInit";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import BookingDesktop from "./partials/BookingDesktop.vue";
import BookingMobile from "./partials/BookingMobile.vue";

export default defineComponent({
  components: { BookingMobile, BookingDesktop },
  setup() {
    const store = useStore();
    const route = useRoute();
    const address_list = ref([] as any);
    onMounted(async () => {
      // get user's address and tel
      await useFurnitureInit();
      const contract_id = store.state.furniture.contract_id;
      const detail = await apis.getContractDetail(contract_id);
      // address
      (detail.moving_info_list || []).forEach(
        async (moving_info: any, i: number) => {
          (moving_info.new_address_info_list || []).forEach(
            async (new_address: any, j: number) => {
              let item: any = {
                zipcode: new_address.zipcode,
                address_01: new_address.address_01,
                address_02: new_address.address_02,
                address_code: new_address.address_code,
              };

              address_list.value.push(item);
            }
          );
        }
      );
      store.dispatch("furniture/saveAddressList", address_list.value);
      store.dispatch("furniture/saveAddressBranch", detail.arrival_branch);

      // tel
      store.dispatch("furniture/saveTelList", detail.tel_list);
    });
    return {
      address_list,
    };
  },
});
