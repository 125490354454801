// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/goods/goods_top2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".goods[data-v-1cefb52a]{position:relative;z-index:-1}.goods .banner[data-v-1cefb52a]{width:100%;margin:0 auto;display:inline-block;position:relative;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50%}.goods .banner h1[data-v-1cefb52a]{font-size:48px}.goods .banner h1[data-v-1cefb52a],.goods .banner h2[data-v-1cefb52a]{background-color:#fff;padding:5px 10px;display:inline;line-height:7rem}.goods .banner[data-v-1cefb52a]:after{padding-top:26%;display:block;content:\"\"}.goods .banner .container>div[data-v-1cefb52a]{position:absolute;top:30%}.goods .banner>img[data-v-1cefb52a]{width:100%}.goods .button[data-v-1cefb52a]{padding:6rem 0;margin:6rem auto 0;text-align:center}.bg-white[data-v-1cefb52a],.goods .button[data-v-1cefb52a]{background:#fff}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
