
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getMessage(title: string) {
      const store = useStore();
      if (store.state.device === "mobile") {
        if (title == '梱包作業日（荷造り）' || title == '解梱作業日（荷解き）') {
          return "※詳細はオプションサービスにてご確認ください";
        }
      } else {
        if (title == '梱包作業日（荷造り）') {
          return "※梱包作業の詳細はオプションサービスにてご確認ください";
        } else if (title == '解梱作業日（荷解き）') {
          return "※解梱作業の詳細はオプションサービスにてご確認ください";
        }
      }
      return null;
    }
  }
});
