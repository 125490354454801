import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inquiries_confirmation_mobile = _resolveComponent("inquiries-confirmation-mobile")!
  const _component_inquiries_confirmation_desktop = _resolveComponent("inquiries-confirmation-desktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_inquiries_confirmation_mobile, {
          key: 0,
          onClick: _ctx.submit
        }, null, 8, ["onClick"]))
      : (_openBlock(), _createBlock(_component_inquiries_confirmation_desktop, {
          key: 1,
          onClick: _ctx.submit
        }, null, 8, ["onClick"]))
  ]))
}