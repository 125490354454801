
import BaseButton from "@/components/BaseButton.vue";
import ContractBlock from "@/components/mypage/ContractBlock.vue";
import ProfileBlock from "@/components/mypage/ProfileBlock.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import TooltipsPopup from "@/components/account/TooltipsPopup.vue";

import useRouteTo from "@/js/useRouteTo";
import { defineComponent, onMounted, ref, toRefs, watch } from "vue";
import apis from "@/js/apis";

export default defineComponent({
  components: {
    ContractBlock,
    BaseButton,
    Logo,
    Footer,
    ProfileBlock,
    TooltipsPopup,
  },
  props: {
    contractData: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      default: "",
    },
  },
  emits: ["click", "delete"],
  setup(props) {
    const isModal = ref(false);
    const tooltips = ref(
      `<p><h3 class="blue">（1）お客様番号 </h3>ご契約情報を示す固有の10桁の番号です。お見積書の左上に記載されております（以下<span class="red">赤枠</span>部分） <br/><span class="pink">※弊社からお送りするメールなどに記載されている「受付時ID」とは異なりますので、ご注意ください。 </span></p><br/><p><h3 class="blue">（2）お電話番号 </h3><span class="pink">お見積書に記載されているお電話番号</span>をご入力ください（以下<span class="green">緑枠</span>部分）</p><br/><img style='width: 100%; margin-top: 1rem' src='${require("../../../assets/addInformation1.png")}' />`
    );

    const { routeTo } = useRouteTo();
    const email = ref("");

    const showModal = () => {
      isModal.value = true;
    };
    const hideModal = () => {
      isModal.value = false;
    };

    const { contractData } = toRefs(props);
    watch(contractData, async () => {
      const userInfo = await apis.getUserInfo();
      email.value = userInfo.email;
    });

    return {
      routeTo,
      email,
      showModal,
      hideModal,
      isModal,
      tooltips,
    };
  },
});
