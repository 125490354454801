
import { computed, defineComponent, onUpdated, ref } from "vue";
import { useStore } from "vuex";
import useComputeClass from "../js/useComputeClass";

export default defineComponent({
  // props: {

  // },
  setup() {
    const store = useStore();
    const { computeClass } = useComputeClass();
    const top = ref(0);
    const left = ref(0);
    onUpdated(() => {
      console.log("onUpdated");
      const device = store.state.device;
      let imageWidthAndHeight: number;
      if (device === "pc") {
        imageWidthAndHeight = window.innerWidth * 0.2;
      } else {
        imageWidthAndHeight = window.innerWidth * 0.6;
      }
      const marginTop = ((imageWidthAndHeight - window.innerHeight) / 2) * -1;
      top.value = marginTop;
      const marginLeft = ((imageWidthAndHeight - window.innerWidth) / 2) * -1;
      left.value = marginLeft;
    });
    const style = computed(() => {
      return {
        top: top.value + "px",
        left: left.value + "px",
      };
    });
    return {
      style,
      computeClass,
    };
  },
});
