import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_mobile = _resolveComponent("price-mobile")!
  const _component_price_desktop = _resolveComponent("price-desktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_price_mobile, {
          key: 0,
          option_category_list: _ctx.detail.option_category_list || [],
          hasOption: _ctx.hasOption
        }, null, 8, ["option_category_list", "hasOption"]))
      : (_openBlock(), _createBlock(_component_price_desktop, {
          key: 1,
          option_category_list: _ctx.detail.option_category_list || [],
          hasOption: _ctx.hasOption
        }, null, 8, ["option_category_list", "hasOption"]))
  ]))
}