
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

import Checkbox from "./Checkbox.vue";
import Select from "./Select.vue";
import RadioButton from "./RadioButton.vue";
import TextareaBlock from "./TextareaBlock.vue";
import ExclamationIcon from "./ExclamationIcon.vue";
import BaseButton from "./../account/BaseButton.vue";

import FurnitureMixin from "@/mixins/furniture.vue";

export default defineComponent({
  name: "Booking",
  components: {
    Checkbox,
    Select,
    RadioButton,
    TextareaBlock,
    BaseButton,
    ExclamationIcon
  },
  props: {
    buttonTitle: {
      type: String,
      default: ""
    },
    msg: {
      type: String,
      default: "お申し込み頂いた後、お電話にて必ず最終確認させていただきます。"
    },
    className: {
      type: String,
      default: "blue"
    },
  },
  mixins: [FurnitureMixin],
  watch: {
    "$store.state.furniture.zipcode": function (value) {
      const state: any = this.$store.state;
      let furniture: any = state.furniture;
      if (furniture && furniture.contract_id) {
        const contract_id = furniture.contract_id;
        this.getAvailableDate(contract_id, value);
      }
    },
  },
  setup(props, context) {
    const store = useStore();

    const errMsg1 = ref("");
    const errMsg2 = ref("");
    const errMsg3 = ref("");
    const errMsg4 = ref("");
    const textMaxLength = ref(500);
    let houseHoldGoods: any[] = store.state.furniture.moving_furniture;
    let houseOther: any = store.state.furniture.other_furniture;
    let selectedDate: any = store.state.furniture.datetime;
    let phoneType: any = store.state.furniture.phone_type;
    let phoneNumber: any = store.state.furniture.phone_number;

    const inputGoods = (items: string[]) => {
      store.dispatch("furniture/inputGoods", items);
      houseHoldGoods = items;
    };

    const inputOther = (item: string) => {
      store.dispatch("furniture/inputOther", item);
      houseOther = item;
    };

    function selectDate(datetime: string) {
      store.dispatch("furniture/inputDatetime", datetime);
      selectedDate = datetime != "0" ? datetime : "";
    };
    const inputPhone = (item: string) => {
      store.dispatch("furniture/inputPhonetype", item);
      phoneType = item;
    };
    const selectPhone = (phone: string) => {
      store.dispatch("furniture/inputPhonenumber", phone);
      phoneNumber = phone;
    };
    const inputComment = (comment: string) => {
      if (comment.length <= textMaxLength.value) {
        errMsg4.value = "";
        store.dispatch("furniture/inputComment", comment);
      } else {
        errMsg4.value = "500文字以内で入力してください";
      }
      
    };
    const submit = () => {
      if (houseHoldGoods.length > 0) {
        errMsg1.value = "";
        if (houseHoldGoods.indexOf("上記以外の家具") >= 0 && !houseOther) {
          errMsg1.value = "上記以外の家具を入力してください";
        }
      } else {
        errMsg1.value = "選択してください";
      }

      if (store.state.furniture.datetime) {
        errMsg2.value = "";
        store.dispatch("furniture/setDatetimeDone", true);
      } else {
        errMsg2.value = "選択してください";
      }

      errMsg3.value = "";
      if (store.state.furniture.phone_number) {
        errMsg3.value = "";
      } else {
        errMsg3.value = "連絡先電話番号を入力してください";
      }

      if (!errMsg1.value && !errMsg2.value && !errMsg3.value && !errMsg4.value) {
        context.emit("click");
      } else {
        window.scrollTo(0, 0);
      }
    };
    return {
      inputGoods,
      inputOther,
      selectDate,
      selectPhone,
      inputComment,
      inputPhone,
      submit,
      errMsg1,
      errMsg2,
      errMsg3,
      errMsg4,
      textMaxLength
    };
  },
  emits: ["click"],
});
