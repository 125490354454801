import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b1a03ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "date-div" }
const _hoisted_4 = { class: "date" }
const _hoisted_5 = { class: "week" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "text"
}
const _hoisted_8 = {
  key: 1,
  class: "text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "delivery-div",
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.month.trim())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.fullDate), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.week), 1)
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.noDelivery)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, "配達予定なし"))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, "日程未定"))
        ]))
  ], 4))
}