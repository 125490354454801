import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactOptionMobile = _resolveComponent("ContactOptionMobile")!
  const _component_ContactOptionDesktop = _resolveComponent("ContactOptionDesktop")!

  return (_ctx.$store.state.device === 'mobile')
    ? (_openBlock(), _createBlock(_component_ContactOptionMobile, {
        key: 0,
        detail: _ctx.$store.state.confirmation.contractDetail,
        hasOption: _ctx.hasOption
      }, null, 8, ["detail", "hasOption"]))
    : (_openBlock(), _createBlock(_component_ContactOptionDesktop, {
        key: 1,
        detail: _ctx.$store.state.confirmation.contractDetail,
        hasOption: _ctx.hasOption
      }, null, 8, ["detail", "hasOption"]))
}