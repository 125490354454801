
import Steps from "@/components/furniture/Steps.vue";
import BookingScheduleView from "@/components/collection/BookingScheduleView.vue";
import { defineComponent, onMounted } from "vue";
import useRouteTo from "../../js/useRouteTo";
import { useStore } from "vuex";

export default defineComponent({
  components: { Steps, BookingScheduleView },
  data: function () {
    return {
      items: [
        { label: "住所選択", class: "current" },
        { label: "日程選択", class: "current" },
        { label: "内容確認" },
        { label: "申込完了" },
      ],
      msg: "今回の回収は無料でご利用いただけます",
    };
  },
  watch: {
  '$store.state.collection.free_or_paid': function(value) {
      if (value == "paid") {
        this.msg = "今回の回収は3,000円(税込)で承ります";
      } else {
        this.msg = "今回の回収は無料でご利用いただけます";
      }
    }
  },
  setup() {
    const store = useStore();

    const { routeTo } = useRouteTo();
    const submit = () => {
      routeTo("ConfirmationCollection");
    };

    onMounted(async () => {
      // check if step1 is already done
      if (!store.state.collection.address_done) {
        routeTo("BookingCollection");
      }
    });
    return {
      submit,
    };
  },
  created() {
    const store = useStore();
    if (store.state.collection.free_or_paid == "paid") {
      this.msg = "今回の回収は3,000円(税込)で承ります";
    }
  },
});
