
import ConfirmationView from "@/components/furniture/FurnitureLineView.vue";
import BaseButton from "@/components/BaseButton.vue";

import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { ConfirmationView, BaseButton },
  props: {
    furnitures: {
      type: Object,
    },
    status: {
      type: String,
      default: ""
    },
  },
  data: function () {
    return {
      furniture: {
        zipcode: "",
        kaishu_date: "",
        kaishu_time: "",
        address_01: "",
        address_02: "",
        address_code: "",
        tel: "",
        request: [],
      },
      msg2: "",
    };
  },
  created() {
    const store = useStore();
    const sampleData = {
      datetime: "11月30日(火)",
      time: "午前中",
      zipcode: "565-0846",
      city: "吹田市千里山虹が丘 大阪府",
      address: "１０−１ シエリアシティ千里山B棟７０５",
      phone_number: "070-1234-5678",
      request: "選択してください",
    };
  },
  emits: ["clickEdit", "clickNewApply"],
  setup(props, context) {
    const click = (data: any) => {
      console.log("click")
    };
    return {
      click,
    };
  },
});
