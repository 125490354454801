
import apis from "@/js/apis";
import { defineComponent, onMounted, ref } from "vue";
import PriceDesktop from "./partials/PriceDesktop.vue";
import PriceMobile from "./partials/PriceMobile.vue";

export default defineComponent({
  components: { PriceMobile, PriceDesktop },
  // props: {

  // },
  setup() {
    const detail = ref<any>({});
    const hasOption = ref(false);
    onMounted(async () => {
      detail.value = await apis.checkIfContractDetailInStore();
      if (detail.value && detail.value.option_category_list) {
        for (let category_list of detail.value.option_category_list) {
          if (category_list.service_kbn) {
            hasOption.value = true;
            break;
          }
        }
      }
    });
    return {
      detail,
      hasOption,
    };
  },
});
