
import BaseButton from "@/components/BaseButton.vue";
import EmailAndPassword from "@/components/account/EmailAndPassword.vue";
import TextBlock from "@/components/account/TextBlock.vue";
import { defineComponent, ref } from "vue";
import useRouteTo from "../../js/useRouteTo";
import ContainerBlock from "@/components/account/ContainerBlock.vue";
import InputBlock from "@/components/account/InputBlock.vue";
import LabelAndInput from "@/components/account/LabelAndInput.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { useStore } from "vuex";
import apis from "@/js/apis";
import util from "@/js/util";

export default defineComponent({
  components: {
    TextBlock,
    EmailAndPassword,
    BaseButton,
    ContainerBlock,
    InputBlock,
    LabelAndInput,
    Logo,
    Footer,
  },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const email = ref("");
    const errMsg = ref("");
    const submit = async () => {
      // validation
      // email validation
      if (!util.validateEmail(email.value)) {
        errMsg.value = "";
      } else {
        errMsg.value = util.validateEmail(email.value);
      }

      if (!errMsg.value) {
        // start loading
        store.dispatch("startLoading", true);

        // API Call -> POST email
        const body = {
          email: email.value,
        };
        const result = await apis.callAPI(
          "put",
          apis.endpoints.editEmail,
          store.state.jwt,
          body
        );
        if (result && result.data.status === "success") {
          routeTo("SendEmailForEditEmail");
        }
      } else {
        window.scrollTo(0, 0);
      }
    };
    return {
      email,
      errMsg,
      // inputEmail,
      submit,
    };
  },
});
