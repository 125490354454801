import router from "@/router";
import store from "@/store";
import checkDevice from "./checkDevice";

export default function () {
  // clear local storagea
  localStorage.clear();

  // clear vuex store
  store.reset();

  // check if device is mobile or pc
  checkDevice();

  router.push({ name: "Login" });
}
