import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dcffcb56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirmation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactPacking = _resolveComponent("ContactPacking")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ContactPacking, {
      contact: _ctx.detail,
      info: _ctx.info
    }, null, 8, ["contact", "info"])
  ]))
}