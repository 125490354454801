
import { defineComponent } from "vue";
import YenSVG from './icon/Yen.vue';
import CircleSVG from './icon/Circle.vue';

export default defineComponent({
  components: { YenSVG, CircleSVG },
  props: {
    mark: {
      type: String,
      required: true,
    },
  },
  // setup() {},
});
