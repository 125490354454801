import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-770da5ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.errMsg)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["error-from-server", _ctx.computeClass]),
        innerHTML: _ctx.errMsg
      }, null, 10, _hoisted_1))
    : _createCommentVNode("", true)
}