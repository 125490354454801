
import { defineComponent } from "vue";
import { useStore } from "vuex";

import RadioButton from "./RadioButton.vue";
import ExclamationIcon from "./ExclamationIcon.vue";
import AddressBlock from "./AddressBlock.vue";

export default defineComponent({
  name: "Booking",
  components: {
    RadioButton,
    ExclamationIcon,
    AddressBlock,
  },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    msg: {
      type: String,
      default: "",
    },
    selectedValue: {
      type: String,
      default: "",
    },
    erroMsg: {
      type: String,
      default: "",
    },
    address_list: {
      type: Object,
    },
  },
  data: function () {
    return {
      items: [
        {
          id: "radio1",
          label:
            "<strong>〒565-0846</strong> <br/><strong>大阪府吹田市千里山虹が丘１０−１ シエリアシティ千里山B棟７０５</strong>",
          value:
            "〒565-0846 大阪府吹田市千里山虹が丘１０−１ シエリアシティ千里山B棟７０５",
        },
        {
          id: "radio2",
          label: "<strong>上記以外の住所</strong>",
          value: "上記以外の住所",
        },
      ],
      selected:
        "〒565-0846 大阪府吹田市千里山虹が丘１０−１ シエリアシティ千里山B棟７０５",
      selectedZipcode: null,
      selectedAddress: null,
      selectedCity: null,
      selectedAddressCode: null,
      className: "",
      multipleZipcode: false,
    };
  },
  created() {
    const store = useStore();
    if (store.state.furniture.free_or_paid == "paid") {
      this.className = "red";
    }
  },
  watch: {
    selected: function (val) {
      if (val && val != "上記以外の住所") {
        this.selectedZipcode = null;
        this.selectedCity = null;
        this.selectedAddress = null;
        this.$emit("changeMessage", 0);
      } else {
        this.$emit("changeMessage", 4);
      }
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    let selectZipcode = "";
    let selectAddess = "";
    let selectCity = "";

    const inputZipcode = (zipcode: string) => {
      store.dispatch("furniture/inputZipcode", zipcode);

      selectZipcode = zipcode;
      if (
        selectZipcode.length > 0 &&
        selectAddess.length > 0 &&
        selectCity.length > 0
      ) {
        emit("redirect", store.state.furniture);
      }
    };
    const inputCity = (city: string) => {
      store.dispatch("furniture/inputCity", city);

      selectCity = city;
      if (
        selectZipcode.length > 0 &&
        selectAddess.length > 0 &&
        selectCity.length > 0
      ) {
        emit("redirect", store.state.furniture);
      }
    };
    const inputAddress = (address: string) => {
      store.dispatch("furniture/inputAddress", address);
      selectAddess = address;
    };
    const changeSelect = (item: string) => {
      store.dispatch("furniture/inputAddressCode", item);
    };
    return {
      inputZipcode,
      inputAddress,
      inputCity,
      changeSelect,
    };
  },
  mounted: function () {
    const store = useStore();
    this.selected = this.$props.selectedValue;
    const furniture = store.state.furniture
    this.selectedZipcode = furniture.zipcode;
    this.selectedCity = furniture.address_01;
    this.selectedAddress = furniture.address_02;
    this.selectedAddressCode = furniture.address_code;
  },
  methods: {
    updateMessage(item: string): void {
      this.$emit("changeMessage", item);
    },
    updateItem(item: string): void {
      this.selected = item;
      this.$emit("change", item);
    },
    getAddressOptions(): any {
      const state: any = this.$store.state;
      const address = state.furniture.address_list || [];
      let options = [];

      for (var i = 0; i < address.length; i++) {
        let item: any = {
          id: "radio" + i,
          label:
            "<strong>〒" +
            address[i]["zipcode"] +
            "</strong> <br/><strong>" +
            `${address[i]["address_01"]}${address[i]["address_02"]}` +
            "</strong>",
          value: address[i]["zipcode"].replace("-", ""),
        };
        if (address[i]["address_list"]) {
          let subItems = [];
          for (var j = 0; j < address[i]["address_list"].length; j++) {
            subItems.push({
              label: `${address[i]["address_list"][j]["prefecture"]}${address[i]["address_list"][j]["municipality"]}${address[i]["address_list"][j]["specific_area"]}`,
              value: address[i]["address_list"][j]["jis_address_code"],
            });
          }
          item["selectItems"] = subItems;
        }
        options.push(item);
      }
      if (options.length > 0) {
        this.multipleZipcode = options.filter((el) => el.value.length == 0).length == 0
      } else {
        this.multipleZipcode = false;
      }
      options.push({
        id: "radio" + address.length,
        label: "<strong>上記以外の住所</strong>",
        value: "上記以外の住所",
      });
      return options;
    },
  },
  emits: ["change", "redirect", "changeMessage"],
});
