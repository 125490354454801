
import { computed, defineComponent } from "vue";
import HorizontalLine from "./HorizontalLine.vue";
import SymbolMark from "./SymbolMark.vue";

export default defineComponent({
  components: { HorizontalLine, SymbolMark },
  props: {
    blank: {
      type: Boolean,
      default: false,
    },
    mark: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "#0060B9",
    },
    bgColor: {
      type: String,
      default: "#ffffff",
    },
    bgImage: {
      type: String,
      default: "",
    },
    nodetail: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "",
    },
    opacity: {
      type: String,
      default: "1",
    },
    height: {
      type: String,
      default: "",
    },
    radius: {
      type: String,
      default: "2rem",
    },
    buttonBottom: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: "詳細を見る ＞",
    },
    buttonColor: {
      type: String,
      default: "#555555",
    },
    boxClass: {
      type: String,
      default: "",
    },
    showTop: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["clickDetail"],
  setup(props) {
    const className = computed(() => {
      return "box-container bound-no-gray " + props.boxClass;
    });
    const style = computed(() => {
      if (props.bgImage) {
        return {
          "background-image": `url(${props.bgImage})`,
          color: props.color,
          width: props.width || "84vw",
          height: props.height || "14.5rem",
          borderRadius: props.radius || "2rem",
          opacity: props.opacity,
        };
      } else {
        return {
          "background-color": props.bgColor,
          width: props.width || "84vw",
          height: props.height || "14.5rem",
          borderRadius: props.radius || "2rem",
          opacity: props.opacity,
        };
      }
    });
    return {
      style,
      className,
    };
  },
});
