import { useRoute } from "vue-router";
import { useStore } from "vuex";
import apis from "./apis";
import useRedirectMypageForConfirmation from "./useRedirectMypageForConfirmation";
import useRouteTo from "./useRouteTo";

export default async function () {
  const store = useStore();
  const route = useRoute();
  const { routeTo } = useRouteTo();
  // check if contract detail exists in store
  if (store.state.confirmation.contractDetail && Object.keys(store.state.confirmation.contractDetail).length > 0) {
    //check if confirmation is already finished
    const res = await apis.checkIfConfirmationIsDone();
    if (res == true) {
      useRedirectMypageForConfirmation(
        store.state.confirmation.contract_id,
        store.state.confirmation.contractDetail
      );
      routeTo("MyPageInfo");
    } else if (res == false) {
      return "ok";
    }
  } else {
    // if no, redirect to the first page with query params
    const query = route.query;
    routeTo("ContactSwitch", query);
  }
}
