import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inquiries_form_mobile = _resolveComponent("inquiries-form-mobile")!
  const _component_inquiries_form_desktop = _resolveComponent("inquiries-form-desktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_inquiries_form_mobile, {
          key: 0,
          params: _ctx.params,
          url: _ctx.url,
          options: _ctx.options
        }, null, 8, ["params", "url", "options"]))
      : (_openBlock(), _createBlock(_component_inquiries_form_desktop, {
          key: 1,
          params: _ctx.params,
          url: _ctx.url,
          options: _ctx.options
        }, null, 8, ["params", "url", "options"]))
  ]))
}