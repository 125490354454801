import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d02afb9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "email-checkbox-div",
    style: _normalizeStyle(_ctx.styleMargin)
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      id: "email-checkbox",
      checked: _ctx.checked,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change')))
    }, null, 40, _hoisted_1),
    _createElementVNode("label", {
      for: "email-checkbox",
      class: "checkbox-text",
      style: _normalizeStyle(_ctx.styleFont)
    }, " 次回からメールアドレス入力を省略する ", 4)
  ], 4))
}