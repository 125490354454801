import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-521b773f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "goods" }
const _hoisted_2 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReservedView = _resolveComponent("ReservedView")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ReservedView, {
      bookedGoods: _ctx.bookedGoods,
      msg: _ctx.popupMessage,
      onClickDelete: _cache[0] || (_cache[0] = (id) => _ctx.$emit('clickDelete', id))
    }, null, 8, ["bookedGoods", "msg"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_base_button, {
          buttonText: "商品を追加する",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click')))
        })
      ])
    ])
  ]))
}