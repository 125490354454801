import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailMobile = _resolveComponent("DetailMobile")!
  const _component_DetailDesktop = _resolveComponent("DetailDesktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_DetailMobile, {
          key: 0,
          dataResult: _ctx.goodsDetail,
          bookedGoods: _ctx.bookedGoods,
          variant_id: _ctx.variant_id,
          recommendedGoods: _ctx.recommendedGoods,
          preview: _ctx.preview,
          onClickReserve: _ctx.clickReserve,
          onGoTop: _ctx.goTop,
          onGoReserved: _ctx.goReserved
        }, null, 8, ["dataResult", "bookedGoods", "variant_id", "recommendedGoods", "preview", "onClickReserve", "onGoTop", "onGoReserved"]))
      : (_openBlock(), _createBlock(_component_DetailDesktop, {
          key: 1,
          dataResult: _ctx.goodsDetail,
          recommendedGoods: _ctx.recommendedGoods,
          preview: _ctx.preview,
          bookedGoods: _ctx.bookedGoods,
          variant_id: _ctx.variant_id,
          onClickReserve: _ctx.clickReserve,
          onGoTop: _ctx.goTop,
          onGoReserved: _ctx.goReserved
        }, null, 8, ["dataResult", "recommendedGoods", "preview", "bookedGoods", "variant_id", "onClickReserve", "onGoTop", "onGoReserved"]))
  ]))
}