import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7ada6a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 0,
  class: "text-block"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "button-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      innerHTML: _ctx.title,
      class: "title"
    }, null, 8, _hoisted_1),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", {
            innerHTML: _ctx.text,
            class: "text"
          }, null, 8, _hoisted_3),
          (_ctx.buttonText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_base_button, {
                  buttonText: _ctx.buttonText,
                  width: _ctx.width,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
                }, null, 8, ["buttonText", "width"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}