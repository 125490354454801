
import ConfirmationView from "@/components/furniture/ConfirmationView.vue";
import Steps from "@/components/furniture/Steps.vue";
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import useRouteTo from "../../../js/useRouteTo";
import usePseudoAPICall from "../../../js/usePseudoAPICall";

export default defineComponent({
  components: { ConfirmationView, Steps },
  data: function () {
    return {
      items: [
        { label: "住所選択", class: "current" },
        { label: "日程選択", class: "current" },
        {
          label: "内容確認",
          class: "current",
        },
        { label: "申込完了" },
      ],
      furniture: {},
    };
  },
  emits: ["click"],
  created() {
    const store = useStore();
    this.furniture = reactive({ ...store.state.furniture });
  },
});
