
import Steps from "@/components/furniture/Steps.vue";
import BookingScheduleView from "@/components/furniture/BookingScheduleView.vue";
import { defineComponent, onMounted } from "vue";
import useRouteTo from "../../js/useRouteTo";
import { useStore } from "vuex";

export default defineComponent({
  components: { Steps, BookingScheduleView },
  data: function () {
    return {
      items: [
        { label: "住所選択", class: "current" },
        { label: "日程選択", class: "current" },
        { label: "内容確認" },
        { label: "申込完了" },
      ],
    };
  },
  setup() {
    const store = useStore();
    const { routeTo } = useRouteTo();
    const submit = () => {
      routeTo("ConfirmationFurniture");
    };

    onMounted(async () => {
      // check if step1 is already done
      if (!store.state.furniture.address_done) {
        routeTo("BookingFurniture");
      }
    });

    return {
      submit,
    };
  },
});
