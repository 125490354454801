import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cee7f22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mark-wrapper" }
const _hoisted_2 = { class: "mark-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_YenSVG = _resolveComponent("YenSVG")!
  const _component_CircleSVG = _resolveComponent("CircleSVG")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.mark == 'yen')
        ? (_openBlock(), _createBlock(_component_YenSVG, { key: 0 }))
        : (_openBlock(), _createBlock(_component_CircleSVG, { key: 1 }))
    ])
  ]))
}