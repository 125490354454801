import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_flickity = _resolveComponent("flickity")!
  const _component_product_grid = _resolveComponent("product-grid")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.recommendedGoods.length > 0)
      ? (_openBlock(), _createBlock(_component_flickity, {
          key: 0,
          ref: "flickity",
          options: {..._ctx.flickityOptions, draggable: _ctx.$store.state.device !== 'pc', prevNextButtons: _ctx.$store.state.device === 'pc'}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recommendedGoods, (item, index) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                to: `/goods/detail/${item.product_id}`,
                key: index,
                class: "carousel-cell",
                style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.getImgUrl(item.product.images) + ')' })
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("h3", null, _toDisplayString(item.product.subtitle), 1),
                    _createElementVNode("p", null, _toDisplayString(item.product.name), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["to", "style"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["options"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.className)
    }, [
      _createVNode(_component_product_grid, { products: _ctx.allGoods }, null, 8, ["products"])
    ], 2)
  ]))
}