
import useCheckStoreForConfirmation from "@/js/useCheckStoreForConfirmation";
import { defineComponent, onMounted } from "vue";

import ContactSuccessDesktop from "./partials/ContactSuccessDesktop.vue";
import ContactSuccessMobile from "./partials/ContactSuccessMobile.vue";

export default defineComponent({
  components: { ContactSuccessMobile, ContactSuccessDesktop },
  setup() {
    onMounted(() => {
      // useCheckStoreForConfirmation();
    });
  },
});
