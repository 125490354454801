
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: ""
    },
  },
  // setup() {},
});
