
import ContactSuccess from "@/components/confirmation/desktop/ContactSuccess.vue";
import Steps from "@/components/furniture/Steps.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { ContactSuccess, Steps, Logo, Footer },
  data: function () {
    return {
      items: [
        { label: "1. 基本情報", class: "current" },
        { label: "2. 作業内容", class: "current" },
        { label: "3. 料金確認", class: "current" },
        { label: "4. 完了", class: "current" },
      ]
    };
  },
});
