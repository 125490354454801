import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_mobile = _resolveComponent("menu-mobile")!
  const _component_menu_desktop = _resolveComponent("menu-desktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_menu_mobile, {
          key: 0,
          contractData: _ctx.contractData,
          selected: _ctx.selected,
          onClick: _ctx.selectContract,
          onDelete: _ctx.deleteContract
        }, null, 8, ["contractData", "selected", "onClick", "onDelete"]))
      : (_openBlock(), _createBlock(_component_menu_desktop, {
          key: 1,
          contractData: _ctx.contractData,
          selected: _ctx.selected,
          onClick: _ctx.selectContract,
          onDelete: _ctx.deleteContract
        }, null, 8, ["contractData", "selected", "onClick", "onDelete"]))
  ]))
}