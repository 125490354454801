
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    className: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const style = computed(() => {
      return props.className + " mark exclamation-point";
    });
    return {
      style,
    };
  },
});
