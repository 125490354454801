
import Balloon from "@/components/mypage/Balloon.vue";
import BaseLongBox from "@/components/mypage/BaseLongBox.vue";
import DatetimeShortBox from "@/components/mypage/DatetimeShortBox.vue";
import DeliveryDiv from "@/components/mypage/DeliveryDiv.vue";
import PriceDiv from "@/components/mypage/PriceDiv.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";
import PriceModal from "./PriceModal.vue";
import { computed, defineComponent, inject, onMounted, ref } from "vue";
import useRouteTo from "../../../js/useRouteTo";
import useGoTo from "../../../js/useGoTo";
import usePseudoAPICall from "../../../js/usePseudoAPICall";
import util from "@/js/util";
import { useStore } from "vuex";
import openAQC from "@/js/openAQC";

export default defineComponent({
  components: {
    Balloon,
    DatetimeShortBox,
    BaseLongBox,
    PriceDiv,
    DeliveryDiv,
    Logo,
    Footer,
    PriceModal
  },
  props: {
    currentInfo: {
      type: Object,
      required: true,
    },
    done: {
      type: Boolean,
      required: true,
    },
    aqcQuestionnaire: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ukt_no: null as any,
    };
  },
  watch: {
    aqcQuestionnaire: function (val) {
      if (val) {
        const openHelp = () => {
          openAQC()
        };
        util.openAlert(this.$store, "アンケートに回答する", "", "※既にアンケートの入力がお済みの場合は回答不要です。", openHelp, "アンケートのお願い", true);
      }
    },
  },
  setup(props) {
    const { routeTo } = useRouteTo();
    const { goTo } = useGoTo();
    const { pseudoAPICall } = usePseudoAPICall();
    const store = useStore();
    const phase = inject("phase") as number;
    const wording = inject("wording");
    const accordion = ref(false);
    const transformOrigin = ref("48% 48%");

    const isPriceModalVisible = ref(false);
    const togglePriceModal = (value: boolean) => {
      isPriceModalVisible.value = value
    }

    const datetimeText = computed(() => {
      if (props.done) {
        return "引越し完了";
      } else {
        return "お引越作業日";
      }
    });
    const computeBGColor = computed(() => {
      if (props.done) {
        return "#555555";
      } else {
        return "#ffffff";
      }
    });
    const loadingRoute = (name: string) => {
      // API Call -> GET data
      // For now, pseudo api call
      pseudoAPICall(name);
    };
    const expand = (event: MouseEvent) => {
      transformOrigin.value = "48% " + event.clientY + "px";
      accordion.value = true;
      // routeTo(route);
    };
    const loadingExpand = (event: any, route: string) => {
      // target.classList.add("bound");
      // store.state.loading = true;

      // const time = (Math.random() + 1) * 1000;
      // setTimeout(() => {
      //   store.state.loading = false;
      //   expand(event, route);
      // }, time);

      setTimeout(() => {
        expand(event);
        setTimeout(() => {
          pseudoAPICall(route);
        }, 200);
      }, 100);
    };
    const style = computed(() => {
      return {
        "transform-origin": transformOrigin.value,
        height: document.body.scrollHeight + "px",
      };
    });
    const showAlert = util.showAlert;
    const clickCollection = () => {
      if (phase >= 2) {
        if (props.done) {
          if (
            props.currentInfo.newer_than_93 &&
            props.currentInfo.noCollection
          ) {
            util.openAlert(store, "OK", "", "お引越し日から３ヶ月が経過しましたので、資材回収をご希望の場合はお手数ではございますが、担当支店までご連絡お願いいたします。<br/>" +
                  store.state.mypage.contractDetail.new_address_main_branch.name +
                  "支店<br/><a href='tel:"+store.state.mypage.contractDetail.new_address_main_branch.tel+"'>" +
                  store.state.mypage.contractDetail.new_address_main_branch.tel + "</a>", () => util.closeAlert(store))
          } else {
            routeTo("CollectionDetail");
          }
        } else {
          util.openAlert(store, "OK", "", "お引越し後にお申し込みいただけます", () => util.closeAlert(store))
        }
      } else {
        showAlert(store);
      }
    };
    const openRakumo = () => {
      window.open("https://www.the0123.com/rakumo/");
    };

    return {
      routeTo,
      goTo,
      wording,
      accordion,
      datetimeText,
      computeBGColor,
      loadingRoute,
      expand,
      loadingExpand,
      style,
      phase,
      showAlert,
      clickCollection,
      openRakumo,
      togglePriceModal,
      isPriceModalVisible
    };
  },
  create() {
    this.ukt_no = localStorage.getItem("ukt_no") || "";
  },
  mounted() {
    window.addEventListener("ukt_no-localstorage-changed", (event: any) => {
      this.ukt_no = event.detail.ukt_no || "";
    });
  },
});
