
import PaidCollectionView from "@/components/collection/desktop/PaidCollectionView.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { defineComponent } from "vue";
import useRouteTo from "../../../js/useRouteTo";

export default defineComponent({
  components: {
    PaidCollectionView,
    Logo,
    Footer,
  },
  setup() {
    const { routeTo } = useRouteTo();
    const submit = () => {
      routeTo("BookingCollection");
    };
    return {
      submit,
    };
  },
});
