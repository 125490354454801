import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69382e54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationIcon = _resolveComponent("ExclamationIcon")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.style)
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("strong", _hoisted_1, [
        _createVNode(_component_ExclamationIcon, { className: "red" }),
        _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("p", { innerHTML: _ctx.msg }, null, 8, _hoisted_2),
      (_ctx.buttonText)
        ? (_openBlock(), _createBlock(_component_base_button, {
            key: 0,
            className: "red",
            buttonText: _ctx.buttonText,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
          }, null, 8, ["buttonText"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}