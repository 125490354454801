
import util from "@/js/util";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  setup() {
    const getJapaneseDate = util.getJapaneseDate;
    // const convertNewLine = util.convertNewLine
    return {
      getJapaneseDate,
      // convertNewLine
    };
  },
});
