
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import InquiriesFormDesktop from "./partials/InquiriesFormDesktop.vue";
import InquiriesFormMobile from "./partials/InquiriesFormMobile.vue";
import useRouteTo from "@/js/useRouteTo";
import apis from "@/js/apis";

export default defineComponent({
  components: { InquiriesFormMobile, InquiriesFormDesktop },
  setup() {
    const store = useStore();
    const { routeTo } = useRouteTo();
    const url = ref("https://office.the0123.com/artmyp/frmartmyp_post.aspx");
    const params = ref({});

    const options = ref([
      {
        label: "契約内容の変更や追加について（オプション作業・お支払方法など）",
        value: "契約内容の変更や追加について（オプション作業・お支払方法など）",
      },
      {
        label: "ご住所について（変更・修正）",
        value: "ご住所について（変更・修正）",
      },
      {
        label: "お引越のご準備や当日作業について（荷造り・資材配達など）",
        value: "お引越のご準備や当日作業について（荷造り・資材配達など）",
      },
      {
        label: "アフターサービスについて（家具移動サービス・資材回収など）",
        value: "アフターサービスについて（家具移動サービス・資材回収など）",
      },
      {
        label: "領収書について",
        value: "領収書について",
      },
      {
        label: "その他",
        value: "その他",
      },
      {
        label: "引越マイページ退会希望",
        value: "引越マイページ退会希望",
      },
    ]);

    onMounted(async () => {
      try {
        // get selected number in local storage
        const contract_id =
          (localStorage.getItem("selectedNumber") as string) || "";

        if (contract_id) {
          const result = await apis.callAPI(
            "get",
            apis.endpoints.getInquiryParams.replace("{}", contract_id),
            store.state.jwt
          );
          if (result && result.data.status === "success") {
            params.value = result.data.result;
          }
        } else {
          routeTo("MyPageMenu");
        }
      } catch {
        routeTo("MyPageMenu");
      }
    });
    return {
      url,
      params,
      options,
    };
  },
});
