
import { computed, defineComponent } from "vue";
import useRouteTo from "@/js/useRouteTo";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    },
    secure: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ""
    },
  },
  setup(props) {
    const className = computed(() => {
      return `number ${props.secure ? 'password' : ''}`
    });
    const { routeTo } = useRouteTo();
    return {
      routeTo,
      className
    };
  }
});
