
import BackgroungImageBlock from "@/components/mypage/BackgroungImageBlock.vue";
import ContentsToggleBlock from "@/components/mypage/ContentsToggleBlock.vue";
import TitleBlock from "@/components/mypage/TitleBlock.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { TitleBlock, ContentsToggleBlock, BackgroungImageBlock },
  props: {
    option_category_list: {
      type: Array,
      required: true,
    },
    hasBanner: {
      type: Boolean,
      default: true,
    },
    hasOption: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.state.mypage.topbar = "お支払い金額";
    });
  },
});
