import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f918adf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "radio" }
const _hoisted_2 = { class: "radio_group" }
const _hoisted_3 = ["type", "id", "name", "value", "disabled"]
const _hoisted_4 = { class: "label" }
const _hoisted_5 = ["for"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_input_block = _resolveComponent("input-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.filter((el) => el.label != '設定なし'), (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.value
        }, [
          _withDirectives(_createElementVNode("input", {
            type: _ctx.type,
            id: item.id,
            name: _ctx.name,
            value: item.value,
            disabled: item.value.length == 0,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedInput) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateItem && _ctx.updateItem(...args)))
          }, null, 40, _hoisted_3), [
            [_vModelDynamic, _ctx.selectedInput]
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", {
              for: item.id
            }, [
              _createElementVNode("span", {
                class: _normalizeClass({disable: item.value.length == 0}),
                innerHTML: item.label
              }, null, 10, _hoisted_6)
            ], 8, _hoisted_5),
            (item.selectItems)
              ? (_openBlock(), _createBlock(_component_Select, {
                  key: 0,
                  placeholder: 
              _ctx.name == 'booking_radio_material[]' || _ctx.name == 'booking_radio_phone' ? '' : '選択してください'
            ,
                  items: item.selectItems,
                  name: item.id,
                  onChange: _ctx.changeSelect,
                  selected: _ctx.selectValue
                }, null, 8, ["placeholder", "items", "name", "onChange", "selected"]))
              : _createCommentVNode("", true),
            (item.label == 'その他' || item.label == 'その他の電話番号')
              ? (_openBlock(), _createBlock(_component_input_block, {
                  key: 1,
                  onInput: _ctx.changeInput,
                  value: _ctx.inputValue,
                  width: _ctx.buttonWidth,
                  placeholder: 
              _ctx.name == 'booking_radio_phone' ? '半角数字、ハイフンのみ' : ''
            ,
                  allowHankaku: _ctx.name != 'booking_radio_phone'
                }, null, 8, ["onInput", "value", "width", "placeholder", "allowHankaku"]))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ])
  ]))
}