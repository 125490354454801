import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/loading_20220425.gif'


const _withScopeId = n => (_pushScopeId("data-v-74f644b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.$store.state.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Loading",
          id: "loading",
          style: _normalizeStyle(_ctx.style),
          class: _normalizeClass(_ctx.computeClass)
        }, null, 6)
      ]))
    : _createCommentVNode("", true)
}