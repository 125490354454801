
import Steps from "@/components/furniture/Steps.vue";
import BookingView from "@/components/collection/BookingView.vue";
import Modal from "@/components/Modal.vue";
import useRouteTo from "../../../js/useRouteTo";

import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import CollectionAddressMixin from "@/mixins/collectionAddress.vue";

export default defineComponent({
  components: { Steps, BookingView, Modal },
  props: {
    address_list: {
      type: Object,
    },
  },
  mixins: [CollectionAddressMixin],
  data: function () {
    return {
      items: [
        { label: "住所選択", class: "current" },
        { label: "日程選択" },
        { label: "内容確認" },
        { label: "申込完了" },
      ],
      msg: "今回の回収は無料でご利用いただけます",
    };
  },
  setup() {
    const { routeTo } = useRouteTo();
    const store = useStore();
    const hideModal = () => {
      store.dispatch("collection/setAddressDone", true);
      routeTo("CollectionSchedule");
    };
    return {
      hideModal,
    };
  },
  watch: {
    "$store.state.collection.free_or_paid": function (value) {
      if (value == "paid") {
        this.msg = "今回の回収は3,000円(税込)で承ります";
      } else {
        this.msg = "今回の回収は無料でご利用いただけます";
      }
    },
  },
  methods: {
    inputAddress(zipcode: string) {
      const address_list = this.address_list || [];
      const findAddress = address_list.filter(
        (i: any) => i.zipcode.replace("-", "") == zipcode
      );
      this.$store.dispatch("collection/resetAddressBook");
      this.$store.dispatch(
        "collection/inputAddress",
        findAddress.length > 0 ? findAddress[0]["address_02"] : ""
      );
      this.$store.dispatch(
        "collection/inputCity",
        findAddress.length > 0 ? findAddress[0]["address_01"] : ""
      );

      if (zipcode !== "上記以外の住所") {
        this.changeMessage(0);
        this.$store.dispatch("collection/inputZipcode", zipcode);
      } else {
        this.classModalName = "";
        this.$store.dispatch("collection/inputDatetime", "");
        this.$store.dispatch("collection/inputTime", "");
      }
    },
  },
  created() {
    const store = useStore();
    if (store.state.collection.free_or_paid == "paid") {
      this.msg = "今回の回収は3,000円(税込)で承ります";
    }
  },
});
