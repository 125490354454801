import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3162cdb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-block" }
const _hoisted_2 = { class: "select-wrapper" }
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: "-1",
  value: ""
}
const _hoisted_5 = ["value", "innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_label, { title: _ctx.title }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("select", {
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateItem && _ctx.updateItem(...args))),
        value: _ctx.selectedItem
      }, [
        (_ctx.placeholder)
          ? (_openBlock(), _createElementBlock("option", _hoisted_4, _toDisplayString(_ctx.placeholder), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: item.value,
            innerHTML: item.label
          }, null, 8, _hoisted_5))
        }), 128))
      ], 40, _hoisted_3)
    ]),
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.errorMsg), 1))
      : _createCommentVNode("", true)
  ]))
}