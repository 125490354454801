
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import useRouteTo from "../../js/useRouteTo";
import { useRoute } from "vue-router";
import SelectDatetimeDesktop from "./partials/SelectDatetimeDesktop.vue";
import SelectDatetimeMobile from "./partials/SelectDatetimeMobile.vue";
import apis from "@/js/apis";

export default defineComponent({
  components: { SelectDatetimeDesktop, SelectDatetimeMobile },
  setup() {
    const route = useRoute();
    const { routeTo } = useRouteTo();
    const store = useStore();
    const availableDate = ref([] as any);
    const goNext = async () => {
      // call api
      const body = {
        ukt_no: localStorage.getItem("ukt_no") as string,
        address_id: store.state.delivery.item.address_id,
        original_delivery_date: store.state.delivery.item.delivery_date,
        original_delivery_time: store.state.delivery.item.delivery_time,
        new_delivery_date: store.state.delivery.delivery_date,
        delivery_time: store.state.delivery.delivery_time,
        is_autolock: store.state.delivery.is_autolock,
        delivery_option: store.state.delivery.placement || 0,
        request: store.state.delivery.request.filter((el: any) => el != "0"),
      };

      if (store.state.device === "mobile") {
        store.dispatch("startLoading", true);
      }

      const res = await apis.callAPI(
        "put",
        apis.endpoints.updateDelivery.replace(
          "{}",
          store.state.delivery.contract_id
        ),
        store.state.jwt,
        body
      );
      if (res && res.data.status === "success") {
        // clear store
        store.dispatch("delivery/reset");
        routeTo("DeliverySuccess", route.query);
      }
    };
    onMounted(async () => {
      const item = store.state.delivery.item;
      // check if item is in store
      if (!Object.keys(item).length) {
        routeTo("DeliveryListItem", route.query);
      } else {
        // get available datetime
        const res = await apis.getAvailableDate(
          "delivery",
          store.state.delivery.contract_id,
          item.zipcode,
          item.address_id,
          item.is_ekoraku,
          item.delivery_date
        );
        if (res) {
          if (res === "no_available_date") {
            console.log("no_available_date");
            availableDate.value = [];
            // alert("当システムでご予約可能な日付がございません");
          } else {
            availableDate.value = res.available_datetime;
          }
        } else {
          availableDate.value = [];
        }
      }
    });
    return {
      goNext,
      availableDate,
    };
  },
});
