// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/mypage/artbot_20220421.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".balloon-container[data-v-200eafe7]{position:absolute;top:0;right:21vw}.balloon-main[data-v-200eafe7]{width:60vw;height:7.2rem;border-radius:60%;background-color:#fff;box-shadow:0 0 2rem rgba(0,0,0,.161);display:flex;align-items:center;justify-content:center;margin-left:33.9vw;padding:0 2rem;position:relative}.balloon-main[data-v-200eafe7]:before{content:\"\";position:absolute;bottom:-.3rem;left:18%;transform:rotate(30deg);border-top:1.2rem solid #fff;border-right:.7rem solid transparent;border-left:.6rem solid transparent}span[data-v-200eafe7]{display:inline-block;font-size:1.6rem;line-height:1.8rem;font-weight:700}.pc .balloon-container[data-v-200eafe7]{right:auto;margin:auto;position:relative;display:flex;justify-content:center;height:100%;align-items:center}.pc .balloon-main[data-v-200eafe7]{border-radius:20px;width:78.6rem;display:flex;color:#555;margin-left:12rem}.pc .balloon-main[data-v-200eafe7]:before{left:-.9rem;bottom:auto;transform:rotate(114deg)}.pc .balloon-main[data-v-200eafe7]:after{content:\"\";position:absolute;height:12.8rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:65%;width:12.8rem;left:-12rem}.pc .balloon-main .balloon-label[data-v-200eafe7]{background:#e5eff8;border-radius:4px;font-size:1.4rem;padding:.5rem 1.6rem;margin-right:1rem}.pc .balloon-main .balloon-link[data-v-200eafe7]{background:#e5eff8;border-radius:16px;color:#555;font-weight:500;font-size:1.2rem;padding:.4rem 1.2rem;margin-left:1rem}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
