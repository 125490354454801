
import ReservedView from "@/components/goods/ReservedView.vue";
import BaseButton from "@/components/BaseButton.vue";

import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { ReservedView, BaseButton },
  emits: ["click", "clickDelete"],
  props: {
    bookedGoods: {
      type: Object,
      required: true,
    },
    reserveFlag: {
      type: Boolean,
      default: false
    },
    deleteFlag: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      popupMessage: ""
    }
  },
  watch: {
    "deleteFlag": function (newValue) {
      if (newValue) {
        this.popupMessage = '商品のご予約を削除しました'
      }
    },
    "reserveFlag": function (newValue) {
      if (newValue) {
        this.popupMessage = 'お引越し当日にお届けする商品のご予約を承りました'
      }
    }
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.state.goods.selected = "two";
    });
  },
});
