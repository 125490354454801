import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "furniture" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Steps = _resolveComponent("Steps")!
  const _component_ConfirmationView = _resolveComponent("ConfirmationView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Steps, { items: _ctx.items }, null, 8, ["items"]),
    _createVNode(_component_ConfirmationView, {
      dataResult: _ctx.furniture,
      success: true,
      buttonTitle: "送信",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
    }, null, 8, ["dataResult"])
  ]))
}