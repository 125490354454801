import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-527de632"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "goods pc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_GoodsTabDesktop = _resolveComponent("GoodsTabDesktop")!
  const _component_ReservedView = _resolveComponent("ReservedView")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Logo, {
      hasLink: true,
      hasMenu: true
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_GoodsTabDesktop, { target: "reserved" }),
      _createVNode(_component_ReservedView, {
        msg: _ctx.popupMessage,
        bookedGoods: _ctx.bookedGoods,
        onClickDelete: _cache[0] || (_cache[0] = (id) => _ctx.$emit('clickDelete', id))
      }, null, 8, ["msg", "bookedGoods"]),
      _createVNode(_component_base_button, {
        width: "34rem",
        buttonText: "商品を追加する",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click')))
      })
    ]),
    _createVNode(_component_Footer, { hasLink: true })
  ]))
}