import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bcace92"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.classNames),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
      disabled: _ctx.disabled
    }, _toDisplayString(_ctx.buttonText), 11, _hoisted_1)
  ], 4))
}