import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_message_block_from_server = _resolveComponent("error-message-block-from-server")!
  const _component_tabs = _resolveComponent("tabs")!
  const _component_transition_block = _resolveComponent("transition-block")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_error_message_block_from_server),
    (_ctx.$store.state.device == 'mobile')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_Transition, { name: _ctx.goodsTransition }, {
            default: _withCtx(() => [
              (_ctx.goods)
                ? (_openBlock(), _createBlock(_component_tabs, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["name"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_transition_block)
  ]))
}