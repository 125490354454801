
import { computed, defineComponent, ref } from "vue";
import util from "../../js/util";

export default defineComponent({
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    width: {
      type: String,
      default: "100%",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
    },
    allowHankaku: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const enteredText = ref("");
    const inputValue = (v: Event) => {
      const target = v.target as HTMLInputElement;
      enteredText.value = props.allowHankaku
        ? target.value
        : util.allowHankaku(target.value);
      target.value = enteredText.value;
      emit("input", target.value);
    };

    const style = computed(() => {
      return {
        width: props.width,
      };
    });
    return {
      style,
      inputValue,
    };
  },
});
