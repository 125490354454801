import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c18beb82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clearfix full-height" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_text_block = _resolveComponent("text-block")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_container_block = _resolveComponent("container-block")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Logo, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$store.state.device === 'mobile')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_text_block, { text: "パスワードが変更されました。" }),
            (_ctx.type === 'reset')
              ? (_openBlock(), _createBlock(_component_base_button, {
                  key: 0,
                  buttonText: "ログインへ進む",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.routeTo('Login')))
                }))
              : (_openBlock(), _createBlock(_component_base_button, {
                  key: 1,
                  buttonText: "マイページへ戻る",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.routeTo('MyPage')))
                }))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.type === 'reset')
              ? (_openBlock(), _createBlock(_component_container_block, {
                  key: 0,
                  text: "パスワードが変更されました。",
                  width: "26.55vw",
                  buttonText: "ログインへ進む",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.routeTo('Login')))
                }))
              : (_openBlock(), _createBlock(_component_container_block, {
                  key: 1,
                  text: "パスワードが変更されました。",
                  width: "26.55vw",
                  buttonText: "マイページへ戻る",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.routeTo('MyPage')))
                }))
          ]))
    ]),
    (_ctx.$store.state.device === 'pc')
      ? (_openBlock(), _createBlock(_component_Footer, {
          key: 1,
          className: "mt"
        }))
      : _createCommentVNode("", true)
  ]))
}