
import apis from "@/js/apis";
import util from "@/js/util";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  setup() {
    const getJapaneseDate = util.getJapaneseDate;
    // const convertNewLine = util.convertNewLine
    const articles = ref([] as any);
    onMounted(async () => {
      articles.value = await apis.getAllNews();
    });
    return {
      getJapaneseDate,
      articles,
      // convertNewLine
    };
  },
});
