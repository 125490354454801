
import Balloon from "@/components/mypage/Balloon.vue";
import BaseLongBox from "@/components/mypage/BaseLongBox.vue";
import DatetimeShortBox from "@/components/mypage/DatetimeShortBox.vue";
import MenuButton from "@/components/mypage/MenuButton.vue";
import PriceDiv from "@/components/mypage/PriceDiv.vue";
import InfoBlock from "@/components/mypage/InfoBlock.vue";
import PriceMobile from "./PriceMobile.vue";
import PopupNews from "@/components/mypage/PopupNews.vue";
import TitleBlock from "@/components/mypage/TitleBlock.vue";
import BaseButton from "@/components/BaseButton.vue";
import PriceModal from "./PriceModal.vue";
import { computed, defineComponent, inject, ref, toRefs, watch } from "vue";
import useRouteTo from "@/js/useRouteTo";
import useGoTo from "@/js/useGoTo";
import usePseudoAPICall from "@/js/usePseudoAPICall";
import apis from "@/js/apis";
import util from "@/js/util";
import openAQC from "@/js/openAQC";

export default defineComponent({
  components: {
    Balloon,
    DatetimeShortBox,
    BaseLongBox,
    PriceDiv,
    MenuButton,
    InfoBlock,
    PriceMobile,
    PopupNews,
    TitleBlock,
    BaseButton,
    PriceModal
  },
  props: {
    currentInfo: {
      type: Object,
      required: true,
    },
    done: {
      type: Boolean,
      required: true,
    },
    newestNews: {
      type: Object,
      required: true,
    },
    aqcQuestionnaire: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["click"],
  watch: {
    aqcQuestionnaire: function (val) {
      if (val) {
        const openHelp = () => {
          openAQC()
        };
        util.openAlert(this.$store, "アンケートに回答する", "", "※既にアンケートの入力がお済みの場合は回答不要です。", openHelp, "アンケートのお願い", true);
      }
    },
  },
  setup(props) {
    const { routeTo } = useRouteTo();
    const { goTo } = useGoTo();
    const { pseudoAPICall } = usePseudoAPICall();
    const wording = inject("wording");
    const accordion = ref(false);
    const transformOrigin = ref("50% 50%");

    const isPriceModalVisible = ref(false);
    const togglePriceModal = (value: boolean) => {
      isPriceModalVisible.value = value
    }

    const { newestNews } = toRefs(props);

    const articles = ref([] as any);
    const newestNewsId = ref("");
    const isModalVisible = ref(false);
    const closeModal = async () => {
      // call API to update is_read flag
      const res = await apis.updateNewsFlag(newestNewsId.value);
      if (res) {
        isModalVisible.value = false;
      }
    };
    const clickNews = async () => {
      // call API to update is_read flag
      const res = await apis.updateNewsFlag(newestNewsId.value);
      if (res) {
        routeTo("NewsList");
      }
    };
    const datetimeText = computed(() => {
      if (props.done) {
        return "引越し完了";
      } else {
        return "お引越作業日";
      }
    });
    const computeBGColor = computed(() => {
      if (props.done) {
        return "#555555";
      } else {
        return "#ffffff";
      }
    });
    const loadingRoute = (name: string) => {
      // API Call -> GET data
      // For now, pseudo api call
      pseudoAPICall(name);
    };
    const expand = (event: MouseEvent) => {
      transformOrigin.value = "50% " + event.clientY + "px";
      accordion.value = true;
      // routeTo(route);
    };
    const loadingExpand = (event: any, route: string) => {
      setTimeout(() => {
        expand(event);
        setTimeout(() => {
          pseudoAPICall(route);
        }, 200);
      }, 100);
    };
    const style = computed(() => {
      return {
        "transform-origin": transformOrigin.value,
        height: document.body.scrollHeight + "px",
      };
    });
    watch(newestNews, () => {
      if (Object.keys(newestNews.value).length) {
        // if not read yet, then show it
        articles.value.push(newestNews.value);
        newestNewsId.value = newestNews.value.id;
        isModalVisible.value = true;
      }
    });
    return {
      routeTo,
      goTo,
      wording,
      accordion,
      isModalVisible,
      articles,
      closeModal,
      clickNews,
      datetimeText,
      computeBGColor,
      loadingRoute,
      expand,
      loadingExpand,
      style,
      togglePriceModal,
      isPriceModalVisible
    };
  },
});
