import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-200eafe7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "balloon-container" }
const _hoisted_2 = { class: "balloon-main" }
const _hoisted_3 = {
  key: 0,
  class: "balloon-label"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$store.state.device === 'pc' && _ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_4)
    ])
  ]))
}