
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    month: {
      type: String,
      default: "",
    },
    day: {
      type: String,
      default: "",
    },
    year: {
      type: String,
      default: "",
    },
    week: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "",
    },
    noDelivery: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "お届け予定日",
    },
  },
  setup(props) {
    const fullDate = computed(() => {
      let val = `${props.month}/${props.day}`;
      if (props.year) val = `${props.year}/${val}`;
      return val;
    });
    const style = computed(() => {
      return {
        height: props.height || "",
        padding: props.padding || "",
      };
    });
    return {
      fullDate,
      style,
    };
  },
});
