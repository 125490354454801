import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReservedMobile = _resolveComponent("ReservedMobile")!
  const _component_ReservedDesktop = _resolveComponent("ReservedDesktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_ReservedMobile, {
          key: 0,
          bookedGoods: _ctx.bookedGoods,
          mode: _ctx.mode,
          reserveFlag: _ctx.reserveFlag,
          deleteFlag: _ctx.deleteFlag,
          onClick: _ctx.click,
          onClickDelete: _ctx.clickDelete
        }, null, 8, ["bookedGoods", "mode", "reserveFlag", "deleteFlag", "onClick", "onClickDelete"]))
      : (_openBlock(), _createBlock(_component_ReservedDesktop, {
          key: 1,
          bookedGoods: _ctx.bookedGoods,
          mode: _ctx.mode,
          reserveFlag: _ctx.reserveFlag,
          deleteFlag: _ctx.deleteFlag,
          onClick: _ctx.click,
          onClickDelete: _ctx.clickDelete
        }, null, 8, ["bookedGoods", "mode", "reserveFlag", "deleteFlag", "onClick", "onClickDelete"]))
  ]))
}