import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-491cb324"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: "-1",
  value: ""
}
const _hoisted_4 = ["value", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("select", {
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateItem && _ctx.updateItem(...args))),
      value: _ctx.selectedItem
    }, [
      (_ctx.placeholder)
        ? (_openBlock(), _createElementBlock("option", _hoisted_3, _toDisplayString(_ctx.placeholder), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: index,
          value: item.value,
          innerHTML: item.label
        }, null, 8, _hoisted_4))
      }), 128))
    ], 40, _hoisted_2)
  ]))
}