import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListingMobile = _resolveComponent("ListingMobile")!
  const _component_ListingDesktop = _resolveComponent("ListingDesktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.device === 'mobile')
      ? (_openBlock(), _createBlock(_component_ListingMobile, {
          key: 0,
          recommendedGoods: _ctx.recommendedGoods,
          allGoods: _ctx.allGoods,
          onClick: _ctx.click
        }, null, 8, ["recommendedGoods", "allGoods", "onClick"]))
      : (_openBlock(), _createBlock(_component_ListingDesktop, {
          key: 1,
          recommendedGoods: _ctx.recommendedGoods,
          allGoods: _ctx.allGoods,
          onClick: _ctx.click
        }, null, 8, ["recommendedGoods", "allGoods", "onClick"]))
  ]))
}