import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d036ce86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pc-container container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_block = _resolveComponent("title-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_title_block, {
      title: _ctx.title,
      text: _ctx.text,
      buttonText: _ctx.buttonText,
      width: _ctx.width,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
    }, null, 8, ["title", "text", "buttonText", "width"]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}