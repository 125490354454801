
import BaseButton from "@/components/account/BaseButton.vue";
import ContainerBlock from "@/components/account/ContainerBlock.vue";
import TextBlock from "@/components/account/TextBlock.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import useRouteTo from "@/js/useRouteTo";
import { defineComponent } from "vue";

export default defineComponent({
  components: { TextBlock, ContainerBlock, BaseButton, Logo, Footer },
  props: {
    type: {
      type: String,
      default: "reset",
    },
  },
  setup() {
    const { routeTo } = useRouteTo();
    return {
      routeTo,
    };
  },
});
