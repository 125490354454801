import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12fabc42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add-box hover-shining" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_exclamation_icon = _resolveComponent("exclamation-icon")!
  const _component_NotifyPopup = _resolveComponent("NotifyPopup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_exclamation_icon, { className: "white" }),
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showModal && _ctx.showModal(...args))),
        class: "text pointer"
      }, _toDisplayString(_ctx.text), 1)
    ]),
    _withDirectives(_createVNode(_component_NotifyPopup, { onClose: _ctx.closeModal }, null, 8, ["onClose"]), [
      [_vShow, _ctx.isModalVisible]
    ])
  ]))
}