
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    target: {
      type: String,
      reuired: true,
    },
  },
  setup(props) {
    const info = ref(false);
    const price = ref(false);
    onMounted(() => {
      if (props.target === "info") {
        info.value = true;
      } else {
        price.value = true;
      }
    });
    return {
      info,
      price,
    };
  },
});
