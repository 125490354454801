
import ConfirmationView from "@/components/furniture/desktop/ConfirmationView.vue";
import Steps from "@/components/furniture/Steps.vue";
import Logo from "@/components/Logo.vue";
import Footer from "@/components/Footer.vue";

import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import useRouteTo from "../../../js/useRouteTo";
import usePseudoAPICall from "../../../js/usePseudoAPICall";

export default defineComponent({
  components: { ConfirmationView, Steps, Logo, Footer },
  data: function () {
    return {
      items: [
        { label: "1. お申込み内容のご入力", class: "current" },
        { label: "2. 入力内容のご確認", class: "current" },
        { label: "3. お申し込み完了" },
      ],
      furniture: {},
    };
  },
  emits: ["click"],
  created() {
    const store = useStore();
    this.furniture = reactive({ ...store.state.furniture });
  },
});
