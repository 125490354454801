<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="グループ_1425" data-name="グループ 1425" transform="translate(-41 -351)">
        <rect id="長方形_113" data-name="長方形 113" width="20" height="20" rx="2" transform="translate(41 351)" fill="#0060b9"/>
        <text id="Password" transform="translate(44 367)" fill="#fff" font-size="14" font-family="YuGothic-Bold, Yu Gothic" font-weight="700"><tspan x="0" y="0">￥</tspan></text>
      </g>
    </svg>
</template>

<script>
export default {
    name: 'YenIcon',
}
</script>

<style scoped>
</style>
