import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af059e2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progressbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.computeClass)
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(item.class),
          key: item.label,
          style: _normalizeStyle(_ctx.styleWidth)
        }, [
          _createElementVNode("span", null, _toDisplayString(item.label), 1)
        ], 6))
      }), 128))
    ])
  ], 2))
}