import store from "@/store";

export default function () {
  // check if device is mobile or pc
  if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    store.dispatch("initDevice", "mobile");
  } else {
    store.dispatch("initDevice", "pc");
  }
}
