
import ContactDetail from "@/components/confirmation/ContactDetail.vue";
import Steps from "@/components/furniture/Steps.vue";
import { defineComponent, inject } from "vue";

export default defineComponent({
  components: { ContactDetail, Steps },
  props: {
    detail: {
      type: Object,
    },
  },
  data: function () {
    return {
      items: [
        { label: "基本情報", class: "current" },
        { label: "作業内容" },
        { label: "料金確認" },
        { label: "完了" },
      ],
    };
  },
  setup() {
    const clause = inject("clause") as any;
    return {
      clause,
    };
  },
});
