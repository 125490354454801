
import apis from "@/js/apis";
import useCollectionInit from "@/js/useCollectionInit";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import BookingDesktop from "./partials/BookingDesktop.vue";
import BookingMobile from "./partials/BookingMobile.vue";

export default defineComponent({
  components: { BookingMobile, BookingDesktop },
  setup() {
    const store = useStore();
    const route = useRoute();
    const free_or_paid = ref("");
    const address_list = ref([] as any);
    onMounted(async () => {
      // check if info is already in store
      free_or_paid.value = store.state.collection.free_or_paid;
      if (!free_or_paid.value) {
        await useCollectionInit();
        free_or_paid.value = store.state.collection.free_or_paid;
      }

      // get user's address and tel
      const contract_id = store.state.collection.contract_id;
      const detail = await apis.getContractDetail(contract_id);
      if (detail && detail.ukt_no) {
        // just in case, save ukt_no
        localStorage.setItem("ukt_no", detail.ukt_no);
        // emit event to notify ukt_no change
        window.dispatchEvent(
          new CustomEvent("ukt_no-localstorage-changed", {
            detail: {
              ukt_no: detail.ukt_no,
            },
          })
        );

        // address
        (detail.moving_info_list || []).forEach(
          async (moving_info: any, i: number) => {
            (moving_info.new_address_info_list || []).forEach(
              async (new_address: any, j: number) => {
                let item: any = {
                  zipcode: new_address.zipcode,
                  address_01: new_address.address_01,
                  address_02: new_address.address_02,
                  address_code: new_address.address_code,
                  branch_info: new_address.branch_info,
                };

                address_list.value.push(item);
              }
            );
          }
        );
        store.dispatch("collection/saveAddressList", address_list.value);
        store.dispatch("collection/saveAddressBranch", detail.arrival_branch);

        // tel
        store.dispatch("collection/saveTelList", detail.tel_list);
      }

      // reset store
      // store.dispatch("collection/resetCreate");
    });
    return {
      free_or_paid,
      address_list,
    };
  },
});
