
import Steps from "@/components/furniture/Steps.vue";
import BookingView from "@/components/furniture/BookingView.vue";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import Modal from "@/components/Modal.vue";
import useRouteTo from "../../../js/useRouteTo";
import FurnitureAddressMixin from "@/mixins/furnitureAddress.vue";

export default defineComponent({
  components: { Steps, BookingView, Modal },
  mixins: [FurnitureAddressMixin],
  data: function () {
    return {
      items: [
        { label: "住所選択", class: "current" },
        { label: "日程選択" },
        { label: "内容確認" },
        { label: "申込完了" },
      ],
      msg: "お申し込み頂いた後、お電話にて必ず最終確認させていただきます。",
    };
  },
  setup() {
    const store = useStore();
    const { routeTo } = useRouteTo();
    const hideModal = () => {
      
      store.dispatch("furniture/setAddressDone", true);
      routeTo("BookingScheduleFurniture");
    };
    return {
      hideModal,
    };
  },
  methods: {
    inputAddress(zipcode: string) {
      const state: any = this.$store.state
      const address_list = state.furniture.address_list;
      const findAddress = address_list.filter(
        (i: any) => i.zipcode.replace("-", "") == zipcode
      );
      this.$store.dispatch("furniture/resetAddressBook");
      this.$store.dispatch(
        "furniture/inputAddress",
        findAddress.length > 0 ? findAddress[0]["address_02"] : ""
      );
      this.$store.dispatch(
        "furniture/inputCity",
        findAddress.length > 0 ? findAddress[0]["address_01"] : ""
      );

      if (zipcode !== "上記以外の住所") {
        this.changeMessage(0);
        this.$store.dispatch("furniture/inputZipcode", zipcode);
      } else {
        this.classModalName = "";
        this.$store.dispatch("furniture/inputDatetime", "");
      }
    },
    redirect(result: any) {
      if (result.zipcode) {
        this.classModalName = "active";
      }
    },
  },
});
